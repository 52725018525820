$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
// src/assets/scss/common/modal/addChildModal.module.scss
.closeBtnContainer {
  display: flex;
  width: 500px; // 고정 너비
  height: 50px; // 고정 높이
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  background: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);
  min-height: 40px; // 최소 높이를 고정
  box-sizing: border-box; // 패딩과 테두리를 포함하여 너비와 높이 계산
  overflow: hidden; // 내용이 넘칠 경우 숨김
}

.addChildModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.addChildInfoModal {
  background-color: #fff;
  width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.innerModalContainer {
  padding: 30px 20px;
  background: #fbfbff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);

  height: 800px;
  overflow-y: auto;
}

.addChildInfoTitle {
  color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);

  /* 💻Desktop/SubTitle/ST_Bold */
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.36px;

  margin-bottom: 20px;
}

.addChildInfoDesc {
  margin-top: 30px;
  margin-bottom: 20px;

  color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);

  /* 💻Desktop/Caption/C_Bold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
  .boldDesc {
    font-weight: 700;
  }
}

.innerTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  display: flex;
  width: 460px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-end;
  // gap: 20px;

  border-radius: 20px;
  border: 1px solid #e5e5ef;
  background: var(--GrayScale-W-FFFFFF, #fff);

  margin-bottom: 20px;
}

.infoContainer {
  width: 100%;
  // margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .infoText {
    margin-top: 10px;
    margin-bottom: 8px;

    color: var(--K-PASS-KPnavy-500_main, #383649);

    /* 💻Desktop/Body/B_Regular */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.3px;

    .requireText {
      color: red;
      margin-right: 6px;
    }

    .optionalText {
      color: #7762df;
      margin-right: 6px;
    }
  }
}

.infoTitleText {
  color: var(--K-PASS-KPnavy-500_main, #383649);

  /* 💻Desktop/Body/B_Bold */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;

  margin-bottom: 20px;
}

.inputField {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  // margin-top: 8px;
  background-color: transparent;

  color: var(--K-PASS-KPnavy-500_main, #383649);

  /* 💻Desktop/Body/B_Regular */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;

  &:focus {
    outline: none;
    border-bottom-color: #007bff; // 하단 밑줄 색상 변경
  }

  &::placeholder {
    color: var(--GrayScale-G-200, #c4c4c4);

    /* 💻Desktop/Body/B_Regular */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.3px;
  }
}

.genderButtons {
  display: flex;
  gap: 10px;
}

.genderBtn {
  display: flex;
  width: 190px;
  height: 54px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid var(--GrayScale-G-100, #e1e1e1);
  background: var(--GrayScale-white, #fff);

  color: var(--GrayScale-G-500_subtext, #6b6b6b);

  /* 💻Desktop/Body/B_Bold */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;
  &:hover {
    background: var(--K-PASS-KPblue-500_main, #583be2);
    color: var(--GrayScale-white, #fff);
  }
}

.selectedGender {
  background: var(--K-PASS-KPblue-500_main, #583be2);
  color: var(--GrayScale-white, #fff);
}

.addressBtn {
  flex-shrink: 0;

  display: flex;
  width: 140px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--GrayScale-G-100, #e1e1e1);
  background: var(--GrayScale-white, #fff);

  color: var(--GrayScale-G-500_subtext, #6b6b6b);

  /* 💻Desktop/Body/B_Bold */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;

  input {
    margin-right: 8px;
  }
}

.submitBtn {
  display: flex;
  width: 220px;
  height: 66px;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);

  margin: 50px auto 0 auto;

  color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  text-align: center;

  /* 💻Desktop/Body/B_Bold */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;

  &:hover {
    color: white;
    background-color: #583be2;
  }
}

.checkboxIcon {
  margin-right: 8px;

  position: relative;
  right: -215px;
}

.sameAsCountry {
  position: relative;
  right: -215px;
}

.selectContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .inputField {
    width: 100%;
    padding-right: 30px; // 아이콘을 위한 패딩 추가
  }

  .downIcon {
    position: absolute;
    right: 10px;
    pointer-events: none; // 아이콘을 클릭할 수 없도록 설정
  }
}

.addressContainer {
  display: flex;
  gap: 20px; // Maintain the desired gap
  margin-top: 8px;

  .inputField {
    flex: 1; // Allow input to take available space
  }

  .addressBtn {
    width: 140px;
    flex-shrink: 0; // Prevent shrinking of the button
    margin-left: auto; // Push the button to the right
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--GrayScale-G-100, #e1e1e1);
    background: var(--GrayScale-white, #fff);
    color: var(--GrayScale-G-500_subtext, #6b6b6b);

    /* 💻Desktop/Body/B_Bold */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.3px;

    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.postcodeModal {
  position: absolute;
  // display: none;

  justify-content: center;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  position: absolute;
  background-color: #ffffff;
  width: 600px;
  height: 400px;
}

.postcodeModal.hidden {
  display: none;
}

.errorText {
  color: var(--Basic-Bred-500_main, #ff2c45);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  margin-top: 8px;
}

.profileContainer {
  position: relative;

  height: 41px;
  margin-bottom: 30px;
}

.removeProfileBtn {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  border: 1px solid var(--GrayScale-G-700_bodytext, #404040);

  color: var(--GrayScale-G-700_bodytext, #404040);
  text-align: center;

  /* Mobile/Caption/C_Bold */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
  letter-spacing: -0.21px;

  position: absolute;
  right: 0;
}

// paymentInfoModal

.paymentInnerModalContainer {
  padding: 30px 20px;
  background: #fbfbff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);

  height: 800px;
  overflow-y: auto;
}

.paymentTime {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
}

.timeText {
  color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);
  text-align: right;

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

.paymentResult {
  color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);
  text-align: right;

  /* 💻Desktop/Caption/C_Bold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

.innerTextPaymentContainer {
  display: flex;
  width: 460px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 20px;
  border: 1px solid #e5e5ef;
  background: var(--GrayScale-W-FFFFFF, #fff);
}

.innerTextTitle {
  color: var(--K-PASS-KPnavy-500_main, #383649);

  /* 💻Desktop/Body/B_Bold */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;
}

.innerTextDesc {
  color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  margin-top: 20px;
  margin-bottom: 20px;
}

.productImg {
  width: 84px;
  height: 84px;
  border-radius: 6px;
  border: 1px solid var(--GrayScale-G5-dedede, #dedede);
}

.productContainer {
  display: flex;
}

.productInfoContainer {
  margin-left: 20px;
}

.productName {
  color: var(--K-PASS-KPnavy-500_main, #383649);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

.productCount {
  color: var(--GrayScale-G2-757575, #757575);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

.productPrice {
  color: var(--K-PASS-KPnavy-500_main, #383649);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  margin-top: 12px;
}

.paymentMethodTextContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.totalAmount {
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: var(--K-PASS-KPnavy-500_main, #383649);
  text-align: right;

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  margin-bottom: 8px;
}

.discountAmount {
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: var(--K-PASS-KPpink-600, #e60063);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  margin-bottom: 8px;
}

.productDiscount {
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: var(--GrayScale-G-500_subtext, #6b6b6b);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  margin-bottom: 8px;
}

.couponDiscount {
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: var(--GrayScale-G-500_subtext, #6b6b6b);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  margin-bottom: 8px;
}

.codeDiscount {
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: var(--GrayScale-G-500_subtext, #6b6b6b);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;

  margin-bottom: 20px;
}

.totalPaymentAmount {
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: var(--K-PASS-KPnavy-500_main, #383649);

  /* 💻Desktop/Body/B_Bold */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;

  margin-top: 20px;
}

.paymentDescContainer {
  li {
    list-style-type: disc;
    margin-left: 15px;

    color: var(--GrayScale-G-500_subtext, #6b6b6b);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;
  }
  .noDisc {
    list-style-type: none;
  }
}

.paymentDescription {
  color: var(--K-PASS-KPnavy-500_main, #383649);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.48px;
}

.vBankTextContainer {
  display: flex; /* Use flexbox layout */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  gap: 8px; /* Adjust spacing between elements */
}

.vBankText {
  color: var(--K-PASS-KPblue-500_main, #583be2);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.48px;
}

.awaitingDeposit {
  color: var(--K-PASS-KPblue-500_main, #583be2);
}

.cancelled {
  color: var(--K-PASS-KPpink-600, #e60063);
}

.paymentInnerModalContainer::-webkit-scrollbar {
  display: none;
}

.innerModalContainer::-webkit-scrollbar {
  display: none;
}

.consultInputTitle {
  color: var(--K-PASS-KPnavy-500_main, #383649);

  /* 💻Desktop/Body/B_Regular */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;
}

.consultInputContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-picker-container {
  display: flex;
  flex-direction: column;
  margin: 20px;

  .date-label {
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
  }

  .date-input-wrapper {
    position: relative;
    display: inline-block;

    .custom {
      width: 100%;
      padding: 12px 16px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: #fff;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      color: #333;

      &:hover {
        border-color: #888;
      }

      &:focus {
        outline: none;
        border-color: #5c9ded;
        box-shadow: 0 0 5px rgba(92, 157, 237, 0.5);
      }

      &::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
}

.customDateInput {
  width: 200px;
  height: 46px;
  padding: 12px 20px;
  border: 1px solid rgb(222, 222, 222);
  color: rgb(117, 117, 117);
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: -0.03em;
}

.textareaInputContainer {
  // margin-top: 20px;
}

.textareaInput {
  width: 400px;
  height: 123px;

  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border: 1px solid var(--GrayScale-G5-dedede, #dedede);
  background: var(--GrayScale-W-FFFFFF, #fff);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.42px;

  &::placeholder {
    color: var(--GrayScale-G4-C6C6C6, #c6c6c6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.42px;
  }
}
.cancelBtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.cancelBtn {
  border-radius: 20px;
  border: 1px solid var(--GrayScale-G-700_bodytext, #404040);

  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
}

.lineText {
  color: var(--GrayScale-G-700_bodytext, #404040);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.21px;
  text-decoration-line: underline;
}

.purpleText {
  color: var(--K-PASS-KPblue-500_main, #583be2);

  /* 📱Mobile/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.21px;
}

.redText {
  color: var(--Basic-Bred-500_main, #ff2c45);

  /* 📱Mobile/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.21px;
}

.innerTextContainerDesc {
  color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);

  /* 📱Mobile/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.21px;

  margin-top: 15px;
}

.starContainer {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;

  img {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.aboutConsultantContainer {
  display: flex;
  padding: 20px 30px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border: 1px solid var(--GrayScale-G5-dedede, #dedede);
  background: var(--GrayScale-W-FFFFFF, #fff);

  color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);

  /* 💻Desktop/Caption/C_Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

.imgContainer {
  width: 100%;
  display: flex;
  justify-content: center; // 가로 방향으로 가운데 정렬
  align-items: center; // 세로 방향으로 가운데 정렬
}
.imgBorderContainer {
  border-radius: 20px;
  border: 1px solid var(--GrayScale-G5-dedede, #dedede);
  background: var(--GrayScale-W-FFFFFF, #fff);
  overflow: hidden;

  width: 300px;
  height: 300px;
  padding: 8px;
}

.consultantImg {
  display: block;
  max-width: 100%; // 이미지를 부모 크기에 맞게 조정
  height: auto; // 이미지 비율 유지

  width: 284px;
  height: 284px;
  border-radius: 20px;
}

@include tablet {
  .closeBtnContainer {
    height: 40px;
    padding: 7px 10px;
  }

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .innerModalContainer {
    padding: 20px 20px 30px 20px;
  }

  .addChildInfoTitle {
    font-size: 20px;
  }

  .addChildInfoDesc {
    font-size: 14px;
    margin-top: 20px;
  }

  .errorText {
    font-size: 14px;
  }

  .infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .infoText {
      margin-top: 10px;
      margin-bottom: 8px;
      font-size: 16px;

      .requireText {
      }

      .optionalText {
      }
    }
  }

  .inputField {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 16px;

    &:focus {
    }

    &::placeholder {
      font-size: 16px;
    }
  }
  .genderBtn {
    width: 170px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid var(--GrayScale-G-100, #e1e1e1);
    font-size: 16px;

    &:hover {
    }
  }
  .genderButtons {
    width: 100%;
    justify-content: center;
  }
  .addressContainer .addressBtn {
    font-size: 16px;
  }

  .innerTextContainer {
    gap: 15px;
    margin-bottom: 20px;
    padding: 20px 30px;
  }
  .submitBtn {
    margin: 40px auto 0;
  }
  .paymentInnerModalContainer {
    padding: 20px 20px 30px 20px;
  }
  .paymentTime {
    margin-top: 20px;
  }
  .innerTextPaymentContainer {
    padding: 20px 30px;
    gap: 15px;
    margin-top: 20px !important;
  }
  .codeDiscount {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .timeText,
  .paymentResult,
  .productName,
  .productCount,
  .productPrice,
  .totalAmount,
  .discountAmount,
  .productDiscount,
  .couponDiscount,
  .codeDiscoun,
  .paymentDescription {
    font-size: 14px;
  }
  .innerTextTitle,
  .totalPaymentAmount {
    font-size: 16px;
  }
  .paymentMethodTextContainer {
    font-size: 14px;
  }
}

@include mobile {
  .addChildInfoModal {
    width: 320px;
  }

  .closeBtnContainer {
    width: 320px;
    height: 40px;
    padding: 7px 10px;
  }

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .addChildInfoTitle {
    font-size: 20px;
  }

  .addChildInfoDesc {
    margin-top: 20px;
    font-size: 14px;
  }

  .infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .infoText {
      margin-top: 10px;
      margin-bottom: 8px;
      font-size: 16px;

      .requireText {
      }

      .optionalText {
      }
    }
  }

  .inputField {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 16px;

    &:focus {
    }

    &::placeholder {
      font-size: 16px;
    }
  }
  .genderBtn {
    width: 170px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid var(--GrayScale-G-100, #e1e1e1);
    font-size: 16px;

    &:hover {
    }
  }
  .genderButtons {
    width: 100%;
    justify-content: center;
  }
  .addressContainer .addressBtn {
    width: 74px;
    height: 44px;
    font-size: 14px;
  }

  .innerTextContainer {
    gap: 15px;
    margin-bottom: 20px;
    width: 280px;
    padding: 20px 10px;
  }
  .checkboxIcon {
    width: 21px;
    height: 21px;
    right: -75px;
  }
  .sameAsCountry {
    right: -75px;
  }
  .errorText {
    font-size: 14px;
  }

  .innerTextPaymentContainer {
    width: 280px;
    // height: 154px;

    display: flex;
    padding: 20px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    border-radius: 15px;
    border: 1px solid #e5e5ef;
    background: var(--GrayScale-W-FFFFFF, #fff);
  }

  .innerTextTitle {
    font-size: 16px;
  }
  .productContainer {
    display: flex;
    align-items: center;
  }
  .productImg {
    max-height: 100%;
    width: 75px;
    height: 75px;
  }
  .productName {
    font-size: 14px;
  }
  .productCount {
    font-size: 14px;
  }
  .productPrice {
    font-size: 14px;
  }
  .innerTextPaymentContainer {
    margin-top: 20px !important;
  }
  .lineImg {
    margin-left: -10px;
    margin-top: -10px;
    margin-bottom: -10px;
    width: 240px;
  }
  .codeDiscount {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .totalPaymentAmount {
    font-size: 16px;
  }
  .paymentMethod {
    font-size: 14px;
  }
  .innerModalContainer {
    padding: 20px 20px 30px 20px;
  }
  .submitBtn {
    width: 180px;
    margin: 40px auto 0;
  }

  .paymentInnerModalContainer {
    padding: 20px 20px 30px 20px;
  }

  .paymentTime {
    margin-top: 20px;
  }

  .paymentDescription {
    font-size: 14px;
  }

  .vBankText {
    font-size: 14px;
  }
  .amountContainer {
    width: 220px;
  }
  .innerTextTitle {
    font-size: 16px;
  }
  .timeText {
    font-size: 14px;
  }
  .paymentResult {
    font-size: 14px;
  }
  .productName {
    font-size: 14px;
  }
  .productCount {
    font-size: 14px;
  }
  .productPrice {
    font-size: 14px;
  }
  .totalAmount {
    font-size: 14px;
  }
  .discountAmount,
  .productDiscount,
  .couponDiscount,
  .codeDiscoun,
  .paymentDescription {
    font-size: 14px;
  }
  .totalPaymentAmount,
  .vBankText {
    font-size: 16px;
  }
}
// end of mobile css
