$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@mixin sectionTitle {
  color: $KPNavy;
  font-weight: bold;

  @include desktop {
    font-size: 2.4rem;
    line-height: 1.4;
    letter-spacing: -0.36px;
  }

  @include tablet {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.3px;

  }

  @include mobile {
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.03em;
  }
}

@mixin desktopCodeDiscountButton {
  display: flex;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.24px;
}

@mixin tabletCodeDiscountButton {
  width: 40px;
  display: flex;
  padding: 4px 8px;
  border-radius: 6px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.21px;
}

@mixin mobileCodeDiscountButton {
  width: 40px;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.21px;
}

@include desktop {
  .mobile {
    display: none !important;
  }

  .orderAndPayment {
    align-items: center;
    background-color: $lightGray;

    > .title {
      margin-top: 214px;
    }

    > .orderAndPaymentWrapper {
      margin-top: 50px;
      width: 1160px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 20px;

      > .orderItemList {
        grid-column: 1 / 3;
        padding: 40px 50px;
        background-color: white;
        border-radius: 20px;

        > .sectionTitle {
          @include sectionTitle;
        }

        > .items {

          margin-top: 20px;
          width: 100%;

          > .tableHeader {

            > .headerRow {

              > .headerCell {
                box-sizing: content-box;
                border-bottom: 2px solid $g5dedede;
                padding-bottom: 14px;
                font-weight: normal;
                font-size: 2rem;
                line-height: 1.5;
                letter-spacing: -0.03em;
                color: $g2757575;
              }
            }
          }

          > .tableBody {
            > .bodyRow {
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.5;
              letter-spacing: -0.03em;
              color: $KPNavy;

              &:last-child {

                > .bodyCell {
                  height: 140px;
                  border-bottom: 0;
                  padding-top: 20px;
                }
              }

              > .bodyCell {
                height: 160px;
                width: 160px;
                text-align: center;
                border-bottom: 2px dashed $g5dedede;

                &.detailCell {
                  width: 420px;

                  > .detailArea {
                    display: flex;
                    align-items: center;

                    > .thumbnail {
                      width: 120px;
                      height: 120px;
                      background-color: #e6e6e6;
                      flex-shrink: 0;
                      border-radius: 3px;

                      > img {
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                      }
                    }

                    > .text {
                      margin-left: 30px;
                      flex: 1;
                      text-align: left;
                    }
                  }
                }

                &.priceAccent {
                  color: $hotpink;
                }
              }
            }
          }
        }
      }

      > .supportPanel {
        padding: 40px 50px;
        background-color: white;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &.codePanel {
          align-self: start;
        }

        > .titleBar {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .sectionTitle {
            @include sectionTitle;
            display: flex;
            align-items: center;
            letter-spacing: -0.36px !important;
          }

          > .couponCount {
            font-size: 2rem;
            line-height: 1.5;
            letter-spacing: -0.03em;
            color: $KPNavy;

            > .count {
              margin-left: 8px;
              font-weight: bold;
            }
          }
        }

        > .inputArea {
          width: 100%;
          padding: 0 10px;
          display: grid;
          grid-template-columns: 398px auto;
          column-gap: 8px;
          row-gap: 10px;
          align-items: center;

          > .codeInput {
            width: 398px;
            padding: 16px 20px;
            border-bottom: 2px solid $e1e1e1;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.3px;
            color: $KPNavy;

            &::placeholder {
              font-weight: normal;
              color: $c4c4c4;
            }

            &:focus, &.hasValue {
              border-bottom-color: $KPNavy;
            }

            &.invalid {
              border-bottom-color: $hotpink;
            }

            &:disabled {
              background-color: $lightGray;
            }
          }

          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.24px;

          > .invalidMessage {
            color: $hotpink;
          }

          > .completedMessage {
            color: $mainColor;
          }

          > .applyButton {
            @include desktopCodeDiscountButton;
            color: white;
            background-color: $navyMedium;
          }

          > .cancelButton {
            @include desktopCodeDiscountButton;
            padding: 4px 7px;
            background-color: white;
            color: $navyMedium;
            border: 1px solid $navyMedium;
          }
        }

        > .selectorArea {
          padding: 0 10px;
          width: 100%;

          > .couponSelector {
            padding: 16px 20px;
            width: 100%;
            height: 62px;
            font-weight: normal;
            font-size: 2rem;
            line-height: 1.5;
            letter-spacing: -0.03em;
            color: $g2757575;

            > option {
              width: 100%;
              height: 62px;
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.5;
              letter-spacing: -0.03em;
              color: $g2757575;
            }
          }
        }
      }

      > .paymentWay {
        background-color: white;
        border-radius: 20px;
        padding: 40px 50px;

        > .sectionTitle {
          @include sectionTitle;
        }

        > .ways {
          margin-top: 20px;
          padding: 0 10px;
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: 1fr 1fr;
          gap: 20px;

          > .payItem {
            width: 215px;
            height: 70px;
            border-radius: 8px;
            border: 1px solid $g5dedede;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            font-weight: normal;
            font-size: 2rem;
            line-height: 1.5;
            letter-spacing: -0.03em;
            color: $g2757575;

            > .text {
              margin-left: 8px;
            }

            &.selected {
              border: 1px solid $KPNavy;
              color: $KPNavy;
              font-weight: bold;
            }
          }
        }
      }

      > .orderedAmountInfo {
        padding: 40px 50px;

        > .sectionTitle {
          @include sectionTitle;
        }

        > .amountDetail {
          padding: 0 30px;

          > .amountList {
            margin: 30px 0;

            > .amountItem {
              margin-bottom: 12px;
              display: flex;
              justify-content: space-between;
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.5;
              letter-spacing: -0.03em;
              color: $fontHeavyLight;

              &.accent {
                color: $hotpink;
              }
            }
          }

          > .totalAmount {
            padding-top: 20px;
            border-top: 2px solid $g4c6c6c6;
            font-weight: bold;
            font-size: 2.4rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            color: $KPNavy;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    > .agreement {
      margin-top: 60px;
      display: flex;
      align-items: center;

      > .checkBtn {
        width: 40px;
        height: 40px;
        background: url("../../../images/sub/purchase_voucher/check_btn_unchecked.svg") no-repeat;

        &.checked {
          background: url("../../../images/sub/purchase_voucher/check_btn_checked.svg") no-repeat;
        }
      }

      > .text {
        margin-left: 20px;
        font-weight: normal;
        font-size: 2rem;
        line-height: 1.5;
        letter-spacing: -0.03em;
        color: $KPNavy;
      }

      > .link {
        margin-left: 10px;
        text-decoration: underline;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::after {
          content: url("../../../images/sub/purchase_voucher/link_icon.svg");
        }
      }
    }

    > .payButton {
      margin: {
        top: 60px;
        bottom: 100px;
      };
      background-color: $mainColor;
      width: 480px;
      height: 84px;
      border-radius: 12px;
      color: white;
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 28px;
    }
  }

  .supportWrapper {
    position: relative;

    .supportButton {
      display: flex;
      width: 38px;
      height: 38px;
    }

    > .supportArea {
      position: absolute;
      z-index: $zIndexOnContent;

      > .bubbleTail {
        display: flex;
        width: fit-content;

        > .bubbleTailImage {
          position: relative;
          z-index: $zIndexOverContent;
        }
      }

      > .supportContent {
        margin-top: -2px;
        width: 470px;
        padding: 14px 16px;
        border: 1px solid $navyMedium;
        background-color: white;
        border-radius: 6px;

        display: flex;
        flex-direction: column;
        gap: 12px;

        > .titleArea {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > .supportTitle {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.24px;
            color: $navyMedium;
          }

          > .closeButton {

          }
        }

        > .contentList {
          list-style-type: disc;
          margin-left: 1em;

          > .item {
            list-style: disc;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.24px;
            font-weight: normal;
            color: $subtextColor;
            white-space: break-spaces;
          }
        }
      }
    }

  }
}

@include tablet {
  .mobile {
    display: none !important;
  }

  .orderAndPayment {
    align-items: center;
    background-color: $lightGray;

    > .title {
      margin-top: 200px;
      display: inline-flex;
      padding: 12px 40px;
      justify-content: center;
      align-items: center;
    }

    > .orderAndPaymentWrapper {
      margin-top: 50px;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 20px;

      display: grid;
      width: 728px;
      padding: 30px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;


      > .orderItemList {
        width: 728px;
        grid-column: 1 / 3;
        padding: 30px;
        background-color: white;
        border-radius: 20px;

        > .sectionTitle {
          @include sectionTitle;
        }

        > .items {

          margin-top: 20px;
          width: 100%;

          > .tableHeader {

            > .headerRow {

              > .headerCell {
                box-sizing: content-box;
                border-bottom: 2px solid $g5dedede;
                padding-bottom: 20px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: -0.24px;
                color: $g2757575;
              }
            }
          }

          > .tableBody {
            > .bodyRow {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
              color: $KPNavy;

              &:last-child {

                > .bodyCell {
                  height: auto;
                  border-bottom: 0;
                  padding-top: 20px;
                }
              }

              > .bodyCell {
                height: 160px;
                width: 160px;
                text-align: center;
                border-bottom: 2px dashed $g5dedede;

                &.detailCell {
                  width: 420px;

                  > .detailArea {
                    width: 284px;
                    display: flex;
                    align-items: center;

                    > .thumbnail {
                      width: 74px;
                      height: 74px;
                      background-color: #e6e6e6;
                      flex-shrink: 0;
                      border-radius: 3px;

                      > img {
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                      }
                    }

                    > .text {
                      margin-left: 16px;
                      flex: 1;
                      text-align: left;
                    }
                  }
                }

                &.priceAccent {
                  color: $hotpink;
                }
              }
            }
          }
        }
      }

      > .supportPanel {
        background-color: white;
        border-radius: 20px;

        display: flex;
        width: 354px;
        padding: 30px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        &.codePanel {
          align-self: start;
        }

        > .titleBar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          > .sectionTitle {
            @include sectionTitle;
            display: flex;
            align-items: center;
            letter-spacing: -0.36px !important;
          }

          > .couponCount {
            font-size: 2rem;
            line-height: 1.5;
            letter-spacing: -0.03em;
            color: $KPNavy;

            > .count {
              margin-left: 8px;
              font-weight: bold;
            }
          }
        }

        > .inputArea {
          width: 100%;
          padding: 0 10px;
          display: grid;
          grid-template-columns: 240px auto;
          column-gap: 8px;
          row-gap: 10px;
          align-items: center;

          > .codeInput {
            justify-content: center;
            align-items: center;

            width: 246px;
            display: flex;
            padding: 16px 12px;
            //align-items: flex-start;
            gap: 8px;
            align-self: stretch;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.24px;

            border-bottom: 2px solid $e1e1e1;
            color: $KPNavy;

            &::placeholder {
              font-weight: normal;
              color: $c4c4c4;
            }

            &:focus, &.hasValue {
              border-bottom-color: $KPNavy;
            }

            &.invalid {
              border-bottom-color: $hotpink;
            }

            &:disabled {
              background-color: $lightGray;
            }
          }

          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.24px;

          > .invalidMessage {
            color: $hotpink;
          }

          > .completedMessage {
            color: $mainColor;
          }

          > .applyButton {
            @include tabletCodeDiscountButton;
            color: white;
            background-color: $navyMedium;
          }

          > .cancelButton {
            @include tabletCodeDiscountButton;
            padding: 4px 7px;
            background-color: white;
            color: $navyMedium;
            border: 1px solid $navyMedium;
          }
        }

        > .selectorArea {

          display: flex;
          //padding: 0 16px;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;


          > .couponSelector {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
            padding: 16px 20px;
            width: 100%;
            height: 62px;
            color: var(--GrayScale-G-200, #C4C4C4);


            > option {
              width: 100%;
              height: 62px;
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.5;
              letter-spacing: -0.03em;
              color: $g2757575;
            }
          }
        }
      }

      > .paymentWay {
        background-color: white;
        border-radius: 20px;

        display: flex;
        width: 354px;
        padding: 30px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        > .sectionTitle {
          @include sectionTitle;
        }

        > .ways {
          grid-template-rows: 1fr;
          grid-template-columns: 1fr 1fr;

          display: flex;
          height: 56px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;

          > .payItem {
            width: 143px;
            height: 56px;

            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid $g5dedede;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            font-weight: normal;
            font-size: 1.5rem;
            line-height: 1.5;
            letter-spacing: -0.03em;
            color: $g2757575;

            > .text {
              margin-left: 8px;
            }

            &.selected {
              border: 1px solid $KPNavy;
              color: $KPNavy;
              font-weight: bold;
            }
          }
        }
      }

      > .orderedAmountInfo {
        //display: flex;
        width: 354px;
        padding: 30px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        > .sectionTitle {
          @include sectionTitle;
          margin-bottom: 20px;
        }

        > .amountDetail {
          > .amountList {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            align-self: stretch;
            margin-bottom: 20px;

            > .amountItem {
              //margin: 20px 0;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;
              color: $fontHeavyLight;

              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.24px;

              &.accent {
                color: $hotpink;
              }
            }
          }

          > .totalAmount {
            padding-top: 20px;
            border-top: 2px solid $g4c6c6c6;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.3px;
            color: $KPNavy;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    > .agreement {
      margin-top: 60px;
      display: flex;
      align-items: center;

      > .checkBtn {
        width: 24px;
        height: 24px;
        background: url("../../../images/sub/purchase_voucher/check_btn_unchecked_tablet.svg") no-repeat;

        &.checked {
          background: url("../../../images/sub/purchase_voucher/check_btn_checked_tablet.svg") no-repeat;
        }
      }

      > .text {
        margin-left: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
        color: $KPNavy;
      }

      > .link {
        margin-left: 4px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: -0.36px;
        text-decoration-line: underline;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::after {
          content: url("../../../images/sub/purchase_voucher/link_icon.svg");
        }
      }
    }

    > .payButton {
      margin: {
        top: 63px;
        bottom: 200px;
      };
      background-color: $mainColor;
      width: 480px;
      height: 84px;
      border-radius: 12px;
      color: white;
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 28px;
    }
  }

  .supportWrapper {
    position: relative;

    .supportButton {
      display: flex;
      > img {
        width: 30px;
        height: 30px;
      }
    }

    > .supportArea {
      position: absolute;
      z-index: $zIndexOnContent;

      > .bubbleTail {
        display: flex;
        width: fit-content;

        > .bubbleTailImage {
          position: relative;
          z-index: $zIndexOverContent;
        }
      }

      > .supportContent {
        margin-top: -2px;
        border: 1px solid $navyMedium;
        background-color: white;
        border-radius: 6px;

        display: flex;
        width: 354px;
        padding: 10px 12px;
        flex-direction: column;
        align-items: center;
        gap: 8px;


        > .titleArea {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;

          > .supportTitle {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.21px;
            color: $navyMedium;
          }

          > .closeButton {

          }
        }

        > .contentList {
          list-style-type: disc;
          margin-left: 1em;

          > .item {
            list-style: disc;
            color: $subtextColor;
            white-space: break-spaces;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.21px;
          }
        }
      }
    }

  }
}

@include mobile {
  .desktop {
    display: none !important;
  }

  .orderAndPayment {
    background-color: $lightGray;
    margin-top: -10px;
    padding-bottom: 100px;

    > .title {
      margin: {
        top: 40px;
        bottom: 30px;
      };
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;

      .textArea {
        width: 190px;
      }
    }

    > .orderAndPaymentWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      > .orderItemList {
        padding: 20px;
        background-color: white;

        > .sectionTitle {
          @include sectionTitle;
        }

        .items {
          margin-top: 20px;

          display: flex;
          flex-direction: column;
          gap: 20px;

          .item {
            display: flex;
            gap: 16px;

            .thumbnail {
              border-radius: 3px;

              > img {
                width: 74px;
                height: 74px;
              }
            }

            .productInfo {
              display: flex;
              flex-direction: column;

              .productName {
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.03em;
                color: $KPNavy;
              }

              .quantity {
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.03em;
                color: $KPNavy;
              }

              .priceWrap {
                margin-top: 8px;

                .sellingPrice {
                  font-size: 16px;
                  line-height: 150%;
                  letter-spacing: -0.03em;
                  color: $KPNavy;
                }

                .regularPrice {
                  margin-left: 4px;
                  font-size: 12px;
                  line-height: 150%;
                  letter-spacing: -0.03em;
                  color: $g4c6c6c6;
                }

              }
            }
          }
        }
      }

      > .supportPanel {
        display: flex;
        flex-direction: column;
        gap: 20px;

        background-color: white;
        padding: 20px;

        > .titleBar {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .sectionTitle {
            @include sectionTitle;
            display: flex;
            align-items: center;
          }

          .couponCount {
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.03em;
            color: $KPNavy;

            > .count {
              margin-left: 4px;
              font-weight: bold;
            }
          }
        }

        > .inputArea {
          padding: 0 10px;
          display: grid;
          grid-template-columns: auto 40px;
          column-gap: 8px;
          row-gap: 10px;
          align-items: center;

          > .codeInput {
            width: calc(100vw - 100px);
            padding: 12px 16px;
            border-bottom: 1px solid $e1e1e1;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.24px;
            color: $KPNavy;

            &::placeholder {
              font-weight: normal;
              color: $c4c4c4;
            }

            &.hasValue, &:focus {
              border-bottom-color: $KPNavy;
            }

            &.invalid {
              border-bottom-color: $hotpink;
            }

            &:disabled {
              background-color: $lightGray;
            }
          }

          font-size: 14px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.21px;

          > .invalidMessage {
            color: $hotpink;
            white-space: break-spaces;
          }

          > .completedMessage {
            color: $mainColor;
          }

          > .applyButton {
            @include mobileCodeDiscountButton;
            color: white;
            background-color: $navyMedium;
          }

          > .cancelButton {
            @include mobileCodeDiscountButton;
            padding: 4px 7px;
            background-color: white;
            color: $navyMedium;
            border: 1px solid $navyMedium;
          }
        }

        > .selectorArea {
          padding: {
            left: 10px;
            right: 10px;
          };

          .selector {
            padding: 12px 16px;
          }
        }
      }

      > .paymentWay {
        background-color: white;
        padding: 20px;
        > .sectionTitle {
          @include sectionTitle;
        }

        > .ways {
          display: flex;
          margin-top: 20px;
          gap: 8px;

          > .payItem {
            width: auto;
            flex: 1 1 0;
            height: 56px;
            line-height: 56px;
            border: 1px solid #DEDEDE;
            border-radius: 4px;

            font-size: 16px;
            text-align: center;
            letter-spacing: -0.03em;
            color: $fontLight;

            &.selected {
              border: 1px solid $KPNavy;
              color: $KPNavy;
              font-weight: bold;
            }
          }
        }
      }

      > .orderedAmountInfo {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        > .sectionTitle {
          @include sectionTitle;
        }

        > .amountDetail {
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.24px;
          color: $KPNavy;

          .amountList {
            display: flex;
            flex-direction: column;
            gap: 6px;

            li.amountItem {
              display: flex;
              justify-content: space-between;

              &.accent {
                color: $hotpink;
              }
            }

            padding-bottom: 20px;
            border-bottom: 1px solid $c4c4c4;
          }

          .totalAmount {
            display: flex;
            justify-content: space-between;

            margin-top: 20px;

            font-weight: bold;
            font-size: 20px;
            line-height: 140%;
            letter-spacing: -0.3px;
            color: $KPNavy;
          }
        }
      }
    }
  }

  .agreement {
    margin-top: 60px;
    padding: 0 30px;

    display: flex;

    .checkBtnWrap {
      margin-right: 10px;
      .checkBtn {
        width: 24px;
        height: 24px;
        background: url("../../../images/sub/purchase_voucher/check_btn_unchecked.svg") no-repeat;
        background-size: 24px 24px;

        &.checked {
          background: url("../../../images/sub/purchase_voucher/check_btn_checked.svg") no-repeat;
          background-size: 24px 24px;
        }
      }
    }

    .textWrap {
      display: flex;
      flex-direction: column;
      word-break: keep-all;

      .text {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.03em;
        color: $KPNavy;
      }

      .linkWrap {
        display: flex;
        align-items: center;
        margin-top: 4px;

        .link {
          font-size: 12px;
          line-height: 150%;
          letter-spacing: -0.03em;
          text-decoration-line: underline;
          color: $KPNavy;
        }

        img {
          content: '';
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .payButton {
    margin:{
      top: 30px;
      left: 30px;
    }
    width: calc(100% - 60px);
    height: 66px;
    line-height: 66px;

    padding: 0 30px;
    border-radius: 8px;

    background-color: $mainColor;

    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: white;
  }

  .supportWrapper {
    position: relative;

    .supportButton {
      display: flex;

      > img {
        width: 30px;
        height: 30px;
      }
    }

    > .supportArea {
      position: absolute;
      z-index: $zIndexOnContent;

      > .bubbleTail {
        display: flex;
        width: fit-content;

        > .bubbleTailImage {
          position: relative;
          z-index: $zIndexOverContent;
        }
      }

      > .supportContent {
        margin-top: -2px;
        width: 90vw;
        max-width: 320px;
        padding: 14px 16px;
        border: 1px solid $navyMedium;
        background-color: white;
        border-radius: 6px;

        display: flex;
        flex-direction: column;
        gap: 12px;

        > .titleArea {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > .closeButton {
            > img {
              width: 26px;
              height: 26px;
            }
          }

          > .supportTitle {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.21px;
            color: $navyMedium;
          }
        }

        > .contentList {
          list-style-type: disc;
          margin-left: 1em;

          > .item {
            list-style: disc;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.21px;
            font-weight: normal;
            color: $subtextColor;
            white-space: break-spaces;
          }
        }
      }
    }

  }
}