$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
.container {
  display: flex;
  width: 620px;
  height: 475px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 4px solid var(--K-PASS-KPblue-000_bg, #f6f3ff);
}

.containerContentBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
}

.containerTitle {
  font-size: 24px;
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
}

.profileCount {
  color: var(--GrayScale-G-500_subtext, #6b6b6b);
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.36px;

  white-space: nowrap; /* 텍스트가 한 줄로 유지되게 함 */
  overflow: hidden; /* 넘치는 부분 숨기기 */
  text-overflow: ellipsis; /* 넘치는 부분을 '...'으로 표시 */
  max-width: 165px; /* 최대 너비 설정 */

  display: inline-block; /* ellipsis가 동작하게 만듦 */
}

.resultButton {
  border-radius: 6px;
  border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  background: var(--K-PASS-KPnavy-400, #76718e);

  display: flex;
  padding: 4px 8px;

  color: var(--GrayScale-W-FFFFFF, #fff);
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.guideButton {
  border-radius: 6px;
  border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  background: #fff;

  display: flex;
  padding: 4px 8px;

  color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.resultButton:hover,
.guideButton:hover {
  background-color: #e0e0e0;
}

.addProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #dcdcdc;
  border-radius: 10px;
  width: 240px;
  height: 244px;
  color: #a0a0a0;
}

.addProfileButton {
  border-radius: 6px;
  border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  display: flex;
  padding: 4px 0px 4px 5px;
  align-items: center;
  box-sizing: border-box;
  width: 190px;
  height: 34px;
  background-color: white;
  color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.24px;
}

.addCheckupInfoButton {
  width: 190px;
  height: 34px;
  align-items: center;
  align-self: stretch;
  color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

.labelContainer {
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  box-sizing: border-box;
  width: 560px;
  height: 60px;

  border-radius: 6px;
  border: 1px solid #e5e5ef;

  /* font */
  color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: -0.48px;

  margin-bottom: 10px;
}

.firstLabel,
.secondLabel,
.thirdLabel,
.fourthLabel {
  display: flex;
  width: 140px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-top-width: 1px;
  border-bottom-width: 1px;
  background: #f6f6f6;
}

.firstLabel {
  border-left-width: 1px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.fourthLabel {
  border-right-width: 1px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.resultInfoContainer {
  width: 560px;
  height: 256px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border: 1px solid #e5e5ef;
  border-radius: 6px;

  margin-bottom: 10px;

  // overflow-y: auto;
}

.resultInfoContainer::-webkit-scrollbar {
  display: none;
}

.resultInfoData {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  display: flex;

  border-bottom: 1px solid #e5e5ef;
  text-align: center;
  color: var(--GrayScale-G2-757575, #757575);
}

.firstInfoData,
.secondInfoData,
.thirdInfoData {
  display: flex;
  width: 140px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.48px;
}

.fourthInfoData {
  display: flex;
  width: 140px;
  height: 64px;
  gap: 5px;

  justify-content: center;
  align-items: center;
  align-self: stretch;

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.48px;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationDiv {
  display: flex;
}

.leftButtonImg,
.rightButtonImg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.leftButtonImg {
  margin-right: 10px;
}

.paginationBtn {
  width: 30px;
  height: 30px;
  border: 0px;
  background: transparent;

  color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.48px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;
}

.paginationBtn:hover {
  border-radius: 100px;
  background: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);

  color: var(--GrayScale-W-FFFFFF, #fff);
}

.active {
  border-radius: 100px;
  background: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);

  color: var(--GrayScale-W-FFFFFF, #fff);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: -0.48px;
}

.noPaymentInfo {
  flex: 1 0 0;

  color: var(--GrayScale-G2-757575, #757575);
  text-align: center;

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.24px;

  margin: 100px auto 0 auto;
}

.consultBtn {
  border-radius: 6px;
  border: 1px solid var(--K-PASS-KPnavy-400, #76718e);
  background: var(--GrayScale-W-FFFFFF, #fff);

  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;

  color: var(--Color-FeelGood-Color-FG-Bodytext-blue-646F96, #646f96);
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
}

.consultReviewBtn {
  border-radius: 6px;
  background: var(--K-PASS-KPnavy-400, #76718e);

  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;

  color: var(--GrayScale-white, #fff);
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
}

.not_alloted {
  color: var(--GrayScale-G2-757575, #757575);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
}

.allotted {
  display: block;

  color: var(--K-PASS-KPblue-500_main, #583be2);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  letter-spacing: -0.48px;
}

.allotted_consultant {
  color: var(--GrayScale-G-700_bodytext, #404040);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.finished {
  color: var(--Basic-Bred-500_main, #ff2c45);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
}

.refunded {
  color: var(--Basic-Bred-500_main, #ff2c45);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
}

@include tablet {
  .container {
    width: 800px;
    height: 400px;
    border-radius: 25px;
  }
  .labelContainer {
    width: 740px;
    height: 40px;
    border: 1px solid #e5e5ef;
    background: #f6f6f6;
  }
  .containerTitle,
  .profileCount {
    font-size: 20px;
  }
  .containerContentBox {
    padding-left: 20px;
  }
  .firstLabel,
  .secondLabel,
  .thirdLabel,
  .fourthLabel {
    width: 185px;
    height: 40px;
    font-size: 14px;
    background: none;
  }
  .resultInfoContainer {
    width: 740px;
    height: 200px;
  }
  .resultInfoData {
    height: 50px;
  }
  .firstInfoData,
  .secondInfoData,
  .thirdInfoData,
  .fourthInfoData {
    width: 185px;
    height: 50px;
    font-size: 14px;
  }

  .fourthInfoData {
    display: flex; /* 플렉스 컨테이너로 설정 */
    justify-content: center; /* 가로 가운데 정렬 */
    align-items: center; /* 세로 가운데 정렬 */
  }
  .addIcon {
    width: 21px;
    height: 21px;
  }

  .noPaymentInfo {
    margin: 80px auto 0 auto;
  }

  .addCheckupInfoButton {
    width: 167px;
    height: 29px;
    color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.21px;
    display: flex;
    align-items: center;
    align-self: stretch;
  }

  .allotted_consultant {
    color: var(--GrayScale-G-700_bodytext, #404040);
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .allotted {
    display: block;
    line-height: 170%;
  }
}

@include mobile {
  .container {
    width: 340px;
    padding: 20px 15px;

    border-radius: 15px;
    border: 4px solid var(--K-PASS-KPblue-000_bg, #f6f3ff);
    height: 517px;
  }

  .containerContentBox {
    display: block;
  }

  .containerTitle {
    color: var(--GrayScale-G-700_bodytext, #404040);

    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
  }

  .containerContentBox {
    padding: 0 10px;
  }

  .profileCount {
    font-size: 20px;
  }

  .labelContainer {
    width: 300px;
    height: 40px;
  }

  .firstLabel,
  .secondLabel,
  .thirdLabel,
  .fourthLabel {
    color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
    letter-spacing: -0.18px;
  }

  .resultInfoContainer {
    width: 300px;
    height: 280px;
    box-sizing: border-box;
  }

  .resultInfoData {
    height: 70px;
  }
  .firstInfoData,
  .secondInfoData,
  .thirdInfoData,
  .fourthInfoData {
    color: var(--GrayScale-G2-757575, #757575);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.18px;
    height: 70px;
    width: 75px;
  }

  .fourthInfoData {
    display: flex;
    width: 75px;
    height: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .resultButton {
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;

    color: var(--GrayScale-W-FFFFFF, #fff);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.18px;
  }

  .guideButton {
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;

    color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.18px;
  }
  .noPaymentInfo {
    font-size: 14px;
    margin: 120px auto 0 auto;
  }

  .addIcon {
    width: 21px;
    height: 21px;
  }

  .addCheckupInfoButton {
    width: 167px;
    height: 29px;
    align-items: center;
    align-self: stretch;

    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;
    margin-left: auto;
  }

  .allotted_consultant {
    color: var(--GrayScale-G-700_bodytext, #404040);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.18px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .allotted {
    line-height: 80%;
  }

  .refunded,
  .finished {
    color: red;
  }

  .allotted {
    color: #583be2;
  }

  .consultReviewBtn {
    border-radius: 6px;
    background: var(--K-PASS-KPnavy-400, #76718e);

    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;

    color: var(--GrayScale-W-FFFFFF, #fff);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.18px;
  }

  .consultBtn {
    width: 57px;
    height: 26px;
    box-sizing: border-box;

    border-radius: 6px;
    border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
    background: #fff;

    display: flex;
    padding: 3px 7px;
    align-items: flex-start;
    gap: 10px;

    color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.18px;
  }
}
