$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@include mobile {
  .section3 {
    display: flex;
    flex-direction: column;
    padding-top: 130px;

    > .researchBackground {
      display: flex;
      flex-direction: column;

      > .textContent {
        width: 100%;
        display: flex;
        flex-direction: column;

        > .slogan {
          padding: 0 30px;
          font-size: 24px;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $mainColor;
          @include newLine;

          &.ru {
          }
        }

        > .text1 {
          padding: 0 30px;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          margin-top: 16px;
          color: $fontHeavy;
          @include newLine;
        }

        > .imgContent {
          margin-top: 60px;
          position: relative;

          > img {
            width: 100%;
          }

          > .bgCircle {
            width: 198px;
            height: 198px;
            border: 43.74px solid $fontHeavy;
            border-radius: 50%;
            position: absolute;
            right: -26px;
            bottom: -162px;
            z-index: $zIndexBgItem;
            opacity: 0.1;
          }
        }

        > .text2 {
          margin-top: 30px;
          padding: 0 30px;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $fontLight;
          @include newLine;
        }
      }


    }

    > .aboutResearcher {
      margin: {
        top: 130px;
        bottom: 100px;
      };
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 30px;

      > .introduction1 {
        display: flex;
        flex-direction: column;
        align-items: center;

        > .pictureContent {
          width: 141px;
          height: 141px;
          border-radius: 50%;
          position: relative;

          > img {
            width: 100%;
          }

          > .bgItem {
            width: 87px;
            height: 87px;
            background-color: $mainColor;
            opacity: 0.1;
            position: absolute;
            top: -16px;
            left: -55px;
            z-index: -999;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
          }
        }
        > .textContent {
          margin-top: 30px;

          > .researcherDesc {

            > .text1 {
              display: block;
              font-size: 1.6rem;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $fontHeavy;
            }

            > .text2 {
              display: block;
              font-size: 2.4rem;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.03em;
              color: $mainColor;
            }

            > .text3 {
              display: block;
              font-size: 2.4rem;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.03em;
              color: $fontHeavy;
            }
          }

          > .researcherHistory {
            padding: 30px;
            margin-left: -1.2rem;
            @include newLine;

            > li {
              font-weight: normal;
              font-size: 1.2rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $fontLight;
              list-style: disc;
            }
          }
        }
      }

      > .introduction2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        > .box {
          width: 100%;
          border-top-right-radius: 30px;
          border-bottom-left-radius: 30px;
          border-bottom-right-radius: 30px;
          padding: 30px;

          &.standard {
            border: 4px solid getFontHeavyColorWithOpacity(0.1);
          }

          &.books {
            border: 4px solid getMainColorWithOpacity(0.1);
            margin: {
              top: 20px;
              bottom: 16px;
            }
          }

          > .title {
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            color: $fontHeavy;
          }

          > .contentList {
            margin: {
              top: 12px;
              left: 1.2rem;
            };
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 1.8;
            letter-spacing: -0.05em;
            color: $fontLight;

            > li {
              list-style: disc;
              @include newLine;

              > ul {
                > li {
                  list-style: none;

                  &:before {
                    content: "- ";
                  }
                }
              }
            }
          }
        }
      }
    }

    > .comingSoon {
      width: 100%;
      margin-bottom: 188px;
      padding: 80px 30px 142px 30px;
      background-image: url("../../images/main_legacy/section3/coming_soon_bg_mobile.svg");
      background-position: center;
      background-size: cover;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > .leftBgItem {
        position: absolute;
        width: 174px;
        height: 220px;
        bottom: -108px;
        left: calc(50vw - 150px);

        > img {
          width: 100%;
        }
      }

      > .rightBgItem {
        position: absolute;
        width: 126px;
        height: 205px;
        left: calc(50vw + 24px);
        bottom: -108px;

        > img {
          width: 100%;
        }
      }

      > .month {
        font-weight: bold;
        font-size: 2.8rem;
        line-height: 1.8;
        letter-spacing: -0.05em;
        color: white;
      }

      > .desc {
        margin-top: 10px;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 1.6;
        letter-spacing: -0.05em;
        color: white;
        text-align: center;
        @include newLine;
      }
    }
  }
}

@include desktop {

  .section3 {
    display: flex;
    flex-direction: column;
    padding-bottom: 380px;

    > .researchBackground {
      @include desktopWidth;
      padding: {
        left: 60px;
        top: 300px;
      };
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      > .textContent {
        display: flex;
        flex-direction: column;

        > .slogan {
          font-size: 6rem;
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $mainColor;
          @include showBottomToTop(0.5s, 0, 10%);
          @include newLine;

          &.ru {
            font-size: 5rem;
          }
        }

        > .text1 {
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          margin-top: 50px;
          color: $KPNavy;
          @include newLine;
        }

        > .text2 {
          @include newLine;
          margin-top: 30px;
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $g2757575;
        }
      }

      > .imgContent {
        margin-left: 76px;
        position: relative;

        > img {
          width: 700px;
          height: 840px;
          border-radius: 30px;
        }

        > .bgCircle {
          width: 746px;
          height: 746px;
          border: 165px solid $KPNavy;
          border-radius: 50%;
          position: absolute;
          right: -373px;
          bottom: -373px;
          z-index: $zIndexBgItem;
          opacity: 0.1;
        }
      }
    }

    > .aboutResearcher {
      @include desktopWidth;
      margin: {
        top: 510px;
      };
      display: flex;
      flex-direction: column;
      align-items: center;

      > .introduction1 {
        display: flex;
        justify-content: center;

        > .pictureContent {
          width: 349px;
          height: 349px;
          border-radius: 50%;
          position: relative;

          > .bgItem {
            width: 500px;
            height: 500px;
            background-color: $mainColor;
            opacity: 0.1;
            position: absolute;
            top: -171px;
            right: 150px;
            z-index: -999;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
          }
        }
        > .textContent {
          padding: 10px 30px 0 0;
          margin-left: 100px;

          > .researcherDesc {
            @include showBottomToTop(0.5s, 0, 10%);

            > .text1 {
              display: block;
              font-size: 2.4rem;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $KPNavy;
            }

            > .text2 {
              display: block;
              font-size: 4rem;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $mainColor;
            }

            > .text3 {
              display: block;
              font-size: 4rem;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $KPNavy;
            }
          }

          > .researcherHistory {
            margin: {
              top: 50px;
              left: 20px;
              bottom: 30px;
            };

            > li {
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.8;
              letter-spacing: -0.05em;
              color: $g2757575;
              list-style: disc;
            }
          }
        }
      }

      > .introduction2 {
        margin-top: 100px;
        display: flex;
        justify-content: center;
        @include showBottomToTop(0.5s, 0, 10%);

        > .box {
          width: 524px;
          min-height: 492px;
          border-top-right-radius: 60px;
          border-bottom-left-radius: 60px;
          border-bottom-right-radius: 60px;
          padding: {
            top: 50px;
            left: 60px;
            right: 43px;
            bottom: 50px;
          }

          &.standard {
            border: 6px solid getFontHeavyColorWithOpacity(0.1);
          }

          &.books {
            margin-left: 32px;
            border: 6px solid getMainColorWithOpacity(0.1);
          }

          > .title {
            font-weight: bold;
            font-size: 2.4rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            color: $KPNavy;
          }

          > .contentList {
            margin: {
              top: 30px;
              left: 20px;
            };
            font-weight: normal;
            font-size: 2rem;
            line-height: 1.8;
            letter-spacing: -0.05em;
            color: $g2757575;

            > li {
              list-style: disc;
              @include newLine;

              > ul {
                > li {
                  list-style: none;

                  &:before {
                    content: "- ";
                  }
                }
              }
            }
          }
        }
      }
    }

    > .comingSoon {
      margin-top: 300px;
      width: 100%;
      height: 498px;
      background-image: url("../../images/main_legacy/section3/coming_soon_bg.svg");
      background-position: center;
      background-size: 1745.16px 498px;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include showBottomToTop(0.5s, 0, 10%);

      > .leftBgItem {
        position: absolute;
        width: 339px;
        height: 508px;
        top: 70px;
        right: calc(50vw + 401px);
      }

      > .rightBgItem {
        position: absolute;
        width: 445px;
        height: 410px;
        top: 150px;
        left: calc(50vw + 295px);
      }

      > .month {
        font-weight: bold;
        font-size: 6rem;
        line-height: 1.8;
        letter-spacing: -0.05em;
        color: white;
      }

      > .desc {
        margin-top: 20px;
        font-weight: normal;
        font-size: 32px;
        line-height: 1.6;
        letter-spacing: -0.05em;
        color: white;
        text-align: center;
        @include newLine;
      }
    }
  }
}
