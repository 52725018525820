$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "src/assets/scss/variables";

// 리팩토링 필수

@mixin buttonText {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.4;
  color: white;
}

@mixin buttonTextTablet {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  color: white;
}

@mixin buttonTextMobile {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  color: white;
}

@mixin nextButtonText {
  color: var(--GrayScale-G-700_bodytext, #404040);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.36px;
}

@mixin nextButtonMobileText {
  color: var(--GrayScale-G-700_bodytext, #404040);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

@mixin nextButtonTabletText {
  color: var(--GrayScale-G-700_bodytext, #404040);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

@mixin nextHoverButtonMobileText {
  color: var(--GrayScale-white, #FFF);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

@mixin nextHoverButtonText {
  color: var(--GrayScale-white, #FFF);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.36px;
}

@mixin nextHoverButtonTabletText {
  color: var(--GrayScale-white, #FFF);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}

@mixin signUpButtonBase {
  min-width: 290px;
  display: flex;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
}

@mixin signUpButtonMobileBase {
  min-width: 280px;
  display: flex;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
}

@mixin signUpButtonMobileBase {
  min-width: 280px;
  display: flex;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
}

@mixin mobileButtonBase {
  display: flex;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
}

@mixin tabletButtonBase {
  display: flex;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
}

@include mobile {
  .wrapper {
    max-width: 320px;
  }

  .content {
    padding: 94px 20px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 600px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    > .images {
      display: none;
    }

    > .textDesc {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      > .emailForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;

        > .input {
          width: 100%;
          height: 50px;
          border: 1px solid $blueLight;
          border-radius: 8px;
          font-size: 16px;
          padding: 12px 52px 12px 16px;

          &::placeholder {
            color: $g4c6c6c6;
            font-weight: normal;
            letter-spacing: -0.03em;
            line-height: 1.6;
          }
        }

        > .timer {
          position: absolute;
          right: 16px;
          top: 16px;
        }

        > .invalidEmailAddr {
          align-self: stretch;
          width: 100%;
          text-align: left;
          font-weight: normal;
          margin-top: 8px;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $fontModalAccent;
          white-space: break-spaces;
        }

        > .button {
          margin-top: 30px;
          width: 100%;
          height: 66px;
          background-color: $KPNavy;
          color: white;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;

          > .sendIcon {
            width: 26px;
            height: 26px;
          }
        }

        > .buttons {
          margin-top: 40px;
          width: 100%;
          height: 66px;
          display: flex;
          gap: 10px;

          > .cancel {
            flex: 1;
            width: 79px;
            height: 66px;
            border: 1px solid $blueLight;
            border-radius: 8px;
            @include buttonTextMobile;
          }

          > .loginBtn {
            max-width: 191px;
            flex: 1;
            height: 66px;
            background-color: $KPNavy;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            @include buttonTextMobile;
          }
        }
      }
    }

    .desc {
      width: 280px;

      text-align: center;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: -0.015em;
      color: $blueLight;
      line-height: 1.5;
      @include wordBreak;

      > .accent {
        color: white;
        font-weight: bold;
      }
    }

    > .authWayWrapper {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 30px;

      > .waysWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          > .checkbox {
            width: 24px;
            height: 24px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;

            flex-shrink: 0;
          }

          > .label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 100%;
            word-break: break-all;
            text-align: left;

            > .light {
              font-weight: normal;
            }
          }

          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          background-color: $deepMainColor;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $kpBlueLight;
          cursor: pointer;

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.21px;
        }
      }
    }
  }

  .buttonsWrapper {
    &.withMarginTop {
      margin-top: 30px;
    }
    display: flex;
    height: 64px;
    width: 280px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    //margin-bottom: 30px;

    //&.withMarginTop {
    //  margin-top: 30px;
    //}

    > .outlineButton {
      flex: 1;
      min-width: 80px;
      height: 100%;
      border: 1px solid $blueLight;
      border-radius: 8px;
      @include buttonTextMobile;
    }

    > .primaryButton {
      width: 190px;
      height: 100%;
      background-color: $KPNavy;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      @include buttonTextMobile;

      display: flex;
      align-items: center;
      justify-content: center;

      > .icon {
        width: 26px;
        height: 26px;
      }
    }

    > .signUpNextButton {
      @include mobileButtonBase;
      @include nextButtonMobileText;
      min-width: 280px;
      height: 64px;

      background: var(--GrayScale-G-100, #E1E1E1);
    }

    > .signUpNextHoverButton {
      @include mobileButtonBase;
      @include nextHoverButtonMobileText;
      background: var(--K-PASS-KPblue-700, #352388);
    }

    > .authenticationNextButton {
      @include nextButtonMobileText;
      @include mobileButtonBase;
      min-width: 190px;
      height: 100%;
      background: var(--GrayScale-G-100, #E1E1E1);
    }

    > .authenticationNextHoverButton {
      @include nextHoverButtonMobileText;
      @include mobileButtonBase;
      min-width: 190px;
      background: var(--K-PASS-KPblue-700, #352388);
    }
  }

  .timerTime {
    font-weight: normal;
    font-size: 12px;
    line-height: 1.5;
    color: $mainColor;
  }

  .inputArea {
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .selectWrapper {
      display: flex;
      width: 280px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .selectTitleWrapper {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;

        .highLight, .titleText {
          color: var(--GrayScale-white, #FFF);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }

        .highLight {
          color: var(--Basic-Bred-500_main, #FF2C45);
        }

        .unHighLight {
          color: var(--K-PASS-KPblue-100, #D6CEFF);
          line-height: 150%;
          font-size: 16px;
        }
      }

      .selectTextFieldWrapper {
        height: 44px;
        display: flex;
        padding: 10px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        border-bottom: 1px solid var(--K-PASS-KPnavy-500_main, #383649);
        background: var(--GrayScale-white, #FFF);

        .selectText {
          max-width: 256px;
          display: flex;
          align-items: center;
          flex: 1 0 0;

          color: var(--K-PASS-KPnavy-500_main, #383649);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;

          &.defaultText {
            color: var(--GrayScale-G-200, #C4C4C4);
          }
          &::placeholder {
            color: var(--GrayScale-G-200, #C4C4C4);
          }
          &.unSelected{
            color: var(--GrayScale-G-200, #C4C4C4);
          }
        }

        .arrowImg {
          background-image: url("../../../../assets/images/modal/login/arrow.svg");
          width: 24px;
          height: 24px;
        }

        .inputField {
          display: flex;
          padding: 10px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        }

        .optionStyle {
          display: flex;
          width: 280px;
          max-height: 200px;
          overflow: auto;
          flex-direction: column;
          align-items: flex-start;

          position: absolute;
          right: 20px;

          transform: translateY(114px);

          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;
          scrollbar-width: none;

          border: 1px solid var(--GrayScale-G-100, #E1E1E1);
          background: var(--GrayScale-white, #FFF);

          .optionTextWrapper {
            display: flex;
            width: 280px;
            padding: 8px 12px;
            align-items: flex-start;
            gap: 10px;

            .optionText {
              color: var(--GrayScale-G-500_subtext, #6B6B6B);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;

              &.selectedOption {
                color: var(--K-PASS-KPnavy-500_main, #383649);
                font-weight: 700;
              }
            }

            &.selectedOption {
              background: var(--GrayScale-G-000_bg, #F6F6F6);
              color: var(--K-PASS-KPnavy-500_main, #383649);
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
            }
          }
        }

        .cellPhoneSelectStyle{
          right: 20px;
        }

        .nationStyle{
          right: 20px;
        }

      }

      .validateText{
        color: var(--Basic-Byellow-500_main, #FFE606);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }

      .cellPhoneInfo {
        align-self: stretch;
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-blue-Light-D6CEFF, #D6CEFF);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 18px */
        letter-spacing: -0.18px;
      }
    }

    .genderBoxWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      .genderBox {
        display: flex;
        width: 120px;
        height: 44px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--GrayScale-G-100, #E1E1E1);
        background: var(--GrayScale-white, #FFF);

        .genderText {
          color: var(--GrayScale-G-500_subtext, #6B6B6B);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }

        &.selected {
          border-radius: 8px;
          border: 1px solid var(--GrayScale-G-100, #E1E1E1);
          background: var(--K-PASS-KPblue-700, #352388);

          .selectedText {
            color: var(--GrayScale-white, #FFF);
          }
        }
      }
    }

    .addressWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      flex: 1 0 0;

      .address {
        max-width: 196px;
        height: 44px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        padding: 10px 12px;

        border-bottom: 1px solid var(--GrayScale-G-200, #C4C4C4);
        background: var(--GrayScale-white, #FFF);

        .addressText {
          max-width: 170px;
          display: flex;
          align-items: center;
          flex: 1 0 0;
          color: var(--GrayScale-G-200, #C4C4C4);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
          white-space: nowrap;
          overflow: hidden;
          direction: rtl;

          &.selected {
            color: var(--K-PASS-KPnavy-500_main, #383649);
          }
        }
      }

      .addressSearchBtn {
        display: flex;
        width: 74px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--GrayScale-G-100, #E1E1E1);
        background: var(--GrayScale-white, #FFF);

        .addressSearchText {
          color: var(--GrayScale-G-500_subtext, #6B6B6B);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */
          letter-spacing: -0.21px;
        }
      }
    }

    .inputWrapper {
      display: flex;
      width: 280px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .addressInput {
        height: 44px;
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1 0 0;
        padding: 12px 12px;
        align-self: stretch;

        color: var(--K-PASS-KPnavy-500_main, #383649);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
        &::placeholder {
          color: var(--GrayScale-G-200, #C4C4C4);
        }
      }
    }

    .maxImg{
      background-image: url("../../../../assets/images/modal/login/max_img_mobile.png");
      width: 100.239px;
      height: 140.99px;
    }


    .buttonsWrapper{
      display: flex;
      height: 64px;
      width: 280px;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-bottom: 30px;

      > .signUpNextButton{
        @include nextButtonMobileText;
        @include mobileButtonBase;
        min-width: 240px;
        height: 100%;
        background: var(--GrayScale-G-100, #E1E1E1);
      }

      > .signUpNextHoverButton {
        @include mobileButtonBase;
        @include nextHoverButtonMobileText;
        background: var(--K-PASS-KPblue-700, #352388);
      }
    }

    .signUpCompleteButtonsWrapper{
      display: flex;
      width: 240px;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      > .signUpNextButton{
        @include nextButtonMobileText;
        @include mobileButtonBase;
        min-width: 240px;
        height: 100%;
        background: var(--GrayScale-G-100, #E1E1E1);
      }

      > .signUpNextHoverButton {
        @include mobileButtonBase;
        @include nextHoverButtonMobileText;
        background: var(--K-PASS-KPblue-700, #352388);
      }

      .submitIcon{
        background-image: url("../../../images/logo/submit_mo.png");
        width: 26px;
        height: 26px;
      }

      .submitIconHovered{
        background-image: url("../../../images/logo/submit_mo_hover.png");
        align-content: center;
        width: 26px;
        height: 26px;
      }
    }
  }

  .agreementWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 280px;
    padding-bottom: 30px;

    .agreementArea{
      display: flex;
      //padding: 0 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      align-self: stretch;

      .totalAgreeWrapper{
        display: flex;
        padding: 16px 20px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        height: 77px;

        border-radius: 8px;
        border: 2px solid var(--K-PASS-KPblue-100, #D6CEFF);
        background: var(--GrayScale-white, #FFF);

        > .totalAgreeTextWrapper{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;

          > .totalAgreeTitle{
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
          }

          > .totalAgreeSubTitle{
            color: var(--K-PASS-KPnavy-400, #76718E);

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            letter-spacing: -0.21px;
          }

        }
        > .agreeCheckImg{
          width: 24px;
          height: 24px;
          background-image: url("../../../../assets/images/modal/login/unselected_checkbox_mobile.png");

          &.selected {
            background-image: url("../../../../assets/images/modal/login/selected_checkbox_mobile.png");
          }
        }
      }

      .selectAgreement{
        display: flex;
        padding: 0 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;


        .selectAgreeWrapper{
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;

          > .selectAgreeTextWrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;

            > .selectAgreeTitle{
              color: var(--K-PASS-KPblue-100, #D6CEFF);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;

              &.necessary{
                color: var(--Basic-Bred-500_main, #FF2C45);
              }
            }

            > .selectAgreeSubTitle{
              color: var(--GrayScale-white, #FFF);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
              letter-spacing: -0.21px;
            }

            > .selectMandatoryInfoWrapper{
              display: flex;
              align-items: center;

              > .selectMandatoryText{
                color: var(--GrayScale-white, #FFF);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 21px */
                letter-spacing: -0.21px;
              }

              > .selectMandatoryImg{
                width: 24px;
                height: 24px;
                background-image: url("../../../../assets/images/modal/login/shortcut.png");
              }
            }

          }
          > .agreeCheckImg{
            width: 24px;
            height: 24px;
            background-image: url("../../../../assets/images/modal/login/unselected_checkbox_mobile.png");

            &.selected {
              background-image: url("../../../../assets/images/modal/login/selected_select_checkbox_mobile.svg");
            }
          }
        }

      }
    }

    .validateText{
      display: flex;
      width: 200px;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
      margin-top: -20px;
      gap: 8px;

      color: var(--Basic-Byellow-500_main, #FFE606);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }

  }
}

@include tablet {

  .content {
    display: flex;
    width: 700px;
    padding: 20px 0 40px 0;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border-radius: 20px;

    max-height: 600px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    > .images {
      display: none;
    }

    > .images {
      width: 175px;

      > img {
        width: 100%;
      }
    }

    > .textDesc {
      display: flex;
      width: 600px;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      > .emailForm {
        //margin-top: 30px;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;

        > .input {
          width: 600px;
          height: 56px;
          border: 1px solid $blueLight;
          border-radius: 8px;
          font-size: 20px;
          padding: 12px 58px 12px 16px;

          &::placeholder {
            color: $g4c6c6c6;
            font-weight: normal;
            letter-spacing: -0.03em;
            line-height: 1.6;
          }
        }

        > .timer {
          font-size: 14px;
          position: absolute;
          right: 16px;
          top: 16px;
        }

        > .invalidEmailAddr {
          align-self: stretch;
          width: 100%;
          text-align: left;
          font-weight: normal;
          margin-top: 8px;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $fontModalAccent;
          white-space: break-spaces;
        }

        > .button {
          margin-top: 30px;
          //display: flex;
          //width: 100%;
          //align-items: center;
          height: 64px;
          background-color: $KPNavy;
          color: white;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          justify-content: center;

          display: flex;
          width: 360px;
          align-items: center;
          gap: 10px;

          font-size: 20px;
          font-weight: bold;
          line-height: 1.5;

          > .sendIcon {
            width: 38px;
            height: 38px;
          }
        }

        > .buttons {
          margin-top: 30px;
          width: 100%;
          height: 64px;

          display: flex;
          gap: 10px;
        }
      }
    }

    .cancel {
      flex: 1;
      min-width: 149px;
      height: 98px;
      border: 2px solid $blueLight;
      border-radius: 8px;
      @include buttonText;
    }

    .loginBtn {
      width: 331px;
      height: 98px;
      background-color: $KPNavy;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      @include buttonText;
    }

    .desc {
      width: 600px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.3px;
      color: $blueLight;

      > .accent {
        color: white;
        font-weight: bold;
      }
    }

    > .authWayWrapper {
      display: flex;
      width: 600px;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      > .waysWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          background-color: $deepMainColor;
          cursor: pointer;

          > .checkbox {
            width: 24px;
            height: 24px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
          }

          > .label {
            width: 100%;
            word-break: break-all;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.015em;
            color: $kpBlueLight;
            text-align: left;

            display: flex;
            //flex-direction: column;
            flex-wrap: wrap;

            > .light {
              font-weight: normal;
            }
          }

          &:hover {
            background-color: $kpDeepBlue;
          }

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.015em;
        }
      }
    }
  }

  .buttonsWrapper {
    &.withMarginTop {
      margin-top: 30px;
    }
    display: flex;
    width: 360px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;


    > .outlineButton {

      display: flex;
      width: 80px;
      padding: 20px 0;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1;
      height: 64px;
      border: 1px solid $blueLight;
      border-radius: 8px;
      @include buttonTextTablet;
    }

    > .primaryButton {
      display: flex;
      width: 270px;
      height: 68px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: $KPNavy;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      @include buttonTextTablet;

      > .icon {
        width: 26px;
        height: 26px;
      }
    }

    > .signUpNextButton {
      @include tabletButtonBase;
      @include nextButtonTabletText;
      min-width: 360px;
      height: 64px;

      background: var(--GrayScale-G-100, #E1E1E1);
    }

    > .signUpNextHoverButton {
      @include tabletButtonBase;
      @include nextHoverButtonTabletText;
      background: var(--K-PASS-KPblue-700, #352388);
    }

    > .authenticationNextButton {
      @include tabletButtonBase;
      @include nextButtonTabletText;
      min-width: 270px;
      height: 100%;
      background: var(--GrayScale-G-100, #E1E1E1);
    }

    > .authenticationNextHoverButton {
      @include tabletButtonBase;
      @include nextHoverButtonTabletText;
      min-width: 270px;
      background: var(--K-PASS-KPblue-700, #352388);
    }
  }

  .timerTime {
    font-weight: normal;
    font-size: 12px;
    line-height: 1.5;
    color: $mainColor;
  }

  .inputArea {
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .selectWrapper {
      width: 600px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .selectTitleWrapper {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;

        .highLight, .titleText {
          color: var(--GrayScale-white, #FFF);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }

        .highLight {
          color: var(--Basic-Bred-500_main, #FF2C45);
        }

        .unHighLight {
          color: var(--K-PASS-KPblue-100, #D6CEFF);
          line-height: 150%;
          font-size: 16px;
        }
      }

      .selectTextFieldWrapper {
        display: flex;
        padding: 10px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        border-bottom: 1px solid var(--K-PASS-KPnavy-500_main, #383649);
        background: var(--GrayScale-white, #FFF);

        .selectText {
          max-width: 544px;
          display: flex;
          align-items: center;
          flex: 1 0 0;

          color: var(--K-PASS-KPnavy-500_main, #383649);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;

          &.defaultText {
            color: var(--GrayScale-G-200, #C4C4C4);
          }
          &::placeholder {
            color: var(--GrayScale-G-200, #C4C4C4);
          }
          &.unSelected{
            color: var(--GrayScale-G-200, #C4C4C4);
          }
        }

        .arrowImg {
          background-image: url("../../../../assets/images/modal/login/arrow.svg");
          width: 24px;
          height: 24px;
        }

        .inputField {
          display: flex;
          padding: 10px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        }

        .optionStyle {
          display: flex;
          width: 600px;
          max-height: 200px;
          overflow: auto;
          flex-direction: column;
          align-items: flex-start;

          position: absolute;
          right: 50px;

          transform: translateY(115px);

          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;
          scrollbar-width: none;

          border: 1px solid var(--GrayScale-G-100, #E1E1E1);
          background: var(--GrayScale-white, #FFF);

          .optionTextWrapper {
            display: flex;
            width: 600px;
            padding: 8px 12px;
            align-items: flex-start;
            gap: 10px;

            .optionText {
              color: var(--GrayScale-G-500_subtext, #6B6B6B);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;

              &.selectedOption {
                color: var(--K-PASS-KPnavy-500_main, #383649);
                font-weight: 700;
              }
            }

            &.selectedOption {
              background: var(--GrayScale-G-000_bg, #F6F6F6);
              color: var(--K-PASS-KPnavy-500_main, #383649);
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
            }
          }
        }

        .cellPhoneSelectStyle{
          right: 50px;
        }

        .nationStyle{
          right: 50px;
        }

      }

      .validateText{
        color: var(--Basic-Byellow-500_main, #FFE606);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }

      .cellPhoneInfo {
        align-self: stretch;
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-blue-Light-D6CEFF, #D6CEFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 18px */
        letter-spacing: -0.18px;
      }
    }

    .genderBoxWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .genderBox {
        display: flex;
        width: 170px;
        height: 50px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--GrayScale-G-100, #E1E1E1);
        background: var(--GrayScale-white, #FFF);

        .genderText {
          color: var(--GrayScale-G-500_subtext, #6B6B6B);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }

        &.selected {
          border-radius: 8px;
          border: 1px solid var(--GrayScale-G-100, #E1E1E1);
          background: var(--K-PASS-KPblue-700, #352388);

          .selectedText {
            color: var(--GrayScale-white, #FFF);
          }
        }
      }
    }

    .addressWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      flex: 1 0 0;

      .address {
        max-width: 400px;
        height: 44px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        padding: 10px 12px;

        border-bottom: 1px solid var(--GrayScale-G-200, #C4C4C4);
        background: var(--GrayScale-white, #FFF);

        .addressText {
          max-width: 370px;
          display: flex;
          align-items: center;
          flex: 1 0 0;
          color: var(--GrayScale-G-200, #C4C4C4);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
          white-space: nowrap;
          overflow: hidden;
          direction: rtl;

          &.selected {
            color: var(--K-PASS-KPnavy-500_main, #383649);
          }
        }
      }

      .addressSearchBtn {
        display: flex;
        width: 180px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--GrayScale-G-100, #E1E1E1);
        background: var(--GrayScale-white, #FFF);

        .addressSearchText {
          color: var(--GrayScale-G-500_subtext, #6B6B6B);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */
          letter-spacing: -0.21px;
        }
      }
    }

    .inputWrapper {
      display: flex;
      width: 600px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .addressInput {
        height: 44px;
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1 0 0;
        padding: 12px 12px;
        align-self: stretch;

        color: var(--K-PASS-KPnavy-500_main, #383649);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
        &::placeholder {
          color: var(--GrayScale-G-200, #C4C4C4);
        }
      }
    }

    .maxImg{
      background-image: url("../../../../assets/images/modal/login/max_img_mobile.png");
      width: 100.239px;
      height: 140.99px;
    }


    .buttonsWrapper{
      display: flex;
      height: 64px;
      width: 360px;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-bottom: 40px;

      > .signUpNextButton{
        @include tabletButtonBase;
        @include nextButtonTabletText;
        min-width: 360px;
        height: 100%;
        background: var(--GrayScale-G-100, #E1E1E1);
      }

      > .signUpNextHoverButton {
        @include tabletButtonBase;
        @include nextHoverButtonTabletText;
        background: var(--K-PASS-KPblue-700, #352388);
      }
    }

    .signUpCompleteButtonsWrapper{
      display: flex;
      align-items: flex-start;
      gap: 20px;

      > .signUpNextButton{
        @include tabletButtonBase;
        @include nextButtonTabletText;
        min-width: 240px;
        height: 100%;
        background: var(--GrayScale-G-100, #E1E1E1);
      }

      > .signUpNextHoverButton {
        @include tabletButtonBase;
        @include nextHoverButtonTabletText;
        background: var(--K-PASS-KPblue-700, #352388);
      }

      .submitIcon{
        background-image: url("../../../images/logo/submit_mo.png");
        width: 26px;
        height: 26px;
      }

      .submitIconHovered{
        background-image: url("../../../images/logo/submit_mo_hover.png");
        align-content: center;
        width: 26px;
        height: 26px;
      }
    }
  }

  .agreementWrapper{
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-bottom: 40px;

    .agreementArea{
      display: flex;
      //padding: 0 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      align-self: stretch;

      .totalAgreeWrapper{
        display: flex;
        padding: 16px 20px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        height: 77px;

        border-radius: 8px;
        border: 2px solid var(--K-PASS-KPblue-100, #D6CEFF);
        background: var(--GrayScale-white, #FFF);

        > .totalAgreeTextWrapper{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;

          > .totalAgreeTitle{
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
          }

          > .totalAgreeSubTitle{
            color: var(--K-PASS-KPnavy-400, #76718E);

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            letter-spacing: -0.21px;
          }

        }
        > .agreeCheckImg{
          width: 36px;
          height: 36px;
          background-image: url("../../../../assets/images/modal/login/unselected_checkbox.png");

          &.selected {
            background-image: url("../../../../assets/images/modal/login/selected_checkbox.png");
          }
        }
      }

      .selectAgreement{
        display: flex;
        padding: 0 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;


        .selectAgreeWrapper{
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;

          > .selectAgreeTextWrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;

            > .selectAgreeTitle{
              color: var(--K-PASS-KPblue-100, #D6CEFF);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;

              &.necessary{
                color: var(--Basic-Bred-500_main, #FF2C45);
              }
            }

            > .selectAgreeSubTitle{
              color: var(--GrayScale-white, #FFF);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
              letter-spacing: -0.21px;
            }

            > .selectMandatoryInfoWrapper{
              display: flex;
              align-items: center;

              > .selectMandatoryText{
                color: var(--GrayScale-white, #FFF);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 21px */
                letter-spacing: -0.21px;
              }

              > .selectMandatoryImg{
                width: 24px;
                height: 24px;
                background-image: url("../../../../assets/images/modal/login/shortcut.png");
              }
            }

          }
          > .agreeCheckImg{
            width: 36px;
            height: 36px;
            background-image: url("../../../../assets/images/modal/login/unselected_checkbox.png");

            &.selected {
              background-image: url("../../../../assets/images/modal/login/selected_select_checkbox.png");
            }
          }
        }

      }
    }

    .validateText{
      display: flex;
      width: 560px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      color: var(--Basic-Byellow-500_main, #FFE606);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }

  }
}


@include desktop {

  .content {
    display: flex;
    align-items: center;
    gap: 60px;
    width: 600px;

    > .images {
      width: 262px;
      height: 342px;
    }

    > .textDesc {
      width: 600px;
      display: flex;
      flex-direction: column;

      > .emailForm {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;

        > .input {
          width: 600px;
          height: 78px;
          border: 2px solid $blueLight;
          border-radius: 8px;
          font-size: 24px;
          padding: {
            left: 25px;
          }

          &::placeholder {
            color: $g4c6c6c6;
            font-weight: normal;
            letter-spacing: -0.03em;
            line-height: 1.6;
          }
        }

        > .timer {
          position: absolute;
          right: 25px;
          top: 27px;
        }

        > .invalidEmailAddr {
          align-self: stretch;
          font-weight: normal;
          text-align: left;
          margin-top: 12px;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $fontModalAccent;
          white-space: pre-line;
        }

        > .button {
          margin: 40px 50px 0;
          width: 500px;
          height: 98px;
          background-color: $KPNavy;
          color: white;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 24px;
          font-weight: bold;
          line-height: 1.6;

          > .sendIcon {
            width: 38px;
            height: 38px;
          }
        }

        > .buttons {
          margin-top: 40px;
          width: 500px;
          height: 98px;

          display: flex;
          gap: 10px;
        }
      }
    }

    .cancel {
      flex: 1;
      min-width: 149px;
      height: 98px;
      border: 2px solid $blueLight;
      border-radius: 8px;
      @include buttonText;
    }

    .loginBtn {
      width: 331px;
      height: 98px;
      background-color: $KPNavy;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      @include buttonText;
    }

    .desc {
      min-width: 600px;
      font-size: 24px;
      font-weight: normal;
      letter-spacing: -0.05em;
      color: $blueLight;
      line-height: 1.6;

      > .accent {
        color: white;
        font-weight: bold;

      }
    }

    > .authWayWrapper {
      width: 600px;

      display: flex;
      flex-direction: column;
      gap: 40px;

      > .waysWrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          width: 100%;
          padding: 16px 30px 16px 25px;
          border-radius: 20px;
          background-color: $deepMainColor;
          cursor: pointer;

          > .checkbox {
            width: 34px;
            height: 34px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
          }

          > .label {
            width: 100%;
            word-break: break-all;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.015em;
            color: $kpBlueLight;
            text-align: left;

            display: flex;
            flex-wrap: wrap;

            > .light {
              font-weight: normal;
            }
          }

          &:hover {
            background-color: $kpDeepBlue;
          }

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.015em;
        }
      }
    }
  }

  .buttonsWrapper {
    &.withMarginTop {
      margin-top: 40px;
    }

    width: 100%;

    display: flex;
    gap: 10px;
    padding: 0 50px;

    > .outlineButton {
      flex: 1;
      height: 94px;
      border: 2px solid $blueLight;
      border-radius: 8px;
      @include buttonText;
    }

    > .primaryButton {
      width: 340px;
      background-color: $KPNavy;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      @include buttonText;

      display: flex;
      align-items: center;
      justify-content: center;

      > .icon {
        width: 34px;
        height: 34px;
      }
    }

    > .signUpNextButton{
      @include signUpButtonBase;
      @include nextButtonText;
      background: var(--GrayScale-G-100, #E1E1E1);
    }

    > .signUpNextHoverButton{
      @include signUpButtonBase;
      @include nextHoverButtonText;
      background: var(--K-PASS-KPblue-700, #352388);
    }

    > .authenticationNextButton {
      @include signUpButtonBase;
      @include nextButtonText;
      width: 340px;
      background: var(--GrayScale-G-100, #E1E1E1);
    }

    > .authenticationNextHoverButton {
      @include signUpButtonBase;
      @include nextHoverButtonText;
      width: 340px;
      background: var(--K-PASS-KPblue-700, #352388);
    }
  }

  .timerTime {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.5;
    color: $mainColor;
  }

  .inputArea {
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 128px;

    .selectWrapper {
      display: flex;
      width: 600px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .selectTitleWrapper {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;

        .highLight, .titleText {
          color: var(--GrayScale-white, #FFF);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: -0.3px;
        }

        .highLight {
          color: var(--Basic-Bred-500_main, #FF2C45);
        }

        .unHighLight {
          color: var(--K-PASS-KPblue-100, #D6CEFF);
          line-height: 150%;
          font-size: 20px;
        }
      }

      .selectTextFieldWrapper {
        height: 50px;
        display: flex;
        padding: 10px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        border-bottom: 1px solid var(--K-PASS-KPnavy-500_main, #383649);
        background: var(--GrayScale-white, #FFF);

        .selectText {
          display: flex;
          align-items: center;
          flex: 1 0 0;

          color: var(--K-PASS-KPnavy-500_main, #383649);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: -0.3px;

          &.defaultText {
            color: var(--GrayScale-G-200, #C4C4C4);
          }
          &::placeholder {
            color: var(--GrayScale-G-200, #C4C4C4);
          }
          &.unSelected{
            color: var(--GrayScale-G-200, #C4C4C4);
          }
        }

        .arrowImg {
          background-image: url("../../../../assets/images/modal/login/arrow.svg");
          width: 24px;
          height: 24px;
        }

        .inputField {
          display: flex;
          padding: 10px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
        }

        .optionStyle {
          display: flex;
          width: 400px;
          max-height: 200px;
          overflow: auto;
          flex-direction: column;
          align-items: flex-start;

          position: absolute;
          right: 50px;

          transform: translateY(123px);

          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none;
          scrollbar-width: none;

          border: 1px solid var(--GrayScale-G-100, #E1E1E1);
          background: var(--GrayScale-white, #FFF);

          .optionTextWrapper {
            display: flex;
            width: 400px;
            padding: 8px 12px;
            align-items: flex-start;
            gap: 10px;

            .optionText {
              color: var(--GrayScale-G-500_subtext, #6B6B6B);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;

              &.selectedOption {
                color: var(--K-PASS-KPnavy-500_main, #383649);
                font-weight: 700;
              }
            }

            &.selectedOption {
              background: var(--GrayScale-G-000_bg, #F6F6F6);
              color: var(--K-PASS-KPnavy-500_main, #383649);
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
            }
          }
        }

        .cellPhoneSelectStyle{
          right: 50px;
        }

        .nationStyle{
          right: 50px;
        }

      }

        .validateText{
          color: var(--Basic-Byellow-500_main, #FFE606);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }

      .cellPhoneInfo {
        align-self: stretch;
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-blue-Light-D6CEFF, #D6CEFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }

    .genderBoxWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .genderBox {
        display: flex;
        width: 200px;
        height: 54px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--GrayScale-G-100, #E1E1E1);
        background: var(--GrayScale-white, #FFF);

        .genderText {
          color: var(--GrayScale-G-500_subtext, #6B6B6B);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.3px;
        }

        &.selected {
          border-radius: 8px;
          border: 1px solid var(--GrayScale-G-100, #E1E1E1);
          background: var(--K-PASS-KPblue-700, #352388);

          .selectedText {
            color: var(--GrayScale-white, #FFF);
          }
        }
      }
    }

    .addressWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      height: 50px;

      .address {
        display: flex;
        width: 380px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding: 12px 12px;
        align-self: stretch;

        border-bottom: 1px solid var(--GrayScale-G-200, #C4C4C4);
        background: var(--GrayScale-white, #FFF);

        .addressText {
          display: flex;
          align-items: center;
          flex: 1 0 0;
          color: var(--GrayScale-G-200, #C4C4C4);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: -0.3px;

          &.selected {
            color: var(--K-PASS-KPnavy-500_main, #383649);
            //font-size: 16px;
            //font-style: normal;
            //font-weight: 400;
            //line-height: 150%; /* 30px */
            //letter-spacing: -0.3px;
          }
        }
      }

      .addressSearchBtn {
        display: flex;
        width: 200px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--GrayScale-G-100, #E1E1E1);
        background: var(--GrayScale-white, #FFF);

        .addressSearchText {
          color: var(--GrayScale-G-500_subtext, #6B6B6B);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 30px */
          letter-spacing: -0.3px;
        }
      }
    }

    .inputWrapper {
      display: flex;
      width: 600px;
      height: 50px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .addressInput {
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1 0 0;
        padding: 12px 12px;
        height: 50px;
        align-self: stretch;

        color: var(--K-PASS-KPnavy-500_main, #383649);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        letter-spacing: -0.3px;
        &::placeholder {
          color: var(--GrayScale-G-200, #C4C4C4);
        }
      }
    }

    .maxImg{
      background-image: url("../../../../assets/images/modal/login/max_img.png");
      width: 262px;
      height: 342px;
    }


    > .buttonsWrapper{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      margin: 0;
      }

    .signUpCompleteButtonsWrapper{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      margin: 0;

      > .signUpNextButton{
        @include signUpButtonBase;
        @include nextButtonText;
        background: var(--GrayScale-G-100, #E1E1E1);
      }

      > .signUpNextHoverButton{
        @include signUpButtonBase;
        @include nextHoverButtonText;
        background: var(--K-PASS-KPblue-700, #352388);
      }

      .submitIcon{
        background-image: url("../../../images/logo/submit_pc.svg");
        width: 34px;
        height: 34px;
      }

      .submitIconHovered{
        background-image: url("../../../images/logo/submit_pc_hover.svg");
        width: 34px;
        height: 34px;
      }
    }
  }

  .agreementWrapper{
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
    //gap: 40px;
    margin-top: 128px;

    .agreementArea{
      display: flex;
      //padding: 0 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      margin-top: 40px;

      .totalAgreeWrapper{
        display: flex;
        padding: 16px 20px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        height: 77px;

        border-radius: 8px;
        border: 2px solid var(--K-PASS-KPblue-100, #D6CEFF);
        background: var(--GrayScale-white, #FFF);

        > .totalAgreeTextWrapper{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;

          > .totalAgreeTitle{
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
          }

          > .totalAgreeSubTitle{
            color: var(--K-PASS-KPnavy-400, #76718E);

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            letter-spacing: -0.21px;
          }

        }
        > .agreeCheckImg{
          width: 36px;
          height: 36px;
          background-image: url("../../../../assets/images/modal/login/unselected_checkbox.png");

          &.selected {
            background-image: url("../../../../assets/images/modal/login/selected_checkbox.png");
          }
        }
      }

      .selectAgreement{
        display: flex;
        padding: 0 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        align-self: stretch;


        .selectAgreeWrapper{
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;

          > .selectAgreeTextWrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;

            > .selectAgreeTitle{
              color: var(--K-PASS-KPblue-100, #D6CEFF);
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;

              &.necessary{
                color: var(--Basic-Bred-500_main, #FF2C45);
              }
            }

            > .selectAgreeSubTitle{
              color: var(--GrayScale-white, #FFF);
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
            }

            > .selectMandatoryInfoWrapper{
              display: flex;
              align-items: center;

              > .selectMandatoryText{
                color: var(--GrayScale-white, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;
              }

              > .selectMandatoryImg{
                width: 20px;
                height: 20px;
                background-image: url("../../../../assets/images/modal/login/shortcut.png");
              }
            }

          }
          > .agreeCheckImg{
            width: 36px;
            height: 36px;
            background-image: url("../../../../assets/images/modal/login/unselected_checkbox.png");

            &.selected {
              background-image: url("../../../../assets/images/modal/login/selected_select_checkbox.png");
            }
          }
        }

      }
    }

    .validateText{
      margin-top: 40px;
      display: flex;
      width: 560px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      color: var(--Basic-Byellow-500_main, #FFE606);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }

  }

}
