$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  .mainWrapper {
    display: flex;
    //width: 360px;
    width: 100%;
    padding: 120px 20px;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    background: var(--GrayScale-G-000_bg, #F6F6F6);


    //height: 600px;
  }

  .titleBoxWrapper {
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 0 50px 50px 31px;
    background: var(--K-PASS-KPnavy-500_main, #383649);

    > .titleText {
      color: var(--GrayScale-white, #FFF);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: -0.3px;
    }
  }


  .modalStyle {
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .modalStyle .modalWrapper {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 15px;
    background: var(--K-PASS-KPblue-500_main, #583BE2);
  }

  .modalStyle .modalWrapper .modalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .modalStyle .modalWrapper .modalContent .contentType {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .modalStyle .modalWrapper .modalContent .contentType .contentTitle {
    width: 280px;
    color: var(--GrayScale-white, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;
  }

  .contentInput {
    flex: 1 0 0;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;

    &::placeholder {
      color: var(--GrayScale-G-200, #C4C4C4);

    }
  }

  .modalStyle .modalWrapper .modalContent .contentType .inputErrorWrapper{
    max-width: 280px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    > .inputWrapper {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      border-radius: 8px;
      border: 1px solid var(--K-PASS-KPblue-100, #D6CEFF);
      background: var(--GrayScale-white, #FFF);

      height: 48px;

  }

  }


  .modalStyle .modalWrapper .modalContent .nextButtonWrapper {
    display: flex;
    width: 280px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
  }

  .nextButton {
    display: flex;
    width: 280px;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--K-PASS-KPnavy-500_main, #383649);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);

    > .nextButtonText {
      color: var(--GrayScale-white, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }
  }


  .exitModalStyle {
    width: 320px;
    display: flex;
    padding: 30px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    //align-self: stretch;

    border-radius: 15px;
    background: var(--K-PASS-KPblue-500_main, #583BE2);
  }

  .informationWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    > .informationTitle {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: -0.3px;
    }
  }

  .informationSub {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    > .informationSubText {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }
  }


  .agreementUpperWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    align-self: stretch;
  }

  .agreementWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }

  .agreementContentWrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }


  .agreeCheckImg {
    width: 24px;
    height: 24px;
    background-image: url("../../../../assets/images/modal/login/unselected_checkbox_mobile.png");

    &.selected {
      background-image: url("../../../../assets/images/modal/login/selected_select_checkbox_mobile.svg");
    }
  }

  .agreementTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    > .agreementText {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;

      &.all {
        color: var(--GrayScale-white, #FFF);
        /* 📱Mobile/Body/B_Bold */
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }
  }


  .nextCancelButtonWrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    > .cancelButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: var(--GrayScale-G-700_bodytext, #404040);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        /* 📱Mobile/SubTitle/ST_Bold */
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;
      }
    }

    > .nextButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
      background: var(--GrayScale-white, #FFF);

      .buttonText {
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;
      }
    }

    > .exitButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
      background: var(--GrayScale-white, #FFF);

      .buttonText {
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;

        &.selected {
          color: var(--GrayScale-white, #FFF);
        }
      }

      &.selected {
        border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        background: var(--K-PASS-KPblue-700, #352388);
      }
    }
  }

  .radioUpperWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    align-self: stretch;
  }

  .radioWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .radioContentWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .radioButton {
      width: 18px;
      height: 18px;
    }

    > .inputWrapper {
      display: flex;
      width: 280px;
      height: 41px;
      flex-shrink: 0;

      .contentInput{
        color: var(--K-PASS-KPnavy-500_main, #383649);
        padding: 10px 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;

        &::placeholder{
          color: var(--GrayScale-G-200, #C4C4C4);

        }
      }
    }
  }

  .authWayUpperWrapper {

    width: 320px;
    .authWayWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      display: flex;
      padding: 30px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 15px;
      background: var(--K-PASS-KPblue-500_main, #583BE2);

      .desc {
        width: 280px;
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        /* 📱Mobile/Body/B_Bold */
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }

      > .waysWrapper {
        display: flex;
        width: 280px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          > .checkbox {
            width: 24px;
            height: 24px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;

            flex-shrink: 0;
          }

          > .label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 100%;
            word-break: break-all;
            text-align: left;

            > .light {
              font-weight: normal;
            }
          }

          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          background-color: $deepMainColor;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $kpBlueLight;
          cursor: pointer;

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.21px;
        }
      }
    }
  }


  .buttonsWrapper{
    display: flex;
    width: 280px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;


    > .cancelButton{
      display: flex;
      width: 80px;
      padding: 20px 0;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid var(--K-PASS-KPblue-100, #D6CEFF);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }

    > .nextButton{
      display: flex;
      padding: 20px 0;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      border-radius: 8px;
      background: var(--K-PASS-KPnavy-500_main, #383649);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }
  }

  .authCodeWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    > .images {
      display: none;
    }

    > .textDesc {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      > .emailForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;

        > .input {
          margin-bottom: 8px;
          width: 100%;
          height: 50px;
          border: 1px solid $blueLight;
          border-radius: 8px;
          font-size: 16px;
          padding: 12px 52px 12px 16px;

          &::placeholder {
            color: $g4c6c6c6;
            font-weight: normal;
            letter-spacing: -0.03em;
            line-height: 1.6;
          }
        }

        > .contentInput {
          flex: 1 0 0;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;

          &::placeholder {
            color: var(--GrayScale-G-200, #C4C4C4);

          }
        }

        > .timer {
          position: absolute;
          right: 16px;
          top: 16px;
        }

        > .invalidEmailAddr {
          align-self: stretch;
          width: 100%;
          text-align: left;
          font-weight: normal;
          margin-top: 8px;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $fontModalAccent;
          white-space: break-spaces;
        }

        > .button {
          margin-top: 30px;
          width: 100%;
          height: 66px;
          background-color: $KPNavy;
          color: white;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;

          > .sendIcon {
            width: 26px;
            height: 26px;
          }
        }

        > .buttons {
          margin-top: 40px;
          width: 100%;
          height: 66px;
          display: flex;
          gap: 10px;

          > .cancel {
            flex: 1;
            width: 79px;
            height: 66px;
            border: 1px solid $blueLight;
            border-radius: 8px;


            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.4;
            color: white;
          }

          > .loginBtn {
            max-width: 191px;
            flex: 1;
            height: 66px;
            background-color: $KPNavy;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 8px;


            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.4;
            color: white;
          }
        }
      }
    }

    .desc {
      width: 280px;

      text-align: center;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: -0.015em;
      color: $blueLight;
      line-height: 1.5;
      @include wordBreak;

      > .accent {
        color: white;
        font-weight: bold;
      }
    }

    > .authWayWrapper {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 30px;

      > .waysWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          > .checkbox {
            width: 24px;
            height: 24px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;

            flex-shrink: 0;
          }

          > .label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 100%;
            word-break: break-all;
            text-align: left;

            > .light {
              font-weight: normal;
            }
          }

          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          background-color: $deepMainColor;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $kpBlueLight;
          cursor: pointer;

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.21px;
        }
      }
    }
  }

  .errorMessageWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    > .errorMessage{
      color: var(--Basic-Byellow-500_main, #FFE606);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: -0.21px;
    }
  }
}

@include tablet {
  .mainWrapper {
    display: flex;
    //width: 360px;
    width: 100%;
    padding: 160px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    background: var(--GrayScale-G-000_bg, #F6F6F6);


    //height: 600px;
  }

  .titleBoxWrapper {
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 0 50px 50px 31px;
    background: var(--K-PASS-KPnavy-500_main, #383649);

    > .titleText {
      color: var(--GrayScale-white, #FFF);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: -0.3px;
    }
  }


  .modalStyle {
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .modalStyle .modalWrapper {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 15px;
    background: var(--K-PASS-KPblue-500_main, #583BE2);
  }

  .modalStyle .modalWrapper .modalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .modalStyle .modalWrapper .modalContent .contentType {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .modalStyle .modalWrapper .modalContent .contentType .contentTitle {
    width: 280px;
    color: var(--GrayScale-white, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;
  }

  .contentInput {
    flex: 1 0 0;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;

    &::placeholder {
      color: var(--GrayScale-G-200, #C4C4C4);

    }
  }

  .modalStyle .modalWrapper .modalContent .contentType .inputErrorWrapper{
    max-width: 280px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    > .inputWrapper {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      border-radius: 8px;
      border: 1px solid var(--K-PASS-KPblue-100, #D6CEFF);
      background: var(--GrayScale-white, #FFF);

      height: 48px;

    }

  }


  .modalStyle .modalWrapper .modalContent .nextButtonWrapper {
    display: flex;
    width: 280px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
  }

  .nextButton {
    display: flex;
    width: 280px;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--K-PASS-KPnavy-500_main, #383649);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);

    > .nextButtonText {
      color: var(--GrayScale-white, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }
  }


  .exitModalStyle {
    width: 320px;
    display: flex;
    padding: 30px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    //align-self: stretch;

    border-radius: 15px;
    background: var(--K-PASS-KPblue-500_main, #583BE2);
  }

  .informationWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    > .informationTitle {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: -0.3px;
    }
  }

  .informationSub {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    > .informationSubText {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }
  }


  .agreementUpperWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    align-self: stretch;
  }

  .agreementWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }

  .agreementContentWrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }


  .agreeCheckImg {
    width: 24px;
    height: 24px;
    background-image: url("../../../../assets/images/modal/login/unselected_checkbox_mobile.png");

    &.selected {
      background-image: url("../../../../assets/images/modal/login/selected_select_checkbox_mobile.svg");
    }
  }

  .agreementTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    > .agreementText {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;

      &.all {
        color: var(--GrayScale-white, #FFF);
        /* 📱Mobile/Body/B_Bold */
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }
  }


  .nextCancelButtonWrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    > .cancelButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: var(--GrayScale-G-700_bodytext, #404040);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        /* 📱Mobile/SubTitle/ST_Bold */
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;
      }
    }

    > .nextButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
      background: var(--GrayScale-white, #FFF);

      .buttonText {
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;
      }
    }

    > .exitButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
      background: var(--GrayScale-white, #FFF);

      .buttonText {
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;

        &.selected {
          color: var(--GrayScale-white, #FFF);
        }
      }

      &.selected {
        border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        background: var(--K-PASS-KPblue-700, #352388);
      }
    }
  }

  .radioUpperWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    align-self: stretch;
  }

  .radioWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .radioContentWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .radioButton {
      width: 18px;
      height: 18px;
    }

    > .inputWrapper {
      display: flex;
      width: 280px;
      height: 41px;
      flex-shrink: 0;

      .contentInput{
        color: var(--K-PASS-KPnavy-500_main, #383649);
        padding: 10px 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;

        &::placeholder{
          color: var(--GrayScale-G-200, #C4C4C4);

        }
      }
    }
  }

  .authWayUpperWrapper {

    width: 320px;
    .authWayWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      display: flex;
      padding: 30px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 15px;
      background: var(--K-PASS-KPblue-500_main, #583BE2);

      .desc {
        width: 280px;
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        /* 📱Mobile/Body/B_Bold */
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }

      > .waysWrapper {
        display: flex;
        width: 280px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          > .checkbox {
            width: 24px;
            height: 24px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;

            flex-shrink: 0;
          }

          > .label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 100%;
            word-break: break-all;
            text-align: left;

            > .light {
              font-weight: normal;
            }
          }

          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          background-color: $deepMainColor;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $kpBlueLight;
          cursor: pointer;

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.21px;
        }
      }
    }
  }


  .buttonsWrapper{
    display: flex;
    width: 280px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;


    > .cancelButton{
      display: flex;
      width: 80px;
      padding: 20px 0;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid var(--K-PASS-KPblue-100, #D6CEFF);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }

    > .nextButton{
      display: flex;
      padding: 20px 0;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      border-radius: 8px;
      background: var(--K-PASS-KPnavy-500_main, #383649);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }
  }

  .authCodeWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    > .images {
      display: none;
    }

    > .textDesc {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      > .emailForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;

        > .input {
          margin-bottom: 8px;
          width: 100%;
          height: 50px;
          border: 1px solid $blueLight;
          border-radius: 8px;
          font-size: 16px;
          padding: 12px 52px 12px 16px;

          &::placeholder {
            color: $g4c6c6c6;
            font-weight: normal;
            letter-spacing: -0.03em;
            line-height: 1.6;
          }
        }

        > .contentInput {
          flex: 1 0 0;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;

          &::placeholder {
            color: var(--GrayScale-G-200, #C4C4C4);

          }
        }

        > .timer {
          position: absolute;
          right: 16px;
          top: 16px;
        }

        > .invalidEmailAddr {
          align-self: stretch;
          width: 100%;
          text-align: left;
          font-weight: normal;
          margin-top: 8px;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $fontModalAccent;
          white-space: break-spaces;
        }

        > .button {
          margin-top: 30px;
          width: 100%;
          height: 66px;
          background-color: $KPNavy;
          color: white;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;

          > .sendIcon {
            width: 26px;
            height: 26px;
          }
        }

        > .buttons {
          margin-top: 40px;
          width: 100%;
          height: 66px;
          display: flex;
          gap: 10px;

          > .cancel {
            flex: 1;
            width: 79px;
            height: 66px;
            border: 1px solid $blueLight;
            border-radius: 8px;


            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.4;
            color: white;
          }

          > .loginBtn {
            max-width: 191px;
            flex: 1;
            height: 66px;
            background-color: $KPNavy;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 8px;


            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.4;
            color: white;
          }
        }
      }
    }

    .desc {
      width: 280px;

      text-align: center;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: -0.015em;
      color: $blueLight;
      line-height: 1.5;
      @include wordBreak;

      > .accent {
        color: white;
        font-weight: bold;
      }
    }

    > .authWayWrapper {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 30px;

      > .waysWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          > .checkbox {
            width: 24px;
            height: 24px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;

            flex-shrink: 0;
          }

          > .label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 100%;
            word-break: break-all;
            text-align: left;

            > .light {
              font-weight: normal;
            }
          }

          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          background-color: $deepMainColor;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $kpBlueLight;
          cursor: pointer;

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.21px;
        }
      }
    }
  }

  .errorMessageWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    > .errorMessage{
      color: var(--Basic-Byellow-500_main, #FFE606);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: -0.21px;
    }
  }
}

@include desktop {
  .mainWrapper {
    display: flex;
    //width: 360px;
    width: 100%;
    padding: 200px 20px;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    background: var(--GrayScale-G-000_bg, #F6F6F6);


    //height: 600px;
  }

  .titleBoxWrapper {
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 0 50px 50px 31px;
    background: var(--K-PASS-KPnavy-500_main, #383649);

    > .titleText {
      color: var(--GrayScale-white, #FFF);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: -0.3px;
    }
  }


  .modalStyle {
    display: flex;
    width: 320px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .modalStyle .modalWrapper {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 15px;
    background: var(--K-PASS-KPblue-500_main, #583BE2);
  }

  .modalStyle .modalWrapper .modalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .modalStyle .modalWrapper .modalContent .contentType {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .modalStyle .modalWrapper .modalContent .contentType .contentTitle {
    width: 280px;
    color: var(--GrayScale-white, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;
  }

  .contentInput {
    flex: 1 0 0;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.24px;

    &::placeholder {
      color: var(--GrayScale-G-200, #C4C4C4);

    }
  }

  .modalStyle .modalWrapper .modalContent .contentType .inputErrorWrapper{
    max-width: 280px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    > .inputWrapper {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      border-radius: 8px;
      border: 1px solid var(--K-PASS-KPblue-100, #D6CEFF);
      background: var(--GrayScale-white, #FFF);

      height: 48px;

    }

  }


  .modalStyle .modalWrapper .modalContent .nextButtonWrapper {
    display: flex;
    width: 280px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
  }

  .nextButton {
    display: flex;
    width: 280px;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--K-PASS-KPnavy-500_main, #383649);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);

    > .nextButtonText {
      color: var(--GrayScale-white, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }
  }


  .exitModalStyle {
    width: 320px;
    display: flex;
    padding: 30px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    //align-self: stretch;

    border-radius: 15px;
    background: var(--K-PASS-KPblue-500_main, #583BE2);
  }

  .informationWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    > .informationTitle {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: -0.3px;
    }
  }

  .informationSub {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    > .informationSubText {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }
  }


  .agreementUpperWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    align-self: stretch;
  }

  .agreementWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }

  .agreementContentWrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }


  .agreeCheckImg {
    width: 24px;
    height: 24px;
    background-image: url("../../../../assets/images/modal/login/unselected_checkbox_mobile.png");

    &.selected {
      background-image: url("../../../../assets/images/modal/login/selected_select_checkbox_mobile.svg");
    }
  }

  .agreementTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;

    > .agreementText {
      align-self: stretch;
      color: var(--GrayScale-white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;

      &.all {
        color: var(--GrayScale-white, #FFF);
        /* 📱Mobile/Body/B_Bold */
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }
  }


  .nextCancelButtonWrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    > .cancelButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: var(--GrayScale-G-700_bodytext, #404040);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        /* 📱Mobile/SubTitle/ST_Bold */
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;
      }
    }

    > .nextButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
      background: var(--GrayScale-white, #FFF);

      .buttonText {
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;
      }
    }

    > .exitButton {
      display: flex;
      width: 120px;
      height: 66px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
      background: var(--GrayScale-white, #FFF);

      .buttonText {
        color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;

        &.selected {
          color: var(--GrayScale-white, #FFF);
        }
      }

      &.selected {
        border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
        background: var(--K-PASS-KPblue-700, #352388);
      }
    }
  }

  .radioUpperWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    align-self: stretch;
  }

  .radioWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .radioContentWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .radioButton {
      width: 18px;
      height: 18px;
    }

    > .inputWrapper {
      display: flex;
      width: 280px;
      height: 41px;
      flex-shrink: 0;

      .contentInput{
        color: var(--K-PASS-KPnavy-500_main, #383649);
        padding: 10px 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;

        &::placeholder{
          color: var(--GrayScale-G-200, #C4C4C4);

        }
      }
    }
  }

  .authWayUpperWrapper {

    width: 320px;
    .authWayWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      display: flex;
      padding: 30px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 15px;
      background: var(--K-PASS-KPblue-500_main, #583BE2);

      .desc {
        width: 280px;
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        /* 📱Mobile/Body/B_Bold */
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }

      > .waysWrapper {
        display: flex;
        width: 280px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          > .checkbox {
            width: 24px;
            height: 24px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;

            flex-shrink: 0;
          }

          > .label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 100%;
            word-break: break-all;
            text-align: left;

            > .light {
              font-weight: normal;
            }
          }

          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          background-color: $deepMainColor;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $kpBlueLight;
          cursor: pointer;

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.21px;
        }
      }
    }
  }


  .buttonsWrapper{
    display: flex;
    width: 280px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;


    > .cancelButton{
      display: flex;
      width: 80px;
      padding: 20px 0;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid var(--K-PASS-KPblue-100, #D6CEFF);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }

    > .nextButton{
      display: flex;
      padding: 20px 0;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      border-radius: 8px;
      background: var(--K-PASS-KPnavy-500_main, #383649);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);

      .buttonText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }
    }
  }

  .authCodeWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    > .images {
      display: none;
    }

    > .textDesc {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      > .emailForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;

        > .input {
          margin-bottom: 8px;
          width: 100%;
          height: 50px;
          border: 1px solid $blueLight;
          border-radius: 8px;
          font-size: 16px;
          padding: 12px 52px 12px 16px;

          &::placeholder {
            color: $g4c6c6c6;
            font-weight: normal;
            letter-spacing: -0.03em;
            line-height: 1.6;
          }
        }

        > .contentInput {
          flex: 1 0 0;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;

          &::placeholder {
            color: var(--GrayScale-G-200, #C4C4C4);

          }
        }

        > .timer {
          position: absolute;
          right: 16px;
          top: 16px;
        }

        > .invalidEmailAddr {
          align-self: stretch;
          width: 100%;
          text-align: left;
          font-weight: normal;
          margin-top: 8px;
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $fontModalAccent;
          white-space: break-spaces;
        }

        > .button {
          margin-top: 30px;
          width: 100%;
          height: 66px;
          background-color: $KPNavy;
          color: white;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;

          > .sendIcon {
            width: 26px;
            height: 26px;
          }
        }

        > .buttons {
          margin-top: 40px;
          width: 100%;
          height: 66px;
          display: flex;
          gap: 10px;

          > .cancel {
            flex: 1;
            width: 79px;
            height: 66px;
            border: 1px solid $blueLight;
            border-radius: 8px;


            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.4;
            color: white;
          }

          > .loginBtn {
            max-width: 191px;
            flex: 1;
            height: 66px;
            background-color: $KPNavy;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 8px;


            font-size: 2.4rem;
            font-weight: bold;
            line-height: 1.4;
            color: white;
          }
        }
      }
    }

    .desc {
      width: 280px;

      text-align: center;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: -0.015em;
      color: $blueLight;
      line-height: 1.5;
      @include wordBreak;

      > .accent {
        color: white;
        font-weight: bold;
      }
    }

    > .authWayWrapper {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 30px;

      > .waysWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > .aWay {
          display: flex;
          gap: 10px;
          align-items: center;

          > .checkbox {
            width: 24px;
            height: 24px;

            background-image: url("../../../../assets/images/modal/login/checkbox_default.svg");
            background-size: 100%;
            background-repeat: no-repeat;

            flex-shrink: 0;
          }

          > .label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 100%;
            word-break: break-all;
            text-align: left;

            > .light {
              font-weight: normal;
            }
          }

          width: 100%;
          padding: 12px 20px;
          border-radius: 10px;
          background-color: $deepMainColor;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $kpBlueLight;
          cursor: pointer;

          &.active {
            background-color: $kpDeepBlue;

            > .checkbox {
              background-image: url("../../../../assets/images/modal/login/checkbox_checked.svg");
            }

            > .label {
              color: white;

              > .light {
                font-weight: bold;
              }
            }
          }
        }

        > .errorMessage {
          color: $modalErrorColor;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.21px;
        }
      }
    }
  }

  .errorMessageWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    > .errorMessage{
      color: var(--Basic-Byellow-500_main, #FFE606);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: -0.21px;
    }
  }
}