$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@mixin resultTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $hotpink;
}

@mixin resultDescription {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $g2757575;
}

@mixin resultTitleTablet {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.36px;
  color: $hotpink;
}

@mixin resultDescriptionTablet {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.24px;
  color: $g2757575;
}

@include desktop {
  .paymentResult {
    align-items: center;
    margin-top: 154px;
    position: relative;

    > .topBg {
      position: absolute;
      width: 100%;
      height: 407px;
      left: 0;
      top: 0;
      background-color: $mainColor;
      z-index: $zIndexNormal;
    }

    > .bottomBg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $lightGray;
      z-index: $zIndexBgItem;
    }

    > .contentArea {
      padding: {
        top: 100px;
        bottom: 100px;
      };
      z-index: $zIndexContent;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .content {
        background-color: white;
        width: 780px;
        border-radius: 20px;
        position: relative;
        padding: 70px 80px;
        display: flex;
        flex-direction: column;

        > .success {
          width: 100%;

          > .bgItem {
            position: absolute;
            right: -130px;
            bottom: -40px;
          }

          > .description {
            margin-bottom: 60px;

            > .title {
              font-weight: bold;
              font-size: 3.2rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $KPNavy;
            }

            > .descText {
              margin-top: 20px;
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $g2757575;

              > .accent {
                font-weight: bold;
                color: $KPNavy;
              }
            }
          }

          > .descEtc {
            margin: {
              top: 20px;
            };
            text-align: center;

            > .text {
              margin: 0 auto;
              font-weight: normal;
              font-size: 1.6rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $g2757575;
            }
          }
        }

        > .cancelled {
          width: 100%;
          display: flex;
          flex-direction: column;

          > .title {
            @include resultTitle;
          }

          > .description {
            margin-top: 20px;
            @include resultDescription;
          }

          > .imgArea {
            margin-top: 60px;
            text-align: center;

            > img {
              width: 193px;
              height: 185px;
            }
          }
        }

        > .failed {
          width: 100%;
          display: flex;
          flex-direction: column;

          > .title {
            @include resultTitle;
          }

          > .description {
            margin: {
              top: 20px;
              bottom: 60px;
            };
            @include resultDescription;
          }
        }
      }

      > .paymentRetryBtn {
        margin-top: 30px;
        background-color: $mainColor;
        width: 480px;
        height: 84px;
        border-radius: 12px;
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 28px;
        color: white;
      }
    }
  }

  .payDetail {
    margin: {
      left: auto;
      right: auto;
    };
    padding: 30px 0;
    width: 480px;
    border-top: 2px solid $g5dedede;
    border-bottom: 2px solid $g5dedede;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .infoTable {
      max-width: 480px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(3, auto);
      row-gap: 20px;
      column-gap: 40px;
      font-size: 2rem;
      line-height: 1.6;
      letter-spacing: -0.05em;
      color: $KPNavy;

      > dt {
        font-weight: bold;

        &.accent {
          color: $mainColor;
        }
      }

      > dd {
        font-weight: normal;

        &.accountInfo {
          display: flex;

          > .copyBtn {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    > .dashDivider {
      margin: 30px 0;
      width: 100%;
      border: 1px dashed $g5dedede;
    }

    > .notice {
      display: flex;
      justify-content: center;
    }
  }
}

@include tablet {
  .paymentResult {
    align-items: center;
    margin-top: 154px;
    position: relative;

    > .topBg {
      position: absolute;
      width: 100%;
      height: 407px;
      left: 0;
      top: 0;
      background-color: $mainColor;
      z-index: $zIndexNormal;
    }

    > .bottomBg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $lightGray;
      z-index: $zIndexBgItem;
    }

    > .contentArea {
      padding: {
        top: 100px;
        bottom: 150px;
      };
      z-index: $zIndexContent;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .content {
        background-color: white;
        width: 608px;
        border-radius: 20px;
        position: relative;
        padding: 50px 60px;
        display: flex;
        flex-direction: column;

        > .success {
          width: 100%;

          > .bgItem {
            display: none;
          }

          > .description {
            margin-bottom: 50px;

            > .title {
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%;
              letter-spacing: -0.36px;
              color: $KPNavy;
            }

            > .descText {
              margin-top: 20px;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.24px;
              color: $g2757575;

              > .accent {
                font-weight: bold;
                color: $KPNavy;
              }
            }
          }

          > .descEtc {
            margin: {
              top: 12px;
            };
            text-align: center;

            > .text {
              margin: 0 auto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.21px;
              color: $g2757575;
            }
          }
        }

        > .cancelled {
          width: 100%;
          display: flex;
          flex-direction: column;

          > .title {
            @include resultTitleTablet;
          }

          > .description {
            margin-top: 20px;
            @include resultDescriptionTablet;
          }

          > .imgArea {
            margin-top: 60px;
            text-align: center;

            > img {
              width: 119.591px;
              height: 112.233px;
            }
          }
        }

        > .failed {
          width: 100%;
          display: flex;
          flex-direction: column;

          > .title {
            @include resultTitleTablet;
          }

          > .description {
            margin: {
              top: 20px;
              bottom: 50px;
            };
            @include resultDescriptionTablet;
          }
        }
      }

      > .paymentRetryBtn {
        margin-top: 30px;
        background-color: $mainColor;
        width: 480px;
        height: 84px;
        border-radius: 12px;
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 28px;
        color: white;
      }
    }
  }

  .payDetail {
    margin: {
      left: auto;
      right: auto;
    };
    padding: 20px 0;
    width: 480px;
    border-top: 2px solid $g5dedede;
    border-bottom: 2px solid $g5dedede;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    //gap: 20px;
    align-self: stretch;

    > .infoTable {
      max-width: 480px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(3, auto);
      row-gap: 10px;
      column-gap: 10px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.24px;
      color: $KPNavy;

      > dt {
        font-weight: bold;

        &.accent {
          color: $mainColor;
        }
      }

      > dd {
        font-weight: normal;

        &.accountInfo {
          display: flex;

          > .copyBtn {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    > .dashDivider {
      margin: 20px 0;
      width: 100%;
      border: 1px dashed $g5dedede;
    }

    > .notice {
      display: flex;
      justify-content: center;

    }
  }
}

@include mobile {
  .paymentResult {
    margin: {
      top: -10px;
    };
    padding-bottom: 100px;
    position: relative;
    background-color: #F6F6F6;
  }

  .topBg {
    position: absolute;
    width: 100%;
    height: 200px;
    left: 0;
    top: 0;
    background-color: $mainColor;
    z-index: $zIndexNormal;
  }

  .bottomBg {
    display: none;
  }

  .contentArea {
    margin: 60px auto 0;
    width: calc(100% - 40px);
    z-index: $zIndexContent;

    > .content {
      padding: 50px 20px;
      background-color: white;
      border-radius: 8px;

      > .success {
        .bgItem {
          display: none;
        }

        .description {
          .title {
            font-weight: bold;
            font-size: 24px;
            line-height: 160%;
            letter-spacing: -0.03em;
            color: $KPNavy;
          }

          .descText {
            margin-top: 20px;
            font-size: 16px;
            line-height: 160%;
            letter-spacing: -0.03em;
            color: $fontLight;

            .accent {
              font-weight: bold;
              color: $KPNavy;
            }
          }
        }
      }
    }
  }


  .payDetail {
    margin-top: 50px;
    padding: 20px 0;
    border-top: 1px solid $g5dedede;
    border-bottom: 1px solid $g5dedede;

    .infoTable {
      padding: 0 10px;
      display: grid;
      grid-template-columns: 60px fit-content(100%);
      grid-template-rows: repeat(3, auto);
      row-gap: 10px;
      column-gap: 10px;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: -0.03em;
      color: $KPNavy;

      dt {
        font-weight: bold;
      }

      dd {
        &.accountInfo {
          display: flex;
          align-items: center;
          justify-content: center;

          > .copyBtn {
            > img {
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
  }

  .descEtc {
    margin-top: 12px;
    text-align: center;

    > .text {
      font-size: 12px;
      line-height: 160%;
      text-align: center;
      letter-spacing: -0.05em;
      color: $fontLight;
    }
  }

  .cancelled, .failed {
    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 160%;
      letter-spacing: -0.03em;
      color: $hotpink;
    }

    .description {
      margin-top: 20px;

      font-size: 16px;
      line-height: 160%;
      letter-spacing: -0.03em;
      color: $fontLight;
    }

    .imgArea {
      margin-top: 40px;
      text-align: center;
    }
  }
}