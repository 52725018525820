$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  .main {
    > .title {
      margin-top: 30px;

      > .textArea {
        display: none;
      }
    }

    > .productDetail {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 30px;

      padding: {
        left: 30px;
        right: 30px;
      }

      > .thumbnail {
        width: 100%;
        height: 100%;
        padding: {
          left: 10px;
          right: 10px;
        }

        > img {
          width: 100%;
          height: auto;
          border-radius: 8px;
        }
      }

      > .desc {
        > .title {
          font-weight: bold;
          font-size: 24px;
          line-height: 160%;
          letter-spacing: -0.05em;
          color: $KPNavy;
        }

        > .descList {
          margin-top: 10px;

          > li {
            list-style: disc inside;
            padding-left: 10px;
            font-size: 16px;
            line-height: 160%;
            letter-spacing: -0.05em;
            color: $KPNavy;

            &.accent {
              color: $hotpink;
            }
          }
        }

        > .dashLine {
          border: 1px dashed #dedede;
          margin: {
            top: 30px;
            left: -10px;
            right: -10px;
          }
        }

        > .quantityAndPrice {
          display: grid;
          grid-template-columns: auto auto;
          grid-column-gap: 8px;

          margin-top: 20px;

          > .quantity {
            display: flex;
            gap: 16px;
            align-items: center;

            > .number {
              font-weight: bold;
              font-size: 20px;
              line-height: 150%;
              letter-spacing: -0.03em;
              color: $navyMedium;
            }
          }
        }

        .regularPrice {
          text-align: right;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: -0.03em;
          text-decoration-line: line-through;
          color: $fontLight;
        }

        .discountPrice {
          grid-column: 2;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          &.positionBottomRow {
            grid-column: 1/3;
          }

          > .icon {
            width: 19px;
            height: 19px;
          }

          > .badge {
            background-color: $hotpink;
            border-radius: 100px;
            height: 19px;
            padding: {
              left: 8px;
              right: 8px;
            }

            font-weight: bold;
            font-size: 12px;
            line-height: 160%;
            letter-spacing: -0.05em;
            color: white;
          }

          > .price {
            margin-left: 12px;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: -0.03em;
            color: $KPNavy;
          }
        }
      }

      .purchaseBtn {
        margin-top: 30px;
        width: 100%;
        height: 66px;
        border-radius: 8px;
        background-color: $mainColor;

        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        color: white;
      }
    }

    .imageArea {
      margin: {
        top: 120px;
        bottom: 100px;
      }

      display: flex;
      flex-direction: column;

      img {
        width: 100%;
        height: auto;
      }

      .videoWrapper {
        width: 100%;
        display: flex;

        > video {
          width: 100%;
        }
      }
    }
  }
}

@include tablet {
  .bannerMain {
    margin-top: 274px;
  }
  .main {
    > .title {
      margin-top: 170px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;

      > .textArea {
        width: 204px;
        height: 52px;
      }

      > .bgItem1 {
        width: 111px;
        height: 112px;
        background-color: rgba(56, 54, 73, 0.1);
        position: absolute;
        top: 170px;
        left: calc(50vw - 400px);
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }

    > .productDetail {
      @include tabletWidth;

      display: flex;
      max-width: 768px;
      padding: 0 20px;
      gap: 20px;

      > .thumbnail {
        width: 304px;
        height: 304px;

        > img {
          border-radius: 20px;
          width: 100%;
          height: 100%;
        }
      }

      > .desc {
        width: 404px;
        margin-left: 20px;

        > .title {
          font-weight: 700;
          font-size: 2.4rem;
          line-height: 1.4;
          letter-spacing: -0.036em;
          color: $KPNavy;
        }

        > .descList {
          margin: {
            top: 10px;
            bottom: 30px;
          }

          > li {
            list-style: disc;
            margin-left: 3rem;
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: -0.024em;
            font-weight: normal;
            color: $KPNavy;

            &.accent {
              color: $hotpink;
            }
          }
        }

        > .dashLine {
          border: 1px dashed #dedede;
          width: 100%;
          height: 0;
        }

        > .quantityAndPrice {
          padding: 0 20px;
          display: grid;
          grid-template-columns: auto auto;
          margin: 30px 0 30px 0;
          grid-row-gap: 8px;

          > .quantity {
            display: flex;

            > .qtyBtn {
              width: 36px;
              height: 36px;
            }

            > .number {
              padding: 0 16px;
              font-size: 2rem;
              line-height: 1.4;
              font-weight: bold;
              letter-spacing: -0.03em;
              color: $navyMedium;
            }
          }

          > .regularPrice {
            text-align: right;
            font-size: 2.4rem;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: -0.03em;
            color: $g2757575;
            text-decoration: line-through;
          }

          > .discountPrice {
            grid-column: 2;

            display: flex;
            justify-content: flex-end;
            align-items: center;

            > .icon {
              width: 26px;
              height: 26px;
            }

            > .badge {
              background-color: $hotpink;
              border-radius: 100px;
              height: 26px;
              padding: {
                left: 8px;
                right: 8px;
              }
              color: white;
              font-size: 1.6rem;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.05em;
            }

            > .price {
              margin-left: 12px;
              font-size: 2.4rem;
              line-height: 1.4;
              letter-spacing: -0.03em;
              color: $KPNavy;
              font-weight: 700;
            }
          }
        }

        > .purchaseBtn {
          background-color: $mainColor;
          width: 364px;
          height: 66px;
          border-radius: 12px;
          color: white;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 24px;
          gap: 10px;
        }
      }
    }

    > .imageArea {
      margin: {
        top: 130px;
        bottom: 130px;
        left: auto;
        right: auto;
      }
      max-width: 700px;

      position: relative;
      width: 728px;

      display: flex;
      flex-direction: column;

      > .bgItem1 {
        position: absolute;
        top: -230px;
        right: -120px;
        width: 231px;
        height: 231px;
        border-radius: 50%;
        border: 50px solid rgba(88, 59, 226, 0.1);
        z-index: $zIndexBgItem;
      }

      .videoWrapper {
        width: 100%;
        height: 560px;
        background-image: url("../../../../assets/images/sub/purchase_voucher/video_background.png");
        background-size: 100%;
        position: relative;

        > video {
          position: absolute;
          width: 429px;
          top: 182px;
          left: 149px;
          border-radius: 10px;
        }
      }
    }
  }
}

@include desktop {
  .bannerMain {
    margin-top: 350px;
  }
  .main {
    > .title {
      margin-top: 214px;
      margin-bottom: 100px;
      display: flex;
      justify-content: center;

      > .textArea {
        width: 373px;
      }

      > .bgItem1 {
        width: 239px;
        height: 239px;
        background-color: rgba(56, 54, 73, 0.1);
        position: absolute;
        top: 135px;
        left: calc(50vw - 708px);
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }

    > .productDetail {
      @include desktopWidth;

      display: flex;

      > .thumbnail {
        width: 420px;
        height: 420px;

        > img {
          border-radius: 20px;
          width: 100%;
          height: 100%;
        }
      }

      > .desc {
        margin-left: 80px;
        width: 480px;

        > .title {
          font-weight: bold;
          font-size: 4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
        }

        > .descList {
          margin: {
            top: 20px;
            bottom: 40px;
          }

          > li {
            list-style: disc;
            margin-left: 3rem;
            font-size: 2rem;
            line-height: 2;
            letter-spacing: -0.05em;
            font-weight: normal;
            color: $KPNavy;

            &.accent {
              color: $hotpink;
            }
          }
        }

        > .dashLine {
          border: 1px dashed #dedede;
          width: 100%;
          height: 0;
        }

        > .quantityAndPrice {
          padding: 0 20px;
          display: grid;
          grid-template-columns: auto auto;
          margin-top: 30px;
          grid-row-gap: 8px;

          > .quantity {
            display: flex;

            > .qtyBtn {
              width: 36px;
              height: 36px;
            }

            > .number {
              padding: 0 16px;
              font-size: 2.4rem;
              line-height: 1.5;
              font-weight: bold;
              letter-spacing: -0.03em;
              color: $navyMedium;
            }
          }

          > .regularPrice {
            text-align: right;
            font-size: 2.4rem;
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: -0.03em;
            color: $g2757575;
            text-decoration: line-through;
          }

          > .discountPrice {
            grid-column: 2;

            display: flex;
            justify-content: flex-end;
            align-items: center;

            > .icon {
              width: 26px;
              height: 26px;
            }

            > .badge {
              background-color: $hotpink;
              border-radius: 100px;
              height: 26px;
              padding: {
                left: 8px;
                right: 8px;
              }
              color: white;
              font-size: 1.6rem;
              font-weight: bold;
              line-height: 1.6;
              letter-spacing: -0.05em;
            }

            > .price {
              margin-left: 12px;
              font-size: 3.2rem;
              line-height: 1.5;
              letter-spacing: -0.03em;
              color: $KPNavy;
              font-weight: bold;
            }
          }
        }

        > .purchaseBtn {
          background-color: $mainColor;
          width: 100%;
          height: 84px;
          border-radius: 12px;
          color: white;
          font-size: 2.4rem;
          font-weight: bold;
          line-height: 28px;
          margin-top: 40px;
        }
      }
    }

    > .imageArea {
      margin: {
        top: 130px;
        bottom: 130px;
        left: auto;
        right: auto;
      }
      max-width: 700px;

      position: relative;
      width: 1120px;

      display: flex;
      flex-direction: column;

      > .bgItem1 {
        position: absolute;
        top: -338px;
        right: -425px;
        width: 498px;
        height: 498px;
        border-radius: 50%;
        border: 110px solid rgba(88, 59, 226, 0.1);
        z-index: $zIndexBgItem;
      }

      > .bgItem2 {
        position: absolute;
        top: 674px;
        left: -400px;
        background: rgba(88, 59, 226, 0.1);
        width: 480px;
        height: 480px;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        z-index: $zIndexBgItem;
      }

      > .bgItem3 {
        position: absolute;
        right: -411px;
        bottom: -458px;
        background: rgba(56, 54, 73, 0.1);
        width: 417px;
        height: 417px;
        border-radius: 50%;
        z-index: $zIndexBgItem;
      }

      .videoWrapper {
        width: 100%;
        height: 862px;
        background-image: url("../../../../assets/images/sub/purchase_voucher/video_background.png");
        background-size: 100%;
        position: relative;

        > video {
          position: absolute;
          width: 660px;
          top: 280px;
          left: 230px;
          border-radius: 10px;
        }
      }
    }
  }
}
