$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  .partnership {
    width: 100%;
    margin-top: -10px;
    overflow-x: hidden;

    > .title {
      @include subpageTitleMobile("../../images/sub/understanding/title_bg_mobile.svg");
    }

    > .article {
      margin-top: -1px;
      width: 100%;

      > .bgItem1 {

        display: none;
      }

      > .bgItem2 {
        display: none;
      }

      > .bgItem3 {
        display: none;
      }

      > .imageSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;

        > .videoWrapper {
          width: 100%;

          > video {
            width: 100%;
          }
        }
      }
    }

    &.companyList {
      > .title {
        @include subpageTitleMobile("../../images/sub/researcher/title_bg.svg");
      }

      > .article {
        background-color: $KPNavy;
        padding: 70px 20px;

        display: flex;
        flex-direction: column;
        align-items: center;

        > .section {
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;

          .domesticSectionTitle {
            background-color: white;
            color: $KPNavy;
            border-radius: 0 200px 200px 200px;
            padding: 12px 40px;

            font-size: 20px;
            font-weight: bold;
            line-height: 1.4;
            letter-spacing: -0.015em;
          }

          > .description {
            padding: 0 10px;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.015em;
            text-align: center;
            color: $kpNavyLight;
            word-break: keep-all;

            > .white {
              color: white;
            }
          }

          > .mapAndCompanyWrapper {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            > .mapWrapper {
              width: 100%;
              height: 280px;
              border-radius: 20px;
              overflow: hidden;

              > .map {
                width: 100%;
                height: 100%;
              }
            }

            > .companyListWrapper {
              width: 100%;
              height: 530px;
              border-radius: 20px;
              background-color: white;
              padding: 20px 0;
            }
          }
        }

        &.white {
          padding: {
            top: 477px;
            bottom: 0;
          };
          background-color: white;
          position: relative;

          gap: 100px;

          > .bgItemSpace {
            width: 363px;
            height: 377px;

            z-index: $zIndexBgItemOnBg;
            position: absolute;
            left: calc(50vw - 270px);
            top: 70px;

            > img {
              width: 100%;
            }
          }

          > .bgItemRight {
            display: none;
          }

          > .bgItemLeft {
            display: none;
          }

          > .section {
            z-index: $zIndexContent;

            .sectionTitle {
              width: 170px;
              border-radius: 0 200px 200px 200px;
              font-size: 20px;
              line-height: 1.4;
              letter-spacing: -0.015em;
              padding: 12px 40px;
            }

            > .description {
              color: $KPNavy;

              > .white {
              }
            }

            > .overseasPartnerWrapper {
              margin-top: 20px;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 20px;
            }
          }

          > .townImage {
            width: 1000px;
            position: relative;
            z-index: $zIndexContent;
          }
        }
      }
    }
  }

  .pick {
    cursor: pointer;
    width: 58px;
    height: 72px;

    background-image: url("../../../assets/images/sub/partner_company/default_pick_mobile.png");
    background-size: 100%;
    background-repeat: no-repeat;

    position: relative;

    &.selected {
      background-image: url("../../../assets/images/sub/partner_company/selected_pick_mobile.png");
    }

    > .logoImage {
      width: 38px;
      height: 38px;
      border-radius: 50%;

      background-size: cover;
      background-position: center;
      background-color: white;

      position: absolute;
      top: 6px;
      left: 10px;
    }
  }
}

@include tablet {
  .partnership {
    width: 100%;
    overflow-x: hidden;

    > .title {
      @include subpageTitleTablet("../../images/sub/understanding/title_bg_mobile.svg");
    }

    > .article {
      margin-top: -1px;
      width: 100%;
      padding: 130px 0 200px;
      background-color: $KPNavy;
      position: relative;

      > .bgItem1 {
        position: absolute;
        top: 138px;
        left: calc(50vw + 230px);

        width: 256px;
        height: 245px;
        border-radius: 50%;
        border: 54px solid rgba(241, 238, 255, 0.10);
        z-index: 1;

      }

      > .bgItem2 {
        position: absolute;
        top: 650px;
        left: calc(50vw - 500px);

        width: 246px;
        height: 236px;
        border-radius: 50% 0 50% 50%;
        background-color: rgba(88, 59, 226, 0.20);
      }

      > .bgItem3 {
        position: absolute;
        top: 970px;
        left: calc(50vw + 260px);

        width: 214px;
        height: 205px;
        border-radius: 50% 50% 50% 0;
        background-color: rgba(255, 255, 255, 0.10);
      }

      > .imageSection {
        margin: 0 auto;
        width: 728px;
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;
        gap: 0;

        > .videoWrapper {
          width: 728px;
          height: 570px;
          background-image: url("../../images/sub/partnership/desktop/video_bg.jpg");
          background-size: 100%;
          position: relative;

          > video {
            border-radius: 10px;
            width: 420px;
            position: absolute;
            left: 155px;
            top: 185px;
          }
        }
      }
    }

    &.companyList {
      > .title {
        @include subpageTitleTablet("../../images/sub/researcher/title_bg.svg");
      }

      > .article {
        background-color: $KPNavy;
        padding: 70px 20px;

        display: flex;
        flex-direction: column;
        align-items: center;

        > .section {
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;

          .domesticSectionTitle {
            background-color: white;
            color: $KPNavy;
            border-radius: 0 200px 200px 200px;
            padding: 12px 40px;

            font-size: 20px;
            font-weight: bold;
            line-height: 1.4;
            letter-spacing: -0.012em;
          }

          > .description {
            padding: 0 10px;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.015em;
            text-align: center;
            color: $kpNavyLight;
            word-break: keep-all;

            > .white {
              color: white;
            }
          }

          > .mapAndCompanyWrapper {
            //margin-top: 50px;
            display: flex;
            gap: 30px;
            padding: 30px 0;

            > .mapWrapper {
              width: 354px;
              height: 530px;
              border-radius: 20px;
              overflow: hidden;


              > .map {
                width: 100%;
                height: 100%;
              }
            }

            > .companyListWrapper {
              width: 354px;
              height: 530px;
              border-radius: 20px;
              background-color: white;
              padding: 20px 0;
            }
          }
        }

        &.white {
          padding: {
            top: 200px;
            bottom: 0;
          };
          background-color: white;
          position: relative;

          gap: 100px;

          > .bgItemSpace {
            width: 363px;
            height: 377px;

            z-index: $zIndexBgItemOnBg;
            position: absolute;
            left: calc(50vw - 580px);
            top: 50px;

            > img {
              width: 100%;
            }
          }

          > .bgItemRight {
            display: none;
          }

          > .bgItemLeft {
            display: none;
          }

          > .section {
            z-index: $zIndexContent;

            .sectionTitle {
              width: 170px;
              border-radius: 0 200px 200px 200px;
              font-size: 20px;
              line-height: 1.4;
              letter-spacing: -0.015em;
              padding: 12px 40px;
            }

            > .description {
              color: $KPNavy;

              > .white {
              }
            }

            > .overseasPartnerWrapper {
              margin-top: 20px;
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-gap: 30px;
            }
          }

          > .townImage {
            width: 1000px;
            position: relative;
            z-index: $zIndexContent;
          }
        }
      }
    }
  }

  .pick {
    cursor: pointer;
    width: 58px;
    height: 72px;

    background-image: url("../../../assets/images/sub/partner_company/default_pick_mobile.png");
    background-size: 100%;
    background-repeat: no-repeat;

    position: relative;

    &.selected {
      background-image: url("../../../assets/images/sub/partner_company/selected_pick_mobile.png");
    }

    > .logoImage {
      width: 38px;
      height: 38px;
      border-radius: 50%;

      background-size: cover;
      background-position: center;
      background-color: white;

      position: absolute;
      top: 6px;
      left: 10px;
    }
  }
}

@include desktop {
  .partnership {
    width: 100%;

    > .title {
      @include subpageTitle("../../images/sub/understanding/title_bg.svg");
    }

    > .article {
      width: 100%;
      padding: 130px 0 200px;
      background-color: $KPNavy;
      position: relative;

      > .bgItem1 {
        position: absolute;
        top: 138px;
        left: calc(50vw + 487px);

        $size: 498px;
        width: $size;
        height: $size;
        border-radius: 50%;
        border: 110px solid rgba(241, 238, 255, 0.10);
        z-index: 1;
      }

      > .bgItem2 {
        $size: 480px;
        position: absolute;
        top: 1150px;
        left: calc(50vw - 880px);

        width: $size;
        height: $size;
        border-radius: 50% 0 50% 50%;
        background-color: rgba(88, 59, 226, 0.20);
      }

      > .bgItem3 {
        $size: 417px;
        position: absolute;
        top: 1801px;
        left: calc(50vw + 557px);

        width: $size;
        height: $size;
        border-radius: 50% 50% 50% 0;
        background-color: rgba(255, 255, 255, 0.10);
      }

      > .imageSection {
        margin: 0 auto;
        width: 1120px;
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;
        gap: 0;

        > .videoWrapper {
          width: 1120px;
          height: 862px;
          background-image: url("../../images/sub/partnership/desktop/video_bg.jpg");
          background-size: 100%;
          position: relative;

          > video {
            border-radius: 10px;
            width: 660px;
            position: absolute;
            left: 230px;
            top: 280px;
          }
        }
      }
    }

    &.companyList {
      > .title {
        @include subpageTitle("../../images/sub/researcher/title_bg.svg");
      }

      > .article {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .section {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 50px;

          .domesticSectionTitle {
            background-color: white;
            color: $KPNavy;
            width: 304px;
          }

          > .description {
            font-size: 24px;
            line-height: 1.4;
            letter-spacing: -0.015em;
            text-align: center;
            color: $kpNavyLight;

            > .white {
              color: white;
            }
          }

          > .mapAndCompanyWrapper {
            margin-top: 50px;
            display: flex;
            gap: 30px;

            > .mapWrapper {
              width: 675px;
              height: 800px;
              border-radius: 30px;
              overflow: hidden;
              background-color: white;
              z-index: $zIndexContent;

              > .map {
                width: 100%;
                height: 100%;


              }
            }

            > .companyListWrapper {
              width: 675px;
              height: 800px;
              border-radius: 30px;
              background-color: white;
              padding: 40px;
            }
          }
        }

        &.white {
          padding: {
            top: 400px;
            bottom: 0;
          };
          background-color: white;
          position: relative;

          gap: 150px;

          > .bgItemSpace {
            width: 660px;
            height: 685px;

            z-index: $zIndexBgItemOnBg;
            position: absolute;
            left: calc(50vw - 1040px);
            top: 120px;

            > img {
              width: 100%;
            }
          }

          > .bgItemRight {
            width: 210px;
            height: 1118px;

            z-index: $zIndexBgItemOnBg;
            position: absolute;
            top: 775px;
            left: calc(50vw + 750px);

            > img {
              width: 100%;
            }
          }

          > .bgItemLeft {
            width: 210px;
            height: 817px;

            z-index: $zIndexBgItemOnBg;
            position: absolute;
            top: 926px;
            left: calc(50vw - 960px);

            > img {
              width: 100%;
            }
          }

          > .section {
            z-index: $zIndexContent;

            .sectionTitle {
              width: 304px;
            }

            > .description {
              color: $KPNavy;

              > .white {
              }
            }

            > .overseasPartnerWrapper {
              margin-top: 50px;
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-column-gap: 20px;
              grid-row-gap: 40px;
            }
          }

          > .townImage {
            width: 1920px;
            position: relative;
            z-index: $zIndexContent;
          }
        }
      }


    }
  }

  .pick {
    cursor: pointer;
    width: 78px;
    height: 98px;

    background-image: url("../../../assets/images/sub/partner_company/default_pick.png");
    background-size: 100%;
    background-repeat: no-repeat;

    position: relative;

    &.selected {
      background-image: url("../../../assets/images/sub/partner_company/selected_pick.png");
    }

    > .logoImage {
      width: 54px;
      height: 54px;
      border-radius: 50%;

      background-size: cover;
      background-position: center;
      background-color: white;

      position: absolute;
      top: 8px;
      left: 12px;
    }
  }
}