$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
.container {
  display: flex;
  margin: 50px auto -10px auto;
  width: 1280px;
  height: 400px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 4px solid var(--K-PASS-KPblue-000_bg, #f6f3ff);
}

.containerContentBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
}

.containerTitle {
  font-size: 24px;
}

.profileCount {
  color: var(--GrayScale-G-500_subtext, #6b6b6b);
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.36px;
}

.profiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 2열로 배열 */
  gap: 20px;
  overflow-y: auto;
  max-height: 400px; /* 필요에 따라 높이 설정 */
  padding: 0 35px;
}

.profiles::-webkit-scrollbar {
  display: none;
}

.profile {
  border-radius: 15px;
  background: var(--K-PASS-KPblue-500_main, #583be2);
  display: flex;
  width: 240px;
  height: 244px;
  box-sizing: border-box;
  padding: 15px 20px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;

  position: relative;

  // profile 이름 길어질 경우 기획 바뀌면 이 부분 제거
  // overflow-y: auto;
  scrollbar-color: transparent transparent;
}

.lightMode {
  border-radius: 15px;
  border: 2px solid var(--K-PASS-KPblue-300, #9b89ee);
  background: var(--GrayScale-white, #fff);

  color: var(--GrayScale-G-500_subtext, #6b6b6b);
  text-align: center;

  /* 💻Desktop/Body/B_Bold */
  font-family: Pretendard;
  // font-size: 20px;
  font-style: normal;
  font-weight: 700;
  // line-height: 150%; /* 30px */
  letter-spacing: -0.3px;

  .profileName {
    // display: table-cell;
    color: #6b6b6b;
    white-space: nowrap; // 텍스트가 한 줄로 유지되게 함
    overflow: hidden; // 넘치는 부분 숨기기
    text-overflow: ellipsis; // 넘치는 부분을 '...'으로 표시
    max-width: 165px; // 최대 너비 설정 (필요에 따라 조정)
  }

  .profileInfo {
    color: #6b6b6b;
    .profileDetailInfo {
      color: #6b6b6b;

      white-space: nowrap; // 텍스트가 한 줄로 유지되게 함
      overflow: hidden; // 넘치는 부분 숨기기
      text-overflow: ellipsis; // 넘치는 부분을 '...'으로 표시
      max-width: 90px; // 최대 너비 설정 (필요에 따라 조정)
    }
  }
}

.settingIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 3;
}

.profileIcon {
  text-align: center;
}

.icon {
  height: 50px;
  width: 45px;
}

.profileName {
  display: inline-block; /* block이나 inline-block으로 설정 */
  color: var(--GrayScale-white, #fff);
  text-align: center; /* 텍스트를 가운데 정렬 */
  color: var(--GrayScale-white, #fff);
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.3px;
  margin-top: -5px;

  white-space: nowrap; // 텍스트가 한 줄로 유지되게 함
  overflow: hidden; // 넘치는 부분 숨기기
  text-overflow: ellipsis; // 넘치는 부분을 '...'으로 표시
  max-width: 165px; // 최대 너비 설정 (필요에 따라 조정)
}

.profileContainer {
  width: 100%;
  color: var(--GrayScale-white, #fff);
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.3px;
}

.profileInfo {
  display: flex;
  justify-content: space-between;
  color: var(--GrayScale-white, #fff);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.21px;
  margin-bottom: 3px;
}

.profileDetailInfo {
  color: var(--GrayScale-white, #fff);
  text-align: right;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.21px;

  white-space: nowrap; // 텍스트가 한 줄로 유지되게 함
  overflow: hidden; // 넘치는 부분 숨기기
  text-overflow: ellipsis; // 넘치는 부분을 '...'으로 표시
  max-width: 90px; // 최대 너비 설정 (필요에 따라 조정)
}

.profileInfoBtn {
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  color: var(--GrayScale-white, #fff);
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: -0.36px;
  border-radius: 8px;
  border: 0px;
  background: var(--K-PASS-KPblue-300, #9b89ee);
}

.addProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #dcdcdc;
  border-radius: 10px;
  width: 240px;
  height: 250px;
  color: #a0a0a0;
}

.addProfileButton {
  border-radius: 6px;
  border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  display: flex;
  padding: 4px 0px 4px 5px;
  align-items: center;
  box-sizing: border-box;
  width: 179px;
  height: 34px;
  background-color: white;
  color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718e);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.24px;

  margin-left: auto; /* 버튼을 오른쪽으로 밀어내기 */
}

.addProfileButtonBottom {
  margin: 0 auto;
}

@include tablet {
  .container {
    width: 800px;
    height: 400px;
    border-radius: 25px;
  }

  .profile {
    border-radius: 15px;
    display: flex;
    width: 220px;
    height: 250px;
    box-sizing: border-box;
    padding: 15px 20px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;

    position: relative;
  }

  .profileCount {
    font-size: 20px;
  }

  .containerTitle {
    font-size: 20px;
  }

  .containerContentBox {
    padding-left: 20px;
  }

  .addProfileButton {
    width: 157px;
    height: 29px;

    font-size: 14px;
  }

  .addIcon {
    width: 21px;
    height: 21px;
  }

  .profileName {
    font-size: 16px;

    white-space: nowrap; // 텍스트가 한 줄로 유지되게 함
    overflow: hidden; // 넘치는 부분 숨기기
    text-overflow: ellipsis; // 넘치는 부분을 '...'으로 표시
    max-width: 165px; // 최대 너비 설정 (필요에 따라 조정)
  }

  .profileInfo {
    margin-bottom: 5px;
  }

  .profiles {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 20px;
  }

  .addProfile {
    width: 220px;
    height: 250px;
  }

  .addProfileButtonBottom {
    margin: 0 auto;
  }
}

@include mobile {
  .container {
    width: 340px;
    height: 500px;
    margin: 0 auto;
    padding: 20px 20px;

    border-radius: 15px;
    border: 4px solid var(--K-PASS-KPblue-000_bg, #f6f3ff);
  }

  .profileName {
    font-size: 16px;

    white-space: nowrap; // 텍스트가 한 줄로 유지되게 함
    overflow: hidden; // 넘치는 부분 숨기기
    text-overflow: ellipsis; // 넘치는 부분을 '...'으로 표시
    max-width: 165px; // 최대 너비 설정 (필요에 따라 조정)
  }

  .profiles {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }

  .profile {
    width: 220px;
    height: 250px;
    margin: 0 auto;
  }

  .profileInfo {
    margin-bottom: 5px;
  }

  .addIcon {
    width: 21px;
    height: 21px;
  }

  .addProfile {
    width: 220px;
    height: 250px;
  }

  .addProfileButton {
    width: 157px;
    height: 29px;
    font-size: 14px;
  }

  .addProfileButtonBottom {
    margin: 0 auto;
  }

  .containerContentBox {
    display: block;
  }

  .containerTitle {
    color: var(--GrayScale-G-700_bodytext, #404040);

    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.3px;

    margin-bottom: 20px;
  }
  .containerContentBox {
    padding: 0 10px;
  }

  .profileCount {
    color: var(--GrayScale-G-500_subtext, #6b6b6b);

    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.3px;
  }
}
