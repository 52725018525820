$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  .codeWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    > .imageWrapper {
      width: 167px;
      height: 136px;

      > img {
        width: 100%;
      }
    }

    > .infoWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      > .description {
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: -0.015em;
        text-align: center;
      }

      > .codeInfo {
        display: flex;
        gap: 6px;
        align-items: center;

        > .code {
          width: 150px;
          height: 60px;
          background-color: white;
          border: 2px solid $blueLight;
          border-radius: 8px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 20px;
          font-weight: normal;
          line-height: 1.4;
          letter-spacing: -0.015em;
          color: $KPNavy;
        }

        > .copyButton {
          width: 60px;
          height: 60px;
          background-color: $KPNavy;
          border-radius: 8px;
          color: white;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
        }
      }

      > .discountInfo {
        display: flex;
        flex-direction: column;

        text-align: center;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.015em;
        color: $blueLight;

        > .bracket {
          color: $modalErrorColor;
        }
      }
    }
  }
}

@include tablet {
  .codeWrapper {
    width: 497px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    > .imageWrapper {
      width: 167px;
      height: 136px;

      > img {
        width: 100%;
      }
    }

    > .infoWrapper {
      display: flex;
      width: 300px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;

      > .description {
        align-self: stretch;
        color: var(--GrayScale-white, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: -0.24px;
      }

      > .codeInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;

        > .code {
          display: flex;
          width: 150px;
          height: 60px;
          justify-content: center;
          align-items: center;

          border-radius: 8px;
          border: 2px solid var(--K-PASS-KPblue-100, #D6CEFF);
          background: var(--GrayScale-white, #FFF);

          color: var(--K-PASS-KPnavy-500_main, #383649);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 28px */
          letter-spacing: -0.3px;
          flex: 1 0 0;
        }

        > .copyButton {
          display: flex;
          width: 60px;
          height: 60px;
          padding: 16px 10px;
          justify-content: center;
          align-items: center;

          border-radius: 8px;
          background: var(--K-PASS-KPnavy-500_main, #383649);

          color: var(--GrayScale-white, #FFF);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
        }
      }

      > .discountInfo {
        display: flex;
        flex-direction: column;
        align-self: stretch;

        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.015em;
        color: $blueLight;

        > .bracket {
          color: $modalErrorColor;
        }
      }
    }
  }
}

@include desktop {
  .codeWrapper {
    display: flex;
    gap: 40px;
    align-items: center;

    > .imageWrapper {
      width: 210px;
      height: 172px;

      > img {
        width: 100%;
      }
    }

    > .infoWrapper {
      width: 400px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      > .description {
        color: white;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: -0.015em;
      }

      > .codeInfo {
        display: flex;
        gap: 6px;
        align-items: center;

        > .code {
          width: 180px;
          height: 72px;
          background-color: white;
          border: 2px solid $blueLight;
          border-radius: 8px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 24px;
          font-weight: normal;
          line-height: 1.4;
          letter-spacing: -0.015em;
          color: $KPNavy;
        }

        > .copyButton {
          width: 72px;
          height: 72px;
          background-color: $KPNavy;
          border-radius: 8px;
          color: white;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
        }
      }

      > .discountInfo {
        display: flex;
        flex-direction: column;

        font-size: 20px;
        line-height: 1.5;
        letter-spacing: -0.015em;
        color: $blueLight;

        > .bracket {
          color: $modalErrorColor;
        }
      }
    }
  }
}