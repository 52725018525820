$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  .downloadTest {
    overflow-x: hidden;
    align-items: center;
    padding: {
      top: 30px;
      bottom: 130px;
    };
    position: relative;

    > .bgItem1 {
      width: 214px;
      height: 214px;
      position: absolute;
      left: calc(50vw + 55px);
      top: 50px;
      border-radius: 50%;
      border: 47.28px solid getMainColorWithOpacity(0.1);
      z-index: $zIndexBgItem;
    }

    > .bgItem2 {
      width: 124px;
      height: 124px;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
      background-color: rgba(56, 54, 73, 0.1);
      position: absolute;
      left: -20px;
      top: 391px;
      z-index: $zIndexBgItem;
    }

    > .title {
    }

    > .tabMenu {
      margin: 50px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;

      > .tab {
        width: 156px;
        height: 40px;
        border-radius: 100px;
        border: 1px solid $g5dedede;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.03em;
        font-weight: normal;
        color: $subNavText;
        background-color: white;

        &.active {
          font-weight: bold;
          color: $mainColor;
          border: 1px solid $mainColor;
        }
      }
    }

    > .guideContent {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .downloadBtn {
        margin-bottom: 50px;
        width: 258px;
        height: 56px;
        border-radius: 8px;
        background: linear-gradient(180deg, #583BE2 0%, #25179F 72.4%, #25179F 94.27%);
        font-weight: normal;
        font-size: 20px;
        line-height: 1.6;
        letter-spacing: -0.05em;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        > .icon {
          width: 32px;
          height: 32px;
        }
      }

      > .googlePlayGuide {
        padding: 0 20px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        > .text {
          text-align: center;
          font-weight: normal;
          font-size: 20px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $KPNavy;
          @include wordBreak;

          > .accent {
            font-weight: bold;
            color: $mainColor;
          }
        }

        > .badges {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          .badgeImage {
            width: 155px;
          }
        }
      }

      > .orderedListGuide {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        gap: 50px;

        > .guide {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;

          > .textArea {
            width: 100%;
            display: flex;
            gap: 8px;

            > .number {
              width: 26px;
              height: 26px;
              background-color: $KPNavy;
              border-radius: 50%;
              color: white;
              font-weight: bold;
              font-size: 12px;
              line-height: 1.6;
              letter-spacing: -0.03em;
              text-align: center;
              flex-shrink: 0;

              display: flex;
              justify-content: center;
              align-items: center;
            }

            > .descText {
              display: flex;
              flex-direction: column;
              font-weight: normal;
              font-size: 16px;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $KPNavy;
              @include newLine;

              > .text {
              }

              > .accent {
                font-size: 12px;
                color: $mainColor;
              }
            }
          }


          > .descImage {
            width: 220px;
            height: 126px;

            > img {
              width: 100%;
              border-radius: 8px;
              filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .downloadTest {
    align-items: center;
    padding: {
      bottom: 130px;
    };
    position: relative;

    > .bgItem1 {
      width: 220px;
      height: 220px;
      position: absolute;
      left: calc(50vw + 250px);
      top: 140px;
      border-radius: 50%;
      border: 50px solid getMainColorWithOpacity(0.1);
      z-index: $zIndexBgItem;
    }

    > .bgItem2 {
      width: 130px;
      height: 130px;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
      background-color: rgba(56, 54, 73, 0.1);
      position: absolute;
      right: calc(50vw + 340px);
      top: 460px;
      z-index: $zIndexBgItem;
    }

    > .title {
      margin-top: 170px;
      display: flex;
      justify-content: center;
    }

    > .tabMenu {
      margin: 50px 0;

      > .tab {
        width: 155px;
        height: 40px;
        border-radius: 100px;
        border: 1px solid $g5dedede;
        font-size: 1.6rem;
        line-height: 1.6;
        letter-spacing: -0.05em;
        font-weight: normal;
        color: $subNavText;

        &:not(:last-child) {
          margin-right: 8px;
        }

        &.active {
          font-weight: bold;
          color: $mainColor;
          border: 2px solid $mainColor;
        }
      }
    }

    > .guideContent {
      @include desktopWidth;
      display: flex;
      flex-direction: column;
      align-items: center;


      > .downloadBtn {
        margin-bottom: 70px;
        width: 267px;
        height: 56px;
        border-radius: 8px;
        background: linear-gradient(180deg, #583BE2 0%, #25179F 72.4%, #25179F 94.27%);
        font-weight: normal;
        font-size: 2.0rem;
        line-height: 1.6;
        letter-spacing: -0.05em;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        > .icon {
          width: 32px;
          height: 32px;
        }
      }

      > .googlePlayGuide {
        margin-bottom: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        > .text {
          font-weight: normal;
          text-align: center;
          font-size: 2rem;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $KPNavy;

          > .accent {
            font-weight: bold;
            color: $mainColor;
          }
        }

        > .badges {
          display: flex;
          gap: 10px;

          .badgeImage {
            width: 156px;
            height: 46px;
          }
        }

      }

      > .orderedListGuide {
        width: 740px;

        > .guide {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 50px;
          }

          > .textArea {
            display: flex;

            > .number {
              width: 26px;
              height: 26px;
              background-color: $KPNavy;
              border-radius: 50%;
              color: white;
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 1.6;
              letter-spacing: -0.03em;
              text-align: center;
              margin-right: 10px;
              flex-shrink: 0;
            }

            > .descText {
              display: flex;
              flex-direction: column;
              font-weight: normal;
              font-size: 1.6rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $KPNavy;
              @include newLine;

              > .text {
                padding-right: 20px;
                margin-bottom: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;
              }

              > .accent {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: -0.21px;
                color: $mainColor;
              }
            }
          }


          > .descImage {
            //margin-left: 20px;
            width: 354px;
            height: 202px;
            flex-shrink: 0;
            flex-grow: 0;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
            border-radius: 8px;

            > img {
              width: 100%;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .downloadTest {
    align-items: center;
    padding: {
      top: 214px;
      bottom: 130px;
    };
    position: relative;

    > .bgItem1 {
      width: 664px;
      height: 664px;
      position: absolute;
      left: calc(50vw + 401px);
      top: -4px;
      border-radius: 50%;
      border: 146.7px solid getMainColorWithOpacity(0.1);
      z-index: $zIndexBgItem;
    }

    > .bgItem2 {
      width: 417px;
      height: 417px;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
      background-color: rgba(56, 54, 73, 0.1);
      position: absolute;
      right: calc(50vw + 543px);
      top: 825px;
      z-index: $zIndexBgItem;
    }

    > .title {
    }

    > .tabMenu {
      margin: 100px 0;

      > .tab {
        width: 250px;
        height: 64px;
        border-radius: 100px;
        border: 2px solid $g5dedede;
        font-size: 2rem;
        line-height: 1.6;
        letter-spacing: -0.05em;
        font-weight: normal;
        color: $subNavText;

        &:not(:last-child) {
          margin-right: 30px;
        }

        &.active {
          font-weight: bold;
          color: $mainColor;
          border: 2px solid $mainColor;
        }
      }
    }

    > .guideContent {
      @include desktopWidth;
      display: flex;
      flex-direction: column;
      align-items: center;

      > .downloadBtn {
        margin-bottom: 80px;
        width: 416px;
        height: 83px;
        border-radius: 8px;
        background: linear-gradient(180deg, #583BE2 0%, #25179F 72.4%, #25179F 94.27%);
        font-weight: normal;
        font-size: 3.2rem;
        line-height: 1.6;
        letter-spacing: -0.05em;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        > .icon {
          width: 51px;
          height: 51px;
        }
      }

      > .googlePlayGuide {
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        > .text {
          font-weight: normal;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $KPNavy;

          > .accent {
            font-weight: bold;
            color: $mainColor;
          }
        }

        > .badges {
          display: flex;

          .badgeImage {
            width: 280px;
          }
        }

      }

      > .orderedListGuide {
        width: 1020px;

        > .guide {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 50px;
          }

          > .textArea {
            display: flex;

            > .number {
              width: 38px;
              height: 38px;
              background-color: $KPNavy;
              border-radius: 50%;
              color: white;
              font-weight: bold;
              font-size: 2.4rem;
              line-height: 1.6;
              letter-spacing: -0.03em;
              text-align: center;
              margin-right: 20px;
              flex-shrink: 0;
            }

            > .descText {
              display: flex;
              flex-direction: column;
              font-weight: normal;
              font-size: 2.4rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $KPNavy;
              @include newLine;

              > .text {
                margin-bottom: 12px;
              }

              > .accent {
                font-size: 2rem;
                color: $mainColor;
              }
            }
          }


          > .descImage {
            margin-left: 82px;
            width: 400px;
            height: 229px;
            flex-shrink: 0;
            flex-grow: 0;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
            border-radius: 8px;

            > img {
              width: 100%;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}