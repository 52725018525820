$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  $popupWidth: calc(100vw - 60px);
  $popupHeight: calc((4 * #{$popupWidth}) / 3);

  .popupWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: $zIndexPopup;

    display: flex;
    align-items: center;
    justify-content: center;

    > .popup {
      display: flex;
      flex-direction: column;
      gap: 10px;

      width: $popupWidth;
      height: fit-content;
      position: relative;

      > .closeButton {
        z-index: $zIndexPopupCloseButton;
        position: absolute;
        top: -30px;
        right: -30px;

        width: 62px;
        height: 62px;
        padding: 10px;

        > .area {
          width: 100%;
          height: 100%;
          border-radius: 500px 500px 500px 0;
          box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);

          display: flex;
          justify-content: center;
          align-items: center;

          position: relative;

          > .defaultBg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 500px 500px 500px 0;
            background: linear-gradient(143deg, $mainColor 18.47%, #351FA1 91.75%);
          }

          > .hoverBg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 500px 500px 500px 0;
            background: linear-gradient(143deg, $hotpink 18.47%, #A02645 91.75%);
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }

          > img {
            width: 24px;
            height: 24px;
            position: relative;
          }
        }

        &:active {
          > .area {

            > .defaultBg {
            }

            > .hoverBg {
              opacity: 1;
            }
          }
        }
      }

      > .popupContent {
        width: $popupWidth;
        height: $popupHeight;
        border-radius: 20px;

        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;

        position: relative;

        > .bullets {
          width: 100%;
          height: fit-content;
          z-index: $zIndexPopupCloseButton;

          position: absolute;
          left: 0;
          bottom: 10px;

          display: flex;
          justify-content: center;
          align-items: center;

          > .bulletButton {
            width: 16px;
            height: 16px;

            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
              border-radius: 50%;
              background-color: $g5dedede;
              display: block;
              width: 6px;
              height: 6px;
              content: " ";
              box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
            }

            &.selected {

              &:before {
                border-radius: 100px 100px 0 100px;
                width: 8px;
                height: 8px;
                background-color: $mainColor;
              }
            }
          }
        }
      }

      > .closeTodayButton {
        padding: 12px 16px;

        display: flex;
        align-items: center;
        gap: 6px;

        > .icon {
          padding-top: 3.7px;
          width: 20px;
          height: 20px;

          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            width: 100%;
          }
        }

        > .text {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $itemBgGray;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .aPopupSlide {
    width: $popupWidth;
    height: $popupHeight;

    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

@include tablet {

  .popupWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: $zIndexPopup;

    display: flex;
    align-items: center;
    justify-content: center;

    > .popup {
      display: flex;
      flex-direction: column;
      gap: 10px;

      width: 350px;
      height: fit-content;
      position: relative;

      > .closeButton {
        z-index: $zIndexPopupCloseButton;
        position: absolute;
        top: -33px;
        right: -30px;

        width: 62px;
        height: 62px;
        padding: 10px;
        transition: all 1s ease-in-out;

        > .area {
          width: 100%;
          height: 100%;
          border-radius: 500px 500px 500px 0;
          box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);

          display: flex;
          justify-content: center;
          align-items: center;

          position: relative;

          > .defaultBg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 500px 500px 500px 0;
            background: linear-gradient(143deg, $mainColor 18.47%, #351FA1 91.75%);
          }

          > .hoverBg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 500px 500px 500px 0;
            background: linear-gradient(143deg, $hotpink 18.47%, #A02645 91.75%);
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }

          > img {
            width: 24px;
            height: 24px;
            position: relative;
          }
        }

        &:hover {
          > .area {

            > .defaultBg {
            }

            > .hoverBg {
              opacity: 1;
            }
          }
        }
      }

      > .popupContent {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        //border: 2px solid pink;

        position: relative;

        > .bullets {
          width: 100%;
          height: fit-content;

          position: absolute;
          left: 0;
          bottom: 20px;
          z-index: $zIndexPopupCloseButton;

          display: flex;
          justify-content: center;
          align-items: center;

          > .bulletButton {
            width: 16px;
            height: 16px;

            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
              border-radius: 50%;
              background-color: $g5dedede;
              display: block;
              width: 10.5px;
              height: 10.5px;
              content: " ";
              box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            }

            &.selected {

              &:before {
                border-radius: 100px 100px 0 100px;
                width: 14px;
                height: 14px;
                background-color: $mainColor;
              }
            }
          }
        }
      }

      > .closeTodayButton {
        padding: 10px 15px;

        display: flex;
        align-items: center;
        gap: 10px;

        > .icon {
          padding-top: 5px;
          width: 20px;
          height: 20px;

          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            width: 100%;
          }
        }

        > .text {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $itemBgGray;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .aPopupSlide {
    width: 350px;
    height: 467px;
    justify-content: center;
    align-items: center;

    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;

    &.link {
      cursor: pointer;
    }
  }
}
@include desktop {

  .popupWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: $zIndexPopup;

    display: flex;
    align-items: center;
    justify-content: center;

    > .popup {
      display: flex;
      flex-direction: column;
      gap: 10px;

      width: 450px;
      height: fit-content;
      position: relative;

      > .closeButton {
        z-index: $zIndexPopupCloseButton;
        position: absolute;
        top: -50px;
        right: -50px;

        width: 90px;
        height: 90px;
        padding: 10px;
        transition: all 1s ease-in-out;

        > .area {
          width: 60px;
          height: 60px;
          border-radius: 500px 500px 500px 0;
          box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);

          display: flex;
          justify-content: center;
          align-items: center;

          position: relative;

          > .defaultBg {
            position: absolute;
            left: 0;
            top: 0;
            width: 60px;
            height: 60px;
            border-radius: 500px 500px 500px 0;
            background: linear-gradient(143deg, $mainColor 18.47%, #351FA1 91.75%);
          }

          > .hoverBg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 500px 500px 500px 0;
            background: linear-gradient(143deg, $hotpink 18.47%, #A02645 91.75%);
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }

          > img {
            width: 44px;
            height: 44px;
            position: relative;
          }
        }

        &:hover {
          > .area {

            > .defaultBg {
            }

            > .hoverBg {
              opacity: 1;
            }
          }
        }
      }

      > .popupContent {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        //border: 2px solid pink;

        position: relative;

        > .bullets {
          width: 100%;
          height: fit-content;

          position: absolute;
          left: 0;
          bottom: 20px;
          z-index: $zIndexPopupCloseButton;

          display: flex;
          justify-content: center;
          align-items: center;

          > .bulletButton {
            width: 28px;
            height: 28px;

            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
              border-radius: 50%;
              background-color: $g5dedede;
              display: block;
              width: 10.5px;
              height: 10.5px;
              content: " ";
              box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            }

            &.selected {

              &:before {
                border-radius: 100px 100px 0 100px;
                width: 14px;
                height: 14px;
                background-color: $mainColor;
              }
            }
          }
        }
      }

      > .closeTodayButton {
        padding: 16px 20px;

        display: flex;
        align-items: center;
        gap: 6px;

        > .icon {
          padding-top: 5px;
          width: 24px;
          height: 24px;

          display: flex;
          justify-content: center;
          align-items: center;

          > img {
            width: 100%;
          }
        }

        > .text {
          font-size: 20px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $itemBgGray;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .aPopupSlide {
    width: 450px;
    height: 600px;

    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;

    &.link {
      cursor: pointer;
    }
  }
}