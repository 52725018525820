$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@include mobile {
  .history {

    > .article1 {
      background-color: $fontHeavy;
      padding: 70px 30px 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      > .bottomWave {
        position: absolute;
        width: 100vw;
        height: 100px;
        bottom: -99px;
        left: 0;
        background-image: url("../../images/sub/understanding/history/bg_mobile.svg");
        background-repeat: repeat-x;
        background-position: bottom;
      }

      .title {
        padding: 6px 20px;
        min-width: 156px;
        background-color: white;
        color: $fontHeavy;
      }

      > .desc1 {
        color: $navyLight;
        font-size: 1.2rem;
        font-weight: normal;
        line-height: 1.6;
        letter-spacing: -0.05em;
        text-align: center;
        margin-top: 30px;
        @include newLine;

        > .accent {
          font-weight: bold;
          color: white;
        }
      }

      > .chart {
        margin-top: 60px;
        width: 100%;
        max-width: 277px;

        > img {
          width: 100%;
        }
      }

      > .desc2 {
        color: $navyLight;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.6;
        letter-spacing: -0.05em;
        margin-top: 60px;
        text-align: center;
        @include wordBreak;
      }
    }

    > .article2 {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding: 170px 30px 100px 30px;
      gap: 30px;

      > .desc {
        width: 100%;
        text-align: center;
        color: $g2757575;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.6;
        letter-spacing: -0.05em;
        @include newLine;

        > .accent {
          font-weight: bold;
          color: $fontHeavy;
        }
      }

      > .image {

        > img {
          width: 214px;
        }
      }
    }
  }
}

@include tablet {

  .history {

    > .article1 {

      background-image: url("../../images/sub/understanding/history/bg.svg");
      width: 100%;
      height: 959px;
      padding: {
        top: 100px;
      };
      display: flex;
      flex-direction: column;
      align-items: center;

      > .bottomWave {
        position: absolute;
        width: 100vw;
        height: 100px;
        bottom: 520px;
        left: 0;
        background-image: url("../../images/sub/understanding/history/bg_mobile.svg");
        background-repeat: repeat-x;
        background-position: bottom;
      }


      .title {
        background-color: white;
        color: $KPNavy;

        &.ru {
          width: fit-content;
          padding: 12px 40px;
        }
      }


      > .desc1 {
        color: $footerFontColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.24px;
        margin-top: 20px;
        text-align: center;
        @include newLine;

        > .accent {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: white;
        }
      }

      > .chart {
        margin-top: 80px;
        width: 440px;
        height: 313px;

        > img {
          width: 100%;
        }
      }

      > .desc2 {
        color: $footerFontColor;
        margin-top: 80px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.24px;
        text-align: center;
        @include newLine;
      }
    }

    > .article2 {
      @include tabletWidth;
      display: flex;

      > .articleArea{
        position: relative;
        width: 768px;
        padding: 0 20px;
        display: flex;
        gap: 20px;
        align-items: flex-end;
        top: -200px;

        padding: {
          top: 121px;
          //bottom: 150px;
        };
        > .desc {
          width: 354px;
          flex: 1;
          color: $KPNavy;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
          @include newLine;

          > .accent {
            font-weight: bold;
          }
        }

        > .image {
          flex: 1;
          //margin-left: 115px;
          position: relative;

          > img {
            flex-shrink: 0;
            width: 354px;
            height: 476px;
            top: -30px;
            left: 0;
          }
        }
      }

    }
  }
}


@include desktop {

  .history {

    > .article1 {
      background-image: url("../../images/sub/understanding/history/bg.svg");
      height: 1688px;
      padding: {
        top: 150px;
      };
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        background-color: white;
        color: $KPNavy;

        &.ru {
          width: fit-content;
          padding: 12px 40px;
        }
      }

      > .desc1 {
        color: $footerFontColor;
        font-size: 2rem;
        font-weight: normal;
        line-height: 1.8;
        letter-spacing: -0.05em;
        text-align: center;
        margin-top: 50px;
        @include newLine;

        > .accent {
          font-weight: bold;
          color: white;
        }
      }

      > .chart {
        margin-top: 150px;
        width: 653px;
        height: 465px;

        > img {
          width: 100%;
        }
      }

      > .desc2 {
        color: $footerFontColor;
        font-weight: normal;
        font-size: 2rem;
        line-height: 1.8;
        letter-spacing: -0.05em;
        margin-top: 150px;
        text-align: center;
        @include newLine;
      }
    }

    > .article2 {
      @include desktopWidth;
      display: flex;
      padding-left: 120px;

      > .desc {
        flex: 1;
        color: $KPNavy;
        font-weight: normal;
        font-size: 2.4rem;
        line-height: 1.6;
        letter-spacing: -0.05em;
        padding: {
          top: 121px;
          bottom: 313px;
        };
        @include newLine;

        > .accent {
          font-weight: bold;
        }
      }

      > .image {
        flex: 1;
        margin-left: 115px;
        position: relative;

        > img {
          position: absolute;
          top: -320px;
          left: 0;
        }
      }
    }
  }
}
