$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  .mainWrapper {
    margin-bottom: 24px;
  }
  .main {
    margin-top: - 10px;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    > .titleWrapper {
      height: 350px;
      background: linear-gradient(90deg, #583BE2 0%, #583BE2 51.56%, #9B89EE 100%);
      position: relative;

      > .titleEffect {
        width: 100vw;
        height: 57.77vw;
        max-height: 90%;
        background-image: url("../../../assets/images/sub/review/title_effect_mobile.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        position: absolute;
        left: 0;
        top: 15px;
        z-index: $zIndexBgItemOnBg;
      }

      > .title {
        position: relative;
        z-index: $zIndexContent;
        @include subpageTitleMobile("../../../assets/images/sub/review/title_cloud_mobile.png");
        height: 101%;
        background-size: 182vw;
        background-repeat: no-repeat;
        padding-top: 130px;
        align-items: flex-start;

        > .text {
          font-size: 36px;
          letter-spacing: -0.015em;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          @include showBottomToTop(0.5s, 0, 50px);
        }
      }
    }

    > .section1 {
      margin-top: -1px;
      position: relative;
      z-index: $zIndexBgItemOnBg;
      background-color: white;
      width: 100%;
      padding: 31px 30px 156px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;

      > .section1Part1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        > .section1Part1Text1 {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 4px 0;

          > .section1Part1Text1Line1 {
            white-space: pre-wrap;
            font-weight: normal;
            font-size: 28px;
            line-height: 1.4;
            color: $fontHeavy;

            > .point {
              font-weight: bold;
              color: $mainColor;
            }
          }

          > .section1Part1Text1Line2 {
            width: 100%;
            font-weight: normal;
            font-size: 20px;
            line-height: 1.4;
            color: $fontHeavy;
          }
        }

        > .section1Part1Text2 {
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          color: $subtextColor;
          @include wordBreak;
        }
      }

      > .section1Part2 {
        width: 100%;
        max-width: 300px;
        height: 691px;

        .sliderWrapper {

          button[data-direction="prev"] {
            display: none;
          }

          button[data-direction="next"] {
            display: none;
          }

          .currentSlide {
            opacity: 1;
          }

          .notCurrentSlide {
            opacity: 1;
          }
        }
      }
    }

    > .section2Wrapper {

      > .section2 {
        margin: 0 20px;
        border-radius: 20px;
        background-color: $mainColor;
        padding: 30px;

        display: flex;
        flex-direction: column;
        gap: 20px;

        > .section2Part1 {
          position: relative;

          > .section2Part1ImageWrapper {
            position: absolute;
            right: -40px;
            bottom: -110px;
            width: 115px;
            height: 140px;

            > .linkeyImg {
              transform: rotateY(180deg);
              width: 100%;
            }
          }

          > .section2Part1Desc {
            font-weight: normal;
            font-size: 14px;
            line-height: 1.5;
            color: white;
            @include wordBreak;
          }
        }

        > .section2Part2 {

        }
      }
    }

    > .section3 {
      width: 100%;
      padding: 170px 20px 200px;

      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: flex-start;

      > .section3Count {
        font-weight: normal;
        font-size: 20px;
        line-height: 1.4;
        color: $KPNavy;
      }

      > .section3List {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > .section3ListItem {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .itemTextArea {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            > .writerInfo {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              > .profileImage {
                display: none;
              }

              > .profileInfo {
                display: flex;
                flex-direction: column;

                > .name {
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 1.5;
                  color: $fontHeavy;
                }

                > .additionalInfo {
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 1.5;
                  color: $c4c4c4;

                  > .exceptName {
                    color: $fontHeavy;
                  }
                }
              }
            }

            > .contentInfo {
              display: flex;
              justify-content: space-between;
              gap: 20px;

              > .contentInfoWrapper {
                width: 100%;
                height: 80px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                > .contentInfoTitle {
                  min-height: 24px;
                  max-height: 48px;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 1.5;
                  color: $fontHeavy;
                  width: 100%;
                  overflow-y: hidden;

                  white-space: normal;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  word-break: keep-all;
                  -webkit-line-clamp: 2;
                }

                > .contentText {
                  flex: 1;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 1.5;
                  color: $subtextColor;
                  overflow: hidden;
                  white-space: normal;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  word-break: keep-all;
                }
              }

              > .image {
                flex-shrink: 0;
                width: 80px;
                height: 60px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-radius: 6px;
              }
            }

            > .contentInfoWrittenAt {
              font-weight: normal;
              font-size: 14px;
              line-height: 1.5;
              color: $subtextColor;
            }
          }

        }

        > .section3ListDivider {
          margin: 20px 0;
          border: 1px solid $e1e1e1;
        }
      }

      > .section3Pagination {
        width: 100%;

      }
    }
  }

  .editableButtonsMobile {
    flex-shrink: 0;

    display: flex;
    gap: 4px;

    > button {
      padding: 4px 12px;
      border-radius: 100px;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.5;
      border: 1.5px solid $blueLight;

      &.purple {
        color: $kpBlue;
      }

      &.pink {
        border-color: $lightPink;
        color: $hotpink;
      }

    }
  }
}

@include tablet {
  .mainWrapper {
    margin-bottom: 24px;
  }
  .main {
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    > .titleWrapper {
      padding-top: calc(#{$headerHeight} - 100px);
      height: calc(#{$headerHeight} - 10px + 290px);
      background: linear-gradient(90deg, #583BE2 0%, #583BE2 81.25%, #9B89EE 100%);
      position: relative;

      > .titleEffect {
        width: 100%;
        height: 100%;
        background: url("../../../assets/images/sub/review/title_effect.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        position: absolute;
        left: 0;
        top: 0;
        z-index: $zIndexBgItemOnBg;
      }

      > .title {
        color: var(--GrayScale-white, #FFF);
        position: relative;
        z-index: $zIndexContent;
        @include subpageTitle("../../../assets/images/sub/review/title_cloud.png");
        height: 101%;
        background-size: 109vw;
        background-repeat: no-repeat;
        align-items: flex-start;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.54px;


        > .text {
          @include showBottomToTop(0.8s, 0, 50px);
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.54px;

        }
      }
    }

    > .section1 {
      padding: 104px 30px 100px;
      @include tabletWidth;
      display: flex;
      justify-content: flex-end;
      //width: 100%;

      > .section1Part1 {
        display: flex;
        width: 340px;
        max-height: 300px;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        z-index: $zIndexStep5;

        > .section1Part1Text1 {
          white-space: pre-wrap;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          > .section1Part1Text1Line1 {
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.42px;
            color: $fontHeavy;

            > .point {
              font-weight: bold;
              color: $mainColor;
            }
          }

          > .section1Part1Text1Line2 {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 28px */
            letter-spacing: -0.3px;
            color: $fontHeavy;
          }
        }

        > .section1Part1Text2 {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
          color: $subtextColor;
        }
      }

      > .section1Part2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 40px;
        width: 424px;
        height: 691px;
        gap: 40px;

        .sliderWrapper {
          width: 300px;
          position: relative;
          align-items: center;
          $buttonWidth: 52px;

          button[data-direction="prev"] {
            position: absolute;
            width: $buttonWidth;
            height: $buttonWidth;
            left: -50px !important;
          }

          button[data-direction="next"] {
            position: absolute;
            width: $buttonWidth;
            height: $buttonWidth;
            right: -110px !important;
          }

          .currentSlide {
            @include showOpacityToOne(0.5s, 0);
            //transition: all 0s;
            //transition-delay: 0s;
          }

          .notCurrentSlide {
            @include showOpacityToOne(0.5s, 0);
            //transition: all 0s;
            //transition-delay: 0s;

            &.show {
              opacity: 0.5;
            }
          }
        }
      }
    }

    > .section2Wrapper {
      position: relative;
      z-index: $zIndexContent;

      width: 100%;
      @include tabletWidth;
      padding: 0 30px;

      > .section2 {
        display: flex;
        width: 728px;
        height: 102px;
        padding: 30px 30px 30px 140px;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        background-color: $mainColor;


        > .section2Part1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 410px;
          height: 42px;

          > .section2Part1ImageWrapper {
            position: relative;

            > .linkeyImg {
              position: absolute;
              left: -115px;
              top: -40px;

              width: 100px;
              height: 122px;
              flex-shrink: 0;
            }
          }

          > .section2Part1Desc {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            letter-spacing: -0.21px;
            color: white;

          }
        }

        > .section2Part2 {

        }
      }
    }

    > .section3 {
      position: relative;
      z-index: $zIndexNormal;

      $headerHeightMinus10: calc(#{$headerHeight} - 50px);
      margin-top: calc(#{$headerHeightMinus10} * -1);
      padding: {
        top: calc(#{$headerHeightMinus10} + 113px);
        left: 30px;
        bottom: 150px;
        right: 30px;
      };
      @include tabletWidth;
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 50px;
      justify-content: flex-start;

      > .section3Count {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.3px;
        color: $KPNavy;
      }

      > .section3List {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > .section3ListItem {
          display: flex;
          gap: 30px;
          justify-content: space-between;
          align-items: stretch;
          @include showBottomToTop(0.8s, 0, 50px);

          > .itemTextArea {
            flex-grow: 1;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 20px;

            > .writerInfo {
              min-width: 728px;
              display: flex;
              gap: 16px;
              align-items: center;
              align-self: stretch;

              > .profileImage {
                width: 63.778px;
                height: 63.778px;

                > img {
                  width: 100%;
                }
              }

              > .profileInfo {
                display: flex;
                gap: 10px;
                align-items: flex-end;

                > .name {
                  min-width: 62px;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 140%;
                  letter-spacing: -0.3px;
                  color: $fontHeavy;
                }

                > .additionalInfo {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                  letter-spacing: -0.21px;
                  color: $c4c4c4;

                  > .exceptName {
                    color: $fontHeavy;
                  }
                }
              }
            }

            > .contentInfo {
              display: flex;
              height: 120px;
              align-items: flex-start;
              gap: 20px;
              align-self: stretch;

              > .contentInfoWrapper {
                width: 100%;
                max-width: 498px;
                height: 80px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                > .contentInfoTitle {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 150%;
                  letter-spacing: -0.24px;
                  color: $fontHeavy;
                  width: 100%;

                  white-space: normal;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  word-break: keep-all;
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                }

                > .contentText {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                  letter-spacing: -0.21px;
                  color: $subtextColor;
                  overflow: hidden;
                  white-space: normal;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  word-break: keep-all;
                }
              }

              > .image {
                flex-grow: 1;
                width: 160px;
                height: 120px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-radius: 6px;
              }
            }
              > .contentInfoWrittenAt {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: -0.21px;
                color: $subtextColor;
              }

          }
        }

        > .section3ListDivider {
          margin: 20px 0;
          border: 1px solid #C4C4C4;
        }
      }

      > .section3Pagination {
        width: 100%;
      }
    }
  }

  .editableButtonsMobile {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;

    > button {
      padding: 4px 12px;
      border-radius: 100px;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.5;
      border: 1.5px solid $blueLight;

      &.purple {
        color: $kpBlue;
      }

      &.pink {
        border-color: $lightPink;
        color: $hotpink;
      }

    }
  }
}

@include desktop {
  .mainWrapper {
    margin-bottom: 100px;
  }

  .main {
    display: flex;
    flex-direction: column;

    > .titleWrapper {
      padding-top: calc(#{$headerHeight} - 10px);
      height: calc(#{$headerHeight} - 10px + 557px);
      background: linear-gradient(90deg, #583BE2 0%, #583BE2 81.25%, #9B89EE 100%);
      position: relative;

      > .titleEffect {
        width: 100%;
        height: 100%;
        background: url("../../../assets/images/sub/review/title_effect.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        position: absolute;
        left: 0;
        top: 0;
        z-index: $zIndexBgItemOnBg;
      }

      > .title {
        position: relative;
        z-index: $zIndexContent;
        @include subpageTitle("../../../assets/images/sub/review/title_cloud.png");
        height: 101%;
        background-size: 109vw;
        background-repeat: no-repeat;
        padding-top: 180px;
        align-items: flex-start;

        > .text {
          letter-spacing: -0.015em;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          @include showBottomToTop(0.8s, 0, 50px);
        }
      }
    }

    > .section1 {
      padding: 104px 30px 150px;
      @include desktopWidth;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      > .section1Part1 {
        width: 558px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        z-index: $zIndexStep5;

        > .section1Part1Text1 {
          white-space: pre-wrap;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          > .section1Part1Text1Line1 {
            font-weight: normal;
            font-size: 60px;
            line-height: 1.4;
            color: $fontHeavy;

            > .point {
              font-weight: bold;
              color: $mainColor;
            }
          }

          > .section1Part1Text1Line2 {
            width: 100%;
            font-weight: normal;
            font-size: 40px;
            line-height: 1.4;
            color: $fontHeavy;
          }
        }

        > .section1Part1Text2 {
          width: 100%;
          font-weight: normal;
          font-size: 24px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $subtextColor;
        }
      }

      > .section1Part2 {
        margin-left: 100px;
        width: 100%;
        flex: 1;

        .sliderWrapper {
          position: relative;
          $buttonWidth: 70px;

          button[data-direction="prev"] {
            position: absolute;
            width: $buttonWidth;
            height: $buttonWidth;
            left: -100px !important;
          }

          button[data-direction="next"] {
            position: absolute;
            width: $buttonWidth;
            height: $buttonWidth;
            right: -100px !important;
          }

          .currentSlide {
            @include showOpacityToOne(0.5s, 0);
            //transition: all 0s;
            //transition-delay: 0s;
          }

          .notCurrentSlide {
            @include showOpacityToOne(0.5s, 0);
            //transition: all 0s;
            //transition-delay: 0s;

            &.show {
              opacity: 0.5;
            }
          }
        }
      }
    }

    > .section2Wrapper {
      position: relative;
      z-index: $zIndexContent;

      width: 100%;
      @include desktopWidth;
      padding: 0 30px;

      > .section2 {
        height: 170px;
        border-radius: 30px;
        background-color: $mainColor;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 60px;

        > .section2Part1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 30px;

          > .section2Part1ImageWrapper {
            position: relative;
            width: 150px;
            height: 170px;

            > .linkeyImg {
              position: absolute;
              left: 0;
              top: 20px;

              width: 150px;
            }
          }

          > .section2Part1Desc {
            font-weight: normal;
            font-size: 24px;
            line-height: 1.4;
            color: white;

          }
        }

        > .section2Part2 {

        }
      }
    }

    > .section3 {
      position: relative;
      z-index: $zIndexNormal;

      $headerHeightMinus10: calc(#{$headerHeight} - 50px);
      margin-top: calc(#{$headerHeightMinus10} * -1);
      padding: {
        top: calc(#{$headerHeightMinus10} + 113px);
        left: 30px;
        bottom: 150px;
        right: 30px;
      };
      @include desktopWidth;
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 50px;
      justify-content: flex-start;

      > .section3Count {
        font-weight: normal;
        font-size: 32px;
        line-height: 1.4;
        color: $KPNavy;
      }

      > .section3List {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > .section3ListItem {
          display: flex;
          gap: 30px;
          justify-content: space-between;
          align-items: stretch;
          @include showBottomToTop(0.8s, 0, 50px);

          > .itemTextArea {
            flex-grow: 1;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 20px;

            > .writerInfo {
              display: flex;
              gap: 13px;
              align-items: center;

              > .profileImage {
                width: 90px;
                height: 90px;

                > img {
                  width: 100%;
                }
              }

              > .profileInfo {
                display: flex;
                gap: 10px;
                align-items: flex-end;

                > .name {
                  font-weight: normal;
                  font-size: 24px;
                  line-height: 1.4;
                  color: $fontHeavy;
                }

                > .additionalInfo {
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 1.5;
                  color: $c4c4c4;

                  > .exceptName {
                    color: $fontHeavy;
                  }
                }
              }
            }

            > .contentInfo {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              > .contentInfoTitle {
                font-weight: bold;
                font-size: 20px;
                line-height: 1.5;
                color: $fontHeavy;

                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-break: keep-all;
                -webkit-line-clamp: 1;
              }

              > .contentText {
                height: 96px;
                font-weight: normal;
                font-size: 16px;
                line-height: 1.5;
                color: $subtextColor;
                overflow: hidden;

                white-space: normal;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-break: keep-all;
                -webkit-line-clamp: 4;
              }

              > .contentInfoWrittenAt {
                font-weight: normal;
                font-size: 16px;
                line-height: 1.5;
                color: $subtextColor;
              }
            }
          }

          > .itemButtonAndImageArea {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 20px;

            .image {
              cursor: pointer;
              width: 322px;
              height: 200px;
              overflow: hidden;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              border-radius: 10px;
            }
          }
        }

        > .section3ListDivider {
          margin: 40px 0;
          border: 1px solid #C4C4C4;
        }
      }

      > .section3Pagination {
        width: 100%;
      }
    }
  }

  .editableButtons {
    display: flex;
    gap: 6px;

    > button {
      padding: 6px 20px;
      border-radius: 100px;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.5;
      border: 1.5px solid $blueLight;

      &.purple {
        color: $kpBlue;
      }

      &.pink {
        border-color: $lightPink;
        color: $hotpink;
      }

    }
  }
}