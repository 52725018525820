$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "src/assets/scss/variables";

@mixin leaveButtonBase {
  display: flex;

  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

@mixin leaveButtonTextPCBase {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.3px;
}

@include mobile{
  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $zIndexModal;

    .leaveMainWrapper {
      display: flex;
      width: 320px;
      flex-direction: column;
      align-items: flex-start;

      height: 600px;
      max-height: 90%;
      overflow-y: auto;
      scrollbar-width: none;

      background: #FBFBFF;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);


    }

    // 헤더 영역 스타일
    .leaveHeaderWrapper {
      display: flex;
      width: 320px;
      height: 40px;
      padding: 7px 10px;
      justify-content: space-between;
      align-items: center;
      position: fixed;

      background: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);

      > .leaveHeaderLogo {
        display: flex;
        height: 23px;
        align-items: flex-end;
        gap: 10px;
        flex: 1 0 0;
      }

      > .leaveCloseIcon {
        cursor: pointer;
        background-image: url("../../../images/modal/member/closeIcon.svg");
        width: 26px;
        height: 26px;
      }
    }

    // 바디 영역 스타일
    .leaveBodyWrapper {
      margin-top: 40px;
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .leaveBodyTitleWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;

        > .leaveBodyTitle {
          align-self: stretch;
          color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 28px */
          letter-spacing: -0.3px;
        }

        .leaveBodySubTitleWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;

          > .leaveBodySubtitle {
            color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
          }
        }
      }
    }
    .leaveAgreementWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      align-self: stretch;

      .leaveAgreementContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;


        .agreementContent {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;

          // 탈퇴 이용약관 체크박스 이미지
          > .agreementCheckImg {
            background-image: url("../../../images/modal/member/unselected_btn_mobile.svg");
            width: 24px;
            height: 24px;

            &.selected {
              background-image: url("../../../images/modal/member/selected_btn_mobile.svg");
            }
          }

          // 탈퇴 사유 라디오 버튼
          > .radioButton{
            width: 18px;
            height: 18px;
          }
        }

        .agreementTextWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;

          > .agreementText {
            align-self: stretch;
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            letter-spacing: -0.21px;

            &.bold{
              font-weight: 700;
            }
          }
        }

        .inputWrapper {
          display: flex;
          width: 280px;
          height: 41px;
          flex-direction: column;

          .contentInput{
            padding: 5px 10px;
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
            letter-spacing: -0.3px;

            &::placeholder{
              color: var(--GrayScale-G-200, #C4C4C4);
            }
          }
          .inputInline{
            width: 280px;
            height: 1px;
            background: var(--K-PASS-KPnavy-500_main, #383649);

          }
        }

        .errorMessageWrapper{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          padding-left: 5px;

          > .message{
            color: var(--Basic-Bred-500_main, #FF2C45);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            letter-spacing: -0.21px;
          }
        }
      }

    }



    // 버튼 영역 스타일
    .leaveButtonWrapper {
      display: flex;
      padding: 20px 0 30px 0;
      flex-direction: column;
      align-items: center;
      align-self: stretch;

      .buttonWrapper {
        display: flex;
        align-items: flex-start;
        gap: 20px;


        .cancelButton {
          @include leaveButtonBase;
          @include leaveButtonTextPCBase;

          width: 120px;
          height: 66px;
          background: var(--GrayScale-G-700_bodytext, #404040);
          color: var(--GrayScale-white, #FFF);
        }

        .confirmButton {
          @include leaveButtonBase;
          @include leaveButtonTextPCBase;

          width: 120px;
          height: 66px;
          color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
          border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
          transition: all 0.3s ease-in-out;

          &.selected{
            background: var(--K-PASS-KPblue-500_main, #583BE2);
            color: var(--GrayScale-white, #FFF);
          }
        }

      }
    }
  }
}

@include tablet {

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $zIndexModal;

    .leaveMainWrapper {
      display: flex;
      width: 500px;
      flex-direction: column;
      align-items: flex-start;

      height: 600px;
      max-height: 80%;
      overflow-y: auto;
      scrollbar-width: none;

      background: #FBFBFF;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);


    }

    // 헤더 영역 스타일
    .leaveHeaderWrapper {
      display: flex;
      width: 500px;
      height: 40px;
      padding: 7px 10px;
      justify-content: space-between;
      align-items: center;
      position: fixed;

      background: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);

      > .leaveHeaderLogo {
        display: flex;
        height: 23px;
        align-items: flex-end;
        gap: 10px;
        flex: 1 0 0;
      }

      > .leaveCloseIcon {
        cursor: pointer;
        background-image: url("../../../images/modal/member/closeIcon.svg");
        width: 26px;
        height: 26px;
      }
    }

    // 바디 영역 스타일
    .leaveBodyWrapper {
      margin-top: 40px;
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .leaveBodyTitleWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        align-self: stretch;

        > .leaveBodyTitle {
          color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 28px */
          letter-spacing: -0.3px;
        }

        .leaveBodySubTitleWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;

          > .leaveBodySubtitle {
            color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
          }
        }
      }
    }
    .leaveAgreementWrapper {
      display: flex;
      padding: 0 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
      align-self: stretch;

      .leaveAgreementContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;


        .agreementContent {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;

          // 탈퇴 이용약관 체크박스 이미지
          > .agreementCheckImg {
            background-image: url("../../../images/modal/member/unselected_btn_mobile.svg");
            width: 24px;
            height: 24px;

            &.selected {
              background-image: url("../../../images/modal/member/selected_btn_mobile.svg");
            }
          }

          // 탈퇴 사유 라디오 버튼
          > .radioButton{
            width: 18px;
            height: 18px;
          }
        }

        .agreementTextWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;

          > .agreementText {
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;

            &.bold{
              font-weight: 700;
            }
          }
        }

        .inputWrapper {
          display: flex;
          width: 420px;
          height: 40px;
          flex-direction: column;

          .contentInput{
            padding: 5px 10px;
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;

            &::placeholder{
              color: var(--GrayScale-G-200, #C4C4C4);
            }
          }
          .inputInline{
            width: 420px;
            height: 1px;
            background: var(--K-PASS-KPnavy-500_main, #383649);

          }
        }

        .errorMessageWrapper{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          padding-left: 5px;

          > .message{
            color: var(--Basic-Bred-500_main, #FF2C45);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
          }
        }
      }

    }



    // 버튼 영역 스타일
    .leaveButtonWrapper {
      display: flex;
      padding: 20px 0 30px 0;
      flex-direction: column;
      align-items: center;
      align-self: stretch;

      .buttonWrapper {
        display: flex;
        align-items: flex-start;
        gap: 20px;

        .cancelButton {
          @include leaveButtonBase;
          @include leaveButtonTextPCBase;

          width: 200px;
          height: 66px;
          background: var(--GrayScale-G-700_bodytext, #404040);
          color: var(--GrayScale-white, #FFF);
        }

        .confirmButton {
          @include leaveButtonBase;
          @include leaveButtonTextPCBase;

          width: 200px;
          height: 66px;
          color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
          border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
          transition: all 0.3s ease-in-out;

          &.selected{
            background: var(--K-PASS-KPblue-500_main, #583BE2);
            color: var(--GrayScale-white, #FFF);
          }
        }

      }
    }
  }
}

@include desktop {

  .backdrop {
    position: fixed;
    //top: 0;
    //left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $zIndexModal;

    .leaveMainWrapper {
      display: flex;
      width: 500px;
      flex-direction: column;
      align-items: flex-start;

      height: 800px;
      max-height: 90%;
      overflow-y: auto;
      scrollbar-width: none;

      background: #FBFBFF;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);


    }

    // 헤더 영역 스타일
    .leaveHeaderWrapper {
      padding: 12px 20px;
      display: flex;
      width: 500px;
      height: 50px;
      justify-content: space-between;
      align-items: center;
      position: fixed;

      background: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);

      > .leaveHeaderLogo {
        display: flex;
        height: 23px;
        align-items: flex-end;
        gap: 10px;
        flex: 1 0 0;
      }

      > .leaveCloseIcon {
        cursor: pointer;
        background-image: url("../../../images/modal/member/closeIcon.svg");
        width: 26px;
        height: 26px;
      }
    }

    // 바디 영역 스타일
    .leaveBodyWrapper {
      margin-top: 50Px;
      display: flex;
      padding: 30px 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 50px;
      align-self: stretch;

      .leaveBodyTitleWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        align-self: stretch;

        > .leaveBodyTitle {
          align-self: stretch;
          color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 33.6px */
          letter-spacing: -0.36px;
        }

        .leaveBodySubTitleWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;

          > .leaveBodySubtitle {
            align-self: stretch;
            color: var(--K-PASS-Color-K-PASS-Corporate-Color-KP-navy-383649, #383649);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
            letter-spacing: -0.3px;
          }
        }
      }
    }
    .leaveAgreementWrapper {
      display: flex;
      padding: 0 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      .leaveAgreementContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        align-self: stretch;


        .agreementContent {
          display: flex;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;

          // 탈퇴 이용약관 체크박스 이미지
          > .agreementCheckImg {
            background-image: url("../../../images/modal/member/unselected_btn_pc.png");
            width: 36px;
            height: 36px;

            &.selected {
              background-image: url("../../../images/modal/member/selected_btn_pc.png");
            }
          }

          // 탈퇴 사유 라디오 버튼
          > .radioButton{
            width: 18px;
            height: 18px;
          }
        }

        .agreementTextWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;

          > .agreementText {
            align-self: stretch;
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
            letter-spacing: -0.3px;

            &.bold{
              font-weight: 700;
            }
          }
        }

        .inputWrapper {
          display: flex;
          width: 420px;
          height: 41px;
          flex-direction: column;

          .contentInput{
            padding: 5px 10px;
            color: var(--K-PASS-KPnavy-500_main, #383649);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
            letter-spacing: -0.3px;

            &::placeholder{
              color: var(--GrayScale-G-200, #C4C4C4);
            }
          }
          .inputInline{
            width: 420px;
            height: 1px;
            background: var(--K-PASS-KPnavy-500_main, #383649);

          }
        }

        .errorMessageWrapper{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          padding-left: 5px;

          > .message{
            color: var(--Basic-Bred-500_main, #FF2C45);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
          }
        }
      }

    }



    // 버튼 영역 스타일
    .leaveButtonWrapper {
      display: flex;
      padding: 50px 0 30px 0;
      flex-direction: column;
      align-items: center;
      align-self: stretch;

      .buttonWrapper {
        display: flex;
        align-items: flex-start;
        gap: 20px;

        .cancelButton {
          @include leaveButtonBase;
          @include leaveButtonTextPCBase;

          width: 200px;
          height: 66px;
          background: var(--GrayScale-G-700_bodytext, #404040);
          color: var(--GrayScale-white, #FFF);
        }

        .confirmButton {
          @include leaveButtonBase;
          @include leaveButtonTextPCBase;

          width: 200px;
          height: 66px;
          color: var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
          border: 1px solid var(--K-PASS-Color-K-PASS-LD-Color-KP-navy-Medium-76718E, #76718E);
          transition: all 0.3s ease-in-out;

          &.selected{
            background: var(--K-PASS-KPblue-500_main, #583BE2);
            color: var(--GrayScale-white, #FFF);
          }
        }

      }
    }
  }
}