$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@include mobile {
  .main {
    margin-top: -10px;
    display: flex;
    flex-direction: column;

    > .title {
      @include subpageTitleMobile("../../images/sub/purpose_and_characteristics/title_bg_mobile.svg");
    }

    > .purpose {
      margin-top: -1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;

      > .part1 {
        padding: 70px 0 84px 0;
        background-color: $KPNavy;

        display: flex;
        flex-direction: column;
        align-items: center;

        > h3 {
          max-width: 100%;
          padding: 0 30px;

          > .title {
            background-color: white;
            color: $KPNavy;
            padding: 6px 20px;
          }
        }

        > .desc1 {
          margin-top: 30px;
          padding: 0 30px;
          font-weight: normal;
          font-size: 12px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: white;
          @include wordBreak;
        }

        > .image {
          margin-top: 60px;
          width: 100%;
          height: 0;
          position: relative;

          > img {
            position: absolute;
            left: calc(50vw - 135px);
            top: 0;
            width: 270px;
            @include filterDropshadow(4px, 8px);
          }
        }
      }

      > .part2 {
        padding: 214px 30px 130px 30px;

        > .desc2 {
          font-weight: bold;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include wordBreak;

          > .accent {
            color: $mainColor;
          }
        }
      }
    }

    > .characteristics {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-bottom: 130px;

      > h3 {
        margin-bottom: 30px;
        max-width: 100%;
        padding: 0 30px;

        > .title {
          padding: 6px 20px;
          @include wordBreak;
        }
      }

      > .article {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &.weakness {
          > .image {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_01_pic.png");
          }

          > .text {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_01_bg.svg");

            > .title {
              color: $fontHeavyLight;
            }

            > .list {
              color: $g2757575;
            }
          }
        }

        &.kpass {
          margin-top: 70px;

          > .image {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_02_pic.png");
          }

          > .text {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_02_bg.svg");
            background-position: center;
            background-size: cover;
            color: white;
          }
        }

        > .image {
          width: 100%;
          height: 180px;
          border-radius: 8px;
          background-size: cover;
          background-position: center;
        }

        > .text {
          width: 100%;
          border-radius: 8px;
          background: linear-gradient(111.97deg, #F4F4F4 5.73%, #EAEAEA 87.85%);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 30px 20px;
          @include wordBreak;

          > .title {
            font-weight: bold;
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: -0.05em;
          }

          > .list {
            margin-top: 16px;
            font-weight: normal;
            font-size: 12px;
            line-height: 1.6;
            letter-spacing: -0.05em;

            > li {
              list-style: disc;
              margin-left: 2rem;
            }
          }
        }
      }
    }

    > .evaluationItems {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: $lightMainColor;
      padding: {
        top: 130px;
        bottom: 100px;
      };

      > .title {
        margin: 0 30px;
        @include wordBreak;
      }

      > .contentWrapper {
        padding: 0 40px;
        margin: 70px auto 0 auto;
        display: grid;

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);
        column-gap: 32px;
        row-gap: 60px;

        @include miniMobile {
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(8, auto);
          > .contentItem {

            > .image {
              margin: 0 auto;
            }
          }
        }


        > .contentItem {

          > .image {
            margin: 0 auto;
            width: 120px;
            height: 120px;
            background-color: $lightHeavyMainColor;
            border-radius: 50% 50% 0 50%;
            position: relative;

            > .indexNumber {
              position: absolute;
              left: 0;
              top: 0;
              width: 37.78px;
              height: 37.78px;
              background-color: $brightHeavyPurple;
              border-radius: 0 50% 50% 50%;

              color: white;
              font-weight: normal;
              font-size: 23px;
              line-height: 1.6;
              letter-spacing: -0.05em;

              display: flex;
              justify-content: center;
              align-items: center;
            }

            &.image1 {
              padding: {
                left: 29.78px;
                top: 34.69px;
              }

              > img {
                width: 63.56px;
              }
            }

            &.image2 {
              padding: {
                left: 29.63px;
                top: 35px;
              }

              > img {
                width: 69.37px;
              }
            }

            &.image3 {
              padding: {
                left: 24px;
                top: 37px;
              }

              > img {
                width: 72px;
              }
            }

            &.image4 {
              padding: {
                left: 29.5px;
                top: 38px;
              }

              > img {
                width: 66.4px;
              }
            }

            &.image5 {
              padding: {
                left: 27px;
                top: 29px;
              }

              > img {
                width: 69.56px;
              }
            }

            &.image6 {
              padding: {
                left: 26px;
                top: 32px;
              }

              > img {
                width: 75.41px;
              }
            }

            &.image7 {
              padding: {
                left: 29.71px;
                top: 42.99px;
              }

              > img {
                width: 61.73px;
              }
            }

            &.image8 {
              padding: {
                left: 30px;
                top: 36.75px;
              }

              > img {
                width: 64.1px;
              }
            }
          }

          > .text {
            margin-top: 12px;
            font-weight: bold;
            font-size: 12px;
            line-height: 1.6;
            letter-spacing: -0.05em;
            text-align: center;
            color: $fontHeavyLight;

            > .lightText {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .main {
    display: flex;
    flex-direction: column;

    > .title {
      @include subpageTitleTablet("../../images/sub/purpose_and_characteristics/title_bg.svg");

      >.text {

        color: var(--GrayScale-white, #FFF);
        text-align: center;
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.30);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.36px;
      }
    }

    > .purpose {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 475px;
      background-color: $KPNavy;
      text-align: center;
      position: relative;

      > .part1 {
        @include tabletWidth;


        > h3 {
          display: flex;
          justify-content: center;

          .title {
            background-color: white;
            color: $KPNavy;
            margin-top: 100px;
            width: 308px;
            height: 52px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.3px;

            &.ru {
              width: fit-content;
              padding: 12px 40px;
            }
          }
        }

        > .desc1 {
          margin-top: 30px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: white;
          @include newLine;

          &.ru {
            padding: 0 100px;
          }
        }

        > .image {
          margin: 80px auto 0 auto;
          width: 712px;
          height: 0;
          position: relative;

          > img {
            left: 0;
            top: 0;
            width: 420px;
            height: 260px;
            @include filterDropshadow(15px, 40px);
          }
        }
      }

      > .part2 {
        > .desc2 {
          width: 100%;
          margin: 0 auto;
          position: absolute;
          left: 0;
          top: 700px;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $KPNavy;

          > .accent {
            color: $mainColor;
          }

          > .ru{
            top: 10px;
          }
        }
      }
    }

    > .characteristics {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-bottom: 150px;

      > .bgItem1 {
        width: 417px;
        height: 417px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        background-color: getFontHeavyColorWithOpacity(0.1);
        position: absolute;
        left: calc(50vw - 1028px);
        top: 114px;
        z-index: $zIndexBgItem;
      }

      > .bgItem2 {
        width: 300px;
        height: 300px;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        background-color: getMainColorWithOpacity(0.1);
        position: absolute;
        left: calc(50vw + 623px);
        top: 540px;
        z-index: $zIndexBgItem;
      }

      > .bgItem3 {
        width: 564px;
        height: 564px;
        border: 124.6px solid getMainColorWithOpacity(0.1);
        border-radius: 50%;
        position: absolute;
        left: calc(50vw - 920px);
        top: 1594px;
        z-index: $zIndexBgItem;
      }

      > h3 {
        > .title {
          margin-top: 400px;
          width: 664.5px;

          &.ru {
            width: fit-content;
            height: fit-content;
            max-width: 700px;
            padding: 14px 20px;
            text-align: center;
            @include newLine;
          }
        }
      }

      > .article {
        display: flex;
        @include tabletWidth;
        min-height: 320px;
        gap: 10px;

        &.ru {
          height: fit-content;

          > .image {
            background-size: cover;
            height: auto;
          }

          > .text {
            padding: 80px;
            height: fit-content;
          }
        }

        &.weakness {
          margin-top: 69px;

          > .image {
            width: 354px;
            //min-height: 320px;
            max-height: 320px;
            border-radius: 30px;
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_01_pic_tablet.png");
          }

          > .text {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_01_bg.svg");

            > .title {
              color: $fontHeavyLight;
            }

            > .list {
              color: $g2757575;
              @include newLine;
            }
          }
        }

        &.kpass {
          margin-top: 50px;

          > .image {
            width: 354px;
            //min-height: 440px;
            max-height: 440px;
            //height: auto;
            border-radius: 30px;
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_02_pic_tablet.png");
          }

          > .text {
            width: 354px;
            min-height: 440px;
            height: auto;
            border-radius: 30px;
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_02_bg.svg");
            color: white;
          }
        }

        > .image {
        }

        > .text {
          flex: 1;
          width: 354px;
          min-height: 320px;
          height: auto;
          margin-left: 10px;
          border-radius: 30px;
          background-size: cover;
          background-position: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 30px 20px;

          > .title {
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.3px;
          }

          > .list {
            margin-top: 16px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.24px;

            > li {
              list-style: disc;
              margin-left: 2.4rem;
              @include newLine;
            }
          }
        }
      }
    }

    > .evaluationItems {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 928px;
      background-color: $lightMainColor;
      padding: 150px 0;

      > .title {
        width: 321px;
        height: 52px;
        display: inline-flex;
        padding: 12px 40px;
        justify-content: center;
        align-items: center;

        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.3px;

        &.ru {
          width: fit-content;
          padding: 12px 40px;
        }
      }

      > .contentWrapper {
        margin-top: 92px;
        display: flex;
        width: 768px;
        padding: 0 40px;
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
        row-gap: 60px;
        flex-wrap: wrap;

        > .contentItem {
          width: 150.002px;
          min-height: 218px;
          height: auto;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          > .image {
            width: 150.002px;
            height: 150.002px;
            background-color: $lightHeavyMainColor;
            border-radius: 50% 50% 0 50%;
            position: relative;

            > .indexNumber {
              position: absolute;
              left: 0;
              top: 0;
              width: 55px;
              height: 55px;
              background-color: $brightHeavyPurple;
              border-radius: 0 50% 50% 50%;
              color: white;
              font-weight: normal;
              font-size: 3.4rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              display: flex;
              justify-content: center;
            }

          }

          > .text {
            margin-top: 20px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
            letter-spacing: -0.24px;
            text-align: center;
            @include newLine;

            > .lightText {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.24px;
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .main {
    display: flex;
    flex-direction: column;

    > .title {
      @include subpageTitle("../../images/sub/purpose_and_characteristics/title_bg.svg");
    }

    > .purpose {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 797px;
      background-color: $KPNavy;
      text-align: center;
      position: relative;

      > .part1 {
        @include desktopWidth;


        > h3 {
          display: flex;
          justify-content: center;

          .title {
            background-color: white;
            color: $KPNavy;
            margin-top: 150px;
            width: 545.5px;

            &.ru {
              width: fit-content;
              padding: 12px 40px;
            }
          }
        }

        > .desc1 {
          margin-top: 50px;
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: white;
          @include newLine;

          &.ru {
            padding: 0 100px;
          }
        }

        > .image {
          margin: 100px auto 0 auto;
          width: 712px;
          height: 0;
          position: relative;

          > img {
            position: absolute;
            left: 0;
            top: 0;
            width: 712px;
            height: 441px;
            @include filterDropshadow(15px, 40px);
          }
        }
      }

      > .part2 {
        > .desc2 {
          width: 100%;
          margin: 0 auto;
          position: absolute;
          left: 0;
          top: 1011px;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;

          > .accent {
            color: $mainColor;
          }
        }
      }
    }

    > .characteristics {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-bottom: 304px;

      > .bgItem1 {
        width: 417px;
        height: 417px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        background-color: getFontHeavyColorWithOpacity(0.1);
        position: absolute;
        left: calc(50vw - 1028px);
        top: 114px;
        z-index: $zIndexBgItem;
      }

      > .bgItem2 {
        width: 300px;
        height: 300px;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        background-color: getMainColorWithOpacity(0.1);
        position: absolute;
        left: calc(50vw + 623px);
        top: 540px;
        z-index: $zIndexBgItem;
      }

      > .bgItem3 {
        width: 564px;
        height: 564px;
        border: 124.6px solid getMainColorWithOpacity(0.1);
        border-radius: 50%;
        position: absolute;
        left: calc(50vw - 920px);
        top: 1594px;
        z-index: $zIndexBgItem;
      }

      > h3 {
        > .title {
          margin-top: 540px;
          width: 664.5px;

          &.ru {
            width: fit-content;
            height: fit-content;
            max-width: 700px;
            padding: 14px 20px;
            text-align: center;
            @include newLine;
          }
        }
      }

      > .article {
        display: flex;
        @include desktopWidth;
        height: 522px;

        &.ru {
          height: fit-content;

          > .image {
            background-size: cover;
            height: auto;
          }

          > .text {
            padding: 80px;
            height: fit-content;
          }
        }

        &.weakness {
          margin-top: 100px;

          > .image {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_01_pic.png");
          }

          > .text {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_01_bg.svg");

            > .title {
              color: $fontHeavyLight;
            }

            > .list {
              color: $g2757575;
              @include newLine;
            }
          }
        }

        &.kpass {
          margin-top: 60px;

          > .image {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_02_pic.png");
          }

          > .text {
            background-image: url("../../images/sub/purpose_and_characteristics/characteristics_02_bg.svg");
            color: white;
          }
        }

        > .image {
          width: 588px;
          height: 100%;
          border-radius: 30px;
        }

        > .text {
          flex: 1;
          width: 898px;
          margin-left: 10px;
          border-radius: 30px;
          background-size: cover;
          background-position: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: {
            left: 80px;
            right: 80px;
          };

          > .title {
            font-weight: bold;
            font-size: 4rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
          }

          > .list {
            margin-top: 30px;
            font-weight: normal;
            font-size: 2rem;
            line-height: 1.8;
            letter-spacing: -0.05em;

            > li {
              list-style: disc;
              margin-left: 2rem;
              @include newLine;
            }
          }
        }
      }
    }

    > .evaluationItems {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: $lightMainColor;
      padding: {
        top: 300px;
        bottom: 300px;
      };

      > .title {
        width: 545.5px;

        &.ru {
          width: fit-content;
          padding: 12px 40px;
        }
      }

      > .contentWrapper {
        margin-top: 120px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        column-gap: 34px;
        row-gap: 82px;

        > .contentItem {
          width: 270px;

          > .image {
            width: 270px;
            height: 270px;
            background-color: $lightHeavyMainColor;
            border-radius: 50% 50% 0 50%;
            position: relative;

            > .indexNumber {
              position: absolute;
              left: 0;
              top: 0;
              width: 85px;
              height: 85px;
              background-color: $brightHeavyPurple;
              border-radius: 0 50% 50% 50%;
              color: white;
              font-weight: normal;
              font-size: 5.2rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              display: flex;
              justify-content: center;
            }

            &.image1 {
              padding: {
                left: 67px;
                top: 78px;
              }
            }

            &.image2 {
              padding: {
                left: 66px;
                top: 84px;
              }
            }

            &.image3 {
              padding: {
                left: 58px;
                top: 80px;
              }
            }

            &.image4 {
              padding: {
                left: 62.41px;
                top: 84.57px;
              }
            }

            &.image5 {
              padding: {
                left: 60px;
                top: 67px;
              }
            }

            &.image6 {
              padding: {
                left: 61.36px;
                top: 71px;
              }
            }

            &.image7 {
              padding: {
                left: 66.76px;
                top: 101.79px;
              }
            }

            &.image8 {
              padding: {
                left: 73px;
                top: 87px;
              }
            }
          }

          > .text {
            margin-top: 30px;
            font-weight: bold;
            font-size: 2rem;
            line-height: 1.5;
            letter-spacing: -0.05em;
            text-align: center;
            @include newLine;

            > .lightText {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}