$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@include mobile {
  .basis {

    > .article1 {
      padding: 70px 20px 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {}

      > .basisImage {
        margin-top: 60px;
        width: 100%;

        > img {
          width: 100%;
        }
      }

      > .desc {
        margin-top: 60px;

        > .text1 {
          font-weight: bold;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include wordBreak;
        }

        > .text2 {
          margin-top: 12px;
          font-weight: normal;
          font-size: 12px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include wordBreak;
        }
      }

      > .standardizationButton {
        width: 100%;
        padding: {
          top: 72px;
          bottom: 12px;
          left: 30px;
          right: 10px;
        };
      }

      > .passImages {
        padding: 0 10px;
        margin-top: 100px;

        > .imageList {
          display: flex;
          flex-direction: column;
          gap: 40px;

          > .itemLine {
            display: flex;
            flex-direction: column;
            gap: 8px;

            > .image {
              width: 100%;
              position: relative;

              &.imagePA {
              }

              &.imageS {
              }

              > .titleImg {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
                width: 64.91px;
                height: 65.36px;
              }

              > .exampleImg {
                width: 100%;
              }
            }

            .descWrapper {
              width: 100%;
              padding: 30px 20px;
              border: 8px solid $lightMainColor;
              border-radius: 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              > .desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                > .subtitle {
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 1.6;
                  color: $mainColor;
                }

                > .title {
                  text-align: center;
                  font-weight: bold;
                  font-size: 24px;
                  line-height: 1.6;
                  letter-spacing: -0.05em;
                  color: $KPNavy;
                }
              }

              font-weight: normal;
              font-size: 12px;
              line-height: 1.6;
              text-align: left;

              > .phrase {
                color: $g2757575;
                margin-top: 20px;
                letter-spacing: -0.05em;
                @include wordBreak;

                .en {
                  letter-spacing: 0;
                }

                > .accent {
                  font-weight: bold;
                  color: $KPNavy;
                }

                > .underline {
                  text-decoration: underline;
                }
              }

              > .phrase2 {

              }
            }
          }
        }

      }
    }

    > .article2 {
      margin: {
        top: 130px;
        bottom: 100px;
      };
      display: flex;
      flex-direction: column;

      > .imageArea {
        width: 100%;

        > img {
          width: 100%;
        }
      }

      > .text {
        margin-top: 30px;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > .phrase1 {
          font-weight: bold;
          font-size: 24px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $KPNavy;
          @include wordBreak;

          > .accent {
            color: $mainColor;
          }
        }

        > .phrase2 {
          margin-top: 30px;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include wordBreak;
        }

        > .phrase3 {
          margin-top: 12px;
          font-weight: normal;
          font-size: 12px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include wordBreak;
        }
      }

      > .image {
        flex: 1;
        height: 100%;
        position: relative;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 736px;
          height: 1110px;
        }
      }
    }
  }
}

@include tablet {
  .basis {

    > .article1 {
      padding: {
        top: 100px;
      };
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {}

      > .basisImage {
        margin-top: 80px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 630px;
        height: 166px;

        > img {
          width: 100%;
        }
      }

      > .desc {
        margin-top: 80px;

        > .text1 {
          padding: 0 100px;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $KPNavy;
          @include newLine;
        }

        > .text2 {
          margin-top: 20px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $g2757575;
          @include newLine;
        }
      }

      > .standardizationButton {
        padding-top: 70px;
        padding-bottom: 12px;
      }

      > .passImages {
        padding: 0 10px;
        margin-top: 100px;
        width: $maxWidthTablet;

        > .imageList {
          display: grid;
          grid-template-columns: repeat(2, 1fr); /* 2열로 설정 */

          gap: 20px;

          > .itemLine {
            width: 354px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            > .image {
              width: 100%;
              position: relative;

              &.imagePA {
              }

              &.imageS {
              }

              > .titleImg {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
                width: 64.91px;
                height: 65.36px;
              }

              > .exampleImg {
                width: 354px;
              }
            }

            .descWrapper {
              width: 100%;
              padding: 30px 20px;
              border: 8px solid $lightMainColor;
              border-radius: 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              > .desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;

                > .subtitle {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                  letter-spacing: -0.21px;
                  color: $mainColor;
                }

                > .title {
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 140%;
                  letter-spacing: -0.36px;
                  color: $KPNavy;
                }

                > .ru{
                }
              }

              font-weight: normal;
              font-size: 12px;
              line-height: 1.6;
              text-align: left;

              > .phrase {
                color: $g2757575;
                margin-top: 20px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: -0.24px;
                @include wordBreak;

                .en {
                  letter-spacing: 0;
                }

                > .accent {
                  font-weight: bold;
                  color: $KPNavy;
                }

                > .underline {
                  text-decoration: underline;
                }
              }

              > .phrase2 {

              }
            }
          }
        }
      }
    }

    > .article2 {
      @include tabletWidth;
      margin: {
        top: 150px;
        bottom: 150px;
      };
      display: flex;
      width: 768px;
      padding: 0 20px;
      align-items: flex-start;

      > .text {
        display: flex;
        width: 354px;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        flex-shrink: 0;
        z-index: $zIndexContent;

        > .phrase1 {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.36px;
          color: $KPNavy;
          @include newLine;

          &.ru {
            font-size: 24px;
          }

          > .accent {
            color: $mainColor;
          }
        }

        > .phrase2 {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $KPNavy;
          @include newLine;
        }

        > .phrase3 {
          @include newLine;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
          color: $g2757575;
        }
      }

      > .image {
        flex: 1;
        height: 100%;
        position: relative;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 354px;
          height: 560px;
        }
      }
    }
  }
}

@include desktop {
  .basis {

    > .article1 {
      padding: {
        top: 150px;
      };
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {}

      > .basisImage {
        margin-top: 100px;
        width: 1045px;
        height: 275px;

        > img {
          width: 100%;
        }
      }

      > .desc {
        margin-top: 100px;

        > .text1 {
          padding: 0 100px;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include newLine;
        }

        > .text2 {
          margin-top: 30px;
          font-weight: normal;
          font-size: 20px;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include newLine;
        }
      }

      > .standardizationButton {
        padding-top: 90px;
        padding-bottom: 20px;
      }

      > .passImages {
        margin-top: 200px;

        > .imageList {
          max-width: $maxWidth;

          .itemLine {
            display: flex;
            margin-bottom: 80px;

            &:last-child {
              margin-bottom: 0;
            }

            > .image {
              width: 520px;
              position: relative;

              &.imagePA {
                height: 360px;
              }

              &.imageS {
                height: 614px;
              }

              > .titleImg {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
              }

              > .exampleImg {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 2;
              }
            }

            .descWrapper {
              padding: 0 72px;
              flex: 1;
              border: 8px solid $lightMainColor;
              border-radius: 50px;
              margin-left: 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              > .desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                > .subtitle {
                  font-weight: normal;
                  font-size: 2rem;
                  line-height: 1.6;
                  color: $mainColor;
                }

                > .title {
                  font-weight: bold;
                  font-size: 4rem;
                  line-height: 1.6;
                  letter-spacing: -0.05em;
                  color: $KPNavy;

                  &.ru {
                    font-size: 3.2rem;
                  }
                }
              }

              font-weight: normal;
              font-size: 2rem;
              line-height: 1.8;
              text-align: left;

              > .phrase {
                color: $g2757575;
                margin-top: 40px;
                letter-spacing: -0.05em;

                .en {
                  letter-spacing: 0;
                }

                > .accent {
                  font-weight: bold;
                  color: $KPNavy;
                }

                > .underline {
                  border-bottom: 2px solid $g2757575;
                }
              }

              > .phrase2 {

              }
            }
          }
        }
      }
    }

    > .article2 {
      @include desktopWidth;
      height: 1110px;
      margin: {
        top: 251px;
        bottom: 150px;
      };
      display: flex;

      > .text {
        width: 674px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        z-index: $zIndexContent;
        margin-right: 67px;

        > .phrase1 {
          font-weight: bold;
          font-size: 4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include newLine;

          &.ru {
            font-size: 3.6rem;
          }

          > .accent {
            color: $mainColor;
          }
        }

        > .phrase2 {
          margin-top: 50px;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include newLine;
        }

        > .phrase3 {
          @include newLine;
          margin-top: 30px;
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $g2757575;
        }
      }

      > .image {
        flex: 1;
        height: 100%;
        position: relative;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 736px;
          height: 1110px;
        }
      }
    }
  }
}