$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  .article1 {
    display: flex;
    flex-direction: column;
  }

  $ellipseWidth: 132vw;
  $ellipseHeight: 48.61vw;
  $section1BottomSpace: 8.2vw;

  .section2BgMobile {
    width: 100vw;
    height: 630px;
  }

  .section2TextArea {
    color: var(--GrayScale-white, #fff);
    text-align: center;

    /* 📱Mobile/SubTitle/ST_Bold */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    letter-spacing: -0.3px;
  }

  .strong {
    font-weight: 700;
  }

  .section1 {
    position: relative;
    padding: 60px 30px 0 30px;

    &.withApp {
      padding-top: 100px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;
    z-index: $zIndexOverContent;

    > .bgItemTopRight {
      width: 114px;
      height: 114px;
      background-color: $itemBgGray;
      position: absolute;
      top: -10px;
      left: calc(50vw + 82px);
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    > .slider {
      margin-bottom: 96px;
      min-height: 537px;
      position: relative;
      z-index: $zIndexContent;
    }

    > .bottomEllipse {
      width: $ellipseWidth;
      height: $ellipseHeight;
      background-color: white;
      position: absolute;
      left: calc(50vw - (#{$ellipseWidth}) / 2);
      bottom: calc(#{$section1BottomSpace} * -1);
      border-radius: 0 0 50% 50% / 0 0 20% 20%;
      overflow: hidden;
      z-index: $zIndexBgItemOnBg;

      > .ellipseBgItem1 {
        width: 197px;
        height: 197px;
        border-radius: 50%;
        border: 43.5px solid $itemBgBlue;
        position: absolute;
        left: calc(#{$ellipseWidth} * 0.02);
        top: 0;
      }
    }
  }

  .pressSection {
    width: 100vw;
    height: fit-content;
    position: relative;
    padding: calc(100px + #{$section1BottomSpace}) 30px 260px 30px;
    background: linear-gradient(180deg, #2c2a3a 22.01%, $KPNavy 44.99%, #2d2b3c 67.49%);

    display: flex;
    flex-direction: column;

    > .maxAndPiece {
      display: none;
    }

    > .audienceBgItem {
      position: absolute;
      bottom: 0;
      left: calc(50vw - 280px);
      width: 560px;

      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        width: 100%;
      }
    }

    > .pressContent {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;

      > .texts {
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;

        > .pressTitle {
          color: white;
          font-size: 44px;
          font-weight: normal;
          line-height: 1.4;
          letter-spacing: -0.66px;
          @include newLine;
        }

        > .pressDescription {
          color: $kpNavyLight;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.24px;

          @include showBottomToTop(0.4s, 0.4s, 30%);
          @include wordBreak;
        }
      }

      > .videoWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        position: relative;

        > .linkeyWrapper {
          display: none;
        }

        > .mainVideo {
          width: 100%;

          > video {
            width: 100%;
            border-radius: 10px;
            box-shadow: 0px -7px 20px 0px rgba(0, 0, 0, 0.5);
          }
        }

        > .videoThumbnails {
          display: flex;
          justify-content: space-between;
          gap: 7px;

          > button {
            flex: 1;
            height: 90px;
            position: relative;
            border-radius: 10px;

            display: flex;
            justify-content: center;
            align-items: center;

            background-size: cover;
            background-position: center;

            @include showBottomToTop(0.4s, 0.2s, 20%);

            > .thumbnailImage {
              width: 100%;
              height: 100%;
            }

            > .buttonCover {
              $activeColor: #6d50ff;
              position: absolute;
              width: calc(100% + 1px);
              height: 100%;
              border-radius: 10px;
              overflow: hidden;

              > .default {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $KPNavy;
                opacity: 0.6;
              }

              > .hover {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $activeColor;
                transform: scale(0);
                border-radius: 50%;
                opacity: 0;
                transition: all 0.4s ease-in-out;
              }
            }

            > .buttonIcon {
              position: absolute;

              display: flex;
              justify-content: center;
              align-items: center;

              > .default {
                position: absolute;
                opacity: 1;
                transition: all 0.4s ease-in-out;
                width: 33px;
              }

              > .hover {
                position: absolute;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                width: 33px;
              }

              > .active {
                position: absolute;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                width: 33px;
              }
            }

            &.active {
              > .buttonCover {
                > .hover {
                  transform: scale(2.5);
                  opacity: 0.4;
                }
              }

              > .buttonIcon {
                > .default {
                  opacity: 0;
                }

                > .hover {
                  opacity: 0;
                }

                > .active {
                  opacity: 1;
                }
              }
            }

            &:not(.active):active {
              > .buttonIcon {
                > .default {
                  opacity: 0;
                }

                > .hover {
                  opacity: 1;
                }

                > .active {
                  opacity: 0;
                }
              }

              > .buttonCover {
                > .hover {
                  transform: scale(2.5);
                  opacity: 0.4;
                }
              }
            }
          }

          > .comingSoon {
            flex: 1;
            background-color: #22202c;
            border-radius: 10px;

            display: flex;
            align-items: center;
            justify-content: center;

            > .text {
              text-align: center;
              font-size: 14px;
              font-weight: bold;
              line-height: 1.5;
              letter-spacing: -0.015em;
              color: #75737b;
            }

            > .max {
              display: none;
              //width: 62px;
              //height: 89px;
            }
          }
        }
      }
    }
  }

  .childImage {
    width: 300px;
    height: 345px;
  }

  .childImage.show {
    opacity: 1;
    transform: scale(1);
  }

  .section2TextArea {
    color: var(--GrayScale-white, #fff);

    text-align: center;
    
    /* 📱Mobile/SubTitle/ST_Regular */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.3px;

    /* 필요한 경우 여백 추가 */
    margin-top: -500px;
    margin-bottom: 80px;
  }

  .strong {
    font-weight: 700;
  }

  .section2 {
    $fixedWidth: 2543px;
    $section2Width: calc(#{$fixedWidth} + 100vw);
    $contentHeight: 630px;
    width: 100vw;
    // height: calc(#{$fixedWidth} + #{$contentHeight});
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.isPassing {
      > .content {
        position: fixed;
        left: 0;
        top: 0;
      }
    }

    &.isDone {
      > .content {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &.isBefore {
      > .content {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    > .content {
      display: flex;

      width: $section2Width;
      height: $contentHeight;
      background: linear-gradient(90deg, #583be2 0%, #583be2 94.79%, #9b89ee 100%);
      position: relative;

      > .descWrapper {
        position: absolute;
        width: 100vw;
        left: 0;
        top: 129px;
        padding: 0 30px;

        display: flex;
        justify-content: center;
        align-items: center;

        > .desc {
          text-align: center;
          font-weight: normal;
          font-size: 20px;
          line-height: 1.4;
          color: white;
          @include newLine;
        }
      }

      > .bottomVillagePattern {
        $villageHeight: 49px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: $villageHeight;
        background-image: url("../../../assets/images/main/article1/section2/village_pattern.png");
        background-repeat: repeat-x;
        background-size: auto $villageHeight;
        z-index: $zIndexBgItemOnBg;
      }

      > .faceGroup {
        position: absolute;
        left: 233px;
        bottom: 138.16px;

        > img {
          width: 1923.63px;
        }
      }

      > .cloud {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 100vw;
        height: 100%;
        padding: 0 30px;

        background-image: url("../../../assets/images/main/article1/section2/cloud_mobile.png");
        background-size: 100vw;
        background-position: center bottom;
        background-repeat: no-repeat;
      }

      > .childrenCharacterWrapper {
        width: 100vw;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: $zIndexOnContent;
        padding: 0 30px;

        &.isPassing {
          position: fixed;
        }

        > .childrenImage {
          margin-bottom: -15vw;
          max-width: 300px;
          width: 100%;
          z-index: $zIndexOnContent;
          transform-origin: bottom;
        }
      }
    }
  }

  .section3 {
    display: flex;
    flex-direction: column;
    padding: 100px 30px 228px;
    position: relative;

    > .bgItemTopRight {
      $diameter: 228px;
      position: absolute;
      width: $diameter;
      height: $diameter;
      border-radius: 50%;
      border: 50.37px solid $itemBgBlue;
      left: calc(50vw + 36px);
      top: 480.27px;
    }

    > .bgItemLeftBottom {
      position: absolute;
      $diameter: 140px;
      width: $diameter;
      height: $diameter;
      background-color: $itemBgGray;
      left: calc(50vw - 240px);
      bottom: 100px;
      border-radius: 50% 50% 0 50%;
      z-index: $zIndexBgItemOnBg;
    }

    > .desc1 {
      margin-bottom: 100px;
      z-index: $zIndexContent;
      text-align: center;
      line-height: 1.4;
      letter-spacing: -0.015em;
      // @include showBottomToTop(0.5s, 0s, 30%);

      > .subText {
        font-weight: normal;
        font-size: 20px;
        color: $navyMedium;
        @include wordBreak;
      }

      > .primaryText {
        font-weight: normal;
        font-size: 24px;
        line-height: 1.4;
        color: $fontHeavy;
        @include wordBreak;
      }

      > .point {
        font-weight: bold;
        display: inline-block;

        background-image: url("../../../assets/images/main/article1/section3/text_point.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100%;

        font-size: 24px;
        line-height: 1.4;
        color: $fontHeavy;
        @include wordBreak;
      }
    }

    > .imageAndText {
      z-index: $zIndexContent;
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;
      @include wordBreak;

      &.part2 {
        flex-direction: column-reverse;
      }

      > .image {
        width: 100%;
        @include showBottomToTop(0.5s, 0, 30%);
      }

      > .textArea {
        padding: 6px 0 6px 26px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-left: 2px solid $mainColor;
        padding-left: 24px;

        &.leftBorder {
          @include showBottomToTop(0.5s, 0, 30%);
        }
        &.rightBorder {
          @include showBottomToTop(0.5s, 0, 30%);
        }

        > .normal {
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          color: $fontHeavy;
        }

        > .point {
          font-weight: bold;
          font-size: 20px;
          line-height: 1.4;
          color: $mainColor;
        }
      }
    }

    > .desc3 {
    }
  }

  .section4 {
    width: 100%;
    display: flex;
    flex-direction: column;

    > .imageArea {
      width: 100%;
      height: 480px;
      background-image: url("../../../assets/images/main/article1/section4/left_pic_mobile.png");
      background-size: cover;
      background-position: center;
    }

    > .textArea {
      width: 100%;
      background-color: $KPNavy;
      padding: 100px 0 624px;
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 100px;

      > .paragraphs {
        padding: 0 30px;
        font-weight: normal;

        display: flex;
        flex-direction: column;
        gap: 40px;

        $quotationMarkWidth: 40px;
        > .openingQuotationMark {
          width: $quotationMarkWidth;
          height: $quotationMarkWidth;

          @include showBottomToTop(0.5s, 0, 30%);
        }

        > .closingQuotationMark {
          width: $quotationMarkWidth;
          height: $quotationMarkWidth;

          @include showBottomToTop(0.5s, 1s, 30%);
        }

        > .innerTextArea {
          display: flex;
          flex-direction: column;
          gap: 40px;
          @include showBottomToTop(0.5s, 0.5s, 30%);

          > .backgroundText {
            font-size: 16px;
            line-height: 1.5;
            color: $kpNavyLight;
          }

          > .targetText {
            font-size: 20px;
            line-height: 1.4;
            color: white;
            @include wordBreak;
          }
        }
      }

      > .roadPic {
        $roadWidth: 807px;
        position: absolute;
        width: $roadWidth;
        height: 247px;
        left: calc(50vw - #{$roadWidth}/ 2);
        bottom: 277px;
        background-image: url("../../../assets/images/main/article1/section4/road.png");
        background-size: 100%;
        background-repeat: no-repeat;

        > .character1 {
          position: absolute;
          width: 150px;
          top: 0;
          left: 328px;
        }

        > .character2 {
          $height: 127px;
          position: absolute;
          width: 133px;
          height: $height;
          left: 254px;
          bottom: calc(#{$height} * -1);
        }

        > .character3 {
          position: absolute;
          width: 167px;
          top: 176px;
          left: 387px;
        }
      }
    }
  }

  .slider {
    display: flex;
    height: 100%;
  }
}

@include tablet {
  .article1 {
    display: flex;
    flex-direction: column;
  }

  .onlyMb {
    display: none;
  }

  $ellipseWidth: 117vw;
  $ellipseHeight: 16.56vw;
  $section1BottomSpace: 7.21vw;

  .section2BgTb {
    width: 100vw;
    height: 573px;
  }

  .childImage {
    width: 456px;
    height: 267px;
  }

  .childImage.show {
    opacity: 1;
    transform: scale(1);
  }

  .section2 {
    height: 700px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .section2TextArea {
    color: var(--GrayScale-white, #fff);
    text-align: center;

    /* 📱Mobile/SubTitle/ST_Bold */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    letter-spacing: -0.3px;
    position: absolute;
    top: 150px;
  }

  .strong {
    font-weight: 700;
  }

  .childImage {
    position: relative;
    bottom: 230px;
    opacity: 0;
    transform: scale(0);
    transition: all 0.5s ease-in;
  }

  .section1 {
    width: 100%;
    height: 480px;
    position: relative;
    margin-top: -10px;
    //padding-top: 101px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;
    z-index: $zIndexStep5;

    > .bgItemTopRight {
      width: 130px;
      height: 130px;
      background-color: $itemBgGray;
      position: absolute;
      top: 100px;
      left: calc(50vw + 330px);
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    > .slider {
      margin-top: 184px;
      width: 768px;
      height: 500px;
      position: relative;
      z-index: $zIndexStep5;
    }

    > .bottomEllipse {
      width: $ellipseWidth;
      height: $ellipseHeight;
      //height: 140px;
      background-color: white;
      position: absolute;
      left: calc(50vw - (#{$ellipseWidth}) / 2);
      bottom: calc(#{$section1BottomSpace} * -1);
      border-radius: 0 0 50% 50% / 0 0 43% 43%;
      overflow: hidden;
      z-index: $zIndexOverContent;

      > .ellipseBgItem1 {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        border: 55px solid $itemBgBlue;
        position: absolute;
        left: calc(100vw * 1.18 / 2 - 525px);
        top: 10px;
      }
    }
  }

  .pressSection {
    width: 100vw;
    height: 900.51px;
    position: relative;
    padding-top: 220px;
    z-index: $zIndexOverContent;

    background: linear-gradient(180deg, #2c2a3a 22%, #383649 44.97%, #2d2b3c 67.46%);

    display: flex;
    justify-content: center;

    mask-image: url("../../../assets/images/main/article1/press/bg.svg");
    -webkit-mask-image: url("../../../assets/images/main/article1/press/bg.svg");
    mask-size: 100vw 1100px;
    -webkit-mask-size: 100vw 1100px;
    -webkit-mask-position-x: center;
    -webkit-mask-position-y: 400px;
    mask-position: center -199px;

    > .maxAndPiece {
      position: absolute;
      width: 189px;
      height: 181.999px;
      top: 690px;
      left: calc(50vw - 365px);

      > img {
        width: 100%;
      }

      @include showScaleTransform(0.4s, 0.1s, 40%);
    }

    > .audienceBgItem {
      position: absolute;
      bottom: 40px;
      left: calc(50vw - 190px);
      width: 645px;

      > img {
        width: 645px;
        height: 219.999px;
      }
    }

    > .pressContent {
      position: relative;
      display: flex;
      gap: 20px;

      > .texts {
        width: 300px;
        height: 300px;
        padding: 20px 30px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;

        > .pressTitle {
          color: white;
          font-size: 44px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: -0.66px;
          @include newLine;
        }

        > .pressDescription {
          color: $kpNavyLight;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;

          @include showBottomToTop(0.4s, 0.4s, 40%);
          @include newLine;
        }
      }

      > .videoWrapper {
        width: 400px;
        // height: 230px;
        gap: 40px;

        position: relative;

        > .linkeyWrapper {
          position: absolute;
          top: 204px;
          left: 722px;
          width: 237px;

          @include showScaleTransform(0.4s, 0.1s, 0);

          > img {
            width: 100%;
          }
        }

        > .mainVideo {
          display: flex;
          width: 100%;
          height: 230px;
          gap: 20px;

          > video {
            width: 100%;
            border-radius: 10px;
            box-shadow: 0px -26px 64px 0px rgba(0, 0, 0, 0.5);
          }
        }

        > .videoThumbnails {
          margin-top: 20px;
          display: flex;
          gap: 10px;
          justify-content: flex-start;
          width: 408px;
          height: 90px;

          > button {
            position: relative;
            border-radius: 10px;

            display: flex;
            justify-content: center;
            align-items: center;

            @include showBottomToTop(0.4s, 0.2s, 30%);

            > .thumbnailImage {
              width: 94.5px;
              height: 90px;
              object-fit: cover;
              border-radius: 10px;
            }

            > .buttonCover {
              $activeColor: #6d50ff;
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 10px;
              overflow: hidden;

              > .default {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $KPNavy;
                opacity: 0.6;
              }

              > .hover {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $activeColor;
                transform: scale(0);
                border-radius: 50%;
                opacity: 0;
                transition: all 0.4s ease-in-out;
              }
            }

            > .buttonIcon {
              position: absolute;

              display: flex;
              justify-content: center;
              align-items: center;

              > .default {
                position: absolute;
                opacity: 1;
                transition: all 0.4s ease-in-out;
                width: 48px;
              }

              > .hover {
                position: absolute;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                width: 48px;
              }

              > .active {
                position: absolute;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                width: 42px;
              }
            }

            &.active {
              > .buttonIcon {
                > .default {
                  opacity: 0;
                }

                > .hover {
                  opacity: 0;
                }

                > .active {
                  opacity: 1;
                }
              }

              > .buttonCover {
                > .hover {
                  transform: scale(2.5);
                  opacity: 0.4;
                }
              }
            }

            &:not(.active):hover {
              > .buttonIcon {
                > .default {
                  opacity: 0;
                }

                > .hover {
                  opacity: 1;
                }

                > .active {
                  opacity: 0;
                }
              }

              > .buttonCover {
                > .hover {
                  transform: scale(2.5);
                  opacity: 0.4;
                }
              }
            }
          }

          > .comingSoon {
            width: 94.5px;
            height: 90px;
            //object-fit: cover;
            background-color: #22202c;
            border-radius: 10px;
            padding: 16px 15px 15px 20px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            > .text {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              letter-spacing: -0.21px;
              color: #75737b;
            }

            > .max {
              display: none;
            }
          }
        }
      }
    }
  }

  .section2 {
    $fixedWidth: 3232px;
    $section2Width: calc(#{$fixedWidth} + 100vw);
    $fixedContentHeight: 573px;
    $contentHeight: $fixedContentHeight;
    // calc(#{$fixedContentHeight} + #{$section1BottomSpace});
    width: 100vw;
    // height: calc(#{$fixedWidth} + #{$fixedContentHeight});
    position: relative;
    z-index: $zIndexOnContent;
    margin-top: -80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.isPassing {
      > .whiteBg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: white;
        top: 0;
        left: 0;
      }

      > .content {
        position: fixed;
        top: 0;
        left: 0;
      }
    }

    &.isDone {
      > .content {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &.isBefore {
      > .content {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    > .content {
      position: absolute;
      width: $section2Width;
      height: $contentHeight;
      //width: 800px;
      //height: 800px;
      background: linear-gradient(90deg, #583be2 0%, #583be2 89.5%, #9b89ee 100%);

      > .descWrapper {
        position: absolute;
        top: 240px;
        left: 0;
        width: 100vw;

        display: flex;
        justify-content: center;
        align-items: center;

        > .desc {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 28px */
          letter-spacing: -0.3px;

          white-space: break-spaces;
          text-align: center;
          color: white;
        }
      }

      > .bottomVillagePattern {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 78px;
        background-image: url("../../../assets/images/main/article1/section2/village_pattern.png");
        background-repeat: repeat-x;
        background-size: auto 78px;
        z-index: $zIndexBgItemOnBg;
      }

      > .faceGroup {
        position: absolute;
        top: 320px; // calc(#{$section1BottomSpace} + 328.5px);
        left: 175px;

        > img {
          width: 1923px;
        }
      }

      > .cloud {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 100vw;
        height: 100%;

        background-image: url("../../../assets/images/main/article1/section2/cloud.png");
        background-size: calc(100% - 152px);
        background-position: center bottom;
        background-repeat: no-repeat;
      }

      > .childrenCharacterWrapper {
        width: 100vw;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: $zIndexOnContent;

        &.isPassing {
          position: fixed;
        }

        > .childrenImage {
          margin-bottom: -52px;
          width: 456px;
          z-index: $zIndexOnContent;
          transform-origin: bottom;
        }
      }
    }
  }

  .section3 {
    display: flex;
    flex-direction: column;
    padding: 0px 0 223px 0;
    position: relative;
    z-index: $zIndexContent;

    @include tabletWidth;

    > .bgItemTopRight {
      position: absolute;
      width: 304px;
      height: 304px;
      border-radius: 50%;
      border: 69px solid $itemBgBlue;
      left: 600px;
      top: 340px;
    }

    > .bgItemLeftBottom {
      position: absolute;
      width: 180px;
      height: 180px;
      background-color: $itemBgGray;
      left: -220px;
      bottom: 100px;
      border-radius: 50% 50% 0 50%;
      z-index: $zIndexBgItemOnBg;
    }

    > .desc1 {
      z-index: $zIndexContent;
      text-align: center;
      line-height: 1.4;
      letter-spacing: -0.015em;
      margin-bottom: 100px;

      // @include showBottomToTop(0.5s, 0s, 30%);

      > .subText {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.3px;
        color: $navyMedium;
        //margin-top: 100px;
      }

      > .primaryText {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 33.6px */
        letter-spacing: -0.36px;
      }

      .point {
        font-size: 32px;
        line-height: 1.4;
        color: $fontHeavy;
        font-weight: bold;
        display: inline-block;
        letter-spacing: -0.48px;

        background-image: url("../../../assets/images/main/article1/section3/text_point.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
      }
    }

    > .imageAndText {
      z-index: $zIndexContent;
      margin-top: 100px;
      display: flex;
      width: 728px;
      align-items: center;
      gap: 20px;

      @include showBottomToTop(0.5s, 0, 30%);

      > .image {
        width: 300px;
        height: 215px;
      }

      > .textArea {
        //padding: 16px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &.leftBorder {
          border-left: 2px solid $mainColor;
          padding-left: 24px;
        }
        &.rightBorder {
          border-right: 2px solid $mainColor;
          padding-right: 24px;
          text-align: right;
        }

        > .normal {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $fontHeavy;
        }

        > .point {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.3px;
          color: $mainColor;
        }
      }
    }

    > .desc3 {
    }
  }

  .section4 {
    width: 100%;
    height: 1292px;
    //display: flex;
    //flex-direction: column;

    > .imageArea {
      width: 100%;
      height: 480px;
      background-image: url("../../../assets/images/main/article1/section4/left_pic_mobile.png");
      background-size: cover;
      background-position: center;
    }

    > .textArea {
      width: 100%;
      height: 812px;
      background-color: $KPNavy;
      padding: 50px 0 624px;
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 50px;

      > .paragraphs {
        display: flex;
        width: 668px;
        flex-direction: column;
        align-items: flex-start;
        align-self: center;
        gap: 40px;

        padding: 0 30px;
        font-weight: normal;

        $quotationMarkWidth: 40px;
        > .openingQuotationMark {
          width: $quotationMarkWidth;
          height: $quotationMarkWidth;

          @include showBottomToTop(0.5s, 0, 30%);
        }

        > .closingQuotationMark {
          width: $quotationMarkWidth;
          height: $quotationMarkWidth;

          @include showBottomToTop(0.5s, 1s, 30%);
        }

        > .innerTextArea {
          display: flex;
          flex-direction: column;
          gap: 40px;
          @include showBottomToTop(0.5s, 0.5s, 30%);

          > .backgroundText {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.24px;
            color: $kpNavyLight;
          }

          > .targetText {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.3px;
            color: white;
            @include wordBreak;
          }
        }
      }

      > .roadPic {
        $roadWidth: 807px;
        position: absolute;
        width: $roadWidth;
        height: 247px;
        left: calc(50vw - #{$roadWidth} / 2);
        bottom: 140px;
        background-image: url("../../../assets/images/main/article1/section4/center roadroad_tablet.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }

      > .roadLeft {
        z-index: 0;
        width: 116px;
        position: absolute;
        //width: $roadWidth;
        height: 247px;
        left: calc(50vw - 460px);
        bottom: 80px;
        background-image: url("../../../assets/images/main/article1/section4/road_left.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }

      > .roadRight {
        z-index: 0;
        width: 116px;
        position: absolute;
        //width: $roadWidth;
        height: 247px;
        left: calc(50vw + 330px);
        bottom: 80px;
        background-image: url("../../../assets/images/main/article1/section4/road_right.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }

  .slider {
    display: flex;
    //height: 100%;
  }
}

@include desktop {
  .article1 {
    display: flex;
    flex-direction: column;
  }

  .onlyMb {
    display: none;
  }

  $ellipseWidth: 117vw;
  $ellipseHeight: 16.56vw;
  $section1BottomSpace: 7.21vw;

  .section1 {
    //height: 691.5px;
    position: relative;
    margin-top: -10px;
    padding-top: 101px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;
    z-index: $zIndexStep5;

    > .bgItemTopRight {
      width: 200px;
      height: 200px;
      background-color: $itemBgGray;
      position: absolute;
      top: -10px;
      left: calc(50vw + 620px);
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    > .slider {
      width: 1380px;
      height: 500px;
      position: relative;
      z-index: $zIndexStep5;
    }

    > .bottomEllipse {
      width: $ellipseWidth;
      height: $ellipseHeight;
      background-color: white;
      position: absolute;
      left: calc(50vw - (#{$ellipseWidth}) / 2);
      bottom: calc(#{$section1BottomSpace} * -1);
      border-radius: 0 0 50% 50% / 0 0 43% 43%;
      overflow: hidden;
      z-index: $zIndexOverContent;

      > .ellipseBgItem1 {
        width: 382px;
        height: 382px;
        border-radius: 50%;
        border: 84px solid $itemBgBlue;
        position: absolute;
        left: calc(100vw * 1.18 / 2 - 851px);
        top: 0;
      }
    }
  }

  .pressSection {
    width: 100vw;
    height: 1515px;
    position: relative;
    padding-top: 430px;
    z-index: $zIndexOverContent;

    background: linear-gradient(180deg, #2c2a3a 22%, #383649 44.97%, #2d2b3c 67.46%);

    display: flex;
    justify-content: center;

    mask-image: url("../../../assets/images/main/article1/press/bg.svg");
    -webkit-mask-image: url("../../../assets/images/main/article1/press/bg.svg");
    mask-size: 100vw 1515px;
    -webkit-mask-size: 100vw 1515px;
    -webkit-mask-position-x: center;
    -webkit-mask-position-y: 0;
    mask-position: center 0;

    > .maxAndPiece {
      position: absolute;
      width: 332px;
      bottom: 30px;
      left: calc(50vw - 690px);

      > img {
        width: 100%;
      }

      @include showScaleTransform(0.4s, 0.1s, 40%);
    }

    > .audienceBgItem {
      position: absolute;
      bottom: 30px;
      left: calc(50vw - 358px);
      width: 1218px;

      > img {
        width: 100%;
      }
    }

    > .pressContent {
      position: relative;
      display: flex;
      gap: 30px;

      > .texts {
        width: 558px;
        height: 444px;
        padding: 40px 60px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;

        > .pressTitle {
          color: white;
          font-size: 60px;
          font-weight: normal;
          line-height: 1.4;
          letter-spacing: -0.9px;
          @include newLine;
        }

        > .pressDescription {
          color: $kpNavyLight;
          font-size: 20px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: -0.3px;

          @include showBottomToTop(0.4s, 0.4s, 40%);
          @include newLine;
        }
      }

      > .videoWrapper {
        width: 792px;
        height: 604px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;

        position: relative;

        > .linkeyWrapper {
          position: absolute;
          top: 204px;
          left: 722px;
          width: 237px;

          @include showScaleTransform(0.4s, 0.1s, 0);

          > img {
            width: 100%;
          }
        }

        > .mainVideo {
          width: 100%;
          height: 444px;

          > video {
            width: 100%;
            border-radius: 20px;
            box-shadow: 0px -26px 64px 0px rgba(0, 0, 0, 0.5);
          }
        }

        > .videoThumbnails {
          display: flex;
          gap: 20px;
          justify-content: flex-start;

          > button {
            width: 183px;
            height: 120px;
            position: relative;
            border-radius: 10px;

            display: flex;
            justify-content: center;
            align-items: center;

            @include showBottomToTop(0.4s, 0.2s, 30%);

            > .thumbnailImage {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            > .buttonCover {
              $activeColor: #6d50ff;
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 10px;
              overflow: hidden;

              > .default {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $KPNavy;
                opacity: 0.6;
              }

              > .hover {
                position: absolute;
                width: 100%;
                height: 100%;
                background: $activeColor;
                transform: scale(0);
                border-radius: 50%;
                opacity: 0;
                transition: all 0.4s ease-in-out;
              }
            }

            > .buttonIcon {
              position: absolute;

              display: flex;
              justify-content: center;
              align-items: center;

              > .default {
                position: absolute;
                opacity: 1;
                transition: all 0.4s ease-in-out;
                width: 48px;
              }

              > .hover {
                position: absolute;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                width: 48px;
              }

              > .active {
                position: absolute;
                opacity: 0;
                transition: all 0.4s ease-in-out;
                width: 42px;
              }
            }

            &.active {
              > .buttonIcon {
                > .default {
                  opacity: 0;
                }

                > .hover {
                  opacity: 0;
                }

                > .active {
                  opacity: 1;
                }
              }

              > .buttonCover {
                > .hover {
                  transform: scale(2.5);
                  opacity: 0.4;
                }
              }
            }

            &:not(.active):hover {
              > .buttonIcon {
                > .default {
                  opacity: 0;
                }

                > .hover {
                  opacity: 1;
                }

                > .active {
                  opacity: 0;
                }
              }

              > .buttonCover {
                > .hover {
                  transform: scale(2.5);
                  opacity: 0.4;
                }
              }
            }
          }

          > .comingSoon {
            width: 183px;
            height: 120px;
            background-color: #22202c;
            border-radius: 10px;
            padding: 16px 15px 15px 20px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            > .text {
              font-size: 20px;
              font-weight: bold;
              line-height: 1.3;
              letter-spacing: -0.015em;
              color: #75737b;
            }

            > .max {
              width: 62px;
              height: 89px;
            }
          }
        }
      }
    }
  }

  .section2 {
    $fixedWidth: 3232px;
    $section2Width: calc(#{$fixedWidth} + 100vw);
    $fixedContentHeight: 860px;
    $contentHeight: $fixedContentHeight; // calc(#{$fixedContentHeight} + #{$section1BottomSpace});
    width: 100vw;
    height: calc(#{$fixedWidth} + #{$fixedContentHeight});
    position: relative;
    z-index: $zIndexOnContent;
    margin-top: -110px;
    height: 1000px;

    .section2Bg {
      width: 100vw;
      height: 860px;
    }

    .section2BgTb {
      width: 100vw;
      height: 573px;
    }

    .section2BgMobile {
      width: 100vw;
      height: 630px;
    }

    .section2TextArea {
      position: absolute;
      top: 337px;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      margin: 0;
      box-sizing: border-box;

      text-align: center;

      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.36px;
    }

    .strong {
      font-weight: 700;
    }

    .childImage {
      position: absolute;
      bottom: -150px;
      left: 50%;
      width: 698px;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.5s ease-in;
    }

    .childImage.show {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    &.isPassing {
      > .whiteBg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: white;
        top: 0;
        left: 0;
      }

      > .content {
        position: fixed;
        top: 0;
        left: 0;
      }
    }

    &.isDone {
      > .content {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &.isBefore {
      > .content {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    > .content {
      position: absolute;
      width: $section2Width;
      height: $contentHeight;
      background: linear-gradient(90deg, #583be2 0%, #583be2 81.25%, #9b89ee 100%);

      > .descWrapper {
        position: absolute;
        top: 337px;
        left: 0;
        width: 100vw;

        display: flex;
        justify-content: center;
        align-items: center;

        > .desc {
          white-space: break-spaces;
          text-align: center;
          font-weight: normal;
          font-size: 24px;
          line-height: 1.4;
          color: white;
        }
      }

      > .bottomVillagePattern {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 78px;
        background-image: url("../../../assets/images/main/article1/section2/village_pattern.png");
        background-repeat: repeat-x;
        background-size: auto 78px;
        z-index: $zIndexBgItemOnBg;
      }

      > .faceGroup {
        position: absolute;
        top: 505px; // calc(#{$section1BottomSpace} + 328.5px);
        left: 175px;

        > img {
          width: 2921px;
        }
      }

      > .cloud {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 100vw;
        height: 100%;

        background-image: url("../../../assets/images/main/article1/section2/cloud.png");
        background-size: calc(100% - 152px);
        background-position: center bottom;
        background-repeat: no-repeat;
      }

      > .childrenCharacterWrapper {
        width: 100vw;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: $zIndexOnContent;

        &.isPassing {
          position: fixed;
        }

        > .childrenImage {
          margin-bottom: -52px;
          width: 698px;
          z-index: $zIndexOnContent;
          transform-origin: bottom;
        }
      }
    }
  }

  .section3 {
    display: flex;
    flex-direction: column;
    padding: 200px 0 300px 0;
    position: relative;
    z-index: $zIndexContent;

    @include desktopWidth;

    > .bgItemTopRight {
      position: absolute;
      width: 544px;
      height: 544px;
      border-radius: 50%;
      border: 120px solid $itemBgBlue;
      left: 1108px;
      top: 335px;
    }

    > .bgItemLeftBottom {
      position: absolute;
      width: 330px;
      height: 330px;
      background-color: $itemBgGray;
      left: -330px;
      bottom: 150px;
      border-radius: 50% 50% 0 50%;
      z-index: $zIndexBgItemOnBg;
    }

    > .desc1 {
      z-index: $zIndexContent;
      text-align: center;
      line-height: 1.4;
      letter-spacing: -0.015em;
      margin-bottom: 108px;

      // @include showBottomToTop(0.5s, 0s, 30%);

      > .subText {
        font-weight: normal;
        font-size: 24px;
        color: $navyMedium;
        margin-bottom: 10px;
      }

      > .primaryText {
        font-weight: normal;
        font-size: 32px;
        line-height: 1.4;
        color: $fontHeavy;
        letter-spacing: -0.48px;
      }

      .point {
        font-size: 32px;
        line-height: 1.4;
        color: $fontHeavy;
        font-weight: bold;
        display: inline-block;
        letter-spacing: -0.48px;

        background-image: url("../../../assets/images/main/article1/section3/text_point.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
      }
    }

    > .imageAndText {
      z-index: $zIndexContent;
      margin-top: 200px;
      display: flex;
      gap: 30px;
      align-items: center;

      @include showBottomToTop(0.5s, 0, 30%);

      > .image {
        width: 460px;
      }

      > .textArea {
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &.leftBorder {
          border-left: 4px solid $mainColor;
          padding-left: 40px;
        }
        &.rightBorder {
          border-right: 4px solid $mainColor;
          padding-right: 40px;
          text-align: right;
        }

        > .normal {
          font-weight: normal;
          font-size: 20px;
          line-height: 1.5;
          letter-spacing: -0.015em;
          color: $fontHeavy;
        }

        > .point {
          font-weight: bold;
          font-size: 24px;
          line-height: 1.4;
          letter-spacing: -0.015em;
          color: $mainColor;
        }
      }
    }

    > .desc3 {
    }
  }

  .section4 {
    width: 100%;
    display: flex;
    height: 1500px;

    > .imageArea {
      width: calc(100vw * 0.37);
      height: 100%;
      background-image: url("../../../assets/images/main/article1/section4/left_pic.png");
      background-size: cover;
      background-position: center;
    }

    > .textArea {
      width: 100%;
      height: 100%;
      flex: 1;
      background-color: $KPNavy;

      display: flex;
      flex-direction: column;
      gap: 50px;

      > .paragraphs {
        padding-left: 148px;
        padding-top: 300px;
        max-width: 940px;
        font-weight: normal;
        letter-spacing: -0.015em;

        display: flex;
        flex-direction: column;
        gap: 60px;

        > .openingQuotationMark {
          width: 50px;
          height: 50px;

          @include showBottomToTop(0.5s, 0, 30%);
        }

        > .closingQuotationMark {
          width: 50px;
          height: 50px;
          transform: rotate(180deg);

          @include showBottomToTop(0.5s, 1s, 30%);
        }

        > .innerTextArea {
          display: flex;
          flex-direction: column;
          gap: 40px;
          @include showBottomToTop(0.5s, 0.5s, 30%);

          > .backgroundText {
            font-size: 20px;
            line-height: 1.5;
            color: $kpNavyLight;
            @include newLine;
          }

          > .targetText {
            font-size: 24px;
            line-height: 1.4;
            color: white;
          }
        }
      }

      > .roadPic {
        width: 100%;
        height: 458px;
        background-image: url("../../../assets/images/main/article1/section4/road.png");
        background-size: 1497px 458px;
        background-repeat: no-repeat;
        position: relative;

        > .character1 {
          position: absolute;
          width: 191px;
          top: 178px;
          left: 60px;
          @include showScaleTransform(0.5s, 0, 0);
        }

        > .character2 {
          position: absolute;
          width: 166px;
          left: 331px;
          bottom: 5px;
          @include showScaleTransform(0.5s, 0.25s, 0);
        }

        > .character3 {
          position: absolute;
          width: 217px;
          top: 152px;
          left: 627px;
          @include showScaleTransform(0.5s, 0.5s, 0);
        }
      }
    }
  }

  .slider {
    display: flex;
    height: 100%;
  }
}
