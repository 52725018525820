$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "src/assets/scss/variables";

// PC
// 취소 버튼 기본
@mixin cancelButtonBase {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  background: var(--GrayScale-G-700_bodytext, #404040);
}

// 수정 버튼 기본
@mixin editButtonBase {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  background: var(--K-PASS-KPblue-500_main, #583BE2);

}

// 버튼 텍스트 기본
@mixin desktopButtonTextBase {
  color: var(--GrayScale-white, #FFF);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.36px;
}


// 호버 버튼 스타일
@mixin hoverButtonBase {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: linear-gradient(97deg, #E60000 30%, #9F2645 100%);
}

//@mixin desktopHoverButtonTextBase {
//  color: var(--GrayScale-white, #FFF);
//  text-align: center;
//  font-size: 24px;
//  font-style: normal;
//  font-weight: 700;
//  line-height: 140%; /* 33.6px */
//  letter-spacing: -0.36px;
//}


/**
  모바일
 */
@mixin mobileButtonTextBase {
  color: var(--GrayScale-white, #FFF);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  letter-spacing: -0.3px;
}

/**
  태블릿
 */
@mixin tabletButtonTextBase {
  color: var(--GrayScale-white, #FFF);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  letter-spacing: -0.3px;
}

@include mobile {
  .mainWrapper {
    display: flex;
    width: 100%;
    padding: 75px 20px 50px 20px;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    background: var(--GrayScale-G-000_bg, #F6F6F6);

    .titleWrapper {
      display: flex;
      padding: 12px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 0 50px 50px 50px;
      background: var(--K-PASS-KPnavy-500_main, #383649);

      > .titleText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;
      }
    }

    .editMainWrapper {
      display: flex;
      width: 320px;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .editWrapper {
        display: flex;
        padding: 30px 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        border-radius: 15px;
        background: var(--K-PASS-KPblue-500_main, #583BE2);

        .inputZone {
          display: flex;
          width: 300px;
          flex-direction: column;
          align-items: center;
          gap: 30px;


          // 기본 회원정보 수정 영역 css
          .selectWrapper {
            display: flex;
            width: 280px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .selectTitleWrapper {
              display: flex;
              align-items: flex-start;
              gap: 6px;
              align-self: stretch;

              .highLight, .titleText {
                color: var(--GrayScale-white, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;
              }

              .highLight {
                color: var(--Basic-Bred-500_main, #FF2C45);
              }

              .unHighLight {
                color: var(--K-PASS-KPblue-100, #D6CEFF);
                line-height: 150%;
                font-size: 16px;
              }
            }

            .selectTextFieldWrapper {
              height: 44px;
              display: flex;
              padding: 10px 12px;
              align-items: center;
              gap: 8px;
              align-self: stretch;

              border-bottom: 1px solid var(--K-PASS-KPnavy-500_main, #383649);
              background: var(--GrayScale-white, #FFF);

              .selectText {
                max-width: 256px;
                display: flex;
                align-items: center;
                flex: 1 0 0;

                color: var(--K-PASS-KPnavy-500_main, #383649);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;

                &.defaultText {
                  color: var(--GrayScale-G-200, #C4C4C4);
                }
                &::placeholder {
                  color: var(--GrayScale-G-200, #C4C4C4);
                }
                &.unSelected{
                  color: var(--GrayScale-G-200, #C4C4C4);
                }
              }

              .arrowImg {
                background-image: url("../../../assets/images/modal/login/arrow.svg");
                width: 24px;
                height: 24px;
              }

              .inputField {
                display: flex;
                padding: 10px 12px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
              }

              .optionStyle {
                display: flex;
                width: 280px;
                max-height: 200px;
                overflow: auto;
                flex-direction: column;
                align-items: flex-start;

                position: absolute;
                transform: translate(69px, 115px);

                &::-webkit-scrollbar {
                  display: none;
                }

                -ms-overflow-style: none;
                scrollbar-width: none;

                border: 1px solid var(--GrayScale-G-100, #E1E1E1);
                background: var(--GrayScale-white, #FFF);

                .optionTextWrapper {
                  display: flex;
                  width: 280px;
                  padding: 8px 12px;
                  align-items: flex-start;
                  gap: 10px;

                  .optionText {
                    color: var(--GrayScale-G-500_subtext, #6B6B6B);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                    letter-spacing: -0.24px;

                    &.selectedOption {
                      color: var(--K-PASS-KPnavy-500_main, #383649);
                      font-weight: 700;
                    }
                  }

                  &.selectedOption {
                    background: var(--GrayScale-G-000_bg, #F6F6F6);
                    color: var(--K-PASS-KPnavy-500_main, #383649);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 24px */
                    letter-spacing: -0.24px;
                  }
                }
              }

              .cellPhoneSelectStyle {
                transform: translate(69px, 121px);
              }

              .nationStyle {
                transform: translate(69px, 115px);
              }

            }

            .validateText {
              color: var(--Basic-Byellow-500_main, #FFE606);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
            }

            .cellPhoneInfo {
              align-self: stretch;
              color: var(--K-PASS-Color-K-PASS-LD-Color-KP-blue-Light-D6CEFF, #D6CEFF);
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 18px */
              letter-spacing: -0.18px;
            }
          }

          // 성별 관련 css
          .genderBoxWrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;

            .genderBox {
              display: flex;
              width: 120px;
              height: 44px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              border: 1px solid var(--GrayScale-G-100, #E1E1E1);
              background: var(--GrayScale-white, #FFF);

              .genderText {
                color: var(--GrayScale-G-500_subtext, #6B6B6B);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                letter-spacing: -0.3px;
              }

              &.selected {
                border-radius: 8px;
                border: 1px solid var(--GrayScale-G-100, #E1E1E1);
                background: var(--K-PASS-KPblue-700, #352388);

                .selectedText {
                  color: var(--GrayScale-white, #FFF);
                }
              }
            }
          }

          // 주소 관련 css
          .addressWrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            flex: 1 0 0;

            .address {
              max-width: 196px;
              height: 44px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              padding: 10px 12px;

              border-bottom: 1px solid var(--GrayScale-G-200, #C4C4C4);
              background: var(--GrayScale-white, #FFF);

              .addressText {
                max-width: 170px;
                display: flex;
                align-items: center;
                flex: 1 0 0;
                color: var(--GrayScale-G-200, #C4C4C4);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;
                white-space: nowrap;
                overflow: hidden;
                direction: rtl;

                &.selected {
                  color: var(--K-PASS-KPnavy-500_main, #383649);
                }
              }
            }

            .addressSearchBtn {
              display: flex;
              width: 74px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid var(--GrayScale-G-100, #E1E1E1);
              background: var(--GrayScale-white, #FFF);

              .addressSearchText {
                color: var(--GrayScale-G-500_subtext, #6B6B6B);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 21px */
                letter-spacing: -0.21px;
              }
            }
          }

          // 주소 - 상세주소 관련 css
          .inputWrapper {
            display: flex;
            width: 280px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .addressInput {
              height: 44px;
              width: 100%;
              display: flex;
              align-items: center;
              flex: 1 0 0;
              padding: 12px 12px;
              align-self: stretch;

              color: var(--K-PASS-KPnavy-500_main, #383649);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;

              &::placeholder {
                color: var(--GrayScale-G-200, #C4C4C4);
              }
            }
          }

          // 선택 동의 관련 css
          .selectAgreement {
            display: flex;
            padding: 0 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            align-self: stretch;


            .selectAgreeWrapper {
              display: flex;
              align-items: center;
              gap: 16px;
              align-self: stretch;

              > .selectAgreeTextWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;

                > .selectAgreeTitle {
                  color: var(--K-PASS-KPblue-100, #D6CEFF);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 24px */
                  letter-spacing: -0.24px;

                  &.necessary {
                    color: var(--Basic-Bred-500_main, #FF2C45);
                  }
                }

                > .selectAgreeSubTitle {
                  color: var(--GrayScale-white, #FFF);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 21px */
                  letter-spacing: -0.21px;
                }

                > .selectMandatoryInfoWrapper {
                  display: flex;
                  align-items: center;

                  > .selectMandatoryText {
                    color: var(--GrayScale-white, #FFF);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 21px */
                    letter-spacing: -0.21px;
                  }

                  > .selectMandatoryImg {
                    width: 24px;
                    height: 24px;
                    background-image: url("../../../assets/images/modal/login/shortcut.png");
                  }
                }

              }

              > .agreeCheckImg {
                width: 24px;
                height: 24px;
                background-image: url("../../../assets/images/modal/login/unselected_checkbox_mobile.png");

                &.selected {
                  background-image: url("../../../assets/images/modal/login/selected_select_checkbox_mobile.svg");
                }
              }
            }

          }
        }
      }
    }

    .exitWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-self: stretch;

      .exitButton {
        display: flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;

        border-radius: 20px;
        border: 1px solid var(--GrayScale-G-700_bodytext, #404040);

        > .text {
          color: var(--GrayScale-G-700_bodytext, #404040);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */
          letter-spacing: -0.21px;
        }
      }
    }

    .buttonWrapper {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .cancelButton {
        @include cancelButtonBase;
        @include mobileButtonTextBase;
        width: 140px;
        height: 66px;

        position: relative;
        transition: all 0.3s ease-in-out;

        &:before {
          @include hoverButtonBase;
          @include mobileButtonTextBase;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(97deg, #E60000 30%, #9F2645 100%);

          transition: opacity 0.3s ease-in-out;
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        span {
          position: relative;
          z-index: 1;
        }
      }

      .editButton {
        @include editButtonBase;
        @include mobileButtonTextBase;
        width: 140px;
        height: 66px;

        position: relative;

        &:before {
          @include hoverButtonBase;
          @include mobileButtonTextBase;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(97deg, #E60000 30%, #9F2645 100%);

          transition: opacity 0.3s ease-in-out;
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        span {
          position: relative;
          z-index: 1;
        }

        transition: all 0.3s ease-in-out;
      }
    }

    .backgroundImg_1 {
      display: none;
      background-image: url("../../../assets/images/modal/member/background-1.png");
      width: 300px;
      height: 300px;
      left: calc(50vw + 390px);
      top: 170px;
      position: absolute;
      z-index: 1;
    }

    .backgroundImg_2 {
      display: none;
      background-image: url("../../../assets/images/modal/member/background-2.png");
      width: 600px;
      height: 600px;
      right: calc(50vw + 340px);
      top: 740px;
      position: absolute;
      z-index: 1;
    }
  }
}

@include tablet {
  .mainWrapper {
    display: flex;
    width: 100%;
    padding: 175px 100px 50px 100px;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    background: var(--GrayScale-G-000_bg, #F6F6F6);

    .titleWrapper {
      display: flex;
      padding: 12px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      z-index: 2;

      border-radius: 0 50px 50px 50px;
      background: var(--K-PASS-KPnavy-500_main, #383649);

      > .titleText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.3px;
      }
    }

    .editMainWrapper {
      display: flex;
      width: 700px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      z-index: 2;

      .editWrapper {
        display: flex;
        padding: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        border-radius: 20px;
        background: var(--K-PASS-KPblue-500_main, #583BE2);

        .inputZone {
          display: flex;
          width: 600px;
          flex-direction: column;
          align-items: center;
          gap: 30px;


          // 기본 회원정보 수정 영역 css
          .selectWrapper {
            width: 600px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;

            .selectTitleWrapper {
              display: flex;
              align-items: flex-start;
              gap: 6px;
              align-self: stretch;

              .highLight, .titleText {
                color: var(--GrayScale-white, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;
              }

              .highLight {
                color: var(--Basic-Bred-500_main, #FF2C45);
              }

              .unHighLight {
                color: var(--K-PASS-KPblue-100, #D6CEFF);
                line-height: 150%;
                font-size: 16px;
              }
            }

            .selectTextFieldWrapper {
              display: flex;
              padding: 10px 12px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              height: 44px;

              border-bottom: 1px solid var(--K-PASS-KPnavy-500_main, #383649);
              background: var(--GrayScale-white, #FFF);

              .selectText {
                max-width: 544px;
                display: flex;
                align-items: center;
                flex: 1 0 0;

                color: var(--K-PASS-KPnavy-500_main, #383649);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;

                &.defaultText {
                  color: var(--GrayScale-G-200, #C4C4C4);
                }
                &::placeholder {
                  color: var(--GrayScale-G-200, #C4C4C4);
                }
                &.unSelected{
                  color: var(--GrayScale-G-200, #C4C4C4);
                }
              }

              .arrowImg {
                background-image: url("../../../assets/images/modal/login/arrow.svg");
                width: 24px;
                height: 24px;
              }

              .inputField {
                display: flex;
                padding: 10px 12px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
              }

              .optionStyle {
                display: flex;
                width: 400px;
                max-height: 200px;
                overflow: auto;
                flex-direction: column;
                align-items: flex-start;

                position: absolute;
                transform: translate(189px, 115px);

                &::-webkit-scrollbar {
                  display: none;
                }

                -ms-overflow-style: none;
                scrollbar-width: none;

                border: 1px solid var(--GrayScale-G-100, #E1E1E1);
                background: var(--GrayScale-white, #FFF);

                .optionTextWrapper {
                  display: flex;
                  width: 100%;
                  padding: 8px 12px;
                  align-items: flex-start;
                  gap: 10px;

                  .optionText {
                    color: var(--GrayScale-G-500_subtext, #6B6B6B);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                    letter-spacing: -0.24px;

                    &.selectedOption {
                      color: var(--K-PASS-KPnavy-500_main, #383649);
                      font-weight: 700;
                    }
                  }

                  &.selectedOption {
                    background: var(--GrayScale-G-000_bg, #F6F6F6);
                    color: var(--K-PASS-KPnavy-500_main, #383649);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 24px */
                    letter-spacing: -0.24px;
                  }
                }
              }

              .cellPhoneSelectStyle {
                transform: translate(189px, 121px);
              }

              .nationStyle {
                transform: translate(189px, 115px);
              }

            }

            .validateText {
              color: var(--Basic-Byellow-500_main, #FFE606);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
            }

            .cellPhoneInfo {
              align-self: stretch;
              color: var(--K-PASS-Color-K-PASS-LD-Color-KP-blue-Light-D6CEFF, #D6CEFF);
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 18px */
              letter-spacing: -0.18px;
            }
          }

          // 성별 관련 css
          .genderBoxWrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;

            .genderBox {
              display: flex;
              width: 170px;
              height: 50px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              border: 1px solid var(--GrayScale-G-100, #E1E1E1);
              background: var(--GrayScale-white, #FFF);

              .genderText {
                color: var(--GrayScale-G-500_subtext, #6B6B6B);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;
              }

              &.selected {
                border-radius: 8px;
                border: 1px solid var(--GrayScale-G-100, #E1E1E1);
                background: var(--K-PASS-KPblue-700, #352388);

                .selectedText {
                  color: var(--GrayScale-white, #FFF);
                }
              }
            }
          }

          // 주소 관련 css
          .addressWrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;
            flex: 1 0 0;

            .address {
              max-width: 400px;
              height: 44px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              padding: 10px 12px;

              border-bottom: 1px solid var(--GrayScale-G-200, #C4C4C4);
              background: var(--GrayScale-white, #FFF);

              .addressText {
                max-width: 370px;
                display: flex;
                align-items: center;
                flex: 1 0 0;
                color: var(--GrayScale-G-200, #C4C4C4);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;
                white-space: nowrap;
                overflow: hidden;
                direction: rtl;

                &.selected {
                  color: var(--K-PASS-KPnavy-500_main, #383649);
                }
              }
            }

            .addressSearchBtn {
              display: flex;
              width: 180px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid var(--GrayScale-G-100, #E1E1E1);
              background: var(--GrayScale-white, #FFF);

              .addressSearchText {
                color: var(--GrayScale-G-500_subtext, #6B6B6B);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 21px */
                letter-spacing: -0.21px;
              }
            }
          }

          // 주소 - 상세주소 관련 css
          .inputWrapper {
            display: flex;
            width: 600px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .addressInput {
              height: 44px;
              width: 100%;
              display: flex;
              align-items: center;
              flex: 1 0 0;
              padding: 12px 12px;
              align-self: stretch;

              color: var(--K-PASS-KPnavy-500_main, #383649);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;

              &::placeholder {
                color: var(--GrayScale-G-200, #C4C4C4);
              }
            }
          }

          // 선택 동의 관련 css
          .selectAgreement {
            display: flex;
            padding: 0 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            align-self: stretch;


            .selectAgreeWrapper {
              display: flex;
              align-items: center;
              gap: 16px;
              align-self: stretch;

              > .selectAgreeTextWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;

                > .selectAgreeTitle {
                  color: var(--K-PASS-KPblue-100, #D6CEFF);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 24px */
                  letter-spacing: -0.24px;

                  &.necessary {
                    color: var(--Basic-Bred-500_main, #FF2C45);
                  }
                }

                > .selectAgreeSubTitle {
                  color: var(--GrayScale-white, #FFF);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 21px */
                  letter-spacing: -0.21px;
                }

                > .selectMandatoryInfoWrapper {
                  display: flex;
                  align-items: center;

                  > .selectMandatoryText {
                    color: var(--GrayScale-white, #FFF);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 21px */
                    letter-spacing: -0.21px;
                  }

                  > .selectMandatoryImg {
                    width: 24px;
                    height: 24px;
                    background-image: url("../../../assets/images/modal/login/shortcut.png");
                  }
                }

              }

              > .agreeCheckImg {
                width: 36px;
                height: 36px;
                background-image: url("../../../assets/images/modal/login/unselected_checkbox.png");

                &.selected {
                  background-image: url("../../../assets/images/modal/login/selected_select_checkbox.png");
                }
              }
            }

          }
        }
      }
    }

    .exitWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-self: stretch;

      .exitButton {
        display: flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;

        border-radius: 20px;
        border: 1px solid var(--GrayScale-G-700_bodytext, #404040);

        > .text {
          color: var(--GrayScale-G-700_bodytext, #404040);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */
          letter-spacing: -0.21px;
        }
      }
    }

    .buttonWrapper {
      display: flex;
      align-items: flex-start;
      gap: 40px;
      z-index: 2;

      .cancelButton {
        @include cancelButtonBase;
        @include tabletButtonTextBase;
        width: 200px;
        height: 66px;

        position: relative;
        transition: all 0.3s ease-in-out;

        &:before {
          @include hoverButtonBase;
          @include tabletButtonTextBase;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(97deg, #E60000 30%, #9F2645 100%);

          transition: opacity 0.3s ease-in-out;
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        span {
          position: relative;
          z-index: 1;
        }
      }

      .editButton {
        @include editButtonBase;
        @include tabletButtonTextBase;
        width: 200px;
        height: 66px;

        position: relative;

        &:before {
          @include hoverButtonBase;
          @include tabletButtonTextBase;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(97deg, #E60000 30%, #9F2645 100%);

          transition: opacity 0.3s ease-in-out;
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        span {
          position: relative;
          z-index: 1;
        }

        transition: all 0.3s ease-in-out;
      }
    }

    .backgroundImg_1 {
      background-image: url("../../../assets/images/modal/member/background-1.png");
      width: 300px;
      height: 300px;
      left: calc(50vw + 390px);
      top: 170px;
      position: absolute;
      z-index: 1;
    }

    .backgroundImg_2 {
      background-image: url("../../../assets/images/modal/member/background-2.png");
      width: 600px;
      height: 600px;
      right: calc(50vw + 340px);
      top: 740px;
      position: absolute;
      z-index: 1;
    }
  }
}

@include desktop {
  .mainWrapper {
    display: flex;
    width: 100%;

    padding: 235px 320px 100px 320px;
    flex-direction: column;
    align-items: center;
    gap: 70px;

    background: var(--GrayScale-G-000_bg, #F6F6F6);

    .titleWrapper {
      display: flex;
      padding: 12px 104px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      z-index: 2;
      border-radius: 0 50px 50px 50px;
      background: var(--K-PASS-KPnavy-500_main, #383649);

      > .titleText {
        color: var(--GrayScale-white, #FFF);
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.6px;
      }
    }

    .editMainWrapper {
      display: flex;
      width: 700px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      z-index: 2;

      .editWrapper {
        display: flex;
        padding: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        border-radius: 20px;
        background: var(--K-PASS-KPblue-500_main, #583BE2);

        .inputZone {
          display: flex;
          width: 600px;
          flex-direction: column;
          align-items: center;
          gap: 40px;


          // 기본 회원정보 수정 영역 css
          .selectWrapper {
            display: flex;
            width: 600px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            .selectTitleWrapper {
              display: flex;
              align-items: flex-start;
              gap: 6px;
              align-self: stretch;

              .highLight, .titleText {
                color: var(--GrayScale-white, #FFF);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 30px */
                letter-spacing: -0.3px;
              }

              .highLight {
                color: var(--Basic-Bred-500_main, #FF2C45);
              }

              .unHighLight {
                color: var(--K-PASS-KPblue-100, #D6CEFF);
                line-height: 150%;
                font-size: 20px;
              }
            }

            .selectTextFieldWrapper {
              height: 50px;
              display: flex;
              padding: 10px 12px;
              align-items: center;
              gap: 8px;
              align-self: stretch;

              border-bottom: 1px solid var(--K-PASS-KPnavy-500_main, #383649);
              background: var(--GrayScale-white, #FFF);

              .selectText {
                display: flex;
                align-items: center;
                flex: 1 0 0;

                color: var(--K-PASS-KPnavy-500_main, #383649);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 30px */
                letter-spacing: -0.3px;

                &.defaultText {
                  color: var(--GrayScale-G-200, #C4C4C4);
                }

                &::placeholder {
                  color: var(--GrayScale-G-200, #C4C4C4);
                }
                &.unSelected{
                  color: var(--GrayScale-G-200, #C4C4C4);
                }
              }

              .arrowImg {
                background-image: url("../../../assets/images/modal/login/arrow.svg");
                width: 24px;
                height: 24px;
              }

              .inputField {
                display: flex;
                padding: 10px 12px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
              }

              .optionStyle {
                display: flex;
                width: 400px;
                max-height: 200px;
                overflow: auto;
                flex-direction: column;
                align-items: flex-start;

                position: absolute;

                transform: translate(189px, 123px);

                &::-webkit-scrollbar {
                  display: none;
                }

                -ms-overflow-style: none;
                scrollbar-width: none;

                border: 1px solid var(--GrayScale-G-100, #E1E1E1);
                background: var(--GrayScale-white, #FFF);

                .optionTextWrapper {
                  display: flex;
                  width: 400px;
                  padding: 8px 12px;
                  align-items: flex-start;
                  gap: 10px;

                  .optionText {
                    color: var(--GrayScale-G-500_subtext, #6B6B6B);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                    letter-spacing: -0.24px;

                    &.selectedOption {
                      color: var(--K-PASS-KPnavy-500_main, #383649);
                      font-weight: 700;
                    }
                  }

                  &.selectedOption {
                    background: var(--GrayScale-G-000_bg, #F6F6F6);
                    color: var(--K-PASS-KPnavy-500_main, #383649);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 24px */
                    letter-spacing: -0.24px;
                  }
                }
              }

              .cellPhoneSelectStyle {
                transform: translate(189px, 123px);
              }

              .nationStyle {
                transform: translate(189px, 123px);
              }

            }

            .validateText {
              color: var(--Basic-Byellow-500_main, #FFE606);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
            }

            .cellPhoneInfo {
              align-self: stretch;
              color: var(--K-PASS-Color-K-PASS-LD-Color-KP-blue-Light-D6CEFF, #D6CEFF);
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 24px */
              letter-spacing: -0.24px;
            }
          }

          // 성별 관련 css
          .genderBoxWrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;

            .genderBox {
              display: flex;
              width: 200px;
              height: 54px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              border: 1px solid var(--GrayScale-G-100, #E1E1E1);
              background: var(--GrayScale-white, #FFF);

              .genderText {
                color: var(--GrayScale-G-500_subtext, #6B6B6B);
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                letter-spacing: -0.3px;
              }

              &.selected {
                border-radius: 8px;
                border: 1px solid var(--GrayScale-G-100, #E1E1E1);
                background: var(--K-PASS-KPblue-700, #352388);

                .selectedText {
                  color: var(--GrayScale-white, #FFF);
                }
              }
            }
          }

          // 주소 관련 css
          .addressWrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;
            height: 50px;

            .address {
              display: flex;
              width: 380px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              padding: 12px 12px;
              align-self: stretch;

              border-bottom: 1px solid var(--GrayScale-G-200, #C4C4C4);
              background: var(--GrayScale-white, #FFF);

              .addressText {
                display: flex;
                align-items: center;
                flex: 1 0 0;
                color: var(--GrayScale-G-200, #C4C4C4);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 30px */
                letter-spacing: -0.3px;

                &.selected {
                  color: var(--K-PASS-KPnavy-500_main, #383649);
                  //font-size: 16px;
                  //font-style: normal;
                  //font-weight: 400;
                  //line-height: 150%; /* 30px */
                  //letter-spacing: -0.3px;
                }
              }
            }

            .addressSearchBtn {
              display: flex;
              width: 200px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid var(--GrayScale-G-100, #E1E1E1);
              background: var(--GrayScale-white, #FFF);

              .addressSearchText {
                color: var(--GrayScale-G-500_subtext, #6B6B6B);
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 30px */
                letter-spacing: -0.3px;
              }
            }
          }

          // 주소 - 상세주소 관련 css
          .inputWrapper {
            display: flex;
            width: 600px;
            height: 50px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .addressInput {
              width: 100%;
              display: flex;
              align-items: center;
              flex: 1 0 0;
              padding: 12px 12px;
              height: 50px;
              align-self: stretch;

              color: var(--K-PASS-KPnavy-500_main, #383649);
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 30px */
              letter-spacing: -0.3px;

              &::placeholder {
                color: var(--GrayScale-G-200, #C4C4C4);
              }
            }
          }

          // 선택 동의 관련 css
          .selectAgreement {
            display: flex;
            padding: 0 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            align-self: stretch;


            .selectAgreeWrapper {
              display: flex;
              align-items: center;
              gap: 16px;
              align-self: stretch;

              > .selectAgreeTextWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;

                > .selectAgreeTitle {
                  color: var(--K-PASS-KPblue-100, #D6CEFF);
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 24px */
                  letter-spacing: -0.24px;

                  &.necessary {
                    color: var(--Basic-Bred-500_main, #FF2C45);
                  }
                }

                > .selectAgreeSubTitle {
                  color: var(--GrayScale-white, #FFF);
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%; /* 24px */
                  letter-spacing: -0.24px;
                }

                > .selectMandatoryInfoWrapper {
                  display: flex;
                  align-items: center;

                  > .selectMandatoryText {
                    color: var(--GrayScale-white, #FFF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 24px */
                    letter-spacing: -0.24px;
                  }

                  > .selectMandatoryImg {
                    width: 20px;
                    height: 20px;
                    background-image: url("../../../assets/images/modal/login/shortcut.png");
                  }
                }

              }

              > .agreeCheckImg {
                width: 36px;
                height: 36px;
                background-image: url("../../../assets/images/modal/login/unselected_checkbox.png");

                &.selected {
                  background-image: url("../../../assets/images/modal/login/selected_select_checkbox.png");
                }
              }

              //}

            }
          }
        }
      }
    }

    .exitWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-self: stretch;

      .exitButton {
        display: flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;

        border-radius: 20px;
        border: 1px solid var(--GrayScale-G-700_bodytext, #404040);

        > .text {
          color: var(--GrayScale-G-700_bodytext, #404040);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 21px */
          letter-spacing: -0.21px;
        }
      }
    }

    .buttonWrapper {
      display: flex;
      align-items: flex-start;
      gap: 40px;
      z-index: 2;


      .cancelButton {
        @include cancelButtonBase;
        @include desktopButtonTextBase;
        width: 240px;
        height: 84px;

        position: relative;
        transition: all 0.3s ease-in-out;

        &:before {
          @include hoverButtonBase;
          @include desktopButtonTextBase;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(97deg, #E60000 30%, #9F2645 100%);

          transition: opacity 0.3s ease-in-out;
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        span {
          position: relative;
          z-index: 1;
        }
      }

      .editButton {
        @include editButtonBase;
        @include desktopButtonTextBase;
        width: 240px;
        height: 84px;

        position: relative;

        &:before {
          @include hoverButtonBase;
          @include desktopButtonTextBase;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(97deg, #E60000 30%, #9F2645 100%);

          transition: opacity 0.3s ease-in-out;
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        span {
          position: relative;
          z-index: 1;
        }

        transition: all 0.3s ease-in-out;
      }

    }

    .backgroundImg_1 {
      background-image: url("../../../assets/images/modal/member/background-1.png");
      width: 300px;
      height: 300px;
      left: calc(50vw + 490px);
      top: 160px;
      position: absolute;
      z-index: 1;
    }

    .backgroundImg_2 {
      background-image: url("../../../assets/images/modal/member/background-2.png");
      width: 600px;
      height: 600px;
      right: calc(50vw + 430px);
      top: 1100px;
      position: absolute;
      z-index: 1;
    }
  }
}