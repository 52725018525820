$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
$coverBorder: #7063AE;
$background: #FBFBFF;
$coverBackground: #382F63;

$minWidth: 1160px;
.wrapper {
  min-width: $minWidth;
  width: 100vw;
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background;

  > .headerWrapper {
    position: relative;
    z-index: $zIndexHeader;
    width: 100vw;
    background: $KPNavy;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1341px) {
      height: 66px;

      > .header {
        width: 1280px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .logo {
          display: flex;
          gap: 10px;
          align-items: flex-end;

          color: white;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: -0.48px;
        }

        > .buttons {
          display: flex;
          gap: 20px;
          align-items: center;

          button > img {
            width: 34px;
          }

          > .copy {
            display: flex;
            align-items: center;

            font-size: 16px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: -0.48px;
            text-decoration-line: underline;
            color: $blueLight;
          }
        }
      }
    }

    @media (max-width: 1340px) {
      height: 132px;

      > .header {
        padding: 0 30px;
        width: 1280px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .logo {
          display: flex;
          gap: 10px;
          align-items: center;

          color: white;
          font-size: 40px;
          font-weight: bold;
          line-height: 1;
          letter-spacing: -1.2px;

          > img {
            width: 152px;
          }
        }

        > .buttons {
          display: flex;
          gap: 20px;
          align-items: center;

          button > img {
            width: 80px;
          }

          > .copy {
            display: flex;
            align-items: center;

            font-size: 40px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: -0.48px;
            text-decoration-line: underline;
            color: $blueLight;
          }
        }
      }
    }

  }

  > .main {
    padding: 50px 0 130px 0;
    width: $minWidth;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}

.articleHeaderWrapper {}

.articleHeader {
  margin: 0 auto;
  width: 1020px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 4px solid $navyMedium;
  padding-bottom: 10px;

  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: -0.54px;
  color: $navyMedium;

  > .logo {
    width: 114px;
  }
}

.articleFooterWrapper {

}

.articleFooter {
  margin: 0 auto;
  width: 1020px;
  border-top: 4px solid $navyMedium;
  background: transparent; // yellowgreen;
}

.article {
  width: $minWidth;
  padding: 50px 70px;
  border-radius: 20px;
  border: 2px solid #E5E5EF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;

  > .contentHeaderWrapper {
    display: block;
  }

  > .contentFooterWrapper {
    display: block;
  }

  &.cover {
    height: 1642px;
    background-color: $coverBackground;
    position: relative;
    overflow: hidden;
    border-color: $blueLight;

    > .bgItemTopRight {
      width: 500px;
      height: 500px;
      border: 110.46px solid rgba(119, 98, 223, 0.20);
      border-radius: 50%;
      position: absolute;
      top: 319px;
      right: -150px;
    }

    > .bgItemLeftBottom {
      width: 250px;
      height: 250px;
      background-color: rgba(119, 98, 223, 0.15);
      position: absolute;
      left: -82px;
      bottom: 320px;
      border-radius: 50% 50% 0 50%;
    }

    > .articleHeader {
      color: $blueLight;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: -0.54px;
      border-color: $coverBorder;
    }

    > .coverMain {
      padding: {
        top: 78px;
        left: 50px;
        bottom: 216px;
      };
      display: flex;
      flex-direction: column;
      gap: 130px;

      > .coverLogo {
        width: 337px;
      }

      > .coverTitle {
        font-size: 85px;
        font-weight: bold;
        line-height: 1.3;
        letter-spacing: -2.55px;
        color: white;
      }

      > .writer {
        display: flex;
        align-items: center;

        font-size: 22px;
        font-weight: bold;
        line-height: 1.6;
        letter-spacing: -0.66px;

        dt {
          padding: 20px 30px;
          color: $kpDeepBlue;
          background-color: white;
          border-radius: 100px 100px 0 100px;
          z-index: $zIndexOverContent;
          position: relative;
        }

        dd {
          color: white;
          border: 1px solid $coverBorder;
          padding: 20px 29px 20px 79px;
          border-radius: 0 100px 100px 0;
          margin-left: -49px;
        }
      }
    }

    > .articleFooter {
      padding-top: 7px;
      border-color: $coverBorder;

      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: -0.54px;
      color: $blueLight;

      > .logo {
        width: 133px;
      }
    }
  }
}

.articleTitle {
  font-size: 85px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: -2.55px;
  color: $KPNavy;
}

.contentSection {
  padding: 40px 30px;

  display: flex;
  flex-direction: column;
  gap: 60px;

  .sectionTitleWrapper {
    margin-bottom: 40px;
  }

  .sectionTitleWrapperWideMarginBottom {
    margin-bottom: 140px;
  }

  .part {
    display: flex;
    flex-direction: column;
    gap: 30px;

    > .partTitle {
      width: 100%;
      padding: 12px 30px;
      background-color: $KPNavy;
      border-radius: 0 100px 100px 100px;

      font-size: 28px;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: -0.84px;
      color: white;

      > .light {
        font-weight: normal;
      }
    }

    > .partSummary {
      font-size: 22px;
      font-weight: normal;
      line-height: 1.6;
      letter-spacing: -0.66px;
      color: $subtextColor;
    }

    > .partContent {
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      gap: 60px;

      > .partContentItem {
        display: flex;
        flex-direction: column;
        gap: 16px;

        > .partContentItemTitleWrapper {
          display: flex;
          align-items: center;
          gap: 12px;

          .numbering {
            padding: 0 12px;
            font-size: 22px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: -0.66px;
            color: white;

            background-color: $KPNavy;
            border-radius: 6px;
          }

          .title {
            font-size: 22px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: -0.66px;
            color: $KPNavy;
          }
        }

        > .partContentItemParagraph {
          color: $subtextColor;
          font-size: 22px;
          font-weight: normal;
          line-height: 1.6;
          letter-spacing: -0.66px;
        }
      }
    }
  }
}

.functionalUnitImages {
  margin: 50px 0;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;

  > img {
    width: 451px;
  }
}

.imageWrapperInsideParagraph {
  width: 100%;
  margin: 60px 0;
  display: flex;
  gap: 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageWrapper {
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.tableWrapper {
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;

  > .table1 {
    width: 800px;
  }

  > .table2 {
    width: 100%;
  }
}

.picture1AndTable7Wrapper {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  > .picture1 {
    width: 811px;
  }

  > .table7 {
    width: 800px;
  }
}

.topButton {
  position: fixed;
  left: calc(50vw + 710px);
  bottom: 100px;

  @media (max-width: 1580px) {
    right: 40px;
    left: auto;
  }
}

.footerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  > .part1Wrapper {
    width: 100%;
    background-color: #22202C;
    padding: 30px;

    > .part1 {
      margin: 0 auto;
      max-width: 1260px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .feelgood {
        display: flex;
        gap: 20px;
        align-items: center;

        font-size: 16px;
        font-weight: normal;
        color: white;
        line-height: 1.8;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        > .logo {
          width: 133px;
        }
      }

      > .link {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.8;
        text-decoration-line: underline;
        color: #F4A418;
      }
    }
  }

  > .part2Wrapper {
    width: 100%;
    background-color: $KPNavy;
    padding: 70px 30px;

    > .part2 {
      max-width: 1260px;
      padding: 33.5px 30px;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;
      align-items: center;

      > .companyInfo {
        font-size: 16px;
        line-height: 1.8;

        > .name {
          color: white;
          font-weight: bold;
        }

        > .otherInfo {
          color: #A3A0BA;
          font-weight: normal;
        }
      }

      > .logo {

      }
    }
  }
}

.fixedHeader, .pageHeaderSpace {
  display: none;
  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  width: 1020px;
  height: 100px;
}

.fixedFooter, .pageFooterSpace {
  display: none;
  position: fixed;
  bottom: -450px;
  width: 1020px;
}

.articleTable {
  page-break-before: always;
  width: 1160px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  .tableTd {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}

@page {
  size: A4;
  margin: 0;// 20px;
}
@page :first {
  margin: 0;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .footerWrapper, .headerWrapper, .topButton {
    display: none !important;
  }

  .wrapper, .main {
    padding: 0 !important;
    gap: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  .wrapper {
    background-color: white;
  }

  .main {
  }

  $printContentWidth: calc(100vw - 140px);
  // 1160px;

  .fixedHeader {
    width: 100vw; // $printContentWidth;//  100vw;
    //min-width: $minWidth;
    padding: 0 70px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .articleHeader {
      width: 100%;
      background-color: transparent;
    }
  }

  .fixedFooter {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .pageHeaderSpace {
    display: block;
    height: 120px;
    margin: 0 auto;
    background-color: transparent;
  }

  .pageFooterSpace {
    display: block;
    height: 10px;
    margin: 0 auto;
    background-color: transparent;
  }

  .articleTable {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .article {
    border: none;
    width: 100vw;
    height: auto;
    page-break-after: always;
    background-color: white;
    margin: 0;
    padding: 0 100px;

    > .contentHeaderWrapper {
      display: none;
    }

    > .contentFooterWrapper {
      display: none;
    }

    &.cover {
      padding: 50px 70px;
      z-index: 10;
      position: relative;
      border: none;
      margin: 0;
      height: 100vh;
      width: 100vw;
      page-break-after: always;
    }

    > .articleHeader {
      width: 100%;
    }

    > .articleFooter {
      width: 100%;
    }

    .contentSection {
      padding: 0;
    }

    &:last-child {
      page-break-after: avoid;
    }
  }

  .printHeader {
    display: table-header-group;
    background-color: transparent;
    height: 120px;

    .pageHeaderSpace {
      background-color: transparent;
    }
  }

  .pageFooterSpace {
    display: none;
  }

  .printFooter {
    background-color: transparent;
    height: 70px;
    display: table-footer-group;
    position: relative;
    z-index: 999;
    width: 100%;

    .pageFooterSpace {
      border-top: 4px solid #76718E;
      width: calc(100vw - 140px);
      display: block;
      height: 10px;
      margin: 0 auto;
      position: static !important;
    }
  }

  .articleTable {
    page-break-before: always;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    .tableTd {
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: center;
    }
  }
}