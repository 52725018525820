$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@include mobile {
  .section1 {
    position: relative;
    padding-bottom: 130px;

    > .bgItem2 {
      position: absolute;
      z-index: $zIndexBgItem;
      width: 124px;
      height: 124px;
      top: -169px;
      left: calc(50vw - 200px);
      background-color: getFontHeavyColorWithOpacity(0.1);
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    .definition {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .bgImage {
        max-width: 100%;

        > img {
          width: 100%;
        }
      }

      > h3 {

        > .title {
          margin-top: 60px;
        }
      }

      > .phrase1 {
        padding: 0 20px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        text-align: center;

        > .text1 {
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $fontHeavy;
          @include newLine;
        }

        > .text2 {
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $fontLight;
          text-align: center;
          margin-top: 4px;
          @include newLine;
        }
      }

      > .phrase2 {
        margin-top: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 20px;

        > .desc {
          letter-spacing: -0.05em;
          display: flex;
          flex-direction: column;
          align-items: center;

          > .title {
            line-height: 1.6;
            color: $fontHeavy;
            font-weight: bold;
            font-size: 1.6rem;
            text-align: center;
          }

          > .description {
            color: $fontLight;
            line-height: 1.8;
            font-size: 1.2rem;
            font-weight: normal;
            letter-spacing: -0.05em;
            margin: {
              top: 4px;
              bottom: 16px;
            };
            text-align: center;
            @include newLine;
          }
        }

        > .descriptions {
          color: white;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          row-gap: 4px;
          column-gap: 4px;
          margin: {
            top: 40px;
          };

          @include miniMobile {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
          }

          > li {
            background-color: gray;
            width: 160px;
            height: 160px;

            > .contentWrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.6);
              position: relative;

              &.ru {
                padding: 10px;

                > .subtitle {
                  font-size: 50px;
                  line-height: 1.2;
                }

                > .subtitleDesc {
                  font-size: 12px;
                }
              }

              > .hoverBg {
              }

              > .subtitle {
                font-size: 5.4rem;
                font-weight: bold;
                line-height: 1.2;
                letter-spacing: -0.05em;
                text-align: center;
                z-index: $zIndexContent;
              }

              > .subtitleDesc {
                font-size: 1.6rem;
                line-height: 1.6;
                text-align: center;
                font-weight: normal;
                z-index: $zIndexContent;
                display: flex;
                flex-direction: column;
                gap: 4px;

                > .enDesc {
                  line-height: 1.2;
                }
              }
            }

            &.descriptionP {
              background: url("../../images/main_legacy/section1/pBg.png") center;
              background-size: cover;
            }
            &.descriptionA {
              background: url("../../images/main_legacy/section1/aBg.png") center;
              background-size: cover;
            }
            &.descriptionS1 {
              background: url("../../images/main_legacy/section1/s1Bg.png") center;
              background-size: cover;
            }
            &.descriptionS2 {
              background: url("../../images/main_legacy/section1/s2Bg.png") center;
              background-size: cover;
            }
          }
        }
      }

      > .phrase3 {
        margin-top: 130px;
        width: 100%;
        display: flex;
        flex-direction: column;

        > .text {
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          > .title {
            display: block;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            text-align: center;
            color: $fontHeavy;
          }

          > .desc {
            display: block;
            text-align: center;
            font-size: 1.2rem;
            line-height: 1.8;
            letter-spacing: -0.05em;
            font-weight: normal;
            margin: {
              top: 4px;
              bottom: 16px;
            };
            color: $fontLight;
            @include newLine;
          }
        }

        .gameImagesSlider {
          margin: {
            top: 30px;
          };
          width: fit-content;
          display: flex;
          position: relative;
          background-color: #F4A418;
          overflow-x: hidden;

          img {
            width: 100vw;
          }
        }
      }
    }
  }
}

@include desktop {

  .section1 {
    position: relative;

    > .bgItem2 {
      position: absolute;
      z-index: $zIndexBgItem;
      width: 417px;
      height: 417px;
      top: -315px;
      left: calc(50vw - 960px);
      background-color: getFontHeavyColorWithOpacity(0.1);
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    .definition {
      @include desktopWidth;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: {
        bottom: 250px;
      }

      > .bgImage {
        max-width: $maxWidth;

        > img {
          width: 1320px;
          border-radius: 30px;
        }
      }

      > h3 {

        > .title {
          margin-top: 120px;
        }
      }

      > .phrase1 {
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        text-align: center;
        @include showBottomToTop(0.5s, 0, 10%);

        > .text1 {
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
        }

        > .text2 {
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $g2757575;
          text-align: center;
          margin-top: 30px;
          @include newLine;
        }
      }

      > .phrase2 {
        margin-top: 340px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        > .desc {
          letter-spacing: -0.05em;
          display: flex;
          flex-direction: column;
          align-items: center;
          @include showBottomToTop(0.5s, 0, 10%);

          > .title {
            line-height: 1.6;
            color: $KPNavy;
            font-weight: bold;
            font-size: 2.4rem;
          }

          > .description {
            color: $g2757575;
            line-height: 1.8;
            font-size: 2rem;
            font-weight: normal;
            letter-spacing: -0.05em;
            margin: {
              top: 30px;
              bottom: 30px;
            };
            text-align: center;
            @include newLine;
          }
        }

        > .descriptions {
          color: white;
          display: flex;
          margin-top: 60px;

          > li {
            background-color: gray;
            width: 259px;
            height: 400px;
            margin-left: 7px;

            > .contentWrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-top: 63px;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.6);
              position: relative;

              &.ru {
                justify-content: flex-start;
                padding: 30px 20px;
              }

              > .hoverBg {
                position: absolute;
                width: 0;
                height: 0;
                top: 50%;
                left: 50%;
                border-radius: 50%;
                transition: all 0.3s ease-in-out;
              }

              &:hover {
                > .hoverBg {
                  background-color: getMainColorWithOpacity(0.85);
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  border-radius: 0;
                }
              }

              > .subtitle {
                font-size: 10rem;
                font-weight: bold;
                line-height: 1.6;
                letter-spacing: -0.05em;
                text-align: center;
                z-index: $zIndexContent;
              }

              > .subtitleDesc {
                font-size: 2.4rem;
                line-height: 1.6;
                text-align: center;
                font-weight: normal;
                z-index: $zIndexContent;
                @include newLine;
                display: flex;
                flex-direction: column;
                gap: 8px;

                &.pa {
                  margin-top: 19px;
                }

                > .enDesc {
                  line-height: 1.2;
                }
              }
            }

            &:first-child {
              margin-left: 0;
            }

            &.descriptionP {
              background: url("../../images/main_legacy/section1/pBg.png");
            }
            &.descriptionA {
              background: url("../../images/main_legacy/section1/aBg.png");
            }
            &.descriptionS1 {
              background: url("../../images/main_legacy/section1/s1Bg.png");
            }
            &.descriptionS2 {
              background: url("../../images/main_legacy/section1/s2Bg.png");
            }
          }
        }
      }

      > .phrase3 {
        margin-top: 340px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          @include showBottomToTop(0.5s, 0, 10%);

          > .title {
            display: block;
            font-weight: bold;
            font-size: 2.4rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            text-align: center;
            color: $KPNavy;
          }

          > .desc {
            display: block;
            text-align: center;
            font-size: 2rem;
            line-height: 1.8;
            letter-spacing: -0.05em;
            font-weight: normal;
            margin: {
              top: 30px;
              bottom: 30px;
            };
            color: $g2757575;
            @include newLine;
          }
        }


        > .gameImages {
          margin-top: 60px;
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          gap: 8px;
          position: relative;

          > .imgItem {
            max-width: 656px;
            max-height: 366px;

            > button {

              > img {
                width: 100%;
                height: 100%;
              }
            }
          }

          > .bgItemRightTop {
            width: 480px;
            height: 480px;
            background-color: $mainColor;
            opacity: 0.1;
            position: absolute;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
            right: -311px;
            top: -255px;
            z-index: $zIndexBgItem;
          }

          > .bgItemLeftBottom {
            width: 417px;
            height: 417px;
            position: absolute;
            background-color: $KPNavy;
            opacity: 0.1;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 50%;
            left: -300px;
            top: 434px;
            z-index: $zIndexBgItem;
          }
        }
      }
    }
  }
}
