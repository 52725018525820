$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@include mobile {
  .main {
    margin-top: -10px;
    display: flex;
    flex-direction: column;

    > .title {
      @include subpageTitleMobile("../../images/sub/researcher/title_bg_mobile.svg");
      background-position: 0;
    }

    > .researcher {
      width: 100%;

      > .area1 {
        padding: 70px 30px 144px 30px;
        display: flex;
        flex-direction: column;

        > .title {
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.05em;
          display: flex;
          flex-direction: column;
          gap: 4px;

          > .institute {
            display: block;
            font-size: 16px;
            color: $KPNavy;
          }

          > .researcher {
            font-size: 24px;
            color: $mainColor;
          }
        }

        > .phrase {
          font-weight: normal;
          font-size: 12px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include wordBreak;

          &.phrase1 {
            margin-top: 16px;
          }

          &.phrase2 {
            margin-top: 19.2px;
          }
        }

        > .phrase3 {
          margin-top: 12px;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include wordBreak;
        }
      }

      > .area2 {
        width: 100%;
        background-image: url("../../images/sub/researcher/bottom_bg.svg");
        background-repeat: repeat-x;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        padding: 0 30px 100px 30px;

        > .childrenPic {
          position: relative;
          max-width: 100%;
          width: 280px;
          height: 0;
          margin: 0 auto;

          > img {
            width: 100%;
            position: absolute;
            top: -91px;
            left: 0;
          }
        }

        > .phrase1 {
          margin-top: 151px;
          font-weight: normal;
          font-size: 12px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include wordBreak;
        }

        > .phrase2 {
          margin-top: 12px;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include wordBreak;
        }
      }
    }
  }
}

@include tablet {
  .main {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .title {
      @include subpageTitleTablet("../../images/sub/researcher/title_bg.svg");
      > .text{
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.36px;

        color: var(--GrayScale-white, #FFF);
        text-align: center;
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.30);
      }

    }

    > .researcher {
      width: 100%;

      > .area1 {
        @include tabletWidth;
        //height: 650px;
        padding: {
          top: 100px;
          left: 20px;
          right: 20px;
          bottom: 200px;
        };
        display: flex;
        flex-direction: column;

        > .title {
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.05em;

          > .institute {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.24px;
            color: $KPNavy;
          }

          > .researcher {
            display: block;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.42px;
            color: $mainColor;
          }
        }

        > .phrase {
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include newLine;

          &.phrase1 {
            margin-top: 35px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.24px;
          }

          &.phrase2 {
            margin-top: 20px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.24px;

          }
        }

        > .phrase3 {
          margin-top: 20px;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $KPNavy;
          @include newLine;
        }
      }

      > .area2 {
        width: 100%;
        min-height: 562px;
        height: auto;
        background-image: url("../../images/sub/researcher/bottom_bg.svg");
        background-repeat: repeat-x;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        > .childrenPic {
          position: relative;
          width: 894px;
          height: 200px;

          > img {
            position: relative;
            top: -110px;
            left: 0;
            width: 462px;
            height: 300px;
            flex-shrink: 0;
          }
        }

        > .phrase1 {
          @include tabletWidth;
          margin-top: 80px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $g2757575;
          @include newLine;
        }

        > .phrase2 {
          @include tabletWidth;
          @include newLine;
          margin-top: 12px;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
          color: $KPNavy;
        }
      }
    }
  }
}

@include desktop {
  .main {
    display: flex;
    flex-direction: column;

    > .title {
      @include subpageTitle("../../images/sub/researcher/title_bg.svg");
      //@include desktopWidth;
    }

    > .researcher {
      width: 100%;

      > .area1 {
        @include desktopWidth;
        padding: {
          left: 100px;
          right: 100px;
          top: 163px;
          bottom: 300px;
        };
        display: flex;
        flex-direction: column;

        > .title {
          font-weight: bold;
          line-height: 1.6;
          letter-spacing: -0.05em;

          > .institute {
            display: block;
            font-size: 2.4rem;
            color: $KPNavy;
          }

          > .researcher {
            display: block;
            font-size: 4rem;
            color: $mainColor;
          }
        }

        > .phrase {
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include newLine;

          &.phrase1 {
            margin-top: 50px;
          }

          &.phrase2 {
            margin-top: 30px;
          }
        }

        > .phrase3 {
          margin-top: 30px;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include newLine;
        }
      }

      > .area2 {
        width: 100%;
        height: 930px;
        background-image: url("../../images/sub/researcher/bottom_bg.svg");
        background-repeat: repeat-x;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        > .childrenPic {
          position: relative;
          width: 894px;
          height: 402px;

          > img {
            position: absolute;
            top: -180px;
            left: 0;
            width: 894px;
            height: 582px;
          }
        }

        > .phrase1 {
          @include desktopWidth;
          margin-top: 60px;
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include newLine;
        }

        > .phrase2 {
          @include desktopWidth;
          @include newLine;
          margin-top: 40px;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
        }
      }
    }
  }
}