$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@mixin prosAndConsArea {
  width: 675px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@mixin prosAndConsAreaTablet {
  width: 675px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@mixin prosAndConsAreaMobile {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@mixin prosAndConsTitleStyle {
  display: flex;
  gap: 10px;
  align-items: flex-start;

  > img {
    width: 45px;
  }

  font-weight: normal;
  font-size: 32px;
  line-height: 1.4;

  .strong {
    font-weight: bold;
  }
}
@mixin prosAndConsTitleStyleTablet {
  display: flex;
  gap: 10px;
  align-items: flex-start;

  > img {
    width: 28px;
  }

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.3px;

  .strong {
    font-weight: bold;
  }
}
@mixin prosAndConsTitleStyleMobile {
  display: flex;
  gap: 10px;
  align-items: flex-start;

  > img {
    width: 28px;
  }

  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
}
@mixin prosAndConsDescriptionList {
  border-radius: 20px;
  padding: 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}
@mixin prosAndConsDescriptionListTablet {
  border-radius: 20px;
  padding: 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}
@mixin prosAndConsDescriptionListMobile {
  border-radius: 16px;
  padding: 20px 24px 30px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

@include mobile {
  .article {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section1 {
    width: 100%;
    padding-top: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;

    > .titleWrapper {
      width: 100%;
      padding: 0 30px;

      > .title {
        @include articleTitleStyleMobile;

        &.ru {
          word-break: break-all;
        }
      }
    }


    > .gridArea {
      padding: 0 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      > .texts {
        display: flex;
        flex-direction: column;
        padding: 40px 0;

        > .title {
          font-weight: bold;
          font-size: 28px;
          line-height: 1.4;
          color: black;
          @include wordBreak;
        }

        > .description {
          margin-top: 40px;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          color: $fontHeavy;
          @include wordBreak;
        }

      }

      > .chart {
        $width: calc(100vw - 60px);
        width: $width;
        height: $width;
        border-radius: 16px;
        background: $itemBgBlue;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        @include showBottomToTop(0.5s, 0, 50px);

        > .title {
          display: flex;
          gap: 10px;
          align-items: flex-start;

          font-weight: bold;
          font-size: 20px;
          line-height: 1.4;
          color: $mainColor;

          > .checkbox {
            width: 28px;
          }
        }

        > .graphWrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &.graph1 {

            > img {
              width: 100%;
            }
          }

          &.graph2 {

            > img {
              width: 100%;
            }
          }

          &.graph3 {
            > img {
              width: 100%;
            }
          }
        }

      }
    }
  }

  .prosAndCons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;

    > .hillBg {
      width: 100%;
      height: 201px;
      background-image: url("../../../assets/images/main/article3/section1/part2/bg_hill_mobile.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    }

    > .contentWrapper {
      background-color: $kpPink;
      padding-bottom: 138px;

      > .content {
        margin-top: -81px;
        display: flex;
        flex-direction: column;
        gap: 60px;
        justify-content: center;
        position: relative;
        z-index: $zIndexContent;
        padding: 0 30px;

        > .disadvantage {
          @include prosAndConsAreaMobile;
          @include showBottomToTop(0.5s, 0, 80px);

          > .title {
            @include prosAndConsTitleStyleMobile;
            color: $fontBodyText;
          }

          > .descriptions {
            @include prosAndConsDescriptionListMobile;
            background-color: $kpPinkHeavy;
            list-style-type: disc;

            > .aDescription {
              margin-left: 1em;
              list-style: disc outside none;
              display: list-item;
              color: $fontGray;

              > .title {
                font-weight: bold;
                font-size: 20px;
                line-height: 1.4;
              }

              > .desc {
                font-weight: normal;
                font-size: 16px;
                line-height: 1.5;
              }
            }
          }
        }

        > .advantage {
          @include prosAndConsAreaMobile;
          @include showBottomToTop(0.5s, 0, 80px);

          > .title {
            @include prosAndConsTitleStyleMobile;
            color: $hotpink;
          }

          > .descriptions {
            @include prosAndConsDescriptionListMobile;
            gap: 16px;
            background-color: white;

            > .aDescription {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              > .icon {
                width: 60px;

                > img {
                  width: inherit;
                }
              }

              > .texts {
                text-align: center;
                > .title {
                  font-weight: bold;
                  font-size: 20px;
                  line-height: 1.4;
                  color: $hotpink;
                  @include wordBreak;
                }

                > .desc {
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 1.5;
                  color: $fontGray;
                  @include newLine;
                }
              }
            }
          }
        }

        > .peace {
          width: 213px;
          position: absolute;
          bottom: -286px;
          right: 30px;
        }
      }
    }

    > .ellipseBgWrapper {
      $ellipseWidth: 140vw;
      $ellipseHeight: calc(#{$ellipseWidth} * 0.186);
      $ellipseHalfHeight: calc(#{$ellipseHeight} / 2);
      position: relative;
      width: 100%;
      height: $ellipseHeight;
      margin-top: calc(#{$ellipseHalfHeight} * -1);

      > .ellipseBg {
        position: absolute;
        left: calc((#{$ellipseWidth} - 100vw) / -2);
        top: 0;
        width: $ellipseWidth;
        height: $ellipseHeight;
        background-color: $kpPink;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }

  .section2 {
    width: 100%;
    padding-top: 301px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > .description {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 30px;

      > .texts {
        width: 100%;
        padding: 40px 30px;

        > .title {
          font-weight: bold;
          font-size: 28px;
          line-height: 1.4;
          color: black;
          @include wordBreak;
        }

        > .desc {
          margin-top: 40px;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          color: $fontHeavy;
          @include wordBreak;
        }
      }

      > .pictureWrapper {
        margin-top: 50px;
        width: 100%;
        height: 360px;

        > .picture {
          width: 100%;
          height: 100%;
          background-image: url("../../../assets/images/main/article3/section2/seminar.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    > .historyWrapper {
      width: 100%;
      background-color: $fontHeavy;
      padding: 120px 30px 150px 30px;

      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 80px;

      position: relative;

      > .bgItem {
        @keyframes floating {
          from {
            transform: translateY(-15px);
          }
          to {
            transform: translateY(15px);
          }
        }
        animation: floating ease-in-out 0.7s infinite alternate;

        width: 240px;
      }

      > .history {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 80px;

        > .panel {
          display: flex;
          flex-direction: column;
          gap: 30px;

          &.left {
            @include showBottomToTop(0.5s, 0, 50px);
          }

          &.right {
            @include showBottomToTop(0.5s, 0, 50px);
          }

          > .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 1.4;
            color: white;

            display: flex;
            align-items: flex-start;
            gap: 10px;
            @include wordBreak;

            > img {
              width: 28px;
            }
          }

          > .list {
            display: flex;
            flex-direction: column;
            gap: 12px;

            font-weight: normal;
            font-size: 16px;
            line-height: 1.5;
            color: white;

            list-style-type: disc;
            @include wordBreak;

            > li {
              margin-left: 1em;
              list-style: disc outside none;
              display: list-item;

              .light {
                font-weight: normal;
                font-size: 14px;
                line-height: 1.5;
                color: $navyLight;
              }
            }
          }
        }
      }
    }
  }

  .section3 {
    width: 100%;
    padding: 61px 30px 104px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    > .bgItemRightTop {

    }

    > .bgItemLeftBottom {

    }

    > .texts {
      display: flex;
      flex-direction: column;
      gap: 40px;

      width: 100%;
      padding: 40px 0;

      > .titleArea {
        > .title {
          font-weight: bold;
          font-size: 28px;
          line-height: 1.4;
          color: black;

          &.ru {
            word-break: break-all;
          }
        }
      }

      > .paragraphArea {
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        color: $fontHeavy;
        @include wordBreak;
      }
    }



    > .aboutPass {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 60px;

      > .passGrid {
        $itemWidth: 100%;
        $itemHeight: 150px;
        display: grid;
        grid-template-rows: repeat(4, $itemHeight);
        grid-template-columns: repeat(1, $itemWidth);
        grid-gap: 10px;

        > .item {
          $borderRadius: 30px;
          background-color: #9B89EE;
          border-radius: $borderRadius;
          background-position: center;
          background-size: cover;
          position: relative;
          overflow: hidden;

          &:active {
            > .mask {

              > .maskHover {
                transform: scale(5);
              }
            }
          }

          > .mask {
            transition: all 1s ease-in-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            > .maskHover {
              $maskWidth: calc(#{$itemHeight} / 2);
              width: $maskWidth;
              height: $maskWidth;
              background-color: $mainColor;
              border-radius: 50%;
              transition: all 200ms ease-in-out;
              transform: scale(0);
              opacity: 0.9;
            }

            > .maskDefault {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: $KPNavy;
              opacity: 0.8;
            }
          }

          &.planning {
            background-image: url("../../../assets/images/main/article3/section3/planning.png");
            @include showBottomToTop(0.5s, 0, 50px);
          }

          &.attention {
            background-image: url("../../../assets/images/main/article3/section3/attention.png");
            @include showBottomToTop(0.5s, 0.3s, 50px);
          }

          &.successiveProcessing {
            background-image: url("../../../assets/images/main/article3/section3/sequential.png");
            @include showBottomToTop(0.5s, 0.8s, 50px);
          }

          &.simultaneousProcessing {
            background-image: url("../../../assets/images/main/article3/section3/simultaneous.png");
            @include showBottomToTop(0.5s, 1.3s, 50px);
          }

          > .content {
            width: 100%;
            height: 100%;
            padding: 40px 30px;
            position: relative;

            display: flex;
            align-items: center;
            gap: 9px;

            > .title {
              font-weight: bold;
              font-size: 70px;
              line-height: 1;
              letter-spacing: -0.05em;
              color: white;
            }

            > .desc {
              font-weight: normal;
              font-size: 20px;
              line-height: 1.4;
              color: white;

              display: flex;
              flex-direction: column;

              > .small {
                font-size: 16px;
              }
            }
          }
        }
      }

      > .readMoreButton {
        @include showBottomToTop(0.5s, 0, 50px);
      }
    }
  }
}

@include tablet {
  .article {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section1 {
    width: 100%;
    padding: {
      top: 200px;
      bottom: 257px;
    };

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;

    > .titleWrapper {
      > .title {
        @include articleTitleStyleTablet;
      }
    }

    > .gridArea {
      width: 728px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 30px;
      padding: 0 20px;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 20px;
      flex-wrap: wrap;
      margin-bottom: 50px;

      > .texts {
        display: flex;
        width: 354px;
        height: auto;
        padding: 40px 20px 0 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &.ru, &.en {
          padding-top: 0;
          padding-bottom: 0;
        }


        > .title {
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.42px;
          color: black;
          margin-bottom: 40px;

        }

        > .description {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $fontHeavy;

          > .strong {
            font-weight: bold;
          }
        }

      }

      > .chart {
        display: flex;
        width: 354px;
        height: 300px;
        padding: 20px 0;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        border-radius: 20px;
        background: $itemBgBlue;

        gap: 30px;

        > .title {
          display: flex;
          gap: 10px;
          align-items: center;

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.3px;
          color: $mainColor;

          > .checkbox {
            width: 28px;
          }
        }

        > .graphWrapper {

          width: 100%;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.graph1 {
            @include showBottomToTop(0.8s, 0, 50px);

            > img {
              width: 260px;
            }
          }

          &.graph2 {
            @include showBottomToTop(0.8s, 0.5s, 50px);

            > img {
              width: 260px;
            }
          }

          &.graph3 {
            @include showBottomToTop(0.8s, 1s, 50px);
            > img {
              width: 260px;
            }
          }
        }

      }
    }
  }

  .prosAndCons {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .hillBg {
      width: 100%;
      height: 227px;
      background-image: url("../../../assets/images/main/article3/section1/part2/bg_hill.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    }

    > .contentWrapper {
      background-color: $kpPink;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 690px;
      height: auto;

      > .content {

        display: flex;
        justify-content: center;
        width: 768px;
        padding: 0 20px;
        gap: 20px;
        margin-top: -100px;
        position: relative;
        z-index: $zIndexContent;

        > .disadvantage {
          @include prosAndConsAreaTablet;
          @include showBottomToTop(1s, 0, 80px);

          > .title {
            @include prosAndConsTitleStyleTablet;

            color: $fontBodyText;
          }

          > .descriptions {
            @include prosAndConsDescriptionListTablet;
            background-color: $kpPinkHeavy;
            gap: 0;
            justify-content: space-between;
            list-style-type: disc;
            height: 100%;

            > .aDescription {
              margin-left: 1em;
              list-style: disc outside none;
              display: list-item;
              color: $fontGray;


              > .title {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                letter-spacing: -0.3px;
              }

              > .desc {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: -0.24px;
              }
            }
          }
        }

        > .advantage {
          @include prosAndConsAreaTablet;
          @include showBottomToTop(1s, 0.4s, 80px);
          position: relative;

          > .peace {
            width: 251px;
            height: 280px;
            position: absolute;
            bottom: -294px;
            right: -20px;

            @include showTranslateX(0.8s, 0.4s, 50%);
          }

          > .title {
            @include prosAndConsTitleStyleTablet;
            color: $hotpink;
          }

          > .descriptions {
            @include prosAndConsDescriptionListTablet;
            background-color: white;

            display: flex;
            padding: 20px 24px 30px 24px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            > .aDescription {
              display: flex;
              align-items: flex-start;

              > .icon {
                width: 60px;

                > img {
                  width: inherit;
                }
              }

              > .texts {
                > .title {
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 140%;
                  letter-spacing: -0.3px;
                  color: $hotpink;
                }

                > .desc {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                  letter-spacing: -0.24px;
                  color: $fontGray;
                }
              }
            }
          }
        }
      }
    }

    > .ellipseBgWrapper {
      margin-top: -90px;
      position: relative;
      width: 100%;
      height: 236px;

      > .ellipseBg {
        position: absolute;
        bottom: 0;
        $width: 100%;
        width: $width;
        height: 237px;
        background-color: $kpPink;
        border-bottom-left-radius: 22%;
        border-bottom-right-radius: 22%;
      }
    }
  }

  .section2 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    > .description {
      @include tabletWidth;
      min-height: 480px;
        height: auto;
      display: flex;
      align-items: stretch;
      gap: 20px;


      $flexItemWidth: 354px;

      > .texts {
        width: $flexItemWidth;
        padding: 40px 20px;

        > .title {
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.42px;

          margin-bottom: 40px;
        }

        > .desc {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
          color: $fontHeavy;

          > .strong {
            font-weight: bold;
          }
        }
      }

      > .pictureWrapper {
        position: relative;
        width: $flexItemWidth;

        > .picture {
          position: absolute;
          width: calc(50vw - 15px);
          height: 480px;
          bottom:  0;
          left: 0;
          background-image: url("../../../assets/images/main/article3/section2/seminar.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          justify-content: flex-end;
        }
      }
    }

    > .historyWrapper {
      width: 100%;
      background-color: $fontHeavy;
      padding: 80px 0 220px 0;

      display: flex;
      align-items: center;
      gap: 30px;

      position: relative;

      > .bgItem {
        @keyframes floating {
          from {
            bottom: 70px;
          }
          to {
            bottom: 100px;
          }
        }
        animation: floating ease-in-out 1s infinite alternate;

        position: absolute;
        width: 240px;
        left: calc(50vw + 150px);
      }

      > .history {
        @include tabletWidth;
        display: flex;
        width: 768px;
        padding: 0 20px;
        align-items: flex-start;
        gap: 20px;

        > .panel {
          display: flex;
          flex-direction: column;
          gap: 30px;
          padding: 0 20px;
          width: 675px;

          &.left {
            @include showBottomToTop(1s, 0, 50px);
          }

          &.right {
            @include showBottomToTop(1s, 0.4s, 50px);
          }

          > .title {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.3px;
            color: white;

            display: flex;
            align-items: flex-start;
            gap: 10px;

            > img {
              width: 28px;
            }
          }

          > .list {
            display: flex;
            flex-direction: column;
            gap: 12px;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.24px;
            color: var(--GrayScale-white, #FFF);

            > li {
              margin-left: 1em;
              list-style: disc outside none;
              display: list-item;

              .string {
                font-weight: bold;
              }

              .light {
                font-weight: normal;
                font-size: 16px;
                line-height: 1.5;
                color: $navyLight;
              }
            }
          }
        }
      }
    }
  }

  .section3 {
    width: 100%;
    @include tabletWidth;
    padding: 150px 0 100px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    > .bgItemRightTop {
      position: absolute;
      right: -30px;
      top: 100px;
      width: 180px;
      height: 180px;
      background-color: $itemBgGray;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    > .bgItemLeftBottom {
      position: absolute;
      left: -190px;
      bottom: 310px;
      width: 304px;
      height: 304px;
      border: 60px solid $itemBgBlue;
      border-radius: 50%;
    }

    > .texts {
      display: flex;
      flex-direction: column;
      gap: 40px;

      width: 100%;
      padding: 40px 20px;

      > .titleArea {
        > .title {
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.42px;
          color: black;
        }
      }

      > .paragraphArea {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.24px;
        color: $fontHeavy;
        z-index: $zIndexContent;
      }
    }

    > .aboutPass {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;

      > .passGrid {
        width: 728px;
        margin-top: 40px;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 10px;
        flex-wrap: wrap;

        $itemWidth: 300px;
        $itemHeight: 150px;
        display: grid;
        grid-template-rows: repeat(2, $itemHeight);
        grid-template-columns: repeat(2, $itemWidth);

        @include showBottomToTop(0.8s, 0, 50px);

        > .item {
          $borderRadius: 47.6748px;
          background-color: #9B89EE;
          border-radius: $borderRadius;
          background-position: center;
          background-size: cover;
          position: relative;
          overflow: hidden;

          &:hover {
            > .mask {

              > .maskHover {
                transform: scale(2.5);
              }
            }
          }

          > .mask {
            transition: all 1s ease-in-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            > .maskHover {
              width: 220px;
              height: 220px;
              background-color: $mainColor;
              border-radius: 50%;
              transition: all 0.3s ease-in-out;
              transform: scale(0);
              opacity: 0.9;
            }

            > .maskDefault {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: $KPNavy;
              opacity: 0.8;
            }
          }

          &.planning {
            border-bottom-right-radius: 0;
            background-image: url("../../../assets/images/main/article3/section3/planning.png");
          }

          &.attention {
            border-bottom-left-radius: 0;
            background-image: url("../../../assets/images/main/article3/section3/attention.png");
          }

          &.successiveProcessing {
            border-top-right-radius: 0;
            background-image: url("../../../assets/images/main/article3/section3/sequential.png");
          }

          &.simultaneousProcessing {
            border-top-left-radius: 0;
            background-image: url("../../../assets/images/main/article3/section3/simultaneous.png");
          }

          > .content {
            width: 300px;
            height: 150px;
            padding: 62px 39px;
            position: relative;

            display: flex;
            align-items: center;
            gap: 20px;

            > .title {
              font-size: 70px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              letter-spacing: -3.5px;
              color: white;
            }

            > .desc {
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.24px;
              color: white;

              display: flex;
              flex-direction: column;
              >.small{
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                letter-spacing: -0.24px;
              }
            }
          }
        }
      }

      > .readMoreButton {
        @include showBottomToTop(0.8s, 0.5s, 50px);
      }
    }
  }
}

@include desktop {
  .article {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section1 {
    width: 100%;
    padding: {
      top: 300px;
      bottom: 200px;
    };

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;

    > .titleWrapper {
      > .title {
        @include articleTitleStyle;
      }
    }

    > .gridArea {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 30px;

      > .texts {
        display: flex;
        flex-direction: column;
        padding: 69px 60px;

        max-width: 675px;
        max-height: 480px;
        &.ru, &.en {
          padding-top: 0;
          padding-bottom: 0;
        }


        > .title {
          font-weight: bold;
          font-size: 40px;
          line-height: 1.4;
          color: black;
        }

        > .description {
          margin-top: 40px;
          font-weight: normal;
          font-size: 20px;
          line-height: 1.5;
          color: $fontHeavy;

          > .strong {
            font-weight: bold;
          }
        }

      }

      > .chart {
        width: 675px;
        height: 480px;
        border-radius: 20px;
        background: $itemBgBlue;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        > .title {
          display: flex;
          gap: 10px;
          align-items: center;

          font-weight: bold;
          font-size: 28px;
          line-height: 1.4;
          letter-spacing: -0.015em;
          color: $mainColor;
        }

        > .graphWrapper {
          width: 100%;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.graph1 {
            @include showBottomToTop(0.8s, 0, 50px);

            > img {
              width: 460px;
            }
          }

          &.graph2 {
            @include showBottomToTop(0.8s, 0.5s, 50px);

            > img {
              width: 480px;
            }
          }

          &.graph3 {
            @include showBottomToTop(0.8s, 1s, 50px);
            > img {
              width: 510px;
            }
          }
        }

      }
    }
  }

  .prosAndCons {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .hillBg {
      width: 100%;
      height: 436px;
      background-image: url("../../../assets/images/main/article3/section1/part2/bg_hill.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    }

    > .contentWrapper {
      background-color: $kpPink;

      > .content {
        margin-top: -153px;
        display: flex;
        gap: 30px;
        justify-content: center;
        position: relative;
        z-index: $zIndexContent;

        > .disadvantage {
          @include prosAndConsArea;
          @include showBottomToTop(1s, 0, 80px);

          > .title {
            @include prosAndConsTitleStyle;

            color: $fontBodyText;
          }

          > .descriptions {
            @include prosAndConsDescriptionList;
            gap: 0;
            justify-content: space-between;
            background-color: $kpPinkHeavy;
            list-style-type: disc;
            height: 100%;

            > .aDescription {
              margin-left: 1em;
              list-style: disc outside none;
              display: list-item;
              color: $fontGray;

              > .title {
                font-weight: bold;
                font-size: 24px;
                line-height: 1.4;
              }

              > .desc {
                font-weight: normal;
                font-size: 20px;
                line-height: 1.5;
              }
            }
          }
        }

        > .advantage {
          @include prosAndConsArea;
          @include showBottomToTop(1s, 0.4s, 80px);
          position: relative;

          > .peace {
            width: 409px;
            height: 454px;
            position: absolute;
            bottom: -430px;
            right: -100px;

            @include showTranslateX(0.8s, 0.4s, 50%);
          }

          > .title {
            @include prosAndConsTitleStyle;
            color: $hotpink;
          }

          > .descriptions {
            @include prosAndConsDescriptionList;
            background-color: white;

            > .aDescription {
              display: flex;
              align-items: flex-start;
              gap: 16px;

              > .icon {
                width: 67px;

                > img {
                  width: inherit;
                }
              }

              > .texts {
                > .title {
                  font-weight: bold;
                  font-size: 24px;
                  line-height: 1.4;
                  color: $hotpink;
                }

                > .desc {
                  font-weight: normal;
                  font-size: 20px;
                  line-height: 1.5;
                  color: $fontGray;
                }
              }
            }
          }
        }
      }
    }

    > .ellipseBgWrapper {
      margin-top: -209px;
      position: relative;
      width: 100%;
      height: 418px;

      > .ellipseBg {
        $width: calc(100vw * 1.17);
        position: absolute;
        left: calc((#{$width} - 100vw) / -2);
        bottom: 0;
        width: $width;
        height: calc(#{$width} * 0.186);
        background-color: $kpPink;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }

  .section2 {
    width: 100%;
    padding: {
      top: 300px;
    };

    display: flex;
    flex-direction: column;
    align-items: center;

    > .description {
      @include desktopWidth;
      height: 100%;
      display: flex;
      align-items: stretch;
      gap: 30px;

      $flexItemWidth: 675px;

      > .texts {
        width: $flexItemWidth;
        padding: 24px 40px 24px 60px;
        margin-bottom: 100px;

        > .title {
          font-weight: bold;
          font-size: 40px;
          line-height: 1.4;
          color: black;
        }

        > .desc {
          margin-top: 40px;
          font-weight: normal;
          font-size: 20px;
          line-height: 1.5;
          color: $fontHeavy;

          > .strong {
            font-weight: bold;
          }
        }
      }

      > .pictureWrapper {
        position: relative;
        width: $flexItemWidth;

        > .picture {
          position: absolute;
          width: calc(50vw - 15px);
          height: 100%;
          top: 0;
          left: 0;
          background-image: url("../../../assets/images/main/article3/section2/seminar.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    > .historyWrapper {
      width: 100%;
      background-color: $fontHeavy;
      padding: 130px 0;

      display: flex;
      align-items: center;
      gap: 30px;

      position: relative;

      > .bgItem {
        @keyframes floating {
          from {
            bottom: 70px;
          }
          to {
            bottom: 100px;
          }
        }
        animation: floating ease-in-out 1s infinite alternate;

        position: absolute;
        width: 335px;
        left: calc(50vw + 475px);
      }

      > .history {
        @include desktopWidth;
        display: flex;
        gap: 30px;

        > .panel {
          display: flex;
          flex-direction: column;
          gap: 30px;
          padding: 50px 60px;
          width: 675px;

          &.left {
            @include showBottomToTop(1s, 0, 50px);
          }

          &.right {
            @include showBottomToTop(1s, 0.4s, 50px);
          }

          > .title {
            font-weight: bold;
            font-size: 28px;
            line-height: 1.4;
            color: white;

            display: flex;
            align-items: flex-start;
            gap: 10px;
 
            > img {
              width: 42.25px;
            }
          }

          > .list {
            display: flex;
            flex-direction: column;
            gap: 12px;

            font-weight: normal;
            font-size: 20px;
            line-height: 1.5;
            color: white;

            list-style-type: disc;

            > li {
              margin-left: 1em;
              list-style: disc outside none;
              display: list-item;

              .string {
                font-weight: bold;
              }

              .light {
                font-weight: normal;
                font-size: 16px;
                line-height: 1.5;
                color: $navyLight;
              }
            }
          }
        }
      }
    }
  }

  .section3 {
    width: 100%;
    @include desktopWidth;
    padding: 200px 0 300px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    > .bgItemRightTop {
      position: absolute;
      right: -174px;
      top: 417px;
      width: 330px;
      height: 330px;
      background-color: $itemBgGray;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    > .bgItemLeftBottom {
      position: absolute;
      left: -450px;
      bottom: -50px;
      width: 585px;
      height: 585px;
      border: 129px solid $itemBgBlue;
      border-radius: 50%;
    }

    > .texts {
      display: flex;
      flex-direction: column;
      gap: 40px;

      width: 100%;
      padding: 84px 60px;

      > .titleArea {
        > .title {
          font-weight: bold;
          font-size: 40px;
          line-height: 1.4;
          color: black;
        }
      }

      > .paragraphArea {
        font-weight: normal;
        font-size: 20px;
        line-height: 1.5;
        color: $fontHeavy;
        z-index: $zIndexContent;
      }
    }

    > .aboutPass {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 81px;

      > .passGrid {
        $itemWidth: 440px;
        $itemHeight: 224px;
        display: grid;
        grid-template-rows: repeat(2, $itemHeight);
        grid-template-columns: repeat(2, $itemWidth);
        grid-gap: 30px;

        @include showBottomToTop(0.8s, 0, 50px);

        > .item {
          $borderRadius: 47.6748px;
          background-color: #9B89EE;
          border-radius: $borderRadius;
          background-position: center;
          background-size: cover;
          position: relative;
          overflow: hidden;

          &:hover {
            > .mask {

              > .maskHover {
                transform: scale(2.5);
                //border-radius: 0;
              }
            }
          }

          > .mask {
            transition: all 1s ease-in-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            > .maskHover {
              width: 220px;
              height: 220px;
              background-color: $mainColor;
              border-radius: 50%;
              transition: all 0.3s ease-in-out;
              transform: scale(0);
              opacity: 0.9;
            }

            > .maskDefault {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: $KPNavy;
              opacity: 0.8;
            }
          }

          &.planning {
            border-bottom-right-radius: 0;
            background-image: url("../../../assets/images/main/article3/section3/planning.png");
          }

          &.attention {
            border-bottom-left-radius: 0;
            background-image: url("../../../assets/images/main/article3/section3/attention.png");
          }

          &.successiveProcessing {
            border-top-right-radius: 0;
            background-image: url("../../../assets/images/main/article3/section3/sequential.png");
          }

          &.simultaneousProcessing {
            border-top-left-radius: 0;
            background-image: url("../../../assets/images/main/article3/section3/simultaneous.png");
          }

          > .content {
            width: 100%;
            height: 100%;
            padding: 62px 39px;
            position: relative;

            display: flex;
            align-items: center;
            gap: 20px;

            > .title {
              font-weight: bold;
              font-size: 100px;
              line-height: 100%;
              letter-spacing: -0.05em;
              color: white;
            }

            > .desc {
              font-weight: normal;
              font-size: 24px;
              line-height: 1.4;
              color: white;

              display: flex;
              flex-direction: column;

            }
          }
        }
      }

      > .readMoreButton {
        @include showBottomToTop(0.8s, 0.5s, 50px);
      }
    }
  }
}