$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "src/assets/scss/variables";

@include mobile {
  .footer {
    background-color: $fontHeavy;
    width: 100%;
    z-index: $zIndexFooter;

    > .bannerWrapper {
      width: 100%;
      background-color: #FBFBFF;
      padding: 50px 0 70px 0;

      > .banner {
        width: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: column-reverse;
        gap: 40px;

        > .textArea {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 40px;
          @include wordBreak;

          > .text {
            text-align: center;
            font-weight: normal;
            font-size: 24px;
            line-height: 1.4;
            color: $fontHeavy;

            > .point {
              font-weight: bold;
              font-size: 28px;

              > .strongPoint {
                color: $mainColor;
              }
            }
          }
        }

        > .image {
          width: 100%;
        }
      }
    }

    > .aboutFeelgood {
      background-color: #292735;

      > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;

        > .logo {
          display: flex;
          flex-direction: column;
          align-items: center;

          > img {
            width: 98px;
          }

          > .text {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.21px;
            color: white;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            text-align: center;
            @include wordBreak;
          }
        }

        > .sns {
          margin-top: 16px;
          width: 100%;
          max-width: 260px;
          display: flex;
          justify-content: space-between;

          > .snsLink {
            width: 50px;
            height: 50px;
            transition: all 0.5s ease-in-out;

            > span {
              position: relative;
              z-index: -1;
            }

            &.naver {
              background-image: url("../../../assets/images/footer/sns_naver.svg");

              &:active {
                background-image: url("../../../assets/images/footer/sns_naver_color.svg");
              }
            }

            &.insta {
              background-image: url("../../../assets/images/footer/sns_insta.svg");

              &:active {
                background-image: url("../../../assets/images/footer/sns_insta_color.svg");
              }
            }

            &.facebook {
              background-image: url("../../../assets/images/footer/sns_facebook.svg");

              &:active {
                background-image: url("../../../assets/images/footer/sns_facebook_color.svg");
              }
            }

            &.youtube {
              background-image: url("../../../assets/images/footer/sns_youtube.svg");

              &:active {
                background-image: url("../../../assets/images/footer/sns_youtube_color.svg");
              }
            }
          }
        }
      }
    }

    > .contentWrapper {
      padding: 40px 30px 50px 30px;

      > .companyInfoAndLang {
        display: flex;
        flex-direction: column;
        gap: 12px;

        > .companyLogo {
          img {
            width: 102px;
          }
        }

        > .companyInfo {
          display: flex;
          flex-direction: column;
          gap: 12px;
          font-size: 1.2rem;
          line-height: 1.4;
          color: $navyLight;

          > .name {
            font-weight: bold;
            color: $navyLight;
          }

          > .others {
            font-weight: normal;
          }

          > .link {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            font-weight: bold;
            line-height: 1.8;
            color: $navyLight;

            > .icon {
              width: 22px;
            }
          }
        }

        > .lang {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          gap: 4px;

          > .label {
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 1.8;
            color: $navyLight;
          }

          > .select {
            width: 113px;
            height: 30px;
            border: 1px solid $navyLight;
            padding: 4px 10px;
            background-color: transparent;
            background-image: url("../../images/select_arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 4px);
            background-position-y: 0;// 4px;
            color: $navyLight;
            font-size: 1.2rem;
            line-height: 1.8;
            font-weight: normal;

            &:focus, &:active {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .footer {
    background-color: $KPNavy;
    width: 100%;
    display: none;

    &.show {
      display: block;
      z-index: $zIndexFooter;
    }

    > .bannerWrapper {
      width: 100%;
      background-color: #FBFBFF;
      padding: 30px 0;

      > .banner {
        width: 768px;
        padding: 0 40px;
        margin: 0 auto;
        display: flex;
        gap: 20px;

        > .textArea {
          width: 273px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;

          > .text {
            font-weight: normal;
            font-size: 24px;
            line-height: 1.4;
            color: $fontHeavy;
            white-space: break-spaces;

            > .point {
              font-weight: bold;
              font-size: 28px;

              > .strongPoint {
                color: $mainColor;
              }
            }
          }
        }

        > .image {
          width: 395px;
        }
      }
    }

    > .aboutFeelgood {
      background-color: #292735;

      > .content {
        width: $footerWidthTablet;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        > .logo {
          display: flex;
          align-items: center;

          > .text {
            font-weight: normal;
            font-size: 14px;
            line-height: 1.5;
            color: white;
            margin-left: 10px;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          }
        }

        > .sns {
          display: flex;
          gap: 20px;

          > .snsLink {
            width: 40px;
            height: 40px;
            transition: all 0.5s ease-in-out;

            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;

            > span {
              position: relative;
              z-index: -1;
            }

            &.naver {
              background-image: url("../../../assets/images/footer/sns_naver.svg");

              &:hover {
                background-image: url("../../../assets/images/footer/sns_naver_color.svg");
              }
            }

            &.insta {
              background-image: url("../../../assets/images/footer/sns_insta.svg");

              &:hover {
                background-image: url("../../../assets/images/footer/sns_insta_color.svg");
              }
            }

            &.facebook {
              background-image: url("../../../assets/images/footer/sns_facebook.svg");

              &:hover {
                background-image: url("../../../assets/images/footer/sns_facebook_color.svg");
              }
            }

            &.youtube {
              background-image: url("../../../assets/images/footer/sns_youtube.svg");

              &:hover {
                background-image: url("../../../assets/images/footer/sns_youtube_color.svg");
              }
            }
          }
        }
      }
    }

    > .contentWrapper {
      padding: 60px 0;

      > .companyInfoAndLang {
        width: $footerWidthTablet;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 30px;

        > .companyLogo {
          img {
            width: 125px;
          }
        }

        > .companyInfo {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          line-height: 1.8;
          color: $navyLight;

          > .name {
            font-weight: bold;
            color: $navyLight;
          }

          > .others {
            font-weight: normal;
          }

          > .link {
            margin-top: 30px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.015em;
            color: $navyLight;

            > .icon {
              width: 24px;
            }
          }
        }

        > .lang {
          display: flex;
          gap: 12px;
          align-items: center;

          > .label {
            font-weight: bold;
            font-size: 12px;
            line-height: 1.8;
            color: $navyLight;
          }

          > .select {
            width: 113px;
            height: 30px;
            border: 1px solid $navyLight;
            padding: 4px 10px;
            background-color: transparent;
            background-image: url("../../images/select_arrow.svg");
            background-repeat: no-repeat;
            background-size: 22px;
            background-position-x: calc(100% - 4px);
            background-position-y: 4px;
            color: $navyLight;
            font-size: 1.2rem;
            line-height: 1.8;
            font-weight: normal;

            &:focus, &:active {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .footer {
    background-color: $KPNavy;
    width: 100%;
    display: none;

    &.show {
      display: block;
      z-index: $zIndexFooter;
    }

    > .bannerWrapper {
      width: 100%;
      background-color: #FBFBFF;

      > .banner {
        width: 1380px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        gap: 112px;

        > .textArea {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 40px;

          > .text {
            font-weight: normal;
            font-size: 28px;
            line-height: 1.4;
            color: $fontHeavy;

            > .point {
              font-weight: bold;
              font-size: 40px;

              > .strongPoint {
                color: $mainColor;
              }
            }
          }
        }

        > .image {
          margin-top: 36px;
          width: 675px;
          height: 408px;
        }
      }
    }

    > .aboutFeelgood {
      background-color: #292735;

      > .content {
        width: $footerWidth;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 30px 0;

        > .logo {
          display: flex;
          align-items: center;

          > .text {
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 1.8;
            color: white;
            margin-left: 20px;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          }
        }

        > .sns {
          display: flex;
          gap: 20px;

          > .snsLink {
            width: 50px;
            height: 50px;
            transition: all 0.5s ease-in-out;

            > span {
              position: relative;
              z-index: -1;
            }

            &.naver {
              background-image: url("../../../assets/images/footer/sns_naver.svg");

              &:hover {
                background-image: url("../../../assets/images/footer/sns_naver_color.svg");
              }
            }

            &.insta {
              background-image: url("../../../assets/images/footer/sns_insta.svg");

              &:hover {
                background-image: url("../../../assets/images/footer/sns_insta_color.svg");
              }
            }

            &.facebook {
              background-image: url("../../../assets/images/footer/sns_facebook.svg");

              &:hover {
                background-image: url("../../../assets/images/footer/sns_facebook_color.svg");
              }
            }

            &.youtube {
              background-image: url("../../../assets/images/footer/sns_youtube.svg");

              &:hover {
                background-image: url("../../../assets/images/footer/sns_youtube_color.svg");
              }
            }
          }
        }
      }
    }

    > .contentWrapper {
      background-color: $KPNavy;
      width: $footerWidth;
      margin: 0 auto;
      padding: {
        top: 70px;
        bottom: 70px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 323px;

      > .companyInfoAndLang {
        display: flex;
        flex-direction: column;

        > .companyInfo {
          font-size: 1.6rem;
          line-height: 1.8;

          > .name {
            font-weight: bold;
            color: white;
          }

          > .others {
            font-weight: normal;
            color: $navyLight;
          }
        }

        > .lang {
          margin-top: 30px;

          > .label {
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 1.8;
            color: $navyLight;
          }

          > .select {
            width: 160px;
            height: 37px;
            margin-left: 20px;
            border: 1px solid $navyLight;
            padding: {
              left: 20px;
              right: 20px;
              top: 4px;
              bottom: 4px;
            };
            background-color: transparent;
            background-image: url("../../../assets/images/footer/select_arrow.svg");
            background-repeat: no-repeat;
            background-position-x: calc(100% - 4px);
            background-position-y: 4px;
            color: $navyLight;
            font-size: 1.6rem;
            line-height: 1.8;
            font-weight: normal;

            &:focus, &:active {
              border-radius: 0;
            }
          }
        }
      }

      > .logoWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;

        > .companyLogo {
          width: 225px;
          height: 36px;
        }

        > .link {
          display: flex;
          align-items: center;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.8;
          color: $navyLight;

          > .icon {
            width: 29px;
          }
        }
      }
    }
  }
}

