$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@include mobile {
  .section4 {
    width: 100%;
    position: relative;

    > .bgItem2 {
      width: 208px;
      height: 105px;
      position: absolute;
      top: 0;
      left: calc(50vw - 28px);
      background: url("../../images/main_legacy/section4/bgItem2.svg") no-repeat;
      background-size: 100%;
      z-index: $zIndexBgItemOnBg;
    }

    > .content1Wrapper {
      width: 100%;
      background-image: url("../../images/main_legacy/section4/bg_mobile.png");
      background-size: 100vw 100%;
      background-repeat: repeat-x;


      > .content1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 130px 0;

        > .titleHeading {
          z-index: $zIndexContent;

          > .title {
            padding: 6px 20px;
            min-width: 111px;
            background-color: white;
            color: $fontHeavy;
          }
        }

        > .video {
          position: relative;
          width: 100%;
          padding: 0 20px;
          margin-top: 60px;
          z-index: $zIndexContent;

          > video {
            width: 100%;
            position: relative;
            z-index: $zIndexContent;
          }

          > .bgItem1 {
            width: 143.23px;
            height: 134.44px;
            position: absolute;
            left: -40px;
            bottom: -67.44px;
            background: url("../../images/main_legacy/section4/bgItem1_mobile.png") no-repeat;
            background-size: 100%;
            z-index: $zIndexBgItemOnBg;
          }
        }

        > .phrase1 {
          margin-top: 130px;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 0 30px;

          > .quote {
            width: 100%;
            background-color: #292735;
            color: white;

            $borderRadiusVal: 30px;
            border-top-right-radius: $borderRadiusVal;
            border-bottom-left-radius: $borderRadiusVal;
            border-bottom-right-radius: $borderRadiusVal;

            font-size: 1.6rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 20px 30px;

            > .text1 {
              font-weight: bold;
              display: block;
            }

            > .text2 {
              font-weight: normal;
              display: block;
              font-size: 1.2rem;
            }
          }

          > .desc {
            margin: {
              top: 20px;
            };
            font-size: 1.2rem;
            line-height: 1.6;
            font-weight: normal;
            letter-spacing: -0.05em;
            color: #A3A0BA;
            text-align: center;
            @include newLine;
          }
        }

        > .phrase2 {
          margin: {
            top: 60px;
            left: 15px;
            right: 15px;
          };
          display: flex;
          flex-direction: column;
          align-items: center;

          > .quote {
            width: 100%;
            padding: 20px 30px;
            background-color: #292735;
            color: white;

            $borderRadiusVal: 30px;
            border-radius: 0 $borderRadiusVal $borderRadiusVal $borderRadiusVal;

            display: flex;
            flex-direction: column;
            justify-content: center;


            font-size: 1.6rem;
            line-height: 1.6;
            letter-spacing: -0.05em;

            > .text1 {
              font-weight: bold;
              @include newLine;
            }

            > .text2 {
              font-weight: normal;
              font-size: 1.2rem;
            }
          }

          > .desc {
            margin: {
              top: 20px;
            };
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            color: #A3A0BA;
            text-align: center;
          }
        }
      }
    }


    > .content2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 130px 30px;

      line-height: 1.6;
      letter-spacing: -0.05em;

      > .phrase1 {
        font-weight: bold;
        font-size: 1.6rem;
        display: flex;
        align-items: flex-start;
        gap: 12px;

        > img {
          width: 24px;
        }

        > .text {
          color: $fontHeavy;
          text-align: center;
          @include newLine;
        }
      }

      > .phrase2 {
        margin-top: 16px;
        font-weight: normal;
        font-size: 1.2rem;
        text-align: center;
        color: $fontLight;
        @include newLine;
      }
    }
  }
}

@include desktop {

  .section4 {
    width: 100%;
    position: relative;

    > .bgItem1 {
      width: 751px;
      height: 511px;
      position: absolute;
      top: 0;
      right: calc(50vw + 209px);
      background: url("../../images/main_legacy/section4/bgItem1.svg") no-repeat;
      z-index: $zIndexBgItemOnBg;
    }

    > .bgItem2 {
      width: 745px;
      height: 376px;
      position: absolute;
      top: 0;
      left: calc(50vw + 155px);
      //right: 60px;
      background: url("../../images/main_legacy/section4/bgItem2.svg") no-repeat;
      z-index: $zIndexBgItemOnBg;
    }

    > .content1Wrapper {
      width: 100%;
      background: url("../../images/main_legacy/section4/bg.png") repeat;

      > .content1 {
        @include desktopWidth;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: {
          top: 300px;
          bottom: 150px;
        };

        > .titleHeading {
          z-index: $zIndexContent;

          > .title {
            width: 212px;
            //height: 62px;
            background-color: white;
            color: $KPNavy;
            //font-size: 3.2rem;
            //font-weight: bold;
            //letter-spacing: -0.05em;
            //border-top-right-radius: 31px;
            //border-bottom-left-radius: 31px;
            //border-bottom-right-radius: 31px;
            //display: flex;
            //justify-content: center;
            //align-items: center;

            &.ru {
              padding: 12px 40px;
              width: auto;
            }
          }
        }


        > .video {
          max-width: 1072px;
          max-height: 603px;
          margin-top: 100px;
          background-color: #c8c8c8;
          z-index: $zIndexContent;

          > video {
            width: 100%;
          }
        }

        > .phrase1 {
          padding-top: 140px;
          width: 100%;

          > .quote {
            width: 652px;
            height: 213px;
            background-color: #292735;
            color: white;

            $borderRadiusVal: 106px;
            border-top-right-radius: $borderRadiusVal;
            border-bottom-left-radius: $borderRadiusVal;
            border-bottom-right-radius: $borderRadiusVal;

            font-size: 3.2rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: {
              left: 105px;
            };

            > .text1 {
              font-weight: bold;
              display: block;
            }

            > .text2 {
              font-weight: normal;
              display: block;
            }
          }

          > .desc {
            margin: {
              left: 105px;
              top: 30px;
            };
            font-size: 2rem;
            line-height: 1.6;
            font-weight: normal;
            letter-spacing: -0.05em;
            color: #A7A6B5;
          }
        }

        > .phrase2 {
          margin: {
            top: 80px;
          };
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          > .quote {
            width: 715px;
            height: 213px;
            background-color: #292735;
            color: white;

            $borderRadiusVal: 106px;
            border-top-left-radius: $borderRadiusVal;
            border-bottom-left-radius: $borderRadiusVal;
            border-bottom-right-radius: $borderRadiusVal;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-right: 105px;

            font-size: 3.2rem;
            line-height: 1.6;
            letter-spacing: -0.05em;

            > .text1 {
              font-weight: bold;
              text-align: right;
              @include newLine;
            }

            > .text2 {
              font-weight: normal;
            }
          }

          > .desc {
            margin: {
              right: 105px;
              top: 30px;
            };
            font-weight: normal;
            font-size: 2rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            color: #A7A6B5;
          }
        }
      }
    }


    > .content2 {
      width: 100%;
      height: 483px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      line-height: 1.6;
      letter-spacing: -0.05em;

      > .phrase1 {
        font-weight: bold;
        font-size: 3.2rem;
        display: flex;
        align-items: center;
        @include showBottomToTop(0.5s, 0, 10%);

        > .text {
          margin: {
            left: 40px;
            right: 40px;
          };
          color: $KPNavy;
        }
      }

      > .phrase2 {
        margin-top: 51px;
        font-weight: normal;
        font-size: 2rem;
        text-align: center;
        color: $g2757575;
      }
    }
  }
}
