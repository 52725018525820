$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "src/assets/scss/variables";

@mixin activeMenu {
  color: $mainColor;
  font-weight: bold;
}

@keyframes shiningButtonBefore {
  from {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-15deg);
  }

  to {
    opacity: 0.6;
    transform: translateX(300px) skewX(-15deg);
  }
}

@keyframes shiningButtonAfter {
  from {
    opacity: 0;
    transform: translateX(-100px) skewX(-15deg);
  }

  to {
    opacity: 1;
    transform: translateX(300px) skewX(-15deg);
  }
}

@include mobile {
  .header {
    position: sticky;
    top: -1px;
    left: 0;
    background: url("../../images/header/top_menu_bg_mobile.svg") bottom repeat-x;
    width: 100%;
    z-index: $zIndexHeader;

    &.hide {
      display: none;
    }

    &.noLoginBar {
      height: $headerHeightMobileWOLoginBar;
      background-position: bottom;
    }

    > .loginBar {
      background-color: $KPNavy;
      width: 100%;

      > .innerBar {
        width: 100%;
        height: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: stretch;

        > .downloadNotice {
          padding: 12px 0 10px 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          > .text {
            color: $blueLight;
            font-size: 12px;
            line-height: 1.6;
            font-weight: normal;
            letter-spacing: -0.05em;
            margin-right: 10px;

            > .accent {
              font-weight: bold;
            }
          }

          > .downloadText {
            display: flex;
            color: white;
            font-weight: bold;
            font-size: 12px;
            line-height: 1.6;
            letter-spacing: -0.05em;
            text-decoration-line: underline;
            cursor: pointer;

            > img {
              width: 19px;
              height: 19px;
            }
          }
        }

        > .loginBtn {
          width: 159px;
          background-color: $mainColor;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          > .icon {
            width: 15px;
          }

          > .text {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: -0.05em;
            color: white;
            padding-left: 4px;
          }
        }

        > .loggedInStatus {
          padding: 8px 20px 7px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > .myInfoWrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            > .voucherCouponButtonWrapper {
              position: relative;

              > .voucherCouponButton {
                display: flex;
                align-items: center;
                gap: 4px;

                > span {
                  font-weight: bold;
                  font-size: 12px;
                  letter-spacing: -0.03em;
                  color: $navyLight;
                }

                > img {
                  display: block;
                  flex-shrink: 0;
                  width: 12px;
                  height: 12px;
                }
              }

              .vouchers {
                width: 126px;
                background-color: $KPNavy;
                border-radius: 0 0 6px 6px;

                position: absolute;
                top: 19.2px;
                left: -54px;
                padding: 22.5px 12px 12px 12px;

                display: flex;
                flex-direction: column;

                > .anItem {
                  text-align: center;
                  font-size: 12px;
                  line-height: 1.6;
                  letter-spacing: -0.03em;

                  svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                  }

                  > .label {
                    font-weight: normal;
                    color: $navyLight;
                  }

                  > .content {
                    margin-left: 10px;
                    color: white;
                    font-weight: bold;
                  }

                  &:not(:last-child)::after {
                    content: " ";
                    display: block;
                    width: 100%;
                    height: 0;
                    border-top: 1px dashed $navyLight;
                    margin: 10px 0;
                  }
                }
              }
            }

            > .userName {
              color: white;
              font-size: 16px;
              line-height: 1.6;
              letter-spacing: -0.05em;
              font-weight: normal;

              > .accent {
                font-weight: bold;
              }
            }
          }

          > .logoutBtn {
            flex-shrink: 0;
            padding: 2px 10px;
            border: 1px solid $navyLight;
            border-radius: 100px;
            font-weight: bold;
            font-size: 12px;
            line-height: 1.6;
            letter-spacing: -0.03em;
            color: $navyLight;
          }
        }
      }
    }

    > .headerBg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 80px;
      padding: 20px 20px;

      > .logo {
        width: 91px;

        a {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
          }
        }
      }

      > .menuArea {
        display: flex;
        gap: 8px;
        align-items: center;

        > .mainTopMenu {
          display: none;
        }

        > .buttonWrapper {
          > .partnerCompanyButton {
            padding: 4px 13px 4px 9px;
            border: 1px solid $itemBgGray;
            border-radius: 100px;

            display: flex;
            align-items: center;
            gap: 4px;

            > .icon {
              flex-shrink: 0;
              width: 21px;
              height: 21px;
              position: relative;

              > .defaultIcon {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                background-image: url("../../images/header/partner_icon.svg");
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
              }

              > .hoverIcon {
                display: none;
              }
            }

            > .title {
              font-size: 14px;
              font-weight: bold;
              line-height: 1.5;
              letter-spacing: -0.015em;
              color: $navyMedium;
            }
          }

          > .partnershipButton {
            padding: 5px 14px 5px 10px;
            background: linear-gradient(139deg, #535162 23.26%, #1b1a2c 95.04%);
            border-radius: 100px;
            position: relative;
            transition: background 1s ease-in-out;
            overflow: hidden;

            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.21px;
            color: white;

            display: flex;
            align-items: center;

            > img {
              width: 21px;
            }

            $animationDuration: 2.5s;

            &:before {
              content: "";
              display: block;
              position: absolute;
              background: rgba(255, 255, 255, 0.7);
              width: 20px;
              height: 100%;
              left: 0;
              top: 0;
              filter: blur(10px);

              animation-name: shiningButtonBefore;
              animation-duration: $animationDuration;
              animation-iteration-count: infinite;
            }
            &:after {
              content: "";
              display: block;
              position: absolute;
              background: rgba(255, 255, 255, 0.5);
              width: 10px;
              height: 100%;
              left: 30px;
              top: 0;
              filter: blur(5px);

              animation-name: shiningButtonAfter;
              animation-duration: $animationDuration;
              animation-iteration-count: infinite;
            }

            &:hover {
              background: linear-gradient(139deg, #583be2 23.26%, #331d9d 95.04%);
              transition: background 1s ease-in-out;
            }
          }
        }

        > .hamburger {
          width: 40px;
          height: 40px;

          > img {
            width: 100%;
          }
        }
      }
    }

    > .drawerArea {
      > .drawerBg {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        z-index: $zIndexDrawerBg;

        &.show {
          display: block;
        }
      }

      > .drawer {
        width: $drawerMenuWidthMobile;
        height: 100%;
        position: fixed;
        background-color: white;
        top: 0;
        right: 0;
        padding: 20px 20px 20px 30px;
        z-index: $zIndexDrawer;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        transition: all 0.4s ease-in-out;
        transform: translateX($drawerMenuWidthMobile);

        &.ru {
          justify-content: center;
        }

        &.show {
          transform: translateX(0);
        }

        > .closeBtn {
          width: 40px;
          height: 40px;
          align-self: flex-end;
        }

        > .beforeLogin {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;

          > .loginBtn {
            padding: 6px 20px;
            border: 1px solid $mainColor;
            border-radius: 0 100px 100px 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
              width: 24px;
            }

            > .btnText {
              color: $mainColor;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: -0.24px;
            }
          }

          > .desc {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.21px;
            color: $navyMedium;
            @include newLine;
          }
        }

        > .afterLogin {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;

          > .welcome {
            width: fit-content;
            max-width: 100%;
            background: $KPNavy;
            border-radius: 100px;
            padding: 6px 20px;

            > .text {
              width: fit-content;
              font-weight: normal;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: -0.24px;
              color: white;

              > .accent {
                font-weight: bold;
              }
            }
          }

          > .desc {
            font-weight: normal;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.21px;
            color: $navyMedium;

            > .accent {
              font-weight: bold;
            }
          }
        }

        > .logoutBtn {
          align-self: flex-end;
          padding: 4px 8px;
          display: flex;
          align-items: flex-end;

          > .text {
            font-weight: normal;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.21px;
            color: $navyMedium;
          }

          > img {
            width: 16px;
          }
        }

        > .navWrapper {
          width: 100%;
          flex: 1;
          background-color: white;
          overflow-y: scroll;

          display: flex;
          flex-direction: column;
          justify-content: safe center;

          > .nav {
            > .navList {
              display: flex;
              flex-direction: column;
              gap: 8px;

              > .menu {
                font-weight: normal;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.24px;
                padding: 10px 14px;

                > a {
                  color: $navyMedium;
                  display: flex;
                  align-items: center;
                  font-weight: normal;
                  position: relative;

                  $transitionTime: 0.4s;
                  $iconWidth: 24px;

                  > .normalIcon {
                    width: $iconWidth;
                    transition: all $transitionTime ease-in-out;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 1;
                  }

                  > .activeIcon {
                    width: $iconWidth;
                    transition: all $transitionTime ease-in-out;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                  }

                  > .menuName {
                    font-weight: normal;
                    opacity: 1;
                    transition: all $transitionTime ease-in-out;
                  }

                  > .activeMenuName {
                    transition: all $transitionTime ease-in-out;
                    opacity: 0;
                    font-weight: bold;
                    position: absolute;
                    left: 0;
                    top: 0;
                  }

                  &.withIcon {
                    padding-left: $iconWidth;

                    > .activeMenuName {
                      left: $iconWidth;
                    }
                  }

                  &:active,
                  &.active {
                    > .normalIcon {
                      opacity: 0;
                    }

                    > .activeIcon {
                      opacity: 1;
                    }

                    > .menuName {
                      opacity: 0;
                    }

                    > .activeMenuName {
                      opacity: 1;
                    }
                  }

                  &.purchasePage {
                    color: $hotpink;
                    flex-wrap: wrap;

                    > .menuName,
                    > .activeMenuName {
                      margin-right: 10px;
                    }

                    > .discountBadge {
                      background-color: $hotpink;
                      padding: 0 8px;
                      width: fit-content;

                      color: white;
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 1.5;
                      letter-spacing: -0.21px;

                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 100px;
                    }
                  }

                  &.downloadPage {
                    color: $mainColor;
                  }

                  &.partnership {
                    color: $KPNavy;
                  }

                  &.partnerlist {
                    color: $navyMedium;
                  }
                }
              }
            }
          }
        }
      }
    }

    .mypageMenu {
      padding: 10px 14px;
      font-size: 16px;
    }
  }

  .brandBannerBox{
    background: url("../../images/header/background_frame_mobile.svg");
    background-repeat: no-repeat;
    background-size: cover;

  }

  .brandBannerFrame {
    cursor: pointer;
    position: relative;
    padding: 0 30px;
    //background: radial-gradient(50% 105.28% at 50% 50%, #6FC9F3 0%, #002978 100%);
    z-index: 2;
    width: 100%;
  }

  .backgroundWrapper {
    display: flex;
    flex-direction: column;
  }

  // 비노출
  .brandBackground1{
    display: none;
  }

  .brandBackground2{
    display: none;
    width: 251.549px;
    height: 250px;
    position: absolute;
    top: 0;
    //background: url("../../images/header/brandBackground2.svg");
  }


  .bannerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    align-self: stretch;
  }

  .brandTrophyContainer{
    display: flex;
    width: 250px;
    height: 250px;
    padding: 42px 50px 41.032px 50px;

    justify-content: center;
    align-items: center;
  }

  .brandTrophy{
    width: 150px;
    height: 166.968px;
    z-index: 3;

    background: url("../../images/header/award_logo.svg");
  }

  .brandDescContainer{
    display: flex;
    height: 250px;
    align-items: center;
    flex: 1 0 0;
  }

  .brandDescWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    flex: 1 0 0;
  }

  .brandDescTitle{
    color: var(--GrayScale-white, #FFF);
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    text-align: center;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.24px;
    white-space: pre-line;

    > .bracket{
      color: var(--FeelGood-FGorange-500_main, #002978);
      font-weight: 700;
    }

    > .bold{
      color: var(--GrayScale-white, #FFF);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: -0.3px;
    }
  }

  .notTodayContainer{
    display: flex;
    padding: 0px 15px 10px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    align-self: stretch;

    > .xIcon{
      display: flex;
      width: 21px;
      height: 21px;
      padding: 6px;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background: url("../../images/header/icon_X.svg");
    }

    > .notTodayText{
      color: var(--K-PASS-KPnavy-100, #E5E5EF);
      text-align: center;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.21px;
    }
  }

  .headerBanner {
    width: auto;
    overflow: hidden;
  }

  .searchIcon {
    width: 24px;
    height: 24px;
  }

  .freeProviding {
    margin-left: 10px;

    display: flex;
    padding: 0px 8px;
    align-items: center;

    border-radius: 100px;
    background: var(--K-PASS-KPpink-600, #e60063);

    color: var(--GrayScale-white, #fff);

    /* 📱Mobile/Caption/C_Bold */
    font-family: Pretendard;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;
  }
}

// mobile scss 끝
@include tablet {
  .header {
    position: fixed;
    top: 0;
    background-image: url("../../../assets/images/header/top_menu_bg_tablet.svg");
    background-position: bottom;
    background-repeat: repeat-x;
    width: 100%;
    height: $headerHeightTablet;
    z-index: $zIndexHeader;

    &.noLoginBar {
      height: $headerHeightTabletWOLoginBar;
      background-position: bottom;
    }

    > .loginBar {
      position: relative;
      z-index: 6;
      background-color: $KPNavy;
      width: 100%;
      height: 44px;

      > .innerBar {
        max-width: $maxWidthTablet;
        margin: {
          left: auto;
          right: auto;
        }
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > .downloadNotice {
          display: flex;
          align-items: center;

          > .text {
            color: $blueLight;
            font-size: 14px;
            line-height: 1.5;
            font-weight: normal;
            letter-spacing: -0.015em;

            > .accent {
              font-weight: bold;
            }
          }

          > .downloadText {
            display: flex;
            align-items: center;
            padding-left: 10px;
            color: white;
            font-weight: bold;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.015em;
            text-decoration-line: underline;
            cursor: pointer;

            > img {
              width: 20px;
              height: 20px;
            }
          }
        }

        > .loginBtn {
          padding: 10px 20px;
          background-color: $mainColor;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          > .icon {
            width: 20px;
          }

          > .text {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.015em;
            color: white;
          }
        }

        > .loggedInStatus {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > .myInfoWrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            > .voucherCouponButtonWrapper {
              position: relative;

              > .voucherCouponButton {
                display: flex;
                align-items: center;
                gap: 4px;

                > span {
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 1.5;
                  letter-spacing: -0.015em;
                  color: $navyLight;
                }

                > img {
                  display: block;
                  flex-shrink: 0;
                  width: 12px;
                  height: 12px;
                }
              }

              .vouchers {
                width: 135px;
                background-color: $KPNavy;
                border-radius: 0 0 6px 6px;
                z-index: $zIndexModal;

                position: absolute;
                top: 19.2px;
                left: -54px;
                padding: 12px;

                display: flex;
                flex-direction: column;

                > .anItem {
                  text-align: center;
                  font-size: 12px;
                  line-height: 1.6;
                  letter-spacing: -0.03em;
                  display: flex;
                  align-items: center;
                  justify-content: center; /* 아이템들을 가운데 정렬 */

                  svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                    flex-shrink: 0;
                  }

                  > .label {
                    font-weight: normal;
                    color: $navyLight;
                    text-align: center; /* 텍스트 가운데 정렬 */
                  }

                  > .content {
                    margin-left: 10px;
                    color: white;
                    font-weight: bold;
                    text-align: center; /* 텍스트 가운데 정렬 */
                  }

                  &:not(:last-child) {
                    margin-bottom: 10px; /* 아이템 사이의 간격 조정 */
                    padding-bottom: 10px; /* dashed line 위아래로 패딩 추가 */
                    border-bottom: 1px dashed $navyLight; /* dashed line 추가 */
                  }
                }
              }
            }

            > .userName {
              color: white;
              font-size: 16px;
              line-height: 1.6;
              letter-spacing: -0.05em;
              font-weight: normal;

              > .accent {
                font-weight: bold;
              }
            }
          }

          > .userName {
            color: white;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.015em;
            font-weight: normal;

            > .accent {
              font-weight: bold;
            }
          }

          .logoutBtn {
            padding: 2px 12px;
            border: 1px solid $navyLight;
            border-radius: 100px;
            color: $navyLight;
            font-weight: bold;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.015em;
          }
        }
      }
    }

    > .headerBg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: $maxWidthTablet;
      height: 80px;
      margin: {
        left: auto;
        right: auto;
      }

      > .logo {
        width: 91px;
      }

      > .menuArea {
        display: flex;
        gap: 16px;
        align-items: center;

        > .buttonWrapper {
          display: flex;
          align-items: center;
          gap: 10px;

          > .partnerCompanyButton {
            display: flex;
            align-items: center;
            gap: 4px;

            border-radius: 100px;
            border: 1px solid #e5e5ef;
            padding: 4px 13px 4px 9px;

            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.015em;
            color: $navyMedium;

            $transitionValue: all 0.3s ease-in-out;
            transition: $transitionValue;

            > .icon {
              display: block;
              width: 21px;
              height: 21px;
              position: relative;

              > .defaultIcon {
                transition: $transitionValue;

                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                background-image: url("../../images/header/partner_icon.svg");
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                opacity: 1;
              }

              > .hoverIcon {
                transition: $transitionValue;

                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                background-image: url("../../images/header/partner_icon_hover.svg");
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                opacity: 0;
              }
            }

            &:hover {
              border: 1px solid $navyMedium;
              background-color: $navyMedium;
              color: white;

              > .icon {
                > .defaultIcon {
                  opacity: 0;
                }

                > .hoverIcon {
                  opacity: 1;
                }
              }
            }
          }

          > .partnershipButton {
            padding: 5px 14px 5px 10px;
            background: linear-gradient(139deg, #535162 23.26%, #1b1a2c 95.04%);
            border-radius: 100px;
            position: relative;
            transition: background 1s ease-in-out;
            overflow: hidden;

            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.015em;
            color: white;

            display: flex;
            align-items: center;

            > .hoverBg {
              opacity: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              background: linear-gradient(139deg, #583be2 23.26%, #331d9d 95.04%);
              transition: opacity 0.4s ease-in-out;
              z-index: $zIndexNormal;
            }

            > img {
              width: 21px;
              position: relative;
              z-index: $zIndexBgItemOnBg;
            }

            > .title {
              position: relative;
              z-index: $zIndexBgItemOnBg;
            }

            $animationDuration: 2s;

            &:before {
              content: "";
              display: block;
              position: absolute;
              background: rgba(255, 255, 255, 0.7);
              width: 40px;
              height: 100%;
              left: 0;
              top: 0;
              filter: blur(20px);

              animation-name: shiningButtonBefore;
              animation-duration: $animationDuration;
              animation-iteration-count: infinite;
            }
            &:after {
              content: "";
              display: block;
              position: absolute;
              background: rgba(255, 255, 255, 0.5);
              width: 30px;
              height: 100%;
              left: 30px;
              top: 0;
              filter: blur(5px);

              animation-name: shiningButtonAfter;
              animation-duration: $animationDuration;
              animation-iteration-count: infinite;
            }

            &:hover {
              > .hoverBg {
                opacity: 1;
              }
            }
          }
        }

        > .hamburger {
          width: 40px;
          height: 40px;
        }

        > .mainTopMenu {
          display: none;
        }
      }
    }

    > .drawerArea {
      > .drawerBg {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        z-index: $zIndexDrawerBg;

        &.show {
          display: block;
        }
      }

      $drawerWidth: calc(max(33vw, 400px));
      > .drawer {
        width: $drawerWidth;
        height: 100vh;
        padding: 20px 13px 20px 30px;
        position: fixed;
        background-color: white;
        top: 0;
        right: 0;
        transition: all 0.4s ease-in-out;
        transform: translateX($drawerWidth);
        z-index: $zIndexDrawer;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;

        &.show {
          transform: translateX(0);
        }

        > .closeBtn {
          width: 40px;
          height: 40px;
          align-self: flex-end;
        }

        .logoutBtn {
          display: flex;
          align-self: flex-end;
          align-items: center;

          > .text {
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 1.5;
            letter-spacing: -0.24px;
            color: $navyMedium;
          }

          > img {
            width: 20px;
          }
        }

        .beforeLogin {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;

          > .loginBtn {
            padding: 5px 19px;
            border: 1px solid $mainColor;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            border-bottom-left-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            > .btnText {
              color: $mainColor;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: -0.015em;
            }

            > img {
              width: 24px;
            }
          }

          > .desc {
            font-size: 2rem;
            line-height: 1.6;
            letter-spacing: -1px;
            color: $navyMedium;
          }
        }

        .afterLogin {
          display: flex;
          flex-direction: column;
          gap: 30px;

          > .welcome {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            > .text {
              padding: 12px 30px;
              height: 100%;
              background-color: $KPNavy;
              font-size: 2rem;
              letter-spacing: -1.2px;
              line-height: 1.6;
              color: white;
              border-top-right-radius: 31px;
              border-bottom-right-radius: 31px;
              border-bottom-left-radius: 31px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              > .accent {
                font-weight: bold;
              }
            }
          }

          > .desc {
            font-size: 1.6rem;
            font-weight: normal;
            line-height: 1.6;
            letter-spacing: -1px;
            color: $navyMedium;

            > .accent {
              font-weight: bold;
            }
          }
        }

        > .navWrapper {
          width: 100%;
          height: 100%;
          overflow-y: auto;

          display: flex;
          flex-direction: column;

          > .nav {
            > .navList {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              &.ru {
                gap: 30px;
              }

              > .menu {
                font-weight: normal;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.015em;
                @include newLine;

                > a {
                  color: $navyMedium;
                  display: flex;
                  align-items: center;
                  font-weight: normal;
                  position: relative;
                  width: fit-content;

                  $transitionTime: 0.2s;
                  $iconWidth: 24px;
                  $paddingTB: 10px;
                  $paddingLR: 14px;
                  $iconSize: 24px;

                  padding: $paddingTB $paddingLR;

                  > .normalIcon {
                    transition: all $transitionTime ease-in-out;
                    position: absolute;
                    left: $paddingLR;
                    top: $paddingTB;
                    opacity: 1;
                    width: $iconSize;
                  }

                  > .activeIcon {
                    transition: all $transitionTime ease-in-out;
                    position: absolute;
                    left: $paddingLR;
                    top: $paddingTB;
                    opacity: 0;
                    width: $iconSize;
                  }

                  > .menuName {
                    font-weight: normal;
                    opacity: 1;
                    transition: all $transitionTime ease-in-out;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  > .activeMenuName {
                    display: flex;
                    transition: all $transitionTime ease-in-out;
                    opacity: 0;
                    font-weight: bold;
                    background-color: white;
                    position: absolute;
                    left: $paddingLR;
                    top: $paddingTB;

                    &.notKO {
                      letter-spacing: -0.8px;
                    }
                  }

                  &.withIcon {
                    padding-left: calc(#{$iconWidth} + #{$paddingLR});

                    > .activeMenuName {
                      left: calc(#{$iconWidth} + #{$paddingLR});
                    }
                  }

                  &:hover,
                  &.active {
                    > .normalIcon {
                      opacity: 0;
                    }

                    > .activeIcon {
                      opacity: 1;
                    }

                    > .menuName {
                      //display: none;
                      opacity: 0;
                      //font-weight: bold;
                    }

                    > .activeMenuName {
                      //display: flex;
                      opacity: 1;
                    }
                  }

                  &.purchasePage {
                    color: $hotpink;

                    > .discountBadge {
                      padding: 0 8px;
                      background-color: $hotpink;
                      color: white;
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 1.5;
                      letter-spacing: -0.015em;

                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 100px;
                      margin-left: 10px;
                    }
                  }

                  &.downloadPage {
                    color: $mainColor;
                  }

                  &.partnership {
                    color: $KPNavy;
                  }

                  &.partnerlist {
                    color: $navyMedium;
                  }
                }
              }
            }
          }
        }
      }
    }

    .mypageMenu {
      padding: 10px 14px;
    }

    .mypageMenu &:hover {
      transition: all 0.2s ease-in-out;
      font-weight: bold;
    }

    .brandBannerBox{
      background: url("../../images/header/background_frame_tablet.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .brandBannerFrame {
      cursor: pointer;
      position: relative;
      padding: 0 30px;
      z-index: 2;
      width: 100%;
    }

    .backgroundWrapper {
      position: fixed;
      top: 50px;
      left: 0;
      min-width: 1440px;
      height: 0;
      z-index: 3;
    }

    // 비노출
    .brandBackground1 {
      display: none;
      width: 654px;
      height: 510px;
      position: absolute;
      right: 90px;

      top: -350px;
      //background: url("../../images/header/brandBackground1.svg");
      //bottom repeat-x;
    }

    // 비노출
    .brandBackground2 {
      display: none;
      width: 251.549px;
      height: 248px;
      position: absolute;

      left: 25px;
      top: 0;
      //background: url("../../images/header/brandBackground2.svg");
    }


    .bannerContainer {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
    }

    .brandTrophyContainer {
      display: flex;
      width: 250px;
      height: 250px;
      padding: 42px 50px 41.032px 50px;
      justify-content: center;
      align-items: center;
    }

    .brandTrophy {
      width: 150px;
      height: 166.968px;
      z-index: 3;

      background: url("../../images/header/award_logo.svg");
    }

    .brandDescContainer {
      display: flex;
      height: 250px;
      align-items: center;
      flex: 1 0 0;
    }

    .brandDescWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      flex: 1 0 0;
    }

    .brandDescTitle {
      color: var(--GrayScale-white, #FFF);
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      align-self: stretch;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.24px;
      white-space: pre-line;

      > .bracket {
        color: var(--FeelGood-FGorange-500_main, #002978);
        font-weight: 700;
      }

      > .bold {
        color: var(--GrayScale-white, #FFF);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.3px;
      }
    }

    .notTodayContainer {
      cursor: pointer;
      display: flex;
      padding-bottom: 10px;
      justify-content: center;
      align-items: center;
      gap: 6px;

      > .xIcon {
        display: flex;
        width: 21px;
        height: 21px;
        padding: 6px;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background: url("../../images/header/icon_X.svg");
      }

      > .notTodayText {
        color: var(--K-PASS-KPnavy-100, #E5E5EF);
        text-align: center;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.21px;
      }
    }
  }
  .headerBanner {
    height: 374px;
    overflow: hidden;
  }

  .searchIcon {
    width: 24px;
    height: 24px;
  }

  .freeProviding {
    margin-left: 10px;

    display: flex;
    padding: 0px 8px;
    align-items: center;

    border-radius: 100px;
    background: var(--K-PASS-KPpink-600, #e60063);

    color: var(--GrayScale-white, #fff);

    /* 📱Mobile/Caption/C_Bold */
    font-family: Pretendard;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: -0.21px;
  }
}
// tablet scss 끝

.brandBannerGif {
  width: 250px;
  height: 250px;
  flex-shrink: 0;
  z-index: 1;
}

@include desktop {
  .header {
    position: fixed;
    top: 0;
    background: url("../../../assets/images/header/top_menu_bg.svg") 0 4px repeat-x;
    background-position: bottom;
    width: 100%;
    height: $headerHeight;
    z-index: $zIndexHeader;

    &.noLoginBar {
      height: $headerHeightWOLoginBar;
      background-position: bottom;
    }

    > .loginBar {
      position: relative;
      z-index: 6;
      background-color: $KPNavy;
      width: 100%;
      height: 50px;

      > .innerBar {
        max-width: $maxWidth;
        margin: {
          left: auto;
          right: auto;
        }
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > .downloadNotice {
          display: flex;
          align-items: center;

          > .text {
            color: $blueLight;
            font-size: 16px;
            line-height: 1.6;
            font-weight: normal;
            letter-spacing: -0.05em;

            > .accent {
              font-weight: bold;
            }
          }

          > .downloadText {
            display: flex;
            padding-left: 20px;
            color: white;
            font-weight: bold;
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: -0.05em;
            text-decoration-line: underline;
            cursor: pointer;
          }
        }

        > .loginBtn {
          width: 175px;
          background-color: $mainColor;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          > .icon {
            width: 20px;
          }

          > .text {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.05em;
            color: white;
            padding-left: 4px;
          }
        }

        > .loggedInStatus {
          display: flex;
          align-items: center;
          padding-right: 20px;

          .vouchers {
            display: flex;
            align-items: center;

            > .anItem {
              display: flex;
              align-items: center;
              svg {
                margin-right: 5px;
                flex-shrink: 0;
              }
              > .label {
                font-size: 1.6rem;
                line-height: 1.6;
                letter-spacing: -0.05em;
                font-weight: normal;
                color: $navyLight;
                margin-right: 8px;
              }

              > .content {
                color: white;
                font-size: 1.6rem;
                line-height: 1.6;
                letter-spacing: -0.05em;
                font-weight: bold;
              }

              &:not(:last-child)::after {
                content: "|";
                color: $navyLight;
                padding: {
                  left: 20px;
                  right: 20px;
                }
              }
            }
          }

          > .userName {
            margin-left: 50px;
            color: white;
            font-size: 1.6rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            font-weight: normal;

            > .accent {
              font-weight: bold;
            }
          }

          .logoutBtn {
            width: 64px;
            height: 26px;
            border: 1px solid $navyLight;
            border-radius: 100px;
            color: $navyLight;
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            margin-left: 20px;
          }
        }
      }
    }

    > .headerBg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: $headerMaxWidth;
      height: 114px;
      margin: {
        left: auto;
        right: auto;
      }

      @media (max-width: $headerMaxWidth) {
        padding: {
          left: 50px;
          right: 50px;
        }
      }

      > .logo {
        width: 109px;
      }

      > .menuArea {
        display: flex;
        gap: 40px;
        align-items: center;

        > .buttonWrapper {
          display: flex;
          gap: 10px;

          > .partnerCompanyButton {
            display: flex;
            align-items: center;
            gap: 4px;

            border-radius: 100px;
            border: 2px solid #e5e5ef;
            padding: 10px 20px 10px 16px;

            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.015em;
            color: $navyMedium;

            $transitionValue: all 0.3s ease-in-out;
            transition: $transitionValue;

            > .icon {
              display: block;
              width: 30px;
              height: 30px;
              position: relative;

              > .defaultIcon {
                transition: $transitionValue;

                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                background-image: url("../../images/header/partner_icon.svg");
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                opacity: 1;
              }

              > .hoverIcon {
                transition: $transitionValue;

                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                background-image: url("../../images/header/partner_icon_hover.svg");
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                opacity: 0;
              }
            }

            &:hover {
              border: 2px solid $navyMedium;
              background-color: $navyMedium;
              color: white;

              > .icon {
                > .defaultIcon {
                  opacity: 0;
                }

                > .hoverIcon {
                  opacity: 1;
                }
              }
            }
          }

          > .partnershipButton {
            padding: 10px 24px 10px 20px;
            background: linear-gradient(139deg, #535162 23.26%, #1b1a2c 95.04%);
            border-radius: 100px;
            position: relative;
            transition: background 1s ease-in-out;
            overflow: hidden;

            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.3px;
            color: white;

            display: flex;
            align-items: center;

            > .hoverBg {
              opacity: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              background: linear-gradient(139deg, #583be2 23.26%, #331d9d 95.04%);
              transition: opacity 0.4s ease-in-out;
              z-index: $zIndexNormal;
            }

            > img {
              width: 30px;
              position: relative;
              z-index: $zIndexBgItemOnBg;
            }

            > .title {
              position: relative;
              z-index: $zIndexBgItemOnBg;
            }

            $animationDuration: 2s;

            &:before {
              content: "";
              display: block;
              position: absolute;
              background: rgba(255, 255, 255, 0.7);
              width: 40px;
              height: 100%;
              left: 0;
              top: 0;
              filter: blur(20px);

              animation-name: shiningButtonBefore;
              animation-duration: $animationDuration;
              animation-iteration-count: infinite;
            }
            &:after {
              content: "";
              display: block;
              position: absolute;
              background: rgba(255, 255, 255, 0.5);
              width: 30px;
              height: 100%;
              left: 30px;
              top: 0;
              filter: blur(5px);

              animation-name: shiningButtonAfter;
              animation-duration: $animationDuration;
              animation-iteration-count: infinite;
            }

            &:hover {
              > .hoverBg {
                opacity: 1;
              }
            }
          }
        }

        > .mainTopMenu {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 26px;

          &.isNotKorean {
            width: fit-content;
          }

          &.hide {
            display: none;
          }

          > .menuItem {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;

            > .anchor {
              width: 100%;
              height: 32px;

              font-size: 2rem;
              color: $subtextColor;
              line-height: 1.6;
              letter-spacing: -1px;
              font-weight: normal;
              position: relative;

              display: flex;
              justify-content: center;
              align-items: center;

              $transitionTime: 0.2s;

              > .normalText {
                transition: all $transitionTime ease-in-out;
                opacity: 1;
              }

              > .activeText {
                width: 100%;
                text-align: center;
                transition: all $transitionTime ease-in-out;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                @include activeMenu;

                &.notKO {
                  letter-spacing: -1.5px;
                }
              }
            }

            &:hover,
            &.active {
              > .anchor {
                > .normalText {
                  opacity: 0;
                }

                > .activeText {
                  opacity: 1;
                }
              }
            }

            &.parentWish {
              &.ko {
                width: 107px;
              }

              &.ru {
                width: 238px;
              }

              &.en {
                width: 153px;
              }
            }

            &.aboutKpass {
              &.ko {
                width: 112px;
              }

              &.ru {
                width: 196px;
              }

              &.en {
                width: 173px;
              }
            }

            &.competitiveness {
              &.ko {
                width: 70px;
              }

              &.ru {
                width: 250px;
              }

              &.en {
                width: 175px;
              }
            }

            &.realReview {
              &.ko {
                width: 90px;
              }

              &.ru {
                width: 199px;
              }

              &.en {
                width: 145px;
              }
            }
          }

          > .hamburger {
            > a {
              display: block;
              width: 40px;
              height: 40px;

              > img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    > .drawerArea {
      > .drawerBg {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        z-index: $zIndexDrawerBg;

        &.show {
          display: block;
        }
      }

      $drawerWidth: #{"max(" + $drawerMenuWidth + ", 636px)"};

      > .drawer {
        width: $drawerWidth;
        height: 100vh;
        padding: 50px 100px 50px 80px;
        position: fixed;
        background-color: white;
        top: 0;
        right: 0;
        transition: all 0.4s ease-in-out;
        transform: translateX($drawerWidth);
        z-index: $zIndexDrawer;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;

        &.show {
          transform: translateX(0);
        }

        > .closeBtn {
          width: 40px;
          height: 40px;
          align-self: flex-end;
        }

        .logoutBtn {
          //position: absolute;
          //height: fit-content;
          //right: 20px;
          //bottom: -128px;
          display: flex;
          align-self: flex-end;
          align-items: center;

          > .text {
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: -0.24px;
            color: $navyMedium;
          }

          > img {
            width: 20px;
          }
        }

        .beforeLogin {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;

          > .loginBtn {
            padding: 12px 30px;
            border: 2px solid $mainColor;
            border-top-right-radius: 31px;
            border-bottom-right-radius: 31px;
            border-bottom-left-radius: 31px;
            display: flex;
            align-items: center;
            justify-content: center;

            > .btnText {
              padding-top: 2px;
              color: $mainColor;
              font-size: 2.4rem;
              line-height: 1.6;
              letter-spacing: -1.2px;
            }

            > img {
              width: 38px;
            }
          }

          > .desc {
            font-size: 2rem;
            line-height: 1.6;
            letter-spacing: -1px;
            color: $navyMedium;
          }
        }

        .afterLogin {
          display: flex;
          flex-direction: column;
          gap: 10px;

          > .welcome {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            > .text {
              padding: 12px 30px;
              height: 100%;
              background-color: $KPNavy;
              font-size: 2.4rem;
              letter-spacing: -1.2px;
              line-height: 1.6;
              color: white;
              border-top-right-radius: 31px;
              border-bottom-right-radius: 31px;
              border-bottom-left-radius: 31px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              > .accent {
                font-weight: bold;
              }
            }
          }

          > .desc {
            font-size: 2rem;
            font-weight: normal;
            line-height: 1.6;
            padding: 12px 20px;
            letter-spacing: -1px;
            color: $navyMedium;

            > .accent {
              font-weight: bold;
            }
          }
        }

        > .navWrapper {
          margin-top: 10px;
          width: 100%;
          height: 100%;
          overflow-y: auto;

          display: flex;
          flex-direction: column;
          justify-content: safe center;

          > .nav {
            > .navList {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              &.ru {
                gap: 30px;
              }

              > .menu {
                font-weight: normal;
                font-size: 2rem;
                line-height: 1.5;
                letter-spacing: -0.3px;
                @include newLine;

                > a {
                  padding: 16px 20px;
                  color: $navyMedium;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  font-weight: normal;
                  position: relative;
                  width: fit-content;

                  $transitionTime: 0.2s;
                  $iconWidth: 30px;
                  $paddingTB: 16px;
                  $paddingLR: 20px;

                  > .normalIcon {
                    transition: all $transitionTime ease-in-out;
                    position: absolute;
                    left: $paddingLR;
                    top: $paddingTB;
                    opacity: 1;
                  }

                  > .activeIcon {
                    transition: all $transitionTime ease-in-out;
                    position: absolute;
                    left: $paddingLR;
                    top: $paddingTB;
                    opacity: 0;
                  }

                  > .menuName {
                    font-weight: normal;
                    opacity: 1;
                    transition: all $transitionTime ease-in-out;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  > .activeMenuName {
                    display: flex;
                    transition: all $transitionTime ease-in-out;
                    opacity: 0;
                    font-weight: bold;
                    background-color: white;
                    position: absolute;
                    left: $paddingLR;
                    top: $paddingTB;

                    &.notKO {
                      letter-spacing: -0.8px;
                    }
                  }

                  &.withIcon {
                    padding-left: calc(#{$iconWidth} + #{$paddingLR});

                    > .activeMenuName {
                      left: calc(#{$iconWidth} + #{$paddingLR});
                    }
                  }

                  &:hover,
                  &.active {
                    > .normalIcon {
                      opacity: 0;
                    }

                    > .activeIcon {
                      opacity: 1;
                    }

                    > .menuName {
                      //display: none;
                      opacity: 0;
                      //font-weight: bold;
                    }

                    > .activeMenuName {
                      //display: flex;
                      opacity: 1;
                    }
                  }

                  &.purchasePage {
                    color: $hotpink;

                    > .discountBadge {
                      padding: 0 8px;
                      background-color: $hotpink;
                      color: white;
                      font-weight: bold;
                      font-size: 1.6rem;
                      line-height: 1.5;
                      letter-spacing: -0.24px;

                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 100px;
                      margin-left: 10px;
                    }
                  }

                  &.downloadPage {
                    color: $mainColor;
                  }

                  &.partnership {
                    color: $KPNavy;
                  }

                  &.partnerlist {
                    color: $navyMedium;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .headerBanner {
    height: 424px;
    overflow: hidden;
  }

  .brandBannerBox{
    background: url("../../images/header/background_frame.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .brandBannerFrame {
    cursor: pointer;
    position: relative;
    padding: 0 220px;
    z-index: 2;
    width: 100%;
    max-width: 1960px;
    margin-right: auto;
    margin-left: auto;

  }

  .backgroundWrapper {
    position: fixed;
    top: 50px;
    left: 0;
    min-width: 1960px;
    height: 0;
    z-index: 3;
  }

  .backgroundUpperWrapper{
    display: flex;
    flex-direction: column;
  }

  // 비노출
  .brandBackground1 {
    display: none;
    width: 654px;
    height: 510px;
    position: absolute;
    right: 260px;
    top: -344px;
    //background: url("../../images/header/brandBackground1.svg");
  }

  // 비노출
  .brandBackground2 {
    display: none;
    width: 251.549px;
    height: 250px;
    position: absolute;
    left: 214px;
    top: 0;
    //background: url("../../images/header/brandBackground2.svg");
  }


  .bannerContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .brandTrophyContainer {
    display: flex;
    z-index: 4;
    width: 250px;
    height: 250px;
    padding: 42px 50px 41.032px 50px;
    justify-content: center;
    align-items: center;
  }

  .brandTrophy {
    width: 150px;
    height: 166.968px;
    z-index: 3;

    background: url("../../images/header/award_logo.svg");
  }

  .brandDescContainer {
    display: flex;
    height: 250px;
    align-items: center;
    flex: 1 0 0;
  }

  .brandDescWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    flex: 1 0 0;
  }

  .brandDescTitle {
    color: var(--GrayScale-white, #FFF);
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    align-self: stretch;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.3px;
    white-space: pre-line;

    > .bracket {
      color: var(--FeelGood-FGorange-500_main, #002978);
      font-weight: 700;
    }

    > .bold {
      color: var(--GrayScale-white, #FFF);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: -0.36px;
    }
  }

  .notTodayContainer {
    cursor: pointer;
    display: flex;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    > .xIcon {
      display: flex;
      width: 21px;
      height: 21px;
      padding: 6px;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background: url("../../images/header/icon_X.svg");
    }

    > .notTodayText {
      color: var(--K-PASS-KPnavy-100, #E5E5EF);
      text-align: center;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }
  }
}

.searchIcon {
  z-index: 10;
}

.simpleTestMenu {
  color: var(--K-PASS-KPblue-500_main, #583be2);
}

.noGap {
  gap: 0px !important;
}

.freeProviding {
  margin-left: 10px;

  display: flex;
  padding: 0px 8px;
  align-items: center;

  border-radius: 100px;
  background: var(--K-PASS-KPpink-600, #e60063);

  color: var(--GrayScale-white, #fff);

  /* 💻Desktop/Caption/C_Bold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.24px;
}
