$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@mixin solidCircle($width, $height) {
  z-index: $zIndexBgItem;
  position: absolute;
  width: $width;
  height: $height;
  border: 5px solid $lightHeavyMainColor;
  border-radius: 50%;
}

@mixin dashedCircle($width, $height) {
  z-index: $zIndexBgItem2;
  position: absolute;
  width: $width;
  height: $height;
  border: 5px dashed #EDEDED;
  border-radius: 50%;
}

@include mobile {
  .understandingKpass {
    width: 100%;
    display: flex;
    flex-direction: column;

    > .descriptionArticle {
      width: 100%;
      display: flex;
      flex-direction: column;

      > .phrase1 {
        padding: 70px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          @include newLine;
        }

        > .desc {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;

          line-height: 1.6;
          letter-spacing: -0.05em;
          text-align: center;

          > .accent {
            @include wordBreak;
            font-weight: bold;
            font-size: 16px;
            color: $fontHeavy;
          }

          > .normal {
            font-weight: normal;
            font-size: 12px;
            color: $g2757575;
          }
        }
      }

      > .phrase2 {
        display: flex;
        flex-direction: column;
        gap: 70px;
        padding: 0 30px;
        margin-bottom: 70px;

        > .descArea {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;

          > .image {
            width: 264px;
            height: 264px;

            > img {
              width: 100%;
            }
          }

          > .text {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            > .titleArea {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: center;

              &.ru {
                flex-direction: column;
                margin-bottom: 10px;
              }

              > .title {
                font-weight: bold;
                font-size: 2.4rem;
                line-height: 1.6;
                letter-spacing: -0.05em;
                color: $fontHeavy;
                display: inline-block;
              }

              > .titleDesc {
                font-weight: normal;
                font-size: 1.2rem;
                line-height: 1.6;
                color: $fontLight;
              }
            }

            > .descList {
              font-weight: normal;
              font-size: 1.2rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $fontLight;
              margin-left: 1.2rem;

              > li {
                list-style: disc;
                @include newLine;
              }
            }

            > .conclusion {
              margin-top: 10px;
              display: flex;

              > .arrowArea {
                width: 20px;
                height: 20px;
                -webkit-mask: url("../../images/sub/understanding/right_arrow_mobile.svg") no-repeat center;
                mask: url("../../images/sub/understanding/right_arrow_mobile.svg") no-repeat center;
                mask-size: 20px;
                flex-shrink: 0;

                &.simArrow {
                  background-color: $simDescColor;
                }

                &.liArrow {
                  background-color: $mainColor;
                }
              }

              > .text {
                font-weight: bold;
                font-size: 1.2rem;
                line-height: 1.5;
                letter-spacing: -0.05em;
                color: $fontHeavy;
                @include wordBreak;
              }
            }
          }
        }
      }

      > .phrase3 {
        > .area1 {
          background-image: url("../../images/sub/understanding/kpass/big_wave_bg_mobile.svg");
          background-repeat: repeat-x;
          background-position-x: center;
          padding: 100px 30px 160px 30px;

          &.ru {
            height: fit-content;
            background-size: cover;
          }

          > .content {

            > .text {
              font-size: 1.2rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $fontLight;
              font-weight: normal;
              @include newLine;

              &.ru {
                padding-top: 250px;
                font-size: 1.8rem;
                line-height: 1.6;
              }

              > .accent {
                font-weight: bold;
                color: $fontHeavy;
                border-bottom: 1px solid $fontHeavy;
              }
            }
          }
        }

        > .area2 {
          padding: 60px 30px 130px 30px;
          background-image: url("../../images/sub/understanding/kpass/small_wave_bg_mobile.svg");
          background-repeat: repeat-x;
          background-size: auto 100%;
          margin-top: -100px;
          position: relative;

          &.ru {
          }

          &.backgroundArea {
            position: absolute;
            width: 100px;
            height: 100px;
            top: 0;
            left: 0;
            background-color: beige;
          }

          > .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 60px;

            &.ru {
            }

            > .image {
              width: 280px;
              > img {
                width: 100%;
              }
            }

            > .text {
              color: white;
              font-size: 1.6rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              font-weight: normal;
              @include newLine;

              &.ru {
                font-size: 1.6rem;
                max-width: 50%;
              }

              > .accent {
                font-weight: bold;
              }
            }
          }
        }
      }

      > .phrase4 {
        width: 100%;
        position: relative;
        padding: 130px 30px;

        > .bgItem1 {
          $diameter: 139.28px;
          width: $diameter;
          height: $diameter;
          position: absolute;
          top: 58.24px;
          left: -37px;
          border: 2px solid $lightHeavyMainColor;
          border-radius: 50%;
        }

        > .bgItem2 {
          $diameter: 76.93px;
          width: $diameter;
          height: $diameter;
          position: absolute;
          top: 23px;
          left: 49.07px;
          border: 2px dashed #EDEDED;
          border-radius: 50%;
        }

        > .bgItem3 {
          $diameter: 41.43px;
          width: $diameter;
          height: $diameter;
          position: absolute;
          right: 0;
          bottom: 80.57px;
          border: 2px solid $lightHeavyMainColor;
          border-radius: 50%;
        }

        > .bgItem4 {
          $diameter: 60.56px;
          width: $diameter;
          height: $diameter;
          position: absolute;
          right: 20.45px;
          bottom: 61.44px;
          border: 2px dashed #EDEDED;
          border-radius: 50%;
        }

        > .contentWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 70px;

          > .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;

            > .desc {
              text-align: center;
              font-weight: normal;
              font-size: 1.2rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $fontLight;
              @include wordBreak;
            }
          }
        }
      }
    }

    > .whyNeedArticle {
      background-color: $lightMainColor;
      width: 100%;
      padding: 130px 20px;

      > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .title {
          padding: 6px 20px;
          @include newLine;
        }

        > .summary {
          padding: 0 10px;
          margin-top: 30px;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $fontHeavy;
          @include wordBreak;
        }

        > .desc {
          margin-top: 4px;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $fontLight;
          @include wordBreak;
        }

        > .descImages {
          margin-top: 60px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 12px;
          grid-row-gap: 30px;

          @include miniMobile {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
          }

          > .reason {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            > img {
              width: 150px;
              height: 150px;
            }

            > .caption {
              font-weight: bold;
              font-size: 1.2rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $fontHeavy;
            }
          }
        }
      }
    }

    > .resultArticle {
      width: 100%;
      position: relative;
      padding: 130px 20px 100px 20px;
      display: flex;
      flex-direction: column;

      &.ru {
      }

      > .text {
        display: flex;
        flex-direction: column;

        > .summary {
          padding: 0 10px;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $fontHeavy;
          @include newLine;

          > .accent {
            font-size: 2.4rem;
            color: $mainColor;
          }
        }

        > .desc {
          padding: 0 10px;
          margin-top: 30px;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $fontLight;
          @include wordBreak;
        }
      }

      > .image {
        margin-top: 60px;
        width: 100%;

        > img {
          width: 100%;
          max-width: 320px;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}

@include tablet {

  .understandingKpass {
    width: 100%;
    display: flex;
    flex-direction: column;

    > .descriptionArticle {
      width: 100%;
      display: flex;
      flex-direction: column;

      > .phrase1 {
        padding: {
          top: 100px;
          bottom: 74px;
        };
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          padding: 12px 40px;
          width: fit-content;
        }

        > .desc {
          @include newLine;
          margin-top: 30px;
          font-size: 1.6rem;
          line-height: 1.5;
          letter-spacing: -0.024em;
          font-weight: normal;
          color: $KPNavy;
          text-align: center;

          > .accent {
            font-weight: bold;
          }
        }
      }

      > .phrase2 {
        @include tabletWidth;
        display: flex;
        flex-direction: column;
        gap: 20px;

        > .descArea {
          display: flex;
          width: 768px;
          padding: 20px;

          justify-content: center;
          align-items: center;
          gap: 20px;

          > .image {
            padding: 20px;

            > img {
              width: 264px;
              height: 264px;
            }
          }

          > .text {
            display: flex;
            padding: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;

            > .title {
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%;
              letter-spacing: -0.36px;
              color: var(--K-PASS-KPnavy-500_main, #383649);
              text-align: center;
            }

            > .titleDesc {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.21px;
              color: var(--GrayScale-G-500_subtext, #6B6B6B);
              text-align: center;
            }

            > .descList {
              @include wordBreak;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.24px;
              color: $g2757575;
              padding: 16px 0 16px 20px;

              > li {
                list-style: disc;
              }
            }

            > .conclusion {
              display: flex;

              > .arrowArea {
                width: 20px;
                height: 20px;
                -webkit-mask: url("../../images/sub/understanding/right_arrow.svg") no-repeat center;
                mask: url("../../images/sub/understanding/right_arrow.svg") no-repeat center;
                margin-right: 10px;

                &.simArrow {
                  background-color: $simDescColor;
                }

                &.liArrow {
                  background-color: $mainColor;
                }
              }

              > .text {
                width: 344px;
                @include newLine;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 24px */
                letter-spacing: -0.24px;
                color: $KPNavy;
              }
            }
          }
        }
      }

      > .phrase3 {
        > .area1 {
          height: 550px;
          background-image: url("../../images/sub/understanding/kpass/big_wave_bg.svg");
          background-repeat: repeat-x;
          background-position-x: center;

          &.ru {
            height: fit-content;
            padding-bottom: 100px;
            background-size: cover;

            > .content {
              gap: 80px;

              > .text {
                padding-top: 230px;
                padding-bottom: 0;
              }
            }
          }

          > .content {
            @include tabletWidth;
            display: flex;
            width: 728px;
            padding: 20px;
            justify-content: center;
            align-items: center;


            > .text {
              @include newLine;
              padding-top: 240px;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.24px;
              color: $g2757575;

              > .accent {
                font-weight: bold;
                color: $KPNavy;
                border-bottom: 2px solid $KPNavy;
              }
            }

            > .image {
              flex: 1;
              position: relative;

              > img {
                position: absolute;
                top: 400px;
                left: 0;
              }
            }
          }
        }

        > .area2 {
          height: 300px;
          background-image: url("../../images/sub/understanding/kpass/small_wave_bg.svg");
          background-repeat: repeat-x;
          margin-top: -33px;

          &.ru {
            > .content {
              padding: {
                left: 60px;
                top: 60px;
              };

              > .text {
                width: 50%;
              }
            }
          }

          > .content {
            display: flex;
            gap: 43px;
            @include tabletWidth;
            padding: {
              left: 60px;
              top: 80px;
            };

            > .text {
              @include newLine;
              width: 354px;
              padding: 20px;
              justify-content: center;
              align-items: center;
              color: white;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.24px;

              > .accent {
                font-weight: bold;
              }
            }

            > .image {
              display: flex;
              flex: 1;
              position: relative;

              > img {
                width: 280px;
                height: 160px;
                top: 194px;
                left: 0;
              }
            }
          }
        }
      }

      > .phrase4 {
        width: 100%;
        height: 660px;
        position: relative;

        > .contentWrapper {
          @include tabletWidth;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: {
            left: 150px;
          };

          > .content {
            display: flex;
            margin-bottom: 40px;
            align-items: center;

            &:last-child {
              margin-bottom: 0;
            }

            > .desc {
              @include newLine;
              width: 410px;
              margin-left: 60px;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              letter-spacing: -0.24px;
              color: $g2757575;
            }
          }
        }

        > .bgItem1 {
          @include solidCircle(805.729px, 1163.021px);
          top: -300px;
          right: calc(50vw + 150px);
        }

        > .bgItem2 {
          @include dashedCircle(805.729px, 1163.021px);
          top: -300px;
          right: calc(50vw + 113px);
        }

        > .bgItemsGroup1 {
          z-index: $zIndexBgItem;
          position: absolute;
          width: 130.491px;
          height: 123.438px;
          top: 110px;
          right: calc(50vw + 330px);

          > .item1 {
            @include solidCircle(74.6613px, 74.6613px);
            left: 0;
            bottom: 0;
          }

          > .item2 {
            @include dashedCircle(144.2238px, 144.2238px);
            right: -40px;
            top: -50px;
          }
        }

        > .bgItemsGroup2 {
          position: absolute;
          width: 93.229px;
          height: 81.771px;
          left: calc(50vw + 470px);
          top: 30px;

          > .item1 {
            @include solidCircle(59px, 59px);
            position: absolute;
            right: 0;
            top: 0;
          }

          > .item2 {
            @include dashedCircle(91px, 91px);
            position: absolute;
            left: -20px;
            bottom: -30px;
          }
        }

        > .bgItemsGroup3 {
          position: absolute;
          width: 481px;
          height: 515px;
          left: calc(50vw + 358px);
          bottom: -72px;

          > .item1 {
            @include solidCircle(214.063px, 214.063px);
            position: absolute;
            left: 0;
            bottom: 0;
          }

          > .item2 {
            @include dashedCircle(118.229px, 118.229px);
            position: absolute;
            right: 240px;
            top: 240px;
            stroke-width: 2.604px;
          }
        }
      }
    }

    > .whyNeedArticle {
      background-color: $lightMainColor;
      width: 100%;
      min-height: 732px;

      > .content {
        @include tabletWidth;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: {
          top: 150px;
          bottom: 100px;
        };
        text-align: center;

        .title {
          width: 352.5px;

          &.ru {
            width: fit-content;
            padding: 12px 40px;
          }
        }

        > .summary {

          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 25.6px */
          letter-spacing: -0.8px;
          margin-top: 30px;
          color: $KPNavy;
          @include newLine;
        }

        > .desc {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: -0.8px;
          color: $g2757575;
          @include newLine;
        }

        > .descImages {
          width: 768px;
          margin-top: 60px;
          padding: 0 20px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: 1fr;
          gap: 20px;

          > .reason {
            display: flex;
            flex-direction: column;
            width: 150px;
            gap: 12px;

            > .caption {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              letter-spacing: -0.24px;
              color: $KPNavy;
            }
          }
        }
      }
    }

    > .resultArticle {
      width: 100%;
      min-height: 914px;
      position: relative;

      > .text {
        @include tabletWidth;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.3px;
        margin-top: 150px;

        > .summary {
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;

          > .accent {
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            letter-spacing: -0.54px;
            color: $mainColor;

            &.ru {
              font-size: 3.5rem;
            }
          }
        }

        > .desc {
          margin-top: 30px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.24px;
          color: $g2757575;
          @include newLine;

          &.ru {
            width: 50%;
            z-index: $zIndexContent;
          }
        }
      }

      > .image {
        width: 539px;
        height: 340px;
        flex-shrink: 0;
        position: absolute;
        bottom: 100px;
        left: calc(50vw - 250px);
        background-image: url("../../images/sub/understanding/kpass/bottom_pic.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;


        &.ru {
          width: 539px;
          height: 340px;
          position: relative;
          margin-bottom: 100px;
          bottom: 0;
          background-size: cover;
          background-position: bottom;
        }
      }
    }
  }
}

@include desktop {

  .understandingKpass {
    width: 100%;
    display: flex;
    flex-direction: column;

    > .descriptionArticle {
      width: 100%;
      display: flex;
      flex-direction: column;

      > .phrase1 {
        padding: {
          top: 150px;
          bottom: 100px;
        };
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {

          padding: 12px 40px;
          width: fit-content;
        }

        > .desc {
          @include newLine;
          margin-top: 50px;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          font-weight: normal;
          color: $KPNavy;
          text-align: center;

          > .accent {
            font-weight: bold;
          }
        }
      }

      > .phrase2 {
        @include desktopWidth;
        display: flex;
        flex-direction: column;
        gap: 70px;
        padding: {
          left: 100px;
          right: 59px;
          bottom: 150px;
        };

        > .descArea {
          display: flex;
          gap: 80px;

          > .image {
            flex: 1;
            width: 480px;
            height: 480px;

            > img {
              width: 480px;
              height: 480px;
            }
          }

          > .text {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            > .title {
              font-weight: bold;
              font-size: 4rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              color: $KPNavy;
            }

            > .titleDesc {
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.8;
              color: $g2757575;
            }

            > .descList {
              @include wordBreak;
              width: 560px;
              margin: {
                top: 40px;
                left: 2rem;
              };
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.8;
              letter-spacing: -0.05em;
              color: $g2757575;

              > li {
                list-style: disc;
              }
            }

            > .conclusion {
              margin-top: 30px;
              display: flex;

              > .arrowArea {
                width: 36px;
                height: 36px;
                -webkit-mask: url("../../images/sub/understanding/right_arrow.svg") no-repeat center;
                mask: url("../../images/sub/understanding/right_arrow.svg") no-repeat center;
                margin-right: 4px;

                &.simArrow {
                  background-color: $simDescColor;
                }

                &.liArrow {
                  background-color: $mainColor;
                }
              }

              > .text {
                width: 520px;
                @include newLine;
                font-weight: bold;
                font-size: 2rem;
                line-height: 1.8;
                letter-spacing: -0.05em;
                color: $KPNavy;
              }
            }
          }
        }
      }

      > .phrase3 {
        > .area1 {
          height: 758px;
          background-image: url("../../images/sub/understanding/kpass/big_wave_bg.svg");
          background-repeat: repeat-x;
          background-position-x: center;

          &.ru {
            height: fit-content;
            padding: 100px 0;
            background-size: cover;

            > .content {
              gap: 80px;

              > .text {
                padding-top: 230px;
                padding-bottom: 0;
              }
            }
          }

          > .content {
            @include desktopWidth;
            padding: {
              left: 60px;
            };
            display: flex;
            gap: 165px;

            > .text {
              flex: 1;
              @include newLine;
              padding-top: 328px;
              font-size: 2rem;
              line-height: 1.8;
              letter-spacing: -0.05em;
              color: $g2757575;
              font-weight: normal;

              > .accent {
                font-weight: bold;
                color: $KPNavy;
                border-bottom: 2px solid $KPNavy;
              }
            }

            > .image {
              flex: 1;
              position: relative;

              > img {
                position: absolute;
                top: 194px;
                left: 0;
              }
            }
          }
        }

        > .area2 {
          height: 352px;
          background-image: url("../../images/sub/understanding/kpass/small_wave_bg.svg");
          background-repeat: repeat-x;
          margin-top: -33px;

          &.ru {
            > .content {
              padding: {
                left: 60px;
                top: 60px;
              };

              > .text {
                width: 50%;
              }
            }
          }

          > .content {
            @include desktopWidth;
            padding: {
              left: 60px;
              top: 100px;
            };

            > .text {
              @include newLine;
              color: white;
              font-size: 2.4rem;
              line-height: 1.6;
              letter-spacing: -0.05em;
              font-weight: normal;

              > .accent {
                font-weight: bold;
              }
            }
          }
        }
      }

      > .phrase4 {
        width: 100%;
        height: 1268px;
        position: relative;

        > .contentWrapper {
          @include desktopWidth;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: {
            left: 150px;
          };

          > .content {
            display: flex;
            margin-bottom: 80px;
            align-items: center;

            &:last-child {
              margin-bottom: 0;
            }

            > .desc {
              @include newLine;
              margin-left: 60px;
              font-weight: normal;
              font-size: 2rem;
              line-height: 1.8;
              letter-spacing: -0.05em;
              color: $g2757575;
            }
          }
        }

        > .bgItem1 {
          @include solidCircle(1547px, 2233px);
          top: -483px;
          right: calc(50vw + 363px);
        }

        > .bgItem2 {
          @include dashedCircle(1547px, 2233px);
          top: -483px;
          right: calc(50vw + 303px);
        }

        > .bgItemsGroup1 {
          z-index: $zIndexBgItem;
          position: absolute;
          width: 250.54px;
          height: 237px;
          top: 126px;
          right: calc(50vw + 600px);

          > .item1 {
            @include solidCircle(116.81px, 116.81px);
            left: 0;
            bottom: 0;
          }

          > .item2 {
            @include dashedCircle(192.14px, 192.14px);
            right: 0;
            top: 0;
          }
        }

        > .bgItemsGroup2 {
          position: absolute;
          width: 179px;
          height: 157px;
          left: calc(50vw + 622px);
          top: 87px;

          > .item1 {
            @include solidCircle(91px, 91px);
            position: absolute;
            right: 0;
            top: 0;
          }

          > .item2 {
            @include dashedCircle(133px, 133px);
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }

        > .bgItemsGroup3 {
          position: absolute;
          width: 481px;
          height: 515px;
          left: calc(50vw + 358px);
          bottom: -72px;

          > .item1 {
            @include solidCircle(411px, 411px);
            position: absolute;
            left: 0;
            bottom: 0;
          }

          > .item2 {
            @include dashedCircle(227px, 227px);
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }

    > .whyNeedArticle {
      background-color: $lightMainColor;
      width: 100%;
      height: 1338px;

      > .content {
        @include desktopWidth;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: {
          top: 300px;
        };
        text-align: center;

        .title {
          width: 545.5px;

          &.ru {
            width: fit-content;
            padding: 12px 40px;
          }
        }

        > .summary {
          margin-top: 70px;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;
          @include newLine;
        }

        > .desc {
          margin-top: 30px;
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include newLine;
        }

        > .descImages {
          margin-top: 80px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: 1fr;
          gap: 30px;

          > .reason {
            display: flex;
            flex-direction: column;

            > .caption {
              margin-top: 20px;
              font-weight: bold;
              font-size: 2rem;
              line-height: 1.8;
              letter-spacing: -0.05em;
              color: $KPNavy;
              padding: 0 30px;
            }
          }
        }
      }
    }

    > .resultArticle {
      width: 100%;
      height: 914px;
      position: relative;

      > .text {
        @include desktopWidth;
        padding-left: 60px;
        display: flex;
        flex-direction: column;
        margin-top: 150px;

        > .summary {
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $KPNavy;

          > .accent {
            font-size: 4rem;
            color: $mainColor;

            &.ru {
              font-size: 3.5rem;
            }
          }
        }

        > .desc {
          margin-top: 50px;
          font-weight: normal;
          font-size: 1.8rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $g2757575;
          @include newLine;

          &.ru {
            width: 50%;
            z-index: $zIndexContent;
          }
        }
      }

      > .image {
        width: 944px;
        height: 596px;
        position: absolute;
        bottom: 0;
        left: calc(50vw - 184px);
        background-image: url("../../images/sub/understanding/kpass/bottom_pic.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &.ru {
          height: 800px;
          background-size: 944px;
          background-position: bottom;
        }
      }
    }
  }
}

