$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@import "../variables";

@include mobile {
  .section2 {

    > .expectation {
      background-image: url("../../images/main_legacy/section2/bg_mobile.png");
      background-size: 100vw 100%;
      background-repeat: repeat-x;
      position: relative;

      > .bgItem1 {
        position: absolute;
        top: 34px;
        width: 150.67px;
        height: 128px;
        left: calc(50vw - 204px);
        z-index: $zIndexBgItemOnBg;

        > img {
          width: 100%;
        }
      }

      > .bgItem2 {
        position: absolute;
        top: 0;
        left: calc(50vw + 113px);
        width: 47px;
        height: 136px;
        z-index: $zIndexBgItemOnBg;

        > img {
          width: 100%;
        }
      }

      > .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 130px 0;
        z-index: $zIndexContent;

        > h3 {
          max-width: 100%;
          padding: 0 30px;
        }

        .title {
          padding: 6px 20px;
          background-color: white;
          color: $fontHeavy;

          &.ru {
            width: fit-content;
          }
        }

        > .description {
          padding: 0 30px;
          font-weight: normal;
          font-size: 1.2rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: white;
          margin-top: 30px;
          text-align: center;
          @include newLine;
        }

        > .effects {
          margin-top: 30px;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: repeat(2, 1fr);

          > .item {
            padding: 10px;
            max-width: 101px;
            height: 128px;
            border: 2px solid #4D4970;
            border-radius: 8px;
            margin: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #313656;

            &.narrowPadding {
              padding: 10px 4px;
            }

            &.ru {
              height: auto;
              padding: 12px;
              justify-content: flex-start;
            }

            > img {
              width: 60px;
              height: 60px;
            }

            > .text {
              text-align: center;
              color: white;
              font-size: 1.2rem;
              font-weight: normal;
              line-height: 1.3;
              margin-top: 4px;
              @include wordBreak;

              &.wideMarginTop {
                margin-top: 12px;
              }

              &.ru {
                font-size: 1rem;
              }
            }

            &:nth-child(1) {
              grid-column: 1 / 3;
              grid-row: 1 / 2;
              margin-top: 0;
            }

            &:nth-child(2) {
              grid-column: 3 / 5;
              grid-row: 1 / 2;
              margin-top: 0;
            }

            &:nth-child(3) {
              grid-column: 5 / 7;
              grid-row: 1 / 2;
              margin-top: 0;
            }

            &:nth-child(4) {
              grid-column: 2 / 4;
              grid-row: 2 / 3;
              margin-bottom: 0;
            }

            &:nth-child(5) {
              grid-column: 4 / 6;
              grid-row: 2 / 3;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@include desktop {

  .section2 {

    > .expectation {
      background: url("../../images/main_legacy/section2/bg.png") repeat;
      position: relative;

      > .bgItem1 {
        position: absolute;
        top: 91px;
        width: 319px;
        height: 271px;
        left: calc(50vw - 734px);
      }

      > .bgItem2 {
        position: absolute;
        top: 0;
        left: calc(50vw + 467px);
      }

      > .content {
        display: flex;
        flex-direction: column;
        @include desktopWidth;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
        padding: {
          top: 300px;
          bottom: 300px;
        };

        .title {
          width: 360px;
          background-color: white;
          color: $KPNavy;

          &.ru {
            width: fit-content;
            padding: 12px 40px;
          }
        }

        > .description {
          @include newLine;
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: white;
          margin-top: 70px;
          text-align: center;
        }

        > .effects {
          margin-top: 100px;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: repeat(2, 1fr);
          @include showBottomToTop(0.5s, 0, 10%);

          > .item {
            width: 344px;
            height: 365px;
            border: 2px solid #4D4970;
            border-radius: 60px;
            margin: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #313656;

            &.ru {
              padding: 10px;

              > .text {
                font-size: 2.4rem;
              }
            }

            > .text {
              color: white;
              font-size: 3.2rem;
              font-weight: normal;
              line-height: 1.6;
              margin-top: 45px;
              text-align: center;
              @include newLine;

              &.narrowMarginTop {
                margin-top: 29px;
              }
            }

            &:nth-child(1) {
              grid-column: 1 / 3;
              grid-row: 1 / 2;
              margin-top: 0;
            }

            &:nth-child(2) {
              grid-column: 3 / 5;
              grid-row: 1 / 2;
              margin-top: 0;
            }

            &:nth-child(3) {
              grid-column: 5 / 7;
              grid-row: 1 / 2;
              margin-top: 0;
            }

            &:nth-child(4) {
              grid-column: 2 / 4;
              grid-row: 2 / 3;
              margin-bottom: 0;
            }

            &:nth-child(5) {
              grid-column: 4 / 6;
              grid-row: 2 / 3;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
