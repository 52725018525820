$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
@include mobile {
  .fromLeft {
    .bannerImage {
      > img {
        @include showTranslateX(0.8s, 0s, -50px);
      }
    }
  }

  .fromRight {
    .bannerImage {
      > img {
        @include showTranslateX(0.8s, 0s, 50px);
      }
    }
  }

  .bannerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    > .bannerImage {
      width: 300px;
      height: 300px;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .textWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include wordBreak;
    @include showBottomToTop(0.5s, 0.3s, 30px);

    &.ko {
      gap: 10px;
    }

    &.ru, &.en {
      justify-content: space-between;
    }

    &.hide {
      transition: all 0s;
      transition-delay: 0s;
    }

    > .slogan {
      font-weight: normal;
      font-size: 24px;
      line-height: 1.4;
      color: $fontHeavy;
      @include newLine;

      > .strong {
        font-size: 28px;
        font-weight: bold;
      }
    }

    > .subtext {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.4;
      color: $fontHeavy;

      > .point {
        font-weight: bold;
        color: $mainColor;
      }
    }
  }

  .downloadTestButtonWrapper {
    margin-top: 30px;
  }

}

@include tablet {
  .fromLeft {
    .bannerImage {
      > img {
        @include showTranslateX(0.8s, 0s, -50px);
      }
    }
  }

  .fromRight {
    .bannerImage {
      > img {
        @include showTranslateX(0.8s, 0s, 50px);
      }
    }
  }

  .bannerWrapper {
    width: 768px;
    height: 100%;
    padding: 0 20px;
    gap: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > .bannerImage {
      width: 300px;

      > img {
        width: 100%;
      }
    }
  }

  .textWrapper {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include showOpacityToOne(0.8s, 0.5s);

    &.ru {
      justify-content: space-between;
      gap: 30px;
    }

    &.ko, &.en {
      gap: 30px;
      justify-content: center;
    }

    > .slogan {
      width: 300px;
      white-space: break-spaces;
      font-weight: normal;
      font-size: 24px;
      line-height: 140%;
      letter-spacing: -0.36px;
      color: $fontHeavy;
      align-self: stretch;

      @include newLine;

      > .strong {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.42px;
      }
    }

    > .subtext {
      white-space: break-spaces;
      font-weight: normal;
      font-size: 24px;
      line-height: 140%;
      letter-spacing: -0.36px;
      color: $fontHeavy;

      > .point {
        font-weight: bold;
        color: $mainColor;
      }
    }
  }

  .downloadTestButtonWrapper {
    //margin-top: 37px;
  }
}

@include desktop {
  .fromLeft {
    .bannerImage {
      > img {
        @include showTranslateX(0.8s, 0s, -50px);
      }
    }
  }

  .fromRight {
    .bannerImage {
      > img {
        @include showTranslateX(0.8s, 0s, 50px);
      }
    }
  }

  .bannerWrapper {
    width: 1040px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .bannerImage {
      width: 500px;
      height: 500px;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .textWrapper {
    width: 390px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include showOpacityToOne(0.8s, 0.5s);

    &.ru {
      justify-content: space-between;
    }

    &.ko, &.en {
      gap: 30px;
      justify-content: center;
    }

    > .slogan {
      font-weight: normal;
      font-size: 32px;
      line-height: 1.4;
      color: $fontHeavy;

      @include newLine;

      > .strong {
        font-size: 40px;
        font-weight: bold;
      }
    }

    > .subtext {
      white-space: break-spaces;
      font-weight: normal;
      font-size: 24px;
      line-height: 1.4;
      color: $fontHeavy;

      > .point {
        font-weight: bold;
        color: $mainColor;
      }
    }
  }

  .downloadTestButtonWrapper {
    margin-top: 37px;
  }
}