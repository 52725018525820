$headerHeight: 164px;
$headerHeightTablet: 124px;
$headerHeightWOLoginBar: 114px;
$headerHeightTabletWOLoginBar: 80px;
$headerHeightMobile: 120px;
$headerHeightMobileWOLoginBar: 80px;
$headerMaxWidth: 1440px;// 1420px;
$maxWidth: 1440px;
$maxWidthTablet: 768px;
$middleWidth: 1380px;
$footerWidth: 1200px;
$footerWidthTablet: 740px;
$mainLeftPadding: 60px;
$mainColor: #583BE2;
$deepMainColor: #462FB5;
$white: #FFFFFF;
$blueLight: #D6CEFF;
$kpBlueLight: #BCB1F3;
$kpNavyLight: #CDCBDE;
$kpPink: #FFF3F6;
$kpPinkHeavy: #FFDEE6;
$lightMainColor: #F1EFFD;
$lightHeavyMainColor: #E4E1FB;
$brightHeavyPurple: #3C2163;
$g4c6c6c6: #C6C6C6;
$g5dedede: #DEDEDE;
$g2757575: #757575;
$c4c4c4: #c4c4c4;
$e1e1e1: #E1E1E1;
$KPNavy: #383649;
$fontLight: #757575;
$fontHeavy: #383649;
$fontHeavyLight: #484848;
$fontModalAccent: #FFE600;
$fontBodyText: #404040;
$fontGray: #565656;
$subNavBg: #383838;
$subNavText: #A1A1A1;
$footerFontColor: #A7A6B5;
$simDescColor: #FF5A81;
$navyMedium: #76718E;
$navyLight: #A3A0BA;
$hotpink: #E60063;
$lightPink: #FFBDCD;
$lightGray: #F6F6F6;
$itemBgGray: #E5E5EF;
$itemBgBlue: #F6F3FF;
$kpBlue: #7762df;
$kpDeepBlue: #352388;
$c9B: #9B89EE;
$cFFEB38: #FFEB38;
$subtextColor: #6B6B6B;
$modalErrorColor: #FFE606;
$drawerMenuWidth: 33vw;
$drawerMenuWidthMobile: 83vw;
$zIndexBgItem2: -2;
$zIndexBgItem: -1;
$zIndexNormal: 0;
$zIndexBgItemOnBg: 1;
$zIndexContent: 2;
$zIndexOnContent: 3;
$zIndexOverContent: 4;
$zIndexStep5: 5;
$zIndexFooter: 7;
$zIndexTopButton: 8;
$zIndexHeader: 9;
$zIndexModal: 10;
$zIndexDrawerBg: 11;
$zIndexDrawer: 12;
$zIndexPopup: 13;
$zIndexPopupCloseButton: 14;
$zIndexLoader: 15;
$modalAppDownloadWidth: 866px;
$modalAppDownloadHeight: 661px;
$modalAppLoginWidth: 1022px;
$modalAppLoginHeight: 660px;
$breakpoint-desktop: 1440px;
$breakpoint-tablet: $breakpoint-desktop -1px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-galaxy-fold: 321px;

@mixin showScaleTransform($durationVal, $delayVal, $scaleFrom) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: scale($scaleFrom);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showTranslateX($durationVal, $delayVal, $translateXVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateX($translateXVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin showOpacityToOne($durationVal, $delayVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin tabletWidth {
  max-width: $maxWidthTablet;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin hideTransition {
  transition: all 0s;
  transition-delay: 0s;
}

@function getMainColorWithOpacity($opacity) {
  @return rgba(88, 59, 226, $opacity);
}

@function getFontHeavyColorWithOpacity($opacity) {
  @return rgba(56, 54, 73, $opacity);
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.3));
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
}

@mixin subpageTitle($imgPath) {
  @include newLine;
  width: 100%;
  padding: $headerHeight 100px 0 100px;
  height: 510px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    padding: 0 50px;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }

  &.noLoginBar {
    padding-top: $headerHeightWOLoginBar;
  }
}

@mixin subpageTitleTablet($imgPath) {
  @include newLine;
  width: 100%;
  height: 204px;
  padding: 210px 30px 100px 0;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}

@mixin subpageTitleMobile($imgPath) {
  @include newLine;
  width: 100%;
  height: 188px;
  padding: 0 30px;
  background-image: url($imgPath);
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  > .text {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
    @include filterDropshadow(4px, 8px);
  }
}


@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-desktop - 1px}){
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile - 1px}) {
    @content;
  }
}

@mixin miniMobile {
  @media (max-width: #{$breakpoint-mobile-galaxy-fold}) {
    @content;
  }
}

@mixin wordBreak {
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin newLine {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: keep-all;
}

@mixin articleTitleStyle {
  padding: 16px 80px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  width: auto;
}

@mixin articleTitleStyleTablet {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.3px;

  padding: 12px 40px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin articleTitleStyleMobile {
  padding: 12px 40px;
  line-height: 1.4;
  font-size: 20px;
  width: fit-content;
  border-top-left-radius: 0;
  letter-spacing: -0.015em;
  margin: 0 auto;
  @include wordBreak;
}
$placeholderColor: #9B89EE;
$activeBackgroundColor: #352388;

@mixin resetBrowserStyle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance:none;
  outline: none;
  box-shadow: none;
  border: none;
}

@mixin desktopInput {
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @include mobile {
    @include resetBrowserStyle;
    width: 100%;
    background-color: #462FB5;
    border-radius: 10px;
    padding: 12px 20px;
  }

  @include desktop {
    @include resetBrowserStyle;
    width: 100%;
    height: 62px;
    background-color: #462FB5;
    border-radius: 20px;
    padding: 16px 30px;
  }
}

@mixin desktopInputFont {
  @include mobile {
    color: #BCB1F3;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
  }

  @include desktop {
    color: #BCB1F3;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
  }
}

@mixin desktopInputFontSelected {
  font-weight: bold;
  color: white;
}

@include mobile {

  .modalWrapper {
    z-index: $zIndexModal;
    height: 90%;
    width: 100%;
    padding: 100px 0 24px 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);;
    border-radius: 10px;
    background-color: $mainColor;
    max-width: max-content !important;
  }

  .modalTitle {
    padding: 10px 20px !important;

    > span {
      margin-left: 0 !important;
    }
  }

  .modalCloseButton {
    background-color: white;
  }

  .modalContent {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 0 20px;

    display: flex;
    flex-direction: column;
    gap: 60px;

    letter-spacing: -0.015em;

    > .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;

      > .field {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;

        > .invalidMessage {
          margin-top: -8px;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.5;
          color: #FFE606;
        }

        > .label {
          font-weight: bold;
          font-size: 16px;
          line-height: 1.5;
          color: white;

          > .light {
            font-weight: normal;
            color: $blueLight;
          }
        }

        > .genderWrapper {
          display: flex;
          justify-content: flex-start;
          gap: 6px;
          max-width: 266px;
          flex-wrap: wrap;
          align-items: center;

          > label {
            width: 130px;
          }
        }

        .inputText, .inputText:-webkit-autofill {
          @include desktopInput;
          height: 48px;
          @include desktopInputFont;
          color: white;

          &::placeholder {
            @include desktopInputFont;
          }
          &:focus, &.inputted {
            &::placeholder {
              color: $placeholderColor;
              -webkit-text-fill-color: $placeholderColor;
            }

            -webkit-text-fill-color: white;
            -webkit-box-shadow: 0 0 0 24px $activeBackgroundColor inset;

            background-color: $activeBackgroundColor;
            border: none;
          }
        }

        .selector {
          @include desktopInput;
          max-width: 220px;
          background-image: url("../../../../assets/images/modal/event_registerer/selector_selected.svg");
          background-repeat: no-repeat;
          background-position: right 16px center;
          background-size: 24px;

          font-size: 16px;
          line-height: 1.5;
          @include desktopInputFontSelected;

          &.noOption {
            font-weight: normal;
            color: #BCB1F3;
            background-image: url("../../../../assets/images/modal/event_registerer/selector_default.svg");
          }

          &.selected {
            background-color: #352388;
          }
        }

        .checkboxLabel {
          cursor: pointer;
          @include desktopInput;
          @include desktopInputFont;
          padding: 12px 16px;
          display: flex;
          align-items: center;
          gap: 6px;

          &.checked {
            @include desktopInputFontSelected;
            background-color: #352388;
          }

          > input[type="checkbox"] {
            @include resetBrowserStyle;
            width: 24px;
            height: 24px;
            background: url("../../../../assets/images/modal/event_registerer/checkbox_default.svg") center no-repeat;

            &:checked {
              background: url("../../../../assets/images/modal/event_registerer/checkbox_checked.svg") center no-repeat;
            }
          }
        }
      }

      .notice {
        margin-top: -26px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        color: $blueLight;
      }

      .detailNoticeWrapper {
        margin-top: -20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .readDetailButton {
          font-weight: bold;
          font-size: 14px;
          line-height: 1.5;
          color: #FFEB38;
          display: flex;
          justify-content: center;
          gap: 4px;

          > img {
            &.show {
              transform: rotate(180deg);
            }
          }
        }

        .detailNotice {
          width: 100%;
          border-top: 1px dashed #FFF06A;
          border-bottom: 1px dashed #FFF06A;
          padding: 10px 0 10px 1.2em;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.5;
          color: #FFF06A;
          list-style-type: decimal;
          @include wordBreak;

          > li {
            list-style: decimal;

            > .detailList {
              margin-left: 1em;
              list-style-type: "- ";

              > li {
                list-style: "- ";
              }
            }
          }
        }
      }

    }

    > .button {
      flex-shrink: 0;
      align-self: center;

      width: 100%;
      height: 68px;
      background-color: $fontHeavy;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      font-weight: bold;
      font-size: 20px;
      line-height: 1.4;
      color: white;
    }
  }


}


@include desktop {

  .modalWrapper {
    z-index: $zIndexModal;
    height: 80vh;
    width: 800px;
    padding: 174px 0 40px;
    box-shadow: 0 16px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: $mainColor;
  }

  .modalTitle {
    padding: 20px 50px 20px 80px;

    > span {
      margin-left: 0 !important;
    }
  }

  .modalCloseButton {
    background-color: white;
  }

  .modalContent {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 0 80px;

    display: flex;
    flex-direction: column;
    gap: 80px;

    letter-spacing: -0.015em;

    > .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 50px;

      > .field {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;

        > .invalidMessage {
          margin-top: -4px;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          color: #FFE606;
        }

        > .label {
          font-weight: bold;
          font-size: 20px;
          line-height: 1.5;
          color: white;

          > .light {
            font-weight: normal;
            color: $blueLight;
          }
        }

        > .genderWrapper {
          display: flex;
          justify-content: center;
          gap: 10px;
          width: 410px;
        }

        .inputText, .inputText:-webkit-autofill {
          @include desktopInput;
          @include desktopInputFont;
          color: white !important;

          &::placeholder {
            @include desktopInputFont;
          }

          &:focus, &.inputted {
            $placeholderColor: #9B89EE;
            $activeBackgroundColor: #352388;

            color: white;
            &::placeholder {
              color: $placeholderColor;
              -webkit-text-fill-color: $placeholderColor;
            }

            -webkit-text-fill-color: white;
            -webkit-box-shadow: 0 0 0 31px $activeBackgroundColor inset;

            background-color: $activeBackgroundColor;
            border: none;
          }
        }

        .selector {
          @include desktopInput;
          width: 300px !important;
          background-image: url("../../../../assets/images/modal/event_registerer/selector_selected.svg");
          background-repeat: no-repeat;
          background-position: right 30px center;

          font-size: 20px;
          line-height: 1.5;
          @include desktopInputFontSelected;

          &.noOption {
            font-weight: normal;
            color: #BCB1F3;
            background-image: url("../../../../assets/images/modal/event_registerer/selector_default.svg");
          }

          &.selected {
            background-color: #352388;
          }
        }

        .checkboxLabel {
          cursor: pointer;
          @include desktopInput;
          @include desktopInputFont;
          padding: 16px 25px;
          display: flex;
          align-items: center;
          gap: 10px;

          &.checked {
            @include desktopInputFontSelected;
            background-color: #352388;
          }

          > input[type="checkbox"] {
            @include resetBrowserStyle;
            width: 30px;
            height: 30px;
            background: url("../../../../assets/images/modal/event_registerer/checkbox_default.svg") center no-repeat;

            &:checked {
              background: url("../../../../assets/images/modal/event_registerer/checkbox_checked.svg") center no-repeat;
            }
          }
        }
      }

      .notice {
        margin-top: -30px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        color: $blueLight;
      }

      .detailNoticeWrapper {
        margin-top: -30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .readDetailButton {
          font-weight: bold;
          font-size: 16px;
          line-height: 1.5;
          color: #FFEB38;
          display: flex;
          justify-content: center;
          gap: 4px;

          > img {
            &.show {
              transform: rotate(180deg);
            }
          }
        }

        .detailNotice {
          width: 100%;
          border-top: 1px dashed #FFF06A;
          border-bottom: 1px dashed #FFF06A;
          padding: 10px 0 10px 1.2em;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.5;
          color: #FFF06A;
          list-style-type: decimal;
          @include wordBreak;

          > li {
            list-style: decimal;

            > .detailList {
              margin-left: 1em;
              list-style-type: "- ";

              > li {
                list-style: "- ";
              }
            }
          }
        }
      }

    }

    > .button {
      flex-shrink: 0;
      align-self: center;

      width: 422px;
      height: 88px;
      background-color: $fontHeavy;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
      border-radius: 20px;

      font-weight: bold;
      font-size: 24px;
      line-height: 1.4;
      color: white;
    }
  }


}
